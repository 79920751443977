// import {  toast } from "react-toastify";
import { toast, ToastContainer, ToastPosition } from "react-toastify";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { subscribePlan } from "../../../Features/PlansSlice";
import { Loader } from "../RepeatedComponent/Loader";
import { fetchUser } from "../../../Features/fetchUserSlice";

export const PaymentModal = (props:any) => {
  const closeRef:any = useRef(null);
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [loading,setLoading] = useState<any>(false);
  const [email, setEmail] = useState<any>('');
  const [isTermsAccepted, setIsTermsAccepted] = useState<any>(false);
  const [selectedTab, setSelectedTab] = useState("credit");
  const monthlyPlan = props?.selectedPlan;
   
  const  userEmail = useAppSelector((state: any) => state?.user?.userInfo?.user?.email
  );

  console.log(props,"props")

  useEffect(()=>{
    dispatch(fetchUser())
  },[])

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#9e2146"
      }
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTab(event.target.value);
  };


  const handleOnSubmit = async (event: any) => {
    event.preventDefault();
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!stripe || !elements) {
      return;
    }
    // if(!email){
    //   toast.error("Please enter your email", {
    //     position: "top-center",
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     draggablePercent: 8000,
    //     pauseOnFocusLoss: true
    //   });
    //   return;
    // }
    // if(!emailRegex.test(email)){
    //   toast.error("Please enter a valid email", {
    //     position: "top-center",
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     draggablePercent: 8000,
    //     pauseOnFocusLoss: true
    //   });
    //   return;
    // }
    const cardElement:any = elements.getElement(CardElement);
    try {
      const { error, token } = await stripe.createToken(cardElement);
      if (error) {
        console.log(error);
        toast.error(error.message, {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 8000,
          pauseOnFocusLoss: true
          
          
        });
        return;
      } else {
        if(!isTermsAccepted){
          toast.error("Please accept terms and conditions", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 8000,
            pauseOnFocusLoss: true
          });
          return;
        }
        setLoading(1)
        const obj:any = {
          "stripeEmail": email,
          "stripeToken":token.id,
          "planId":monthlyPlan.id, 
          "amount":monthlyPlan.price*100
        }
        
        dispatch(subscribePlan(obj)).then((res: any) => {
          setLoading(0);
          if(res.payload.success){
            toast.success(res.payload.message, {
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 8000,
              pauseOnFocusLoss: true
            });
            props.handlePaymentModal();
            closeRef.current.click();
          } else {
            toast.error("Something went wrong. Please try again", {
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 8000,
              pauseOnFocusLoss: true
            });
           // props.handlePaymentModal(false,monthlyPlan);
          }
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
   
  };

    return (
      <>
      {loading ? <Loader /> : ""}
       
        <form onSubmit={handleOnSubmit}>
          <div
            className="modal fade bd-example-modal-lg"
            role="dialog"
            aria-labelledby="mySmallModalLabel"
            aria-modal="true"
            data-backdrop="static"
            data-bs-backdrop='static'
            data-keyboard="false"
          >
            <div className="modal-dialog modal-lg modal-custom-size">
              <div
                className="modal-content"
                style={{ borderRadius: "1rem" }}
              >
                <div className="modal-header modal-order-summary-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-8 text-left">
                        <h5
                          className="modal-title  modal-order-summary-header-text "
                          id="exampleModalLabel"
                        >
                          Order Summary
                        </h5>
                      </div>
                    </div>
                  </div>
                
                  <img
                    src="images/modal-img/cross-img.svg"
                    className="close custom-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={closeRef}
                  ></img>
                 
                  
                </div>
                <div className="modal-body bg-light ">
                  <div className="container-fluid subscription-pricing">
                    <div className="row text-black">
                      <div className="col-md-6 col-sm-12">
                        {/* DETAILS */}
                        <div className="col-12 custom-card-border px-4 py-3 text-left font-inter mb-3">
                          <div className="header">
                            DETAILS
                          </div>
                          <div className="sub-header">
                            {monthlyPlan.plan_name==="MONTHLY"?"Monthly (1 month)":monthlyPlan.plan_name==="THREE MONTH"?"Quarterly (3 months)":monthlyPlan.plan_name==="ONE YEAR"?"Yearly (12 months)":monthlyPlan.plan_name}
                          </div>
                          <div className="d-flex">
                          <p className="para col-9 p-0 pt-1">
                          Price is for  {monthlyPlan.plan_name==="MONTHLY"?"1 month":monthlyPlan.plan_name==="THREE MONTH"?"3 months":monthlyPlan.plan_name==="ONE YEAR"?"12 months":monthlyPlan.plan_name}.{monthlyPlan.description}
                            </p>
                            <p className="custom-text-color ml-auto">
                            ${monthlyPlan.price}
                            </p>
                          </div>
                          <div className="d-flex"><div className="col-8 px-0"><p className="para">7-day <span  className="custom-text-color ml-auto">FREE</span> Trial Promotion</p></div><div className="d-flex justify-content-end custom-text-color col-4 px-0">(${monthlyPlan.price})</div></div>
                          <p className="sub-para col-9 p-0 pt-2 ">
                            *Cancel Anytime, After your
                            7 day free trial, you will
                            be billed ${monthlyPlan.price} {monthlyPlan.plan_name==="MONTHLY"?"per month":monthlyPlan.plan_name==="THREE MONTH"?"per quarter":monthlyPlan.plan_name==="ONE YEAR"?"per year":monthlyPlan.plan_name}
                          </p>
                          <hr></hr>
                          <p className="custom-text-color ml-auto">
                            Total:  $0.00
                          </p>
                        </div>
                        <div className="col-12 custom-card-border px-4 py-3 text-left font-inter mb-3">
                          <div className="d-flex">
                            <div className="mr-3">
                              <img
                                src="images/modal-img/thumbs-up-black-icon 1.svg"
                                className="sub-header-thumbs-up"
                              />
                            </div>
                            <div className="sub-header font-weight-bold ">
                              We Are Here to Help You
                              <p className="para pt-2">
                                If you have questions
                                with our product, please
                                contact our Customer
                                Care team at
                                <Link
                                  to=""
                                  className="px-1"
                                  style={{
                                    textDecoration:
                                      "none",
                                    borderBottom:
                                      "none",
                                  }}
                                >
                                  Hello@planelists.com.
                                </Link>
                                We are available 7 days
                                a week to assist you
                              </p>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="mr-4">
                              <img
                                src="images/modal-img/lock-icon (1) 1.svg"
                                className="sub-header-lock"
                              />
                            </div>
                            <div className="sub-header font-weight-bold ">
                              Your Search History and
                              Reports are Privately
                              Stored
                              <p className="para pt-2">
                                We saved your search
                                history and created
                                reports for your future
                                use.  You can access
                                your stored reports at
                                any time.
                              </p>
                            </div>
                            <div></div>
                          </div>
                          {/* <div className="d-flex">
                            <div className="mr-3">
                              <img
                                src="images/modal-img/speaking-bubbles-b-icon 1.svg"
                                className="sub-header-speaking-bubbles"
                              />
                            </div>
                            <div className="sub-header font-weight-bold ">
                              Customer Testimonials
                              <p className="para pt-2">
                                Lorem Ipsum is simply
                                dummy text of the
                                printing and typesetting
                                industry. Lorem Ipsum
                                has been the industry's
                                standard dummy text ever
                                since the 1500s.
                              </p>
                            </div>
                            <div></div>
                          </div> */}
                        </div>
                      </div>
                      {/* SECURE CHECKOUT */}
                      <div className="col-md-6 col-sm-12 ">
                        <div className="col-12 custom-card-border px-4 py-3 text-left font-inter mb-3">
                          <div className="header">
                            SECURE CHECKOUT
                          </div>
                          <div className="checkout-sub-header pt-3 pb-2">
                            CHOOSE YOUR PAYMENT METHOD
                          </div>
                          {/* card payment */}
                          <div className="payment-method d-flex align-items-center">
                            <div className="ml-3 d-flex align-items-center ">
                              <div>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="paymentGetway"
                                id="credit"
                                value="credit"
                                checked={
                                  selectedTab ===
                                  "credit"
                                }
                                // onClick={
                                //   handleRadioChange
                                // }
                                
                              />
                              </div>
                            
                              <label
                                className=" col-md-10  pt-1  credit-text  text-nowrap"
                                htmlFor="credit"
                              >
                                Credit/Debit Card
                              </label>
                            </div>
                          </div>

                          {/* payPal */}
                          {/* <div className="payment-method  ">
                            <div className="ml-3">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="paymentGetway"
                                id="payPal"
                                value="payPal"
                                checked={
                                  selectedTab ===
                                  "payPal"
                                }
                                onClick={
                                  handleRadioChange
                                }
                              />
                              <label
                                className="m-0 ml-3 credit-text"
                                htmlFor="payPal"
                              >
                                payPal
                              </label>
                            </div>
                          </div> */}

                          {/* G-pay */}
                          {/* <div className="payment-method ">
                            <div className="ml-3">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="paymentGetway"
                                id="G-pay"
                                value="G-pay"
                                checked={
                                  selectedTab ===
                                  "G-pay"
                                }
                                onClick={
                                  handleRadioChange
                                }
                              />
                              <label
                                className="m-0 ml-3 credit-text"
                                htmlFor="G-pay"
                              >
                                G-pay
                              </label>
                            </div>
                          </div> */}

                          {/* venmo */}
                          {/* <div className="payment-method mb-4">
                            <div className="ml-3">
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="paymentGetway"
                                id="venmo"
                                value="venmo"
                                checked={
                                  selectedTab ===
                                  "venmo"
                                }
                                onClick={
                                  handleRadioChange
                                }
                              />
                              <label className="m-0 ml-3 credit-text">
                                venmo
                              </label>
                            </div>
                          </div> */}

                          {/* Credit card */}
                          {selectedTab === "credit" ? (
                            <div className="mt-3">
                              <div className="credit-form">
                                EMAIL (THIS WILL BE YOUR
                                LOGIN)
                              </div>
                              <div>
                                <div className="form-row">
                                  <div className="form-group col-md-12 m-0 mt-2">
                                    <input
                                      className="form-control input-form"
                                      type="text"
                                      name="email"
                                      id="email"
                                      value={userEmail}    
                                      placeholder="Email"
                                      // onChange={(e:any) => setEmail(e.target.value)}
                                    />
                                    <p className="text-danger field-errorMessage-checkout">
                                    </p>
                                  </div>
                                </div>

                                {/* <div className="form-group mb-0 mt-2">
                                  <label
                                    htmlFor="cardName"
                                    className="credit-form"
                                  >
                                    NAME ON CARD
                                  </label>
                                  <Field
                                    className="form-control input-form"
                                    type="text"
                                    name="cardName"
                                    id="cardName"
                                    placeholder="ex. John Doe"
                                  />
                                  <p className="text-danger field-errorMessage-checkout">
                                    <ErrorMessage name="cardName" />
                                  </p>
                                </div> */}
                                <CardElement 
                                className="my-3" 
                                options={CARD_ELEMENT_OPTIONS}
                                />
                                {/* <div className="form-group mb-0">
                                  <label
                                    className="credit-form"
                                    htmlFor="cardNumber"
                                  >
                                    CARD NUMBER
                                  </label>
                                  <Field
                                    className="form-control input-form"
                                    type="number"
                                    name="cardNumber"
                                    id="cardNumber"
                                    placeholder="ex. 1234 1234 1234 1234"
                                  />
                                  <p className="text-danger field-errorMessage-checkout">
                                    <ErrorMessage name="cardNumber" />
                                  </p>
                                </div>

                                <div className="form-row">
                                  <div className="form-group col-md-4">
                                    <label
                                      htmlFor="expDate"
                                      className="credit-form"
                                    >
                                      EXP DATE
                                    </label>
                                    <Field
                                      className="form-control input-form"
                                      type="text"
                                      name="expDate"
                                      id="expDate"
                                      placeholder="MM/YY"
                                    />
                                    <p className="text-danger field-errorMessage-checkout">
                                      <ErrorMessage name="expDate" />
                                    </p>
                                  </div>

                                  <div className="form-group col-md-4">
                                    <label
                                      htmlFor="CVV"
                                      className="credit-form"
                                    >
                                      CVV
                                    </label>

                                    <Field
                                      className="form-control input-form"
                                      type="text"
                                      name="CVV"
                                      id="CVV"
                                    />
                                    <p className="text-danger field-errorMessage-checkout">
                                      <ErrorMessage name="CVV" />
                                    </p>
                                  </div>

                                  <div className="form-group col-md-4">
                                    <label
                                      htmlFor="zipCode"
                                      className="credit-form"
                                    >
                                      ZIP CODE
                                    </label>
                                    <Field
                                      className="form-control input-form"
                                      type="number"
                                      name="zipCode"
                                      id="zipCode"
                                      placeholder="ZIP CODE"
                                    />
                                    <p className="text-danger field-errorMessage-checkout">
                                      <ErrorMessage name="zipCode" />
                                    </p>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <StripePayment/> */}
                          <div className="text-center">
                            <div className="d-flex ">
                              <div
                                className=""
                                style={{
                                  width: "11px",
                                  marginTop: "-2",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  id="checkAgree"
                                  name="checkAgree"
                                  value={isTermsAccepted}
                                  onChange={(event:any) => setIsTermsAccepted(event.target.checked)}
                                />
                                {/* <p className="text-danger field-errorMessage-checkout">
                                      <ErrorMessage name="checkAgree" />
                                    </p> */}
                              </div>
                              <div className="col-11 pl-4">
                                <label className="i-understand" htmlFor="checkAgree">
                                  I understand that
                                  PlaneLists.com is not
                                  a consumer reporting
                                  agency.  I agree to
                                  the PlanLists.com
                                  Terms and Conditions
                                  and Privacy Policy. 
                                  PlaneLists.com will
                                  use the personal
                                  informationI provide
                                  here by the
                                  PlaneLists.com Privacy
                                  Policy.  I understand
                                  that unless I cancel
                                  during my 7-dayfree
                                  trial period, I will
                                  be billed ${monthlyPlan.price} 
                                  {" "} {monthlyPlan.plan_name==="MONTHLY"?"Monthly":monthlyPlan.plan_name==="THREE MONTH"?"Quarterly":monthlyPlan.plan_name==="ONE YEAR"?"Yearly":monthlyPlan.plan_name}.  I may cancel
                                  my account hassle-free
                                  online at any time.
                                  All sales are final
                                  and no refunds will be
                                  issued if cancellation
                                  is not completed
                                  before the 7-day free
                                  trial period ends.
                                </label>
                              </div>
                            </div>

                            <div className="col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                              {/* <Link to="#"  > */}
                              <button
                                type="submit" 
                                disabled={!stripe}
                                className="custom-freeTrial-btn"
                                // onClick={() => {
                                //   paidSuccessfully();
                                //   notify();
                                // }}
                              >
                                CONTINUE TO PAY
                              </button>
                              {/* <button
                                type="button" 
                                disabled={!stripe}
                                className="custom-freeTrial-btn"
                                // onClick={props.handlePaymentModal(false,{})}
                              >
                                Cancel
                              </button> */}
                              {/* </Link> */}
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      
      </>
    )
}