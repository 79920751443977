import { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import { fetchUser } from "../../../Features/fetchUserSlice";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { Loader } from "../RepeatedComponent/Loader";
import { Link, useNavigate } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { FaMobileRetro } from "react-icons/fa6";
import { logoutTrue, userLogout } from "../../../Features/authSlice";

import "./Userdashboard.css";

export const Userdashboard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(0);
   const [details,setDetails] =useState({name:"John Doe",email:"johndoe@example.com"})
  const [edit, setEdit] = useState(true);
  const [postcardHTML, setPostcardHTML] = useState<string>("");
  const status = useAppSelector((state: any) => state.auth.status);
  
  const [mobileMenu, showMobileMenu] = useState(false);
  const [subMobileMenu,setSubMobileMenu] =  useState(false);
  
  const [activeItem, setActiveItem] = useState("");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };
  const handleNavigation = (pathname: string) => {
    navigate(pathname);
    setActiveItem(pathname);
    showMobileMenu(false);
  };
  const logOut = () => {
    setLoading(1);
    dispatch(userLogout()).then((res:any) => {
      setLoading(0);
      dispatch(logoutTrue());
    });
  };
  
  
  
  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);
  
  const editForm = () => {
    setEdit(false);
  };

  const cancelForm = () => {
    setEdit(true);
  };
  useEffect(() => {
    setLoading(1);
    dispatch(fetchUser()).then((res:any) => {
      console.log(res.payload.loggedin_user.user.email

        ,"response")
      if(res.payload.success)
      {
        setDetails({name:res?.payload?.loggedin_user?.user.name,email:res?.payload?.loggedin_user?.user?.email})
        
      }
      setLoading(0);
    });
  }, []);

  const capitalizeFirstLetterOfWords = (str: string) => {
    return str.replace(/\b\w/g, (char: string) => char.toUpperCase());
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <div
        className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center prototype-no-background 
      widget_1600979911688 "
      >
        <div className="container-fluid">
          <div
            className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5"
            style={{ backgroundColor: "black", color: "white" }}
          >
            <div className="col-md-10 dashtext ">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: postcardHTML }} />
      <h2
        className=" section-heading font-inter text-white text-center dash-heading"
        style={{ backgroundColor: "black", color: "white" }}
      >
       Dashboard
      </h2>
      <div
        id="hs_cos_wrapper_widget_1604012269835"
        className="hs_cos_wrapper hs_cos_wrapper_widget 
        hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-multicol-media bg-black sr-padding-half text-center 
          prototype-no-background widget_1604012269835"
          id="research-property"
        >
          <div
            className="container-fluid content-wrapper pt-md-3 dashtext"
            style={{
              backgroundColor: "black",
              color: "white",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-md-9 ">
                <div className="row  ">
                  {/* Blogs*/}
                  {/* <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-1">
                    <div className="content-wrapper">
                      <div className="container ">
                        <div className="row  justify-content">
                          <div className="col-12 d-flex pt-2 pb-md-4 pb-0">
                            <div>
                              <img
                                src="images/Profile-icon.svg"
                                className="profile-image"
                              />
                            </div>
                            <div className="pl-4 pt-3">
                              <div className="h5 font-weight-bold dashtext">
                                John Doe
                              </div>
                              <div className="h6 dashtext">johndoe@example.com</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* FURLOUGHED AIRLINE PILOTS */}
                  <div className="col-md-3 sr-col text-left  sr-repeater-1 pt-3 my-0 my-md-2">
                    <div className="row  justify-content-center">
                      <div className="col-12 d-flex align-items-center">
                        <div className=" d-flex align-items-center">
                          <img
                            src="images/Profile-icon.svg"
                            className="profile-image"
                          />
                        </div>
                        <div className="pl-3 pt-3">
                          <div className="h5 font-weight-bold dashtext">
                            {capitalizeFirstLetterOfWords(details?.name)}
                          </div>
                          <div className="h6 dashtext">{details?.email}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 pt-3 my-0">
                    {/* <div className="stats-wrapper d-flex">
                    <div className="stat col-4">
                      <h2 className="text-white font-inter">155</h2>
                      <p className="themecolor">
                        Total no. of Downloads
                      </p>
                    </div>
                    <div className="stat  col-4">
                      <h2 className="text-white font-inter">308</h2>
                      <p className="themecolor">
                       Active Subscription days
                      </p>
                    </div>
                    <div className="stat  col-4">
                      <h2 className="text-white font-inter ">185</h2>
                      <p className="themecolor">
                        Current Plan
                      </p>
                    </div>
                  </div> */}
                  </div>
                  <div className="col-md-3 sr-col text-left  sr-repeater-1 pt-3">
                    <div className="content-wrapper ">
                      <div
                        className="container mobile-container custom-blog-outline p-2"
                        style={{ backgroundColor: "black" }}
                      >
                        <Link
                          to="/user-dashboard"
                          className="dropdown-item dashtext "
                          // onClick={() => {
                          //   scrollToTop();
                          //   handleNavigation("/personalInfo");
                          // }}
                          style={{ backgroundColor: "black" }}
                        >
                           Dashboard
                        </Link>

                        <Link
                          to="/airmenrecords"
                          className="dropdown-item dashtext"
                          // onClick={() => {
                          //   scrollToTop();
                          //   handleNavigation("/airmenrecords");
                          // }}
                          style={{ backgroundColor: "black" }}
                        >
                          Airmen Records
                        </Link>

                        <Link
                          to="/aircraftrecords"
                          className="dropdown-item dashtext"
                          // onClick={() => {
                          //   scrollToTop();
                          //   handleNavigation("/aircraftrecords");
                          // }}
                          style={{ backgroundColor: "black" }}
                        >
                          Aircraft Records
                        </Link>

                        <Link
                          to="/your-payment-history"
                          className="dropdown-item dashtext"
                          // onClick={() => {
                          //   scrollToTop();
                          //   handleNavigation("/your-payment-history");
                          // }}
                          style={{ backgroundColor: "black" }}
                        >
                          Payment History
                        </Link>
                        <Link
                          to="/your-downloads-history"
                          className="dropdown-item dashtext"
                          // onClick={() => {
                          //   scrollToTop();
                          //   handleNavigation("/your-downloads-history");
                          // }}
                          style={{ backgroundColor: "black" }}
                        >
                          Download History
                        </Link>
                        <Link
                          to="/subscriptions-list"
                          className="dropdown-item dashtext"
                          // onClick={() => {
                          //   scrollToTop();
                          //   handleNavigation("/subscriptions-list");
                          // }}
                          style={{ backgroundColor: "black" }}
                        >
                          Subscription Info
                        </Link>
                        <Link
                          to="/subscriptions-list"
                          className="dropdown-item dashtext"
                          // onClick={() => {
                          //   scrollToTop();
                          //   handleNavigation("/cancel-subscriptions");
                          // }}
                          style={{ backgroundColor: "black" }}
                        >
                          Cancel Subscription
                        </Link>
                        <Link
                          to="/change-password"
                          className="dropdown-item dashtext"
                          // onClick={() => {
                          //   scrollToTop();
                          //   handleNavigation("/changepassword");
                          // }}
                          style={{ backgroundColor: "black" }}
                        >
                          Change Password
                        </Link>
                        <Link
                          to="/"
                          className="dropdown-item dashtext"
                          onClick={() => {
                            logOut();
                            scrollToTop();
                            handleNavigation("/subscriptions-list");
                          }}
                          style={{ backgroundColor: "black" }}
                          // onChange={scrollToTop}
                        >
                          Logout
                        </Link>
            
          
                      </div>
                    </div>
                  </div>
                  
                  
                  
                  
                  
                  
                  
                  <div className="col-md-9 pt-3">
                    <div className="content-wrapper">
                      <div className="container custom-blog-outline ">
                        <div className="row justify-content-center pt-md-3">
                          <div
                            className="col-md-11 d-flex justify-content-between px-1 py-2  
                          personal-profile-boder m-4 dashtext "
                          >
                            <div className="h5 font-weight-bold dashtext">
                              Profile
                            </div>
                            {/* <div className="d-flex align-items-center">
                              <img
                                src="images/edit-info-img.svg"
                                alt=""
                                className="personal-edit-img"
                                onClick={editForm}
                              />
                              <div
                                className="h5 personal-edit m-0 font-weight-bold "
                                onClick={editForm}
                              >
                                Edit
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="row  justify-content-center pt-md-2 ">
                          <div className="col-md-11  px-2 py-1 mx-3 m-md-0 mx-sm-3">
                            <form className="personal-info-form">
                              <div className="row d-flex justify-content-between">
                                <div className="col-sm-12 col-md-6 d-flex align-items-center mb-sm-3 mb-2 ">
                                  <label className="col-md-2 col-sm-2 col-3 col-form-labe py-md-2 py-3  personal-info-lable text-center">
                                    <FaUserAlt style={{fontSize:"16px"}} />
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control col-md-10 col-sm-10 col-9 personal-info-input h-100 m-0"
                                    placeholder="First Name"
                                    value={details.name}
                                    disabled={edit}
                                  />
                                </div>

                                <div className="col-sm-12 col-md-6 d-flex align-items-center mb-sm-3 mb-2 ">
                                  <label className="col-md-2 col-sm-2 col-3 col-form-labe py-md-2 py-3  personal-info-lable text-center">
                                    <FaUserAlt style={{fontSize:"16px"}} />
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control col-md-10 col-sm-10 col-9 personal-info-input h-100 m-0"
                                    placeholder="Last Name"
                                    disabled={edit}
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="row  justify-content-center pb-md-2">
                          <div className="col-md-11  px-2 py-1 mx-3 m-md-0 mx-sm-3 pb-3">
                            <form className="personal-info-form  user-form">
                              <div className="row d-flex justify-content-between">
                                <div className="col-sm-12 col-md-6 d-flex align-items-center mb-sm-3 mb-2 ">
                                  <label className="col-md-2 col-sm-2 col-3 col-form-labe py-md-2 py-3 personal-info-lable text-nowrap text-center">
                                    {/* Email ID */}
                                    {/* <FaUserAlt /> */}
                                    <MdEmail style={{fontSize:"20px"}}/>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control col-md-10 col-9 col-sm-10 personal-info-input h-100 m-0"
                                    placeholder="johndoe@example.com"
                                    value={details.email}
                                    disabled={true}
                                  />
                                </div>

                                <div className="col-sm-12 col-md-6 d-flex align-items-center mb-sm-3 mb-2 ">
                                  <label className="col-md-2 col-sm-2 col-3 col-form-labe py-md-2 py-3  personal-info-lable text-center">
                                    {/* <FaUserAlt /> */}
                                    <FaMobileRetro  style={{fontSize:"20px"}}/>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control col-md-10 col-sm-10 col-9 personal-info-input h-100 m-0"
                                    placeholder="Enter Mobile Number"
                                    disabled={edit}
                                  />
                                </div>

                                {/* CANCEL */}
                                {edit == false ? (
                                  <div className="col-sm-12 col-md-12 d-flex  justify-content-center mb-sm-3">
                                    <div className=" d-flex justify-content-between pt-3">
                                      <button
                                        className="text-center custom-cancel-btn "
                                        onClick={cancelForm}
                                      >
                                        CANCEL
                                      </button>
                                      <button className="text-center custom-save-btn ml-3">
                                        SAVE
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Userdashboard;
