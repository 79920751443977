import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";

interface User {
  success: "",
}
// interface ExportsRecords {
//   email: string;
//   password: string;
//   password_confirmation: string;
//   name: string;
//   temp_sub_id?: string;
//   coupon_code?: string;
// }


const initialState:User = {
  success: "",
};


// ------- Fetch User  -----
export const fetchUser = createAsyncThunk(
  "fetchUser/user",
  async (body, thunkAPI) => {
    // console.log(body,"data")
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/fetch_current_user`,
        {
        }
      );

      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {

      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);


// ------- createSlice for all ----
export const fetchUserSlice = createSlice({
  name: "fetchUser",
  initialState,
  reducers: {
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(fetchUser.pending, (state: any) => {
        state.status = false;
      })
      .addCase(fetchUser.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.userInfo=action.payload.loggedin_user;
        state.user={
          pending_tasks:action.payload.loggedin_user.pending_tasks,
        }
        // console.log("user",state.user);
        state.response = action.meta.requestStatus;
      })
      .addCase(fetchUser.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });

  },
});

export const userList = (state: RootState) => state.user;
export default fetchUserSlice.reducer;