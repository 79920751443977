import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import "../RepeatedComponent/ameHealthPopup.css";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { Field, Formik, Form } from "formik";
import { ameZipCode } from "../../../Features/ameZipCodeSlice";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import { exportsRecords } from "../../../Features/exportsRecordsSlice";
import { Loader } from "../RepeatedComponent/Loader";

interface CheckboxState {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  airTrafficController: boolean;
  aircraftMechanics: boolean;
  parachuteRiggers: boolean;
  dropZoneManagement: boolean;
  jumpPilots: boolean;
  [key: string]: boolean;
}
interface Pilot {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  allClasses: boolean;
}
interface Option {
  label: string;
  value: string;
}
interface zip {
  zipCode: number;
  distance: number;
  success: string;
}

export const LoggedRightContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(0);
  const [location, setLocation] = useState("State");
  const [selectedTab, setSelectedTab] = useState("pilot");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);
  const [includeAircraft, setIncludeAircraft] = useState(false);
  const states = useAppSelector((state: any) => state.downloadHistory.states);

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
  };
  var intervalId: any = "";
  const taskStatus = useAppSelector(
    (state: any) => state.exportsRecords.taskStatus
  );
  const loggedin_user = useAppSelector((state: any) => state.user.user);

  const [zipCode, setZipCode] = useState([]);
  // const { task_log_id} = taskStatus;
  const [zipData, setZipData] = useState<any>({
    zipCode: 0,
    distance: 0,
    success: "",
  });

  const [zipResponse, setZipResponse] = useState("");

  const marketingDistance = [
    {
      value: "selected",
      label: "Please Select",
    },
  ];

  useEffect(() => {
    let a = {
      name: "",
      surname: "",
    };

    dispatch(getPilotStates()).then((res: any) => {
      // console.log(res.payload.states, "res of state");
      let filteredStates = res.payload.states.filter(
        (item: any) => item.state !== ""
      );
      let hello: any = filteredStates.map((item: any) => ({
        value: item.state,
        label: item.state_abbrev,
      }));
      // console.log(hello, "all hello");
      setOptions(hello);
      // console.log(hello,"show hello")
    });
  }, []);

  const onChangeMarketingDistance = () => {
    // Assuming zipData is of type ZipData, specify its type or provide a type assertion
    if (zipData && zipData.zipCode && zipData.distance) {
      console.log("function called for zipdata");
      const payload: any = {
        zipCode: zipData.zipCode,
        distance: zipData.distance,
        success: "", // Ensure that the payload matches the expected shape of ZipCodeState
      };
      dispatch(ameZipCode(payload))
        .then((res: any) => {
          console.log(res, "response of zipdata");
          if (res.payload.length > 0) {
            // Handle the result of the async thunk action
            const zipCodes = res.payload.map((item: any) => item.ZipCode);
            const convertedString = zipCodes.join(", ");
            // console.log(convertedString, "zipcodes after");
            setZipResponse(convertedString);
          } else {
            toast.dismiss();
            toast.error("No Zip code found", {
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 8000,
              pauseOnFocusLoss: true,
            });
          }
          // setZipCode(zipCodes);
        })
        .catch((error: any) => {
          // Handle any errors that occur during the async operation
          console.error("Error: in zipdata api", error);
        });
    }
  };

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
    });
  }

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, []);

  const [checkboxes, setCheckboxes] = useState<Pilot>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
  });

  const [contains,setContains]=useState({
    radio1:true,
    radio2:true,
    radio3:true,
    radio4:true,
    radio5:true,
    radio6:true,
    radio7:true,
    radio8:true,
    // radio1:true
  })

  const [allRadioCheck, setAllRadioCheck] = useState<CheckboxState>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    airTrafficController: false,
    aircraftMechanics: false,
    parachuteRiggers: false,
    dropZoneManagement: false,
    jumpPilots: false,
    // Add more checkboxes as needed
  });

  // const onChangeMarketingDistance = (e: any) => {
  //   // const distance = e.target.value;
  //   // console.log(zipData,"zipData")
  //   if (zipData.zipCode > 10000 && zipData.distance) {
  //     // console.log("api of zip.dta")
  //     // zipData.distance = distance;
  //     let newd = { ...zipData };
  //     dispatch(ameZipCode({ ...e, ...newd })).then((res: any) => {
  //       if (res.payload.length > 0) {
  //         const zipCodes = res.payload.map((item: any) => item.ZipCode);
  //         console.log(zipCodes, "zipcodes recieced");
  //         setZipCode(zipCodes);
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    onChangeMarketingDistance();
  }, [zipData]);
  // defaultValue form values
  const defaultValue: CheckboxState = {
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    airTrafficController: false,
    aircraftMechanics: false,
    parachuteRiggers: false,
    dropZoneManagement: false,
    jumpPilots: false,
  };

  const initialValues: Pilot = {
    ...defaultValue,
    // location:"State",
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
  };
  const initialValues1 = {
    ...initialValues,
    medicalExpiring: false,
    outputFormat: "CSV",
    location1: "Contains1",
  };

  // console.log("all data",{
  //   "last_name": "david",
  //   "last_name_check": "sub_string",
  //   "first_name": "",
  //   "first_name_check": "sub_string",
  //   "address": "",
  //   "address_check": "sub_string",
  //   "city_name": "",
  //   "city_name_check": "sub_string",
  //   "region_type": "state",
  //   "zip_code": [],
  //   "airman": "non_pilot",
  //   "airman_class": ["1st", "2nd"],
  //   "expire": "all",
  //   "sort_type": "first_middle_name",
  //   "sort_type_order": "asc",
  //   "export_file": "CSV",
  //   "task_type": "pilot convert to json"
  // })

  // Validation schema using yup
  const validationSchema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    firstClass: yup.string().required("Please select an option"),
    secondClass: yup.string().required("Please select an option"),
    thirdClass: yup.string().required("Please select an option"),
    allClasses: yup.string(),
    airTrafficController: yup.string(),
    aircraftMechanics: yup.string(),
    parachuteRiggers: yup.string(),
    dropZoneManagement: yup.string(),
    jumpPilots: yup.string(),
    medicalExpiring: yup.string(),
  });

  function getClassList(classes: any) {
    const classList = [];
    if (classes.firstClass) classList.push("1st");
    if (classes.secondClass) classList.push("2nd");
    if (classes.thirdClass) classList.push("3rd");
    return classList;
  }
  //  Handle form submission
  const handleSubmit = (values: any) => {
    console.log(values, "Form Values");
    // checkboxes.fi
    let airmenclass =getClassList(checkboxes);
 
    
   let updatedValues= {
      "last_name": values.lastName,
      "last_name_check": values.locations1==="exactmatch1"?"exact_string":'sub_string',
      "first_name": values.firstName,
      "first_name_check":  values.locations2==="exactmatch2"?"exact_string":'sub_string',
      "address": values.address,
      "address_check":  values.locations3==="exactmatch3"?"exact_string":'sub_string',
      "city_name": values.city,
      "city_name_check":   values.locations4==="exactmatch4"?"exact_string":'sub_string',
      "region_type": location,
      "zip_code": selectedOptions.map(item => item.value),
      "airman": selectedTab,
      "airman_class": airmenclass,
      "expire": values.medicalExpiring,
      "sort_type": values.pilotype,
      "sort_type_order": values.order || "asc",
      "export_file": values.outputFormat,
      "task_type": "pilot convert to json"
    }
values=updatedValues;
    console.log(values,"values pasdded to api")
    setLoading(1);
    dispatch(exportsRecords({...values})).then((res:any) => {
      console.log({taskID:res.payload.task_log_id,
        WorkerId:res.payload.worker_job_id
      },"after api response in AME Patients Lead")
         setLoading(0);
      toast.success("Export request has been submitted successfully. Your file will download shortly.", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true
      });
    }).catch((err)=>{
setLoading(0);
    })
    // setLoading(0);
    // scrollToTop();
    // navigate("/your-downloads-history");
  };

  /// Handle form submission
  //   const handleSubmitSetData = () => {
  //     setLoading(1);
  //     dispatch(exportsRecords()).then((res:any) => {

  //       if(res.payload && res.payload.task_log_id){
  //         intervalId = setInterval(async () => {
  //           const userData:any = await dispatch(fetchUser());
  //           if(userData.payload && userData.payload.success){
  //               console.log(userData.payload,userData.payload.loggedin_user,'expotr')
  //               if(userData.payload.loggedin_user && userData.payload.loggedin_user.ready_to_download_task && userData.payload.loggedin_user.ready_to_download_task.hasOwnProperty(res.payload.task_log_id)){
  //                 window.open(`${process.env.REACT_APP_API_URL}/export_task_file?task_log_id=${res.payload.task_log_id}`,'_blank');
  //                 clearInterval(intervalId);
  //                 setLoading(0);
  //                 scrollToTop();
  //                 navigate('/your-downloads-history');
  //               }
  //           }
  //         },3000)
  //       }
  //     }); // Dispatching the signInUser action with form values

  //     // const fetchData = async () => {
  //     //   // setLoading(1);
  //     //   try {
  //     //     const userData = await dispatch(fetchUser());
  //     //     console.log(userData)
  //     //     // if (userData && userData.pending_tasks && Object.keys(userData?.pending_tasks).length === 0) {
  //     //     //   if (task_log_id) {
  //     //     //     await dispatch(downloadFile({ task_log_id: task_log_id }));
  //     //     //   }
  //     //     // }
  //     //     // setLoading(0);
  //     //   } catch (error) {
  //     //     console.error("Error fetching user data:", error);
  //     //     setLoading(0);
  //     //   }
  //     // };

  //     // const interval = setInterval(fetchData, 2000);
  //  };

  const checkedAllClass = (event: any) => {
    const { name, checked } = event.target;

    // Update individual checkbox state
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    // If "All Class" checkbox is checked, update all other checkboxes accordingly
    if (name === "allClasses") {
      setCheckboxes({
        firstClass: checked,
        secondClass: checked,
        thirdClass: checked,
        allClasses: checked,
      });
    } else {
      // If any other checkbox is unchecked, uncheck "All Class" checkbox
      if (!checked) {
        setCheckboxes((prevState) => ({
          ...prevState,
          allClasses: false,
        }));
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTab(event.target.value);
    const { value } = event.target;

    if (value === "all") {
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = true;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    } else {
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = false;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    }
  };

  const handleCheckboxChange = (event: any) => {
    const { name, checked } = event.target;
    setAllRadioCheck((prevCheckboxes: any) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
  };

  // console.log(selectedTab, "selected Tab");

  const naviii = () => {
    navigate("/your-downloads-history");
  };

  return (
    <>
 {loading ? <Loader /> : ""}
    <ToastContainer/>
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <div className="container-fluid bg-white">
          <div className="row text-black">
            {/* content*/}
            <div className="col-md-12 col-sm-12 ">
              <div className="col-12  px-4 py-3 text-left font-inter mb-3">
                <Form>
                  {/*Name*/}
                  <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Field name="lastName">
                          {({ field }: { field: any }) => (
                            <TextField
                              {...field}
                              // required
                              color="warning"
                              id="outlined-number"
                              name="lastName"
                              className="w-100 text-black orange-autocomplete"
                              label="Last Name:(Leave blank for all)"
                              type="text"
                              // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
                              placeholder="Last Name"
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#E35D22", fontWeight: "bold" },
                              }}
                              InputProps={{
                                style: { color: "black" }, // Text color of the input
                              }}
                              sx={{
                                "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                                // Change border color when focused
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                      <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
                                 <div className=" amePopup-label col-6 d-flex align-items-center">
                                   <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations1"
                                     id="exactmatch1"
                                     value="exactmatch1"
                                     onClick={() =>
                                      setContains({...contains,radio1:false})
                                    }
                                    checked={!contains.radio1}
                                    
                                     // checked={location === "Geo Location"}
                                     // onClick={() =>
                                     //   setLocation("Geo Location")
                                     // }
                                   />
                                   </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="exactmatch1"
                                   >
                                     Exact Match
                                   </label>
                                 </div>

                                 <div className=" amePopup-label  d-flex align-items-center">
                                 <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations1"
                                     id="Contains1"
                                     value="Contains1"
                                     onClick={() =>
                                      setContains({...contains,radio1:true})
                                    }
                                    checked={contains.radio1}
                                    //  checked
                                     // checked={location === "State"}
                                     // onClick={() => setLocation("State")}
                                   />
                                    </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="Contains1"
                                   >
                                     Contains
                                   </label>
                                 </div>

                                 <div className="amePopup-label">
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="outputFormat"
                                   ></label>
                                 </div>
                               </div>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        {" "}
                        <Field name="firstName">
                          {({ field }: { field: any }) => (
                            <TextField
                              {...field}
                              required
                              color="warning"
                              id="outlined-number"
                              name="firstName"
                              className="w-100 text-black orange-autocomplete"
                              label="First Name/ Middle Name:(Leave blank for all)"
                              type="text"
                              // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
                              placeholder="First Name"
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#E35D22", fontWeight: "bold" },
                              }}
                              InputProps={{
                                style: { color: "black" }, // Text color of the input
                              }}
                              sx={{
                                "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                                // Change border color when focused
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                      <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
                                 <div className=" amePopup-label col-6 d-flex align-items-center">
                                   <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations2"
                                     id="exactmatch2"
                                     value="exactmatch2"
                                     onClick={() =>
                                      setContains({...contains,radio2:false})
                                    }
                                    checked={!contains.radio2}

                                     // checked={location === "Geo Location"}
                                     // onClick={() =>
                                     //   setLocation("Geo Location")
                                     // }
                                   />
                                   </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="exactmatch2"
                                   >
                                     Exact Match
                                   </label>
                                 </div>

                                 <div className=" amePopup-label  d-flex align-items-center">
                                 <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations2"
                                     id="Contains2"
                                     value="Contains2"
                                     onClick={() =>
                                      setContains({...contains,radio2:true})
                                    }
                                    checked={contains.radio2}

                                    //  checked
                                     // checked={location === "State"}
                                     // onClick={() => setLocation("State")}
                                   />
                                    </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="Contains2"
                                   >
                                     Contains
                                   </label>
                                 </div>

                                 <div className="amePopup-label">
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="outputFormat"
                                   ></label>
                                 </div>
                               </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Field name="address">
                          {({ field }: { field: any }) => (
                            <TextField
                              {...field}
                              color="warning"
                              id="outlined-number"
                              name="address"
                              className="w-100 text-black orange-autocomplete"
                              label="Address:(Leave blank for all)"
                              type="text"
                              // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
                              placeholder="Address"
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#E35D22", fontWeight: "bold" },
                              }}
                              InputProps={{
                                style: { color: "black" }, // Text color of the input
                              }}
                              sx={{
                                "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                                // Change border color when focused
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                      <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                        <div className=" amePopup-label col-6 d-flex align-items-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="locations3"
                              id="exactmatch3"
                              value="exactmatch3"
                              onClick={() =>
                                setContains({...contains,radio3:false})
                              }
                              checked={!contains.radio3}
                              // checked={location === "Geo Location"}
                              // onClick={() =>
                              //   setLocation("Geo Location")
                              // }
                            />
                          </div>
                          <label
                            className="form-check-label pl-2 pl-md-0 text-nowrap"
                            htmlFor="exactmatch3"
                          >
                            Exact Match
                          </label>
                        </div>

                                  <div className=" amePopup-label  d-flex align-items-center">
                                  <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="locations3"
                                      id="Contains3"
                                      value="Contains3"
                                      onClick={() =>
                                        setContains({...contains,radio3:true})
                                      }
                                      checked={contains.radio3}
                                      // checked
                                      // checked={location === "State"}
                                      // onClick={() => setLocation("State")}
                                    />
                                     </div>
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      htmlFor="Contains3"
                                    >
                                      Contains
                                    </label>
                                  </div>

                        <div className="amePopup-label">
                          <label
                            className="form-check-label pl-2 pl-md-0"
                            htmlFor="outputFormat"
                          ></label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Field name="city">
                          {({ field }: { field: any }) => (
                            <TextField
                              {...field}
                              color="warning"
                              id="outlined-number"
                              name="city"
                              className="w-100 text-black orange-autocomplete"
                              label="City:(Leave blank for all)"
                              type="text"
                              // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
                              placeholder="City"
                              InputLabelProps={{
                                shrink: true,
                                style: { color: "#E35D22", fontWeight: "bold" },
                              }}
                              InputProps={{
                                style: { color: "black" }, // Text color of the input
                              }}
                              sx={{
                                "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                                // Change border color when focused
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                      <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
                                 <div className=" amePopup-label col-6 d-flex align-items-center">
                                   <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations4"
                                     id="exactmatch4"
                                     value="exactmatch4"
                                     onClick={() =>
                                      setContains({...contains,radio4:false})
                                    }
                                    checked={!contains.radio4}
                                     // checked={location === "Geo Location"}
                                     // onClick={() =>
                                     //   setLocation("Geo Location")
                                     // }
                                   />
                                   </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="exactmatch4"
                                   >
                                     Exact Match
                                   </label>
                                 </div>

                                 <div className=" amePopup-label  d-flex align-items-center">
                                 <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations4"
                                     id="Contains4"
                                     value="Contains4"
                                     onClick={() =>
                                      setContains({...contains,radio4:true})
                                    }
                                    checked={contains.radio4}
                                    //  checked
                                     // checked={location === "State"}
                                     // onClick={() => setLocation("State")}
                                   />
                                    </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="Contains4"
                                   >
                                     Contains
                                   </label>
                                 </div>

                                 <div className="amePopup-label">
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="outputFormat"
                                   ></label>
                                 </div>
                               </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                      {location === "State" && (
                        <div>
                          <Autocomplete
                            className="orange-autocomplete mt-3"
                            multiple
                            id="multi-select"
                            options={options.filter(
                              (option: any) =>
                                !selectedOptions.some(
                                  (selectedOption) =>
                                    selectedOption.value === option.value
                                )
                            )}
                            getOptionLabel={(option) => option.label}
                            value={selectedOptions}
                            onChange={(event, newValue) =>
                              setSelectedOptions(newValue)
                            }
                            inputValue={inputValue}
                            onInputChange={handleInputChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State:(Leave blank for all)"
                                variant="outlined"
                                fullWidth
                                color="warning"
                                InputLabelProps={{ shrink: true , style: { color: "#E35D22", fontWeight: "bold" },}} // This will make the label always visible
                              />
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  // key={index}
                                  color="warning"
                                  label={option.label}
                                  // onDelete={() => handleDeleteOption(option)}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                          />
                          {/* <Box
                                  component="form"
                                  sx={{
                                    "& .MuiTextField-root": {
                                      mt: 2,
                                      width: "25ch",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <Field name="ameOfficeZipCode">
                                    {({ field }: { field: any }) => (
                                      <TextField
                                        {...field}
                                        color="warning"
                                        id="outlined-select-currency"
                                        select
                                        label="State:(Leave blank for all)"
                                        className="w-100"
                                        defaultValue="New Student Pilots"
                                        value={field.ameOfficeZipCode}
                                        // onChange={onChangeMarketingDistance}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: {
                                            color: "#E35D22",
                                            fontWeight: "bold",
                                          }, // Color of the label
                                        }}
                                        InputProps={{
                                          placeholder:
                                            "Marketing Distance From Office", // Set placeholder text
                                          style: { outlineColor: "#E35D22" },
                                        }}
                                        sx={{
                                          "& input::placeholder": {
                                            color: "black  ",
                                          }, // Style for the placeholder text color
                                        }}
                                      >
                                        {Array.isArray(states) && states.length > 0 && states.map((state:any) => (
                                          <MenuItem
                                          key={state.state}
                                          value={state.state_abbrev}
                                          >
                                            {state.state_abbrev}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  </Field>
                                </Box> */}
                        </div>
                      )}
                      <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                        <div className="amePopup-sub-heading">Choose By:</div>
                        <div className=" amePopup-label col-6 d-flex align-items-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="location"
                              id="Geo Location"
                              value="Geo Location"
                              checked={location === "Geo Location"}
                              onClick={() => setLocation("Geo Location")}
                            />
                          </div>
                          <label
                            className="form-check-label pl-2 pl-md-0"
                            htmlFor="Geo Location"
                          >
                            Geo Location
                          </label>
                        </div>

                        <div className=" amePopup-label  d-flex align-items-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="location"
                              id="State"
                              value="State"
                              checked={location === "State"}
                              onClick={() => setLocation("State")}
                            />
                          </div>
                          <label
                            className="form-check-label pl-2 pl-md-0"
                            htmlFor="State"
                          >
                            State
                          </label>
                        </div>

                        <div className="amePopup-label">
                          <label
                            className="form-check-label pl-2 pl-md-0"
                            htmlFor="outputFormat"
                          ></label>
                        </div>
                      </div>
                      <div></div>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                      {location === "State" ? (
                        <div>
                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                width: "25ch",
                              },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <Field name="ameOfficeZipCode">
                              {({ field }: { field: any }) => (
                                <TextField
                                  {...field}
                                  color="warning"
                                  required
                                  id="outlined-number"
                                  type="number"
                                  name="ameOfficeZipCode"
                                  className="w-100 text-black orange-autocomplete"
                                  label="Zip Code"
                                  value={field.ameOfficeZipCode}
                                  placeholder="e.g.12345"
                                  onChange={(e: any) =>
                                    setZipData({
                                      ...zipData,
                                      zipCode: e.target.value,
                                    })
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      color: "#E35D22",
                                      fontWeight: "bold",
                                    },
                                  }}
                                  InputProps={{
                                    style: {
                                      color: "black",
                                      borderColor: "orange",
                                    }, // Set borderColor to orange
                                    inputProps: {
                                      maxLength: 9,
                                      minLength: 5,
                                    },
                                  }}
                                  sx={{
                                    "& input::placeholder": {
                                      color: "black",
                                    }, // Style for the placeholder text color
                                  }}
                                />
                              )}
                            </Field>
                          </Box>

                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                width: "25ch",
                              },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <Field name="marketingDistance">
                              {({ field }: { field: any }) => (
                                <TextField
                                  {...field}
                                  color="warning"
                                  id="outlined-select-currency"
                                  select
                                  label=" Distance"
                                  className="w-100 orange-autocomplete"
                                  defaultValue="selected"
                                  value={field.marketingDistance}
                                  onChange={(e: any) =>
                                    setZipData({
                                      ...zipData,
                                      distance: e.target.value,
                                    })
                                  }
                                  // onChange={onChangeMarketingDistance}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      color: "#E35D22",
                                      fontWeight: "bold",
                                    }, // Color of the label
                                  }}
                                  InputProps={{
                                    placeholder:
                                      "Marketing Distance From Office", // Set placeholder text
                                    style: {
                                      outlineColor: "#E35D22",
                                    },
                                  }}
                                  sx={{
                                    "& input::placeholder": {
                                      color: "black  ",
                                    }, // Style for the placeholder text color
                                  }}
                                >
                                  {marketingDistance.map((option) => (
                                    <MenuItem
                                      disabled={
                                        option.label === "Please Select"
                                          ? true
                                          : false
                                      }
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Field>
                          </Box>
                        </div>
                      ) : (
                        <Box
                          component="form"
                          sx={{
                            "& .MuiTextField-root": {
                              mt: 2,
                              width: "25ch",
                            },
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <Field name="geolocation">
                            {({ field }: { field: any }) => (
                              <TextField
                                {...field}
                                color="warning"
                                id="outlined-select-currency"
                                select
                                label="Geo Location:(Leave blank for all)"
                                className="w-100 orange-autocomplete"
                                defaultValue="New Student Pilots"
                                value={field.ameOfficeZipCode}
                                // onChange={onChangeMarketingDistance}
                                InputLabelProps={{
                                  shrink: true,
                                  style: {
                                    color: "#E35D22",
                                    fontWeight: "bold",
                                  }, // Color of the label
                                }}
                                InputProps={{
                                  placeholder: "Marketing Distance From Office", // Set placeholder text
                                  style: { outlineColor: "#E35D22" },
                                }}
                                sx={{
                                  "& input::placeholder": {
                                    color: "black  ",
                                  }, // Style for the placeholder text color
                                }}
                              >
                                {[
                                  "Alaskan",
                                  "Central",
                                  "Eastern",
                                  "Foreign",
                                  "Great Lakes",
                                  "Northwest Mountain",
                                  "Southern",
                                  "Southwestern",
                                  "Western-Pacific",
                                ].map((state: any) => (
                                  <MenuItem key={state} value={state}>
                                    {state}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </Field>
                        </Box>
                      )}
                      {/* <p className="text-danger field-errorMessage ">
                                    <ErrorMessage name="marketingDistance" />
                                  </p> */}
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          required
                          color="warning"
                          id="outlined-number"
                          className="w-100 text-black"
                          label="AME Office Zip Code"
                          type="text"
                          onChange={(e: any) =>
                            setZipData({ ...zipData, zipCode: e.target.value })
                          }
                          placeholder="e.g.12345"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#E35D22", fontWeight: "bold" },
                          }}
                          InputProps={{
                            style: { color: "black" }, // Text color of the input
                          }}
                          sx={{
                            "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                            // Change border color when focused
                          }}
                        />
                      </Box>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-select-currency"
                          color="warning"
                          select
                          label="Marketing Distance From Office"
                          onChange={(e: any) =>
                            setZipData({ ...zipData, distance: e.target.value })
                          }
                          className="w-100"
                          defaultValue="selected"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#E35D22", fontWeight: "bold" }, // Color of the label
                          }}
                          InputProps={{
                            placeholder: "Marketing Distance From Office", // Set placeholder text
                            style: { outlineColor: "#E35D22" },
                          }}
                          sx={{
                            "& input::placeholder": { color: "black  " }, // Style for the placeholder text color
                          }}
                        >
                          {marketingDistance.map((option) => (
                            <MenuItem
                              disabled={
                                option.label === "Please select" ? true : false
                              }
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          required
                          color="warning"
                          id="outlined-number"
                          name="lastName"
                          className="w-100 text-black"
                          label="Last Name:(Leave blank for all)"
                          type="text"
                          // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
                          placeholder="e.g.12345"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#E35D22", fontWeight: "bold" },
                          }}
                          InputProps={{
                            style: { color: "black" }, // Text color of the input
                          }}
                          sx={{
                            "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                            // Change border color when focused
                          }}
                        />
                      </Box>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          required
                          color="warning"
                          id="outlined-number"
                          name="firstName"
                          className="w-100 text-black"
                          label="First Name/ Middle Name:(Leave blank for all)"
                          type="text"
                          // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
                          placeholder="e.g.12345"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#E35D22", fontWeight: "bold" },
                          }}
                          InputProps={{
                            style: { color: "black" }, // Text color of the input
                          }}
                          sx={{
                            "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                            // Change border color when focused
                          }}
                        />
                      </Box>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          required
                          color="warning"
                          id="outlined-number"
                          className="w-100 text-black"
                          label="AME Office Zip Code"
                          type="text"
                          onChange={(e: any) =>
                            setZipData({ ...zipData, zipCode: e.target.value })
                          }
                          placeholder="e.g.12345"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#E35D22", fontWeight: "bold" },
                          }}
                          InputProps={{
                            style: { color: "black" }, // Text color of the input
                          }}
                          sx={{
                            "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                            // Change border color when focused
                          }}
                        />
                      </Box>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-select-currency"
                          color="warning"
                          select
                          label="Marketing Distance From Office"
                          onChange={(e: any) =>
                            setZipData({ ...zipData, distance: e.target.value })
                          }
                          className="w-100"
                          defaultValue="selected"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#E35D22", fontWeight: "bold" }, // Color of the label
                          }}
                          InputProps={{
                            placeholder: "Marketing Distance From Office", // Set placeholder text
                            style: { outlineColor: "#E35D22" },
                          }}
                          sx={{
                            "& input::placeholder": { color: "black  " }, // Style for the placeholder text color
                          }}
                        >
                          {marketingDistance.map((option) => (
                            <MenuItem
                              disabled={
                                option.label === "Please select" ? true : false
                              }
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    </div>
                  </div> */}
                  {/* Airmen Type */}
                  <div className="row pt-4">
                    <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
                      <div className="amePopup-sub-heading">Airmen Type</div>
                      <div className="form-check amePopup-label  d-flex align-items-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="airmenType"
                            id="pilot"
                            value="pilot"
                            checked={selectedTab === "pilot"}
                            onChange={handleRadioChange}
                          />
                        </div>

                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="pilot"
                        >
                          Pilot
                        </label>
                      </div>
                      <div className="form-check amePopup-label d-flex align-items-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="airmenType"
                            id="non_pilot"
                            value="non_pilot"
                            checked={selectedTab === "non_pilot"}
                            onChange={handleRadioChange}
                          />
                        </div>

                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="non_Pilot"
                        >
                          Non-Pilot
                        </label>
                      </div>
                      <div className="form-check amePopup-label d-flex align-items-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="airmenType"
                            id="all"
                            value="all"
                            checked={selectedTab === "all"}
                            onChange={handleRadioChange}
                          />
                        </div>

                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="all"
                        >
                          All
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* {selectedTab === "pilot" ? ( */}

                  <div
                    className="row mx-0 mx-sm-3 popUp-tab-transition"
                    id="airmenMedical"
                  >
                    <div className="col-12  ame-sub-boxShadow">
                      <div className="col-12 pt-2 sub-heading">
                        Airmen Medical Class
                      </div>

                      {/* <div className="d-flex justify-content-center align-items-center  flex-column pt-2 pb-3"> */}

                      <div className="col-12 d-flex ">
                        <div className="form-check amePopup-label col-6  d-flex align-items-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              name="firstClass"
                              id="firstClassCheckbox"
                              checked={checkboxes.firstClass}
                              onChange={checkedAllClass}
                            />
                          </div>
                          <label
                            className="form-check-label pl-2 "
                            htmlFor="firstClass"
                          >
                            1st Class
                          </label>
                        </div>
                        <div className="form-check amePopup-label col-6   d-flex align-items-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              name="secondClass"
                              id="secondClassCheckbox"
                              checked={checkboxes.secondClass}
                              onChange={checkedAllClass}
                            />
                          </div>
                          <label
                            className="form-check-label pl-2 "
                            htmlFor="secondClass"
                          >
                            2nd Class
                          </label>
                        </div>
                      </div>
                      <div className="col-12  d-flex pb-2">
                        <div className="form-check amePopup-label  col-6   d-flex align-items-center ">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="thirdClass"
                            id="thirdClassCheckbox"
                            checked={checkboxes.thirdClass}
                            onChange={checkedAllClass}
                          />
                          <label
                            className="form-check-label pl-2 "
                            htmlFor="thirdClass"
                          >
                            3rd Class
                          </label>
                        </div>
                        <div className="form-check amePopup-label  col-6   d-flex align-items-center">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="allClasses"
                            id="allClassesCheckbox"
                            checked={checkboxes.allClasses}
                            onChange={checkedAllClass}
                          />
                          <label
                            className="form-check-label pl-2 "
                            htmlFor="allClasses"
                          >
                            All Class
                          </label>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>

                  {/* <div
                          className={
                            selectedTab === "nonPilot" ? "tab-transition" : ""
                          }
                        >
                          {selectedTab === "nonPilot" ? (
                            <div className="row mx-0 mx-sm-3 ">
                              <div className="col-12  ame-sub-boxShadow">
                                <div className="col-12 pt-2 sub-heading">
                                  Non-Pilot Designation
                                </div>
                                <div className="d-flex justify-content-center align-items-center  flex-column pt-1 pb-2">
                                  <div className="col-11 col-md-12 col-lg-11 d-flex flex-column flex-md-row ">
                                    <div className="form-check amePopup-label col-12 col-md-6">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        name="airTrafficController"
                                        id="airTrafficController"
                                      />
                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="airTrafficController"
                                      >
                                        Air Traffic Controller
                                      </label>
                                    </div>
                                    <div className="form-check amePopup-label col-12 col-md-6">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        name="aircraftMechanics"
                                        id="aircraftMechanics"
                                      />
                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="aircraftMechanics "
                                      >
                                        Aircraft Mechanics
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-11 col-md-12 col-lg-11 d-flex flex-column flex-md-row ">
                                    <div className="form-check amePopup-label col-12 col-md-6">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        name="parachuteRiggers"
                                        id="parachuteRiggers"
                                      />
                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="parachuteRiggers"
                                      >
                                        Parachute Riggers & Packers
                                      </label>
                                    </div>
                                    <div className="form-check amePopup-label col-12 col-md-6">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        name="dropZoneManagement"
                                        id="dropZoneManagement"
                                      />
                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="dropZoneManagement"
                                      >
                                        Drop Zone Management
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-11 col-md-12 col-lg-11 d-flex flex-column flex-md-row">
                                    <div className="form-check amePopup-label col-12 col-md-6">
                                      <Field
                                        className="form-check-input"
                                        type="checkbox"
                                        name="jumpPilots"
                                        id="jumpPilots"
                                      />
                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="jumpPilots"
                                      >
                                        Jump Pilots
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div> */}
                  {/* {selectedTab === "all" ? (
                 
                    <div className="row mx-0 mx-sm-3">
                      <div className="col-12 d-flex flex-lg-row flex-column ame-sub-boxShadow ">
                        <div className="col-md-12 col-lg-6">
                          <div className="col-12 pt-2 sub-heading">
                            Airmen Medical Class
                          </div>

                          <div className="d-flex justify-content-center align-items-center pl-1  flex-column pt-1 pb-2">
                            <div className="col-11 col-md-12 d-flex flex-column p-0 pl-md-3">
                              <div className="form-check amePopup-label col-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="firstClass"
                                  id="firstClass"
                                  checked={allRadioCheck.firstClass}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label pl-2 pl-md-0"
                                  htmlFor="firstClass"
                                >
                                  1st Class
                                </label>
                              </div>
                              <div className="form-check amePopup-label col-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="secondClass"
                                  id="secondClass"
                                  checked={allRadioCheck.secondClass}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label pl-2 pl-md-0"
                                  htmlFor="secondClass"
                                >
                                  2nd Class
                                </label>
                              </div>

                              <div className="form-check amePopup-label col-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="thirdClass"
                                  id="thirdClass"
                                  checked={allRadioCheck.thirdClass}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label pl-2 pl-md-0"
                                  htmlFor="thirdClass"
                                >
                                  3rd Class
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col col-lg-6">
                          <div className="col-12 pt-2 sub-heading">
                            Non-Pilot Designation
                          </div>
                          <div className="d-flex justify-content-center align-items-center flex-column pt-1 pb-2 ">
                            <div className="col-12 col-md-12 d-flex flex-column ">
                              <div className="form-check amePopup-label col-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="airTrafficController"
                                  id="airTrafficController"
                                  checked={allRadioCheck.airTrafficController}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label pl-2 pl-md-0"
                                  htmlFor="airTrafficController"
                                >
                                  Air Traffic Controller
                                </label>
                              </div>
                              <div className="form-check amePopup-label col-12 ">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="aircraftMechanics"
                                  id="aircraftMechanics"
                                  checked={allRadioCheck.aircraftMechanics}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label pl-2 pl-md-0"
                                  htmlFor="aircraftMechanics "
                                >
                                  Aircraft Mechanics
                                </label>
                              </div>
                            </div>

                            <div className="col-md-12 d-flex flex-column">
                              <div className="form-check amePopup-label col-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="parachuteRiggers"
                                  id="parachuteRiggers"
                                  checked={allRadioCheck.parachuteRiggers}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label pl-2 pl-md-0"
                                  htmlFor="parachuteRiggers"
                                >
                                  Parachute Riggers & Packers
                                </label>
                              </div>
                              <div className="form-check amePopup-label col-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="dropZoneManagement"
                                  id="dropZoneManagement"
                                  checked={allRadioCheck.dropZoneManagement}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label pl-2 pl-md-0"
                                  htmlFor="dropZoneManagement"
                                >
                                  Drop Zone Management
                                </label>
                              </div>
                            </div>

                            <div className="col-md-12 d-flex flex-column flex-md-row">
                              <div className="form-check amePopup-label col-12">
                                <Field
                                  className="form-check-input"
                                  type="checkbox"
                                  name="jumpPilots"
                                  id="jumpPilots"
                                  checked={allRadioCheck.jumpPilots}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label pl-2 pl-md-0"
                                  htmlFor="jumpPilots"
                                >
                                  Jump Pilots
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}

                  {/* Medical Expiring Within: */}

                  <div className="row pt-4">
                    <div className="col-12 d-flex offmarket-output-format  flex-wrap ame-boxShadow px-0 py-4">
                      <div className="amePopup-sub-heading">
                        Medical Expiring Within
                      </div>
                      <div className=" amePopup-label col-6 d-flex">
                        <div className="pl-4">
                          <Field
                            className="form-check-input "
                            type="radio"
                            name="medicalExpiring"
                            id="30 Days"
                            value="30 Days"
                            checked={true}
                          />
                        </div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="30 Days"
                        >
                          30 Days
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex  align-items-center">
                        <div className="pl-4">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="medicalExpiring"
                            id="60 Days"
                            value="60 Days"
                          />
                        </div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="60 Days"
                        >
                          60 Days
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex  align-items-center">
                        <div className="pl-4">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="medicalExpiring"
                            id="90 Days"
                            value="90 Days"
                          />
                        </div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="90 Days"
                        >
                          90 Days
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex   align-items-center">
                        <div className="pl-4">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="medicalExpiring"
                            id="all"
                            value="all"
                          />
                        </div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="all"
                        >
                          All
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex   align-items-center">
                        <div className="pl-4">
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="medicalExpiring"
                            id="expired"
                            value="expired"
                          />
                        </div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="expired"
                        >
                          Expired
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex   align-items-center"></div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-md-12 col-sm-12 p-0 ">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Field name="pilotype">
                          {({ field }: { field: any }) => (
                            <TextField
                              {...field}
                              color="warning"
                              id="outlined-select-currency"
                              select
                              label="Sort By:"
                              className="w-100 orange-autocomplete"
                              defaultValue="Please Select"
                              value={field.pilotype}
                              // onChange={onPilottype}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: "#E35D22",
                                  fontWeight: "bold",
                                }, // Color of the label
                              }}
                              InputProps={{
                                placeholder: "Marketing Distance From Office", // Set placeholder text
                                style: { outlineColor: "#E35D22" },
                              }}
                              sx={{
                                "& input::placeholder": {
                                  color: "black  ",
                                }, // Style for the placeholder text color
                              }}
                            >
                              {[
                                {
                                  name: "Please Select",
                                  value: "Please Select",
                                },
                                {
                                  name: "First/Middle Name",
                                  value: "first_middle_name",
                                },
                                {
                                  name: "Last Name",
                                  value: "last_name_suffix",
                                },
                                { name: "City", value: "city" },
                              ].map((option) => (
                                <MenuItem
                                  disabled={
                                            option.value ===
                                            "Please Select"
                                              ? true
                                              : false
                                          }
                                  key={option.name}
                                  value={option.value}
                                >
                               
                                  {option.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Field>
                      </Box>
                    </div>
                  </div>

                  <div className="row ">
                  <div className="col-md-12 col-sm-12 p-0 ">            
                                <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                  <div className="amePopup-sub-heading">
                                    Order:
                                  </div>
                                  <div className=" amePopup-label col-6 d-flex align-items-center">
                                    <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="order"
                                      id="asc"
                                      value="asc"
                                      onClick={() =>
                                        setContains({...contains,radio5:true})
                                      }
                                      checked={contains.radio5}
                                      // checked
                                      // checked={location === "Geo Location"}
                                      // onClick={() =>
                                      //   setLocation("Geo Location")
                                      // }
                                    />
                                    </div>
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      // htmlFor="CSV"
                                    >
                                      Ascending Order
                                    </label>
                                  </div>

                        <div className=" amePopup-label  d-flex align-items-center">
                          <div>
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="order"
                              id="Descending Order"
                              value="desc"
                              onClick={() =>
                                setContains({...contains,radio5:false})
                              }
                              checked={!contains.radio5}
                              // checked={location === "State"}
                              // onClick={() => setLocation("State")}
                            />
                          </div>
                          <label
                            className="form-check-label pl-2 pl-md-0"
                            // htmlFor="RTF"
                          >
                            Descending Order
                          </label>
                        </div>

                        <div className="amePopup-label">
                          <label
                            className="form-check-label pl-2 pl-md-0"
                            htmlFor="outputFormat"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Output Format */}
                  <div className="row pt-4 pb-4">
                    <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
                      <div className="amePopup-sub-heading">Output Format</div>
                      <div className=" amePopup-label d-flex align-items-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="outputFormat"
                            id="CSV"
                            value="CSV"
                            checked={true}
                          />
                        </div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="CSV"
                        >
                          CSV
                        </label>
                      </div>
                      {/* <div className=" amePopup-label  d-flex justify-content-center">
                        <div>
                        <Field
                          className="form-check-input"
                          type="radio"
                          name="outputFormat"
                          id="TXT"
                          value="TXT"
                        />
                        </div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="TXT"
                        >
                         
                        </label>
                      </div> */}
                      <div className=" amePopup-label  d-flex align-items-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="outputFormat"
                            id="RTF"
                            value="RTF"
                          />
                        </div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="RTF"
                        >
                          RTF
                        </label>
                      </div>

                      <div className=" amePopup-label  d-flex align-items-center">
                        <div>
                          {" "}
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="outputFormat"
                            id="avery5163LabelPDF"
                            value="avery5163LabelPDF"
                          />
                        </div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="avery5163LabelPDF"
                        >
                          Avery 5163 Label PDF
                        </label>
                      </div>
                      <div className="amePopup-label">
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="outputFormat"
                        ></label>
                      </div>
                    </div>
                  </div>

                  <div className="text-center ">
                    <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                      {/* <Link to="/your-downloads-history"> */}
                      <button
                        type="submit"
                        // onClick={() => {
                        //   handleSubmitSetData();
                        // }}
                        className="custom-freeTrial-btn"
                        data-dismiss="modal"
                      >
                        Exports Records
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Formik>
    </>
  );
};
