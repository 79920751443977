import "./AboutUs.css";
import { Screen } from "../RepeatedComponent/Screens";

export const AboutUsPage = () => {
  return (
    <>
      <Screen />
      <div
        id="hs_cos_wrapper_widget_1666115421100"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-multicol-media bg-white sr-padding-custom text-center prototype-no-background widget_1666115421100 "
          id="interior_nav"
        >
          <div className="container content-wrapper">
            <div className="row  sr-valign-top">
              <div className="col-md-2 sr-col text-center  sr-repeater-1">
                <div className="content-wrapper">
                  <div className="btn-wrapper btn-white-wrapper sr-spacer-top-25">
                    <a href="#" className="cta_button font-inter">
                      About
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-2 sr-col text-center  sr-repeater-2">
                <div className="content-wrapper">
                  <div className="btn-wrapper btn-white-wrapper sr-spacer-top-25">
                    <a href="#" className="cta_button font-inter">
                      US Aircraft Owners
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-2 sr-col text-center  sr-repeater-3">
                <div className="content-wrapper">
                  <div className="btn-wrapper btn-white-wrapper sr-spacer-top-25">
                    <a href="#" className="cta_button font-inter">
                      Historical-Data
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-2 sr-col text-center  sr-repeater-4">
                <div className="content-wrapper">
                  <div className="btn-wrapper btn-white-wrapper sr-spacer-top-25">
                    <a href="#" className="cta_button font-inter">
                      AME
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-2 sr-col text-center  sr-repeater-5">
                <div className="content-wrapper">
                  <div className="btn-wrapper btn-white-wrapper sr-spacer-top-25">
                    <a href="#" className="cta_button font-inter">
                      Active Airmen And Pilots
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-2 sr-col text-center  sr-repeater-6">
                <div className="content-wrapper">
                  <div className="btn-wrapper btn-white-wrapper sr-spacer-top-25">
                    <a href="#" className="cta_button font-inter">
                      Marketing Genius
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1666115784329 "
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module about-Us-Img1"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-multicol-media  sr-padding-half sr-bg-image prototype-no-background widget_1666115784329 "
          id="vision"
        >
          <div className="container content-wrapper">
            <div className="row  sr-valign-center">
              <div className="col-md-5 sr-col text-left prototype-background sr-repeater-1 bg-white">
                <div className="content-wrapper">
                  <h2 className="col-heading font-inter ">
                    About Our Database
                  </h2>
                  <div className="col-content">
                    <p className="justify-content">
                      At PlaneLists.com not only will you find the most
                      up-to-date marketing aviation information in the industry,
                      but it is also the most accurate. We do more than list
                      data; we power your marketing machine with our current
                      database that is updated regularly using proprietary
                      software to ensure properly maintained records. This
                      provides access to the most precise details for your
                      marketing aviation needs anytime from anywhere in the
                      world at a moment’s notice.
                    </p>
                    <p className="justify-content">
                      If you are looking to buy an airplane, knowing about
                      changes in ownership right away is critical. The same
                      thing goes with aircraft services companies, and our pilot
                      database changes let your company contact the right people
                      promptly, putting your marketing dollars to good use. of
                      each month, and aircraft records are compiled every
                      morning at 2:00 a.m. EST (GMT-5), giving you accuracy, you
                      can trust, and quality you can rely on.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1666116079671"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module about-Us-Img2"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-multicol-media  sr-padding-half sr-bg-image prototype-no-background widget_1666116079671 "
          id="mission"
        >
          <div className="container content-wrapper">
            <div className="row  sr-valign-center">
              <div className="col-md-5 offset-md-7 sr-col text-left prototype-background sr-repeater-1 bg-white">
                <div className="content-wrapper">
                  <h2 className="col-heading font-inter">US Aircraft Owners</h2>
                  <div className="col-content">
                    <p className="justify-content">
                      Our US Aircraft Owners database allows users to find the
                      exact aviation information they are without the complexity
                      or difficulty of searching through the FAA’s records. We
                      have made our database simple and intuitive to use so that
                      you can search, sort, and locate the information you need
                      with little to no effort. Using the PlaneLists.com’s
                      database will give you access to select aircraft owners by
                      manufacture, class, powerplant, and more. You can also
                      choose to organize the data by single-engine aircraft or
                      business jet. All data is downloadable through our server
                      and available with lowercase conversion, company names,
                      and correct salutations (Mr., Dr., Capt.), so you can
                      address your marketing materials properly. We have
                      produced our database for maximum effectiveness and
                      organization by taking the necessary steps to organize the
                      data for the most practical and functional approach to
                      use. Our proprietary software drives this efficiency so
                      that you can take full advantage of the data for your
                      marketing campaigns. Plus, you can try our US Aircraft
                      Owners database for free for one month – a $39.95 value –
                      to see the value and benefit of having the information
                      right at your fingertips.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1667841451838"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-one-col-features-01 bg-secondary sr-padding-half prototype-no-background widget_1667841451838"
          id="values"
        >
          <div className="container">
            <div className="sr-spacer-bottom-50">
              <h2 className=" heading   text-center font-inter">
                Aircraft Ownership
              </h2>
            </div>
            <div className="section-content">
              <div className="link-wrapper">
                <div className="media">
                  <div className="media-body">
                    <h5>Historical Data</h5>
                    <p className="justify-content">
                      Planelists.com also offers a historical database that
                      rivals the FAA archives. These records go beyond the
                      agency’s aircraft and ownership information as we also
                      give instant access to new owners. You’ll be able to trace
                      a specific aircraft and its ownership history, including
                      single owners and corporate entities. Look up multi-engine
                      aircraft, turbine-power planes, as well as specific
                      aircraft models and manufacturers for the most detailed
                      database archive offered in the aviation market. Couple
                      this with the power to search whose registration is
                      expiring or expired while cross-referencing ownership and
                      medical certificate status of the owner and you’ll have a
                      recipe for a cutting-edge tool that can be used to drive
                      the success of your business further. You’ll want to dive
                      in deep with this historical database and use this
                      information to your advantage as it provides valuable
                      records that can give your company an edge and allow you
                      to increase your customer base more efficiently.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1668461686891"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-multicol-media sr-one-col-01 bg-none sr-padding-half text-center prototype-no-background widget_1668461686891 "
          id="the-team"
        >
          <div className="container">
            <h2 className=" section-heading font-inter">AME</h2>
            <div className="row sr-spacer-top-50  sr-valign-center">
              <div className="col-md-12 sr-col text-center  text-  sr-repeater-1 ">
                <div className="content-wrapper">
                  <div className="col-content">
                    <p>
                      PlaneLists.com has kept aviation medical examiners in mind
                      by making our database easy to search for pilot and
                      non-pilot aviation groups (air traffic controllers,
                      mechanics, and inspectors). Here, AMEs can choose
                      selectable data sets that range from student to ATP,
                      ratings, and medical expiration date to determine who to
                      contact for their next required medical examination. Data
                      can also be centralized by city, state, zip code, and
                      geographical and regional ranges. This is the perfect
                      opportunity to find pilots and other airmen who have
                      medical certificates that are expiring or are expired and
                      are in need of updating. In addition, all of our AME
                      database information is downloadable in multiple formats,
                      making it easier to create mailing lists using standard
                      labels [Avery 5163]. Contact us to create your direct mail
                      campaigns for you. We can develop a custom postcard mailer
                      or personalized query to target pilots and airmen
                      requiring medical examinations, giving your business a
                      boost with the most current aviation data available in the
                      industry.
                    </p>
                  </div>
                </div>
                {/* images */}
                <div className="col-md-12 d-flex justify-content-space-evenly align-items-baseline pt-3">
                  <div className="col-md-3">
                    <img src="images/AboutUs/planeBlueImg.png" />
                  </div>
                  <div className="col-md-5">
                    <img src="images/AboutUs/planeBatch.png" />
                  </div>
                  <div className="col-md-3">
                    <img src="images/AboutUs/planeDataBase.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1666125734925"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-cards-timeline-01 widget_1666125734925 sr-padding-half bg-secondary prototype-no-background"
          id="from-humble-beginnings"
        >
          <div className="container">
            <div className="sr-spacer-bottom-25   text-center">
              <h2 className=" heading font-inter">Active Airmen and Pilots</h2>
              <div className="description">
                <p>
                  Active airmen and pilots will also find PlaneLists.com a
                  useful tool as they look for employment. Our database provides
                  access to owners of aircraft you’re certified to fly as well
                  as potential students as a flight instructor. Even new owners
                  can gain from the database functionality by locating
                  prospective pilots to fly their aircraft. We can help anyone
                  who is looking for a particular type of aviation professional.
                  Our up-to-date database gives you accurate information to find
                  pilots before your competitors. Some changes happen quickly,
                  such as new students entering the aviation field, or pilots
                  receiving new ratings, such as commercial licenses, instrument
                  or multi-engine certifications, or their ATP. Looking for a
                  combination of pilot ratings? Our filters allow for that too.
                  As an added bonus, users can trial our database for free for
                  one month – a $39.95 value – and take advantage of the
                  benefits it offers without commitment.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="timeline-card-row ">
                  <div className="timeline-card">
                    <div className="timeline-arrow ">
                      <svg
                        width={201}
                        height={14}
                        viewBox="0 0 201 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12.1315 11.3453L7.22711 8.01548L200.5 8.01288V5.40741L7.81163 5.41001L12.4316 2.75894L11.1321 0.5L0.5 6.59941L10.6637 13.5L12.1315 11.3453Z" />
                      </svg>
                    </div>
                    <div className="timeline-card-inner">
                      <div className="timeline-image">
                        <img
                          src="images/AboutUs/About-Us-Timeline.jpg"
                          alt="About Us Timeline - PlaneLists.com Couch"
                          loading="lazy"
                          width={1400}
                          height={600}
                          sizes="(max-width: 1400px) 100vw, 1400px"
                        />
                      </div>
                      <div className="timeline-details">
                        <h4 className=" heading font-inter">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                        </h4>
                        <div className="description">
                          In 2006, EquiMine was born with the goal of
                          aggregating real estate data from top industry
                          sources. With this data, we wanted to empower real
                          estate professionals to fulfill their lead generation
                          needs and successfully find off-market properties.
                        </div>
                      </div>
                    </div>
                    <div className="timeline-year  bg-secondary">2006</div>
                  </div>
                  <div className="timeline-card">
                    <div className="timeline-arrow ">
                      <svg
                        width={201}
                        height={14}
                        viewBox="0 0 201 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12.1315 11.3453L7.22711 8.01548L200.5 8.01288V5.40741L7.81163 5.41001L12.4316 2.75894L11.1321 0.5L0.5 6.59941L10.6637 13.5L12.1315 11.3453Z" />
                      </svg>
                    </div>
                    <div className="timeline-card-inner">
                      <div className="timeline-image">
                        <img
                          src="images/AboutUs/Disc.jpg"
                          alt="Disc – 2"
                          loading="lazy"
                          width={1400}
                          height={600}
                          sizes="(max-width: 1400px) 100vw, 1400px"
                        />
                      </div>
                      <div className="timeline-details">
                        <h4 className=" heading font-inter">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </h4>
                        <div className="description">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-year  bg-secondary">2007</div>
                  </div>
                  <div className="timeline-card">
                    <div className="timeline-arrow ">
                      <svg
                        width={201}
                        height={14}
                        viewBox="0 0 201 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12.1315 11.3453L7.22711 8.01548L200.5 8.01288V5.40741L7.81163 5.41001L12.4316 2.75894L11.1321 0.5L0.5 6.59941L10.6637 13.5L12.1315 11.3453Z" />
                      </svg>
                    </div>
                    <div className="timeline-card-inner">
                      <div className="timeline-image">
                        <img
                          src="images/AboutUs/About-Us-Timeline-Online.jpg"
                          alt="About Us Timeline - PlaneLists.com Online"
                          loading="lazy"
                          width={1400}
                          height={600}
                          sizes="(max-width: 1400px) 100vw, 1400px"
                        />
                      </div>
                      <div className="timeline-details">
                        <h4 className=" heading font-inter">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </h4>
                        <div className="description">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker including versions of
                            Lorem Ipsum..
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-year  bg-secondary">2018</div>
                  </div>
                  <div className="timeline-card">
                    <div className="timeline-arrow ">
                      <svg
                        width={201}
                        height={14}
                        viewBox="0 0 201 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12.1315 11.3453L7.22711 8.01548L200.5 8.01288V5.40741L7.81163 5.41001L12.4316 2.75894L11.1321 0.5L0.5 6.59941L10.6637 13.5L12.1315 11.3453Z" />
                      </svg>
                    </div>
                    <div className="timeline-card-inner">
                      <div className="timeline-image">
                        <img
                          src="images/AboutUs/About-Us-Timeline-Mobile.jpg"
                          alt="About Us Timeline - PlaneLists.com Mobile"
                          loading="lazy"
                          width={1400}
                          height={600}
                          sizes="(max-width: 1400px) 100vw, 1400px"
                        />
                      </div>
                      <div className="timeline-details">
                        <h4 className=" heading font-inter">
                          Lorem Ipsum is simply dummy text of the printing.{" "}
                        </h4>
                        <div className="description">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-year  bg-secondary">2020</div>
                  </div>
                  <div className="timeline-card">
                    <div className="timeline-arrow ">
                      <svg
                        width={201}
                        height={14}
                        viewBox="0 0 201 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12.1315 11.3453L7.22711 8.01548L200.5 8.01288V5.40741L7.81163 5.41001L12.4316 2.75894L11.1321 0.5L0.5 6.59941L10.6637 13.5L12.1315 11.3453Z" />
                      </svg>
                    </div>
                    <div className="timeline-card-inner">
                      <div className="timeline-image">
                        <img
                          src="images/AboutUs/About-Us-Timeline-Stewart-Title.jpg"
                          alt="About Us Timeline - Stewart Title"
                          loading="lazy"
                          width={1400}
                          height={600}
                          sizes="(max-width: 1400px) 100vw, 1400px"
                        />
                      </div>
                      <div className="timeline-details">
                        <h4 className=" heading">
                          Lorem Ipsum is simply dummy text of the.
                        </h4>
                        <div className="description">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-year  bg-secondary">2021</div>
                  </div>
                  <div className="timeline-card">
                    <div className="timeline-arrow ">
                      <svg
                        width={201}
                        height={14}
                        viewBox="0 0 201 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12.1315 11.3453L7.22711 8.01548L200.5 8.01288V5.40741L7.81163 5.41001L12.4316 2.75894L11.1321 0.5L0.5 6.59941L10.6637 13.5L12.1315 11.3453Z" />
                      </svg>
                    </div>
                    <div className="timeline-card-inner">
                      <div className="timeline-image">
                        <img
                          src="images/AboutUs/About-Us-Timeline-Future.jpg"
                          alt="About Us Timeline - Future"
                          loading="lazy"
                          width={1400}
                          height={600}
                          sizes="(max-width: 1400px) 100vw, 1400px"
                        />
                      </div>
                      <div className="timeline-details">
                        <h4 className=" heading font-inter">
                          Lorem Ipsum is simply ....
                        </h4>
                        <div className="description">
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="timeline-year  bg-secondary font-inter">
                      What's Next?
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1668459863383"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-cards-flip-01 widget_1668459863383 sr-padding-full bg-light   text-center prototype-no-background"
          id="supporting-the-community"
        >
          <div className="container">
            <div className="sr-spacer-bottom-25">
              <h2 className=" heading font-inter">Marketing Genius</h2>
              <div className="description">
                <p>
                  Sometimes, marketing genius requires more than one datapoint
                  in each mailing list. After all, each type of aviation
                  professional has their own unique needs. A combination of
                  aviation certifications, however, can produce specialized
                  opportunities for a marketing professional. For instance, a
                  pilot who also owns an aircraft will need plane maintenance
                  services as well as keeping his license up to date. Living
                  close to their plane might also be a consideration, offering
                  opportunities for specialized real estate deals. With
                  PlaneLists.com proprietary database, you can search for any
                  combination of relationships with aircraft. This allows you to
                  develop a fine-tuned mailing list that is most relevant to
                  your products or services. Targeted mailing lists allow for a
                  higher return on your investment, making you a true marketing
                  genius with our product. If you are not taking advantage of
                  our free trial, then why not start one today? It’s a $39.95
                  value – yours for free for one month.
                </p>
                <p>
                  Some of the organizations we've contributed to so far are:
                </p>
              </div>
            </div>
            <div className="row card-wrapper">
              <div className="col-md-6 col-xl-4">
                <div className="card-block">
                  <img
                    src="images/AboutUs/About-Us-Community-Autism-Speaks.jpg"
                    alt="Autism Speaks"
                    loading="lazy"
                    width={1200}
                    height={800}
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
                  <h4 className=" heading mt-4 font-inter">Autism Speaks</h4>
                  <div className="card-icon bg-light active">
                    <span />
                  </div>
                  <div className="card-overlay">
                    <div className="card-description">
                      <p>
                        Sometimes, marketing genius requires more than one
                        datapoint in each mailing list. After all, each type of
                        aviation professional has their own unique needs. A
                        combination of aviation certifications, however, can
                        produce specialized opportunities for a marketing
                        professional. For instance, a pilot who also owns an
                        aircraft will need plane maintenance services as well as
                        keeping his license up to date. Living close to their
                        plane might also be a consideration, offering
                        opportunities for specialized real estate deals. With
                        PlaneLists.com proprietary database, you can search for
                        any combination of relationships with aircraft. This
                        allows you to develop a fine-tuned mailing list that is
                        most relevant to your products or services. Targeted
                        mailing lists allow for a higher return on your
                        investment, making you a true marketing genius with our
                        product. If you are not taking advantage of our free
                        trial, then why not start one today? It’s a $39.95 value
                        – yours for free for one month.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card-block">
                  <img
                    src="images/AboutUs/AboutUsCommunityForWarriors.jpg"
                    alt="K9s For Warriors"
                    loading="lazy"
                    width={1200}
                    height={800}
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
                  <h4 className=" heading mt-4  font-inter">
                    K9s For Warriors
                  </h4>
                  <div className="card-icon bg-light active">
                    <span />
                  </div>
                  <div className="card-overlay">
                    <div className="card-description">
                      <p>
                        Veterans who return from active duty can suffer from
                        PTSD.&nbsp; Having a highly trained emotional support
                        animal can help! PlaneLists.com launched a social Media
                        Campaign and{" "}
                        <span style={{ color: "#000000" }}>
                          <a
                            className="font-inter"
                            href="#"
                            style={{ color: "#000000" }}
                          >
                            raised over $10K
                          </a>
                          .
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card-block">
                  <img
                    src="images/AboutUs/AboutUsCommunitySkidRow.jpg"
                    alt="Mutual Aid"
                    loading="lazy"
                    width={1200}
                    height={800}
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
                  <h4 className=" heading mt-4 font-inter">
                    Skid Row in Los Angeles
                  </h4>
                  <div className="card-icon bg-light active">
                    <span />
                  </div>
                  <div className="card-overlay">
                    <div className="card-description">
                      <p>
                        Everyone deserves dignity and respect! Our team came
                        together (2021-2022) to{" "}
                        <span style={{ color: "#000000" }}>
                          <a href="#" style={{ color: "#000000" }}>
                            donate bags of warm clothes
                          </a>
                        </span>{" "}
                        for individuals currently experiencing homelessness.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card-block">
                  <img
                    src="images/AboutUs/AboutUsCommunityOneTreePlanted.jpg"
                    alt="About Us Community - One Tree Planted"
                    loading="lazy"
                    width={1200}
                    height={800}
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
                  <h4 className=" heading mt-4 font-inter">One Tree Planted</h4>
                  <div className="card-icon bg-light active">
                    <span />
                  </div>
                  <div className="card-overlay">
                    <div className="card-description">
                      <p>
                        <span className="font-inter">
                          Trees help clean the air we breathe, filter our
                          drinking water, and so much more. PlaneLists.com launched
                          a social media campaign and{" "}
                          <span style={{ color: "#000000" }}>
                            <a
                              className="font-inter"
                              href="#"
                              style={{ color: "#000000" }}
                              rel="noopener"
                              target="_blank"
                            >
                              raised $12,000 for One Tree Planted
                            </a>
                            .
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card-block">
                  <img
                    src="images/AboutUs/AboutUsCommunityFaCT.jpg"
                    alt="About Us Community - FaCT"
                    loading="lazy"
                    width={1200}
                    height={800}
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
                  <h4 className=" heading mt-4 font-inter">
                    Families and Communities Together
                  </h4>
                  <div className="card-icon bg-light active">
                    <span />
                  </div>
                  <div className="card-overlay">
                    <div className="card-description">
                      <p>
                        We are pleased to have recently contributed to a toy
                        drive organized by Families and Communities Together, a
                        group of non-profit organizations that support families
                        in need in Orange County, CA. Our donation will help
                        provide toys to children in our community, offering the
                        joy of having gifts to open this holiday season.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="card-block">
                  <img
                    src="images/HomePageImg/Logo%20A%20Sense%20of%20Home-1.png"
                    alt="Logo A Sense of Home-1"
                    loading="lazy"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <h4 className=" heading mt-4">A Sense of Home</h4>
                  <div className="card-icon bg-light active">
                    <span />
                  </div>
                  <div className="card-overlay">
                    <div className="card-description">
                      <p>
                        <span
                          className="font-inter"
                          style={{ color: "#000000" }}
                        >
                          We made a lasting impact by donating $7,000 to{" "}
                          <span style={{ textDecoration: "underline" }}>
                            <a
                              href="#"
                              rel="noopener"
                              style={{
                                color: "#000000",
                                textDecoration: "underline",
                              }}
                            >
                              A Sense of Home
                            </a>
                          </span>{" "}
                          and providing a former foster youth with their first
                          home and a fresh start! With your generous support of
                          $356, we were able to help transform empty spaces into
                          warm and inviting places of joy, turning their dreams
                          into a magical reality for these young adults.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1668461621694"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div className="sr-cards-grid-02 bg-secondary sr-padding-full    prototype-no-background widget_1668461621694">
          <div className="container">
            <div className="row sr-spacer-bottom-50">
              <div className="col-md-12">
                <div className=" text-center ">
                  <h2 className=" heading ">Stay in Touch</h2>
                  <div className="description">
                    <span className="font-inter">
                      Stay connected with PlaneLists.com to keep up-to-date on tips,
                      new product launches, news, and more.
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards row">
              <div className="col-lg-4 col-md-6 ">
                <div className="card card-1 text-center bg-white">
                  <div className="card-inner">
                    <div className="card-image sr-spacer-bottom-25">
                      <img
                        src="https://7296653.fs1.hubspotusercontent-na1.net/hub/7296653/hubfs/instagram@2x.jpg?width=75&name=instagram@2x.jpg"
                        alt="Instagram"
                      />
                    </div>
                    <h4 className="font-inter">Instagram</h4>
                    <div className="btn-wrapper btn-outline-primary-wrapper btn-wrapper-sm">
                      <a href="#" className="cta_button font-inter">
                        Follow
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ">
                <div className="card card-2 text-center bg-white">
                  <div className="card-inner">
                    <div className="card-image sr-spacer-bottom-25">
                      <img
                        src="https://7296653.fs1.hubspotusercontent-na1.net/hub/7296653/hubfs/%D0%A1%D0%BB%D0%BE%D0%B8%CC%86_x-1@2x.jpg?width=75&name=%D0%A1%D0%BB%D0%BE%D0%B8%CC%86_x-1@2x.jpg"
                        alt="TikTok"
                      />
                    </div>
                    <h4 className="font-inter">TikTok</h4>
                    <div className="btn-wrapper btn-outline-primary-wrapper btn-wrapper-sm">
                      <a href="#" className="cta_button font-inter">
                        Follow
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ">
                <div className="card card-3 text-center bg-white">
                  <div className="card-inner">
                    <div className="card-image sr-spacer-bottom-25">
                      <img
                        src="https://7296653.fs1.hubspotusercontent-na1.net/hub/7296653/hubfs/twitter@2x.jpg?width=75&name=twitter@2x.jpg"
                        alt="Twitter"
                      />
                    </div>
                    <h4 className="font-inter">Twitter</h4>
                    <div className="btn-wrapper btn-outline-primary-wrapper btn-wrapper-sm">
                      <a href="#" className="cta_button font-inter">
                        Follow
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ">
                <div className="card card-4 text-center bg-white">
                  <div className="card-inner">
                    <div className="card-image sr-spacer-bottom-25">
                      <img
                        src="https://7296653.fs1.hubspotusercontent-na1.net/hub/7296653/hubfs/%D0%A1%D0%BB%D0%BE%D0%B8%CC%86_x0020_1@2x.jpg?width=75&name=%D0%A1%D0%BB%D0%BE%D0%B8%CC%86_x0020_1@2x.jpg"
                        alt="LinkedIn"
                      />
                    </div>
                    <h4 className="font-inter">LinkedIn</h4>
                    <div className="btn-wrapper btn-outline-primary-wrapper btn-wrapper-sm">
                      <a href="#" className="cta_button font-inter">
                        Follow
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ">
                <div className="card card-5 text-center bg-white">
                  <div className="card-inner">
                    <div className="card-image sr-spacer-bottom-25">
                      <img
                        src="https://7296653.fs1.hubspotusercontent-na1.net/hub/7296653/hubfs/facebook@2x.jpg?width=75&name=facebook@2x.jpg"
                        alt="Facebook"
                      />
                    </div>
                    <h4 className="font-inter">Facebook</h4>
                    <div className="btn-wrapper btn-outline-primary-wrapper btn-wrapper-sm">
                      <a href="#" className="cta_button">
                        Follow
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 ">
                <div className="card card-6 text-center bg-white">
                  <div className="card-inner">
                    <div className="card-image sr-spacer-bottom-25">
                      <img
                        src="https://7296653.fs1.hubspotusercontent-na1.net/hub/7296653/hubfs/youtube@2x.jpg?width=75&name=youtube@2x.jpg"
                        alt="YouTube"
                      />
                    </div>
                    <h4 className="font-inter">YouTube</h4>
                    <div className="btn-wrapper btn-outline-primary-wrapper btn-wrapper-sm">
                      <a href="#" className="cta_button font-inter">
                        Subscribe
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
