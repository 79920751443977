import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";
import { count } from "console";

interface exportsRecords {
  success: "",
}
// interface ExportsRecords {
//   email: string;
//   password: string;
//   password_confirmation: string;
//   name: string;
//   temp_sub_id?: string;
//   coupon_code?: string;
// }

interface ExportsRecordsCertificate {

last_name: string,
last_name_check: string,
first_name: string,
first_name_check: string,
address: string,
address_check: string,
city_name: string,
city_name_check: string,
region_type: string,
zip_code: [],
airman:string,
airman_class: [],
expire: string,
sort_type: string,
sort_type_order:string,
export_file: string,
task_type: string

}

const initialState:exportsRecords = {
  success: "",
};


// -------  Exports Records form -----
export const exportsRecords = createAsyncThunk(
  "exportsRecords/form",
  async (  body:ExportsRecordsCertificate,thunkAPI) => {

const {last_name, last_name_check, first_name, first_name_check, address, address_check, city_name, city_name_check, region_type, airman, airman_class, expire, sort_type, sort_type_order, export_file, task_type,zip_code}= body;
console.log(body,"body in slice")
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/export_pilot`,body
      

      );

      if (response.data) {
        console.log(response.data, "Export ")
        return response.data
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {

      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);
export const exportsRecordsCertificate = createAsyncThunk(
  "exportsRecordsVertificate/form",
  async (  body:ExportsRecordsCertificate,thunkAPI) => {

const {last_name, last_name_check, first_name, first_name_check, address, address_check, city_name, city_name_check, region_type, airman, airman_class, expire, sort_type, sort_type_order, export_file, task_type,zip_code}= body;
console.log(body,"body in slice")
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/export_pilot_certificate`,body
      

      );

      if (response.data) {
        console.log(response.data, "Export ")
        return response.data
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {

      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);


export const exportsRecordsAircraft = createAsyncThunk(
  "exportsRecordsAircraft/form",
  async (  body:ExportsRecordsCertificate,thunkAPI) => {

const {last_name, last_name_check, first_name, first_name_check, address, address_check, city_name, city_name_check, region_type, airman, airman_class, expire, sort_type, sort_type_order, export_file, task_type,zip_code}= body;
console.log(body,"body in slice")
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/export_aircraft`,body
      

      );

      if (response.data) {
        console.log(response.data, "Export ")
        return response.data
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {

      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- createSlice for all ----
export const exportsRecordsSlice = createSlice({
  name: "fetchUser",
  initialState,
  reducers: {
  },
  extraReducers: (builder: any) => {
    // Exports Records form
    builder
      .addCase(exportsRecords.pending, (state: any) => {
        state.status = false;
      })
      .addCase(exportsRecords.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.taskStatus = {
          task_log_id: action.payload.task_log_id,
          worker_job_id: action.payload.worker_job_id
        };
        console.log(state.taskStatus,"from promies")
        state.response = action.meta.requestStatus;
      })
      .addCase(exportsRecords.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });
      builder
      .addCase(exportsRecordsCertificate.pending, (state: any) => {
        state.status1 = false;
      })
      .addCase(exportsRecordsCertificate.fulfilled, (state: any, action: any) => {
        state.status1 = true;
        state.error1 = "";
        state.taskStatus1 = {
          task_log_id: action.payload.task_log_id,
          worker_job_id: action.payload.worker_job_id
        };
        console.log(state.taskStatus,"from promies")
        state.response1 = action.meta.requestStatus;
      })
      .addCase(exportsRecordsCertificate.rejected, (state: any, action: any) => {
        state.status1 = false;
        state.error1 = action.error.message;
      });


      builder
      .addCase(exportsRecordsAircraft.pending, (state: any) => {
        state.status2 = false;
      })
      .addCase(exportsRecordsAircraft.fulfilled, (state: any, action: any) => {
        state.status2 = true;
        state.error2 = "";
        state.taskStatus2 = {
          task_log_id: action.payload.task_log_id,
          worker_job_id: action.payload.worker_job_id
        };
        console.log(state.taskStatus,"from promies")
        state.response2 = action.meta.requestStatus;
      })
      .addCase(exportsRecordsAircraft.rejected, (state: any, action: any) => {
        state.status2 = false;
        state.error2 = action.error.message;
      });

  },
});

export const userList = (state: RootState) => state.exportsRecords;
export default exportsRecordsSlice.reducer;