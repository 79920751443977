import { useAppSelector } from "../../../hook";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";

const AviationListServices = () => {
  const status = useAppSelector((state) => state.auth.status);
  return (
    <>
     <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
            <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1695156991887"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Aviation List and Label Services */}
        <div className="sr-multicol-media bg-black text-white sr-padding-full text-center prototype-no-background widget_1695156991887 ">
          <div className="container content-wrapper">
            <div className="row  sr-valign-center">
              <div className="col-md-12 sr-col text-center  sr-repeater-2">
                <h2 className=" section-heading font-inter">
                  Aviation List and Label Services
                </h2>
              </div>
              <div className="col-md-12 p-2 pb-4 sr-col text-left  sr-repeater-2">
                <div className="content-wrapper">
                  <div className="container">
                    <div className="col-content">
                      <p className="justify-content">
                        Looking for mailing lists and marketing leads?
                        PlaneLists.com has you covered with detailed lists for
                        various campaigns. Share your needs, and our experts
                        will create a customized list. We search databases,
                        narrowing down findings based on your preferences,
                        ensuring no duplicates for cost savings. Finalized lists
                        come in versatile formats—labels, reports, emailed
                        files, or spreadsheets.
                      </p>
                      <p className="justify-content">
                        Our focus is on reducing costs and boosting your
                        conversion rate by providing top-quality leads. We're
                        committed to long-term customer relationships,
                        delivering ongoing service excellence to all clients.
                      </p>
                      <p className="justify-content">
                        Choose our "Do-it-for-you" service for seamless
                        delivery, collaborating with partners like print shops
                        or marketing agencies. We aim for cost-effectiveness,
                        minimizing duplicate or irrelevant mailings to maximize
                        your return on investment. Allocate saved funds to more
                        services or marketing efforts, enhancing your conversion
                        rate with the best leads possible.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {status ? (
          ""
        ) : (
          //  7-day free trial component
          <FreeTrial />
        )}
      </div>
    </>
  );
};

export default AviationListServices;
