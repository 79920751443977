import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";

interface Search {
  success: "",
}
interface TaskStatus {
  task_log_id:string,
}
const initialState:Search = {
  success: "",
};

// -------  Search -----
// export const freeAirmenRecords = createAsyncThunk(
//     "search/freeAirmenRecords",
//     async ({task_log_id}:TaskStatus, thunkAPI) => {
//       console.log(task_log_id, "File id")
//       try {
//         const response = await apiClient.get(
//           `${process.env.REACT_APP_API_URL}/free_pilot_cert_record?id=A0968566&type=pilot`,
//         );
  
//         if (response.data) {
//           console.log(response.data, "freeAirmenRecords");
//           return response.data.success;
//         } else {
//           return thunkAPI.rejectWithValue(response.data);
//         }
//       } catch (error: any) {
  
//         return thunkAPI.rejectWithValue({ success: false, error: error.message });
//       }
//     }
//   );

export const getFreeAirmenRecords = createAsyncThunk(
  "search/getFreeAirmenRecords",
  async (body:any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/get_free_download`,
        body
      );
      
      if (response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

export const getFreeAircraftRecords = createAsyncThunk(
  "search/getFreeAircraftRecords",
  async (body:any, thunkAPI) => {
    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/get_free_aircraft`,
        body
      );
      
      if (response.data) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- Search Slice ----
export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    clearState:(state:any)=>{
      state.response="";
      state.error = "";
    }
  },
  extraReducers: (builder: any) => {

    // Free Airmen Records
    builder
      .addCase(getFreeAirmenRecords.pending, (state: any) => {
        state.status = false;
      })
      .addCase(getFreeAirmenRecords.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        console.log(action.payload);
        state.response = action.payload;
      })
      .addCase(getFreeAirmenRecords.rejected, (state: any, action: any) => {
        state.status = false;
        state.error =action.error.message;
      });

    // Get Free Aircraft Records
    builder
    .addCase(getFreeAircraftRecords.pending, (state: any) => {
      state.status = false;
    })
    .addCase(getFreeAircraftRecords.fulfilled, (state: any, action: any) => {
      state.status = true;
      state.error = "";
      state.response = action.payload;
    })
    .addCase(getFreeAircraftRecords.rejected, (state: any, action: any) => {
      state.status = false;
      state.error = action.error.message;
    });
  },
});

export const userList = (state: RootState) => state.search;
export const { clearState } = searchSlice.actions;
export default searchSlice.reducer;