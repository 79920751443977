import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../hook";

export const Screen = () => {
  const status = useAppSelector((state: any) => state.auth.status);
  const location = useLocation();

  // Customize the content based on the location.pathname
  const getPageContent = () => {
    switch (location.pathname) {
      case "/about-us":
        return {
          title: "About Us",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };
      case "/subscriptions-list":
        return {
          title:
            "Select Subscription Plan &\nDownload FAA Aircraft Registration Database",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };

      case "/aviation-research-services":
        return {
          title: "Off-Market Aircraft Leads",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };

      case "/search":
        return {
          title: "Search",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };

        // case "/aviation-research-services":
        return {
          title: "Aviation Research Services",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };

      case "/aviation-list-services":
        return {
          title: "Aviation List and Label Services",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };

      case "/ame-map":
        return {
          title: "AME Patient Leads",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };

      // Admin
      // Quick Search
      case "/quick-search":
        return {
          textUp: "1",
          title: "Quick Search",
          description:
            " Here, you can instantly download the latest updated pilot and aircraft records.",
          background: "url('images/home-banner.png')",
          buttonContent: "Quick Search for Airmen & Aircraft",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      // Begin Your Search
      case "/search-airmen-records":
        return {
          title: "Search Airmen Records",
          textUp: "1",
          description:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s",
          buttonContent: "Quick Search for Airmen & Aircraft",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };

      case "/search-airmen-certificate-records":
        return {
          title: "Search Airmen Certificate Records",
          description:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s",
          background: "url('images/home-banner.png')",
          buttonContent: "Quick Search for Airmen & Aircraft",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };

      case "/search-aircraft-records":
        return {
          textUp: "1",
          title: "Search Aircraft Records",
          description:
            "An absolute must for aviation and aircraft sales professionals.",
          background: "url('images/home-banner.png')",
          buttonContent: "Quick Search for Airmen & Aircraft",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/personalInfo":
        return {
          title: "Personal Info",
          background: "url('images/AdminImages/Personal-Info-img.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      // history
      case "/your-subscription-history":
        return {
          title: "Your Subscription History",
          description:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/your-payment-history":
        return {
          title: "Your Payments History",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };

      case "/your-downloads-history":
        return {
          title: "Your Downloads History",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };

      // planeList DataBase
      case "/plane-lists-databases":
        return {
          title: "PlaneLists.com Databases",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/login":
        return {
          title: "Login",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/register":
        return {
          title: "Register",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/active-airmen-pilots":
        return {
          title: "Active Airmen & Pilots ",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/AMEs":
        return {
          title: "AMEs",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/marketing-genius":
        return {
          title: "Marketing Genius ",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/historical-data":
        return {
          title: "Historical Data ",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };
      case "/US-Aircraft-Owners":
        return {
          title: "US Aircraft Owners ",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
          // Other specific content for Page 2
        };

      // Footer Links
      case "/about":
        return {
          title: "About US",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };
      case "/contact":
        return {
          title: "Contact US",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };
      case "/privacy-policy":
        return {
          title: "Privacy Policy",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };
      case "/blog":
        return {
          title: "Blogs",
          background: "url('images/home-banner.png')",
          backgroundSize: "cover",
        };
      // Add more cases for different pages as needed
      default:
        return {
          title: "Default Title",
          description: "Default Description...",
          // Default content
        };
    }
  };

  const { title, description, background, buttonContent, textUp } =
    getPageContent();

  return (
    <>
      <div
        id="hs_cos_wrapper_widget_34472035839"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {/* Label For Modules Data Page */}
        <div className="widget_1601501547743 sr-hero-01 sr-cover-parallax  prototype-no-background  ">
          <div
            className="sr-cover-image overlay-"
            style={{ background, backgroundSize: "cover" }}
          />
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="col-12">
                  <div className="sr-cover d-flex align-items-center  text-center height-medium">
                    <div className="sr-cover-inner text-white">
                      {title ? (
                        <h1 className="section-heading text-white">{title}</h1>
                      ) : (
                        ""
                      )}
                      {status ? (
                        <>
                          <div className="description mt-1">
                            <p>{description}</p>
                          </div>

                          {buttonContent && (
                            <div className="sr-spacer-topx-0 py-25 cta-wrapper">
                              <div className="btn-wrapper btn-primary-wrapper">
                                <a href="#" className="cta_button">
                                 {buttonContent}
                                </a>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {buttonContent && (
                            <div className="sr-spacer-topx-0 py-25 cta-wrapper">
                              <div className="btn-wrapper btn-primary-wrapper">
                                <Link to="/login" className="cta_button">
                                  {buttonContent}
                                </Link>
                              </div>
                            </div>
                          )}

                          <div className="description mt-3">
                            <p>{description}</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
