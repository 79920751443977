import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../RepeatedComponent/Breadcrumb";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { currentPlan } from "../../../../Features/PlansSlice";
import { Loader } from "../../RepeatedComponent/Loader";
import { fetchUser } from "../../../../Features/fetchUserSlice";
 
export const YourPaymentsHistory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const paymentsHistory = useAppSelector((state: any) => state?.plans?.response);
  const status = useAppSelector((state: any) => state.auth.status);
  const userEmail = useAppSelector((state: any) => state?.user?.userInfo?.user?.email);
 
  useEffect(() => {
    dispatch(fetchUser());
    dispatch(currentPlan());
  }, [dispatch]);
 
  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);
 
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: any = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
 
  const capitalizeFirstLetterOfWords = (str: string) => {
    return str.replace(/\b\w/g, (char: string) => char.toUpperCase());
  };
 
  const transformData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      task_type: item.task_type
        ? capitalizeFirstLetterOfWords(item.task_type.replace(/_/g, " ").toLowerCase())
        : "",
      export_file_type: item.export_file_type
        ? item.export_file_type.replace(/_/g, " ").toUpperCase()
        : "",
      planType: item.planType
        ? capitalizeFirstLetterOfWords(item.planType.toLowerCase())
        : "",
    }));
  };
 
  const handleExport = () => {
    // Convert data to CSV format
    const csvData = transformData(paymentsHistory.subscriptions_history)
      .map((item: any) => Object.values(item).join(","))
      .join("\n");
 
    // Create a Blob object with CSV data
    const blob = new Blob([csvData], { type: "text/csv" });
 
    // Create a link element to trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "payments_history.csv";
    link.click();
 
    // Log the data in JSON format to console
    console.log(JSON.stringify(paymentsHistory.subscriptions_history));
  };
 
  return (
    <>
      {loading && <Loader />}
      <div className="sr-multicol-media pt-1 pt-lg-4 text-center prototype-no-background widget_1600979911688 global">
        <div className="container-fluid content-wrapper">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      {/* Payment History */}
      <div id="hs_cos_wrapper_widget_1604099686647" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{}} data-hs-cos-general-type="widget" data-hs-cos-type="module">
        <span id="hs_cos_wrapper_main_flexible_column" className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container bg-secondary" style={{}} data-hs-cos-general-type="widget_container" data-hs-cos-type="widget_container">
          <div id="hs_cos_wrapper_widget_1604340283000" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" style={{ background: "#EDF1D1" }} data-hs-cos-general-type="widget" data-hs-cos-type="module">
            <div className="sr-cards-grid-02 bg-black sr-padding-full prototype-no-background widget_1604340283000">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <div className="cards row">
                      <div className="container-fluid">
                        <div className="row ">
                          <div className="col-md-12">
                            <h2 className=" section-heading font-inter text-white text-center dash-heading" style={{ backgroundColor: "black", color: "white" }}>Payment History</h2>
                          </div>
                          <div className="col-md-12 pt-3 d-flex justify-content-between border-0">
                            <div className="col-12 col-sm-12 col-md-11 col-lg-11 d-flex border-0">
                              <div className="col-5 col-sm-4 input-group">
                                <span className="input-group-text payment-history-inputes" id="basic-addon1">From Date</span>
                                <input type="date" className="form-control small h-100" name="from-date" id="from-date" autoComplete="on" />
                              </div>
                              <div className="col-4 col-sm-4 input-group input-small-screen">
                                <span className="input-group-text payment-history-inputes" id="basic-addon1">To Date</span>
                                <input type="date" className="form-control small h-100 " name="to-date" id="to-date" autoComplete="on" />
                              </div>
                              <div className="col-2 col-sm-2 d-flex input-small-screen1">
                                <button type="button" className="btn btn-primary payment-history-inputes" onClick={handleExport}>
                                  <span className="text-white">EXPORT</span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 overflow-auto border mb-5 m-3">
                            <table className="table custom-downloads-table bg-black text-white">
                              <thead className="thead custom-downloads-thead">
                                <tr>
                                  <th scope="col" className="text-nowrap">Email</th>
                                  <th scope="col" className="text-nowrap">Plan Name</th>
                                  <th scope="col" className="text-nowrap">Plan Type</th>
                                  <th scope="col" className="text-nowrap">Payment Date</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentsHistory?.subscriptions_history && paymentsHistory?.subscriptions_history.length > 0 ? (
                                  paymentsHistory?.subscriptions_history.map((item: any, index: number) => (
                                    <tr key={index}>
                                      <td scope="row" className="text-nowrap">{userEmail}</td>
                                      <td scope="row" className="text-nowrap">{item?.plan_name}</td>
                                      <td scope="row" className="text-nowrap">{item?.plan_type?.replace(/\b\w/g, (char: string) => char.toUpperCase())}</td>
                                      <td scope="row" className="text-nowrap">{formatDate(item?.start_date)}</td>
                                      <td scope="row" className="text-nowrap">{item?.is_active ? "Active" : "Inactive"}</td>
                                      <td scope="row" className="text-nowrap">${item?.price}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={6}>No Payment History found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>{" "}
      </div>
    </>
  );
};