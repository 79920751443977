import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import "react-toastify/dist/ReactToastify.css";
import { TiMail } from "react-icons/ti";
import { BiSolidLock } from "react-icons/bi";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { clearState, userLogin } from "../../../Features/authSlice";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import CryptoJS from "crypto-js";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { Loader } from "../RepeatedComponent/Loader";
import ReCAPTCHA from "react-google-recaptcha";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";

export const Login: React.FC = () => {
  const data = useAppSelector((state) => state.auth);
  const { status, error } = data;
  // console.log(data ,"Data")
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(0);
  const [defaultValue, setDefaultValue] = useState({ email: "", password: "" });

  useEffect(() => {
    const encryptedEmail = Cookies.get("encryptedEmail");
    // Load data from cookie when component mounts
    if (encryptedEmail) {
      const decryptedEmail = CryptoJS.AES.decrypt(
        encryptedEmail,
        "secret_key"
      ).toString(CryptoJS.enc.Utf8);
      console.log(decryptedEmail, "decrepeted email");
      setDefaultValue((prevState) => ({
        ...prevState,
        email: decryptedEmail,
      }));
    }
  }, []);

  const toggleShowPassword = () => {
    setShowPassword(true);
  };

  const toggleHidePassword = () => {
    setShowPassword(false);
  };
  const handleRecaptchaChange = (token: any) => {
    console.log("Recaptcha token:", token);
    setToken(token);
    // Add any logic for handling reCAPTCHA verification
  };
  // Validation schema using yup
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    password: yup
      .string()
      .required("Password is required")
      .matches(/^.{8,}$/, "Password must contain at least 8 characters"),
    // tokencap:yup
    //  .string()
    //  .required("Please verify reCaptcha")
  });

  /// Handle form submission
  const handleSubmit = (values: any) => {
    if (!token) {
      toast.error("ReCaptcha Validation Failed", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
      });
      return;
    }
    if (rememberMe) {
      // Set cookies only if rememberMe is true
      // Encrypt email using a secret key
      const encryptedEmail = CryptoJS.AES.encrypt(
        values.email,
        "secret_key"
      ).toString();

      // Store encrypted email in cookie
      Cookies.set("encryptedEmail", encryptedEmail);
      // Cookies.set("userEmail", values.email);
    } else {
      // Remove cookies if rememberMe is false
      Cookies.remove("userEmail");
    }
    setLoading(1);
    dispatch(userLogin(values)).then((res: any) => {
      setLoading(0);
    }); // Dispatching the signInUser action with form values
  };

  useEffect(() => {
    if (error && error === "Rejected") {
      toast.error("Invalid email or password", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
      });
      dispatch(clearState());
    }
  }, [handleSubmit]);

  // useEffect(() => {
  //   if (error && error === "Rejected") {
  //     toast.success(" Password updated successfully", {
  //       position: "top-center",
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       draggablePercent: 8000,
  //       pauseOnFocusLoss: true,
  //     });
  //     dispatch(clearState());
  //   }
  // }, []);

  useEffect(() => {
    if (status) {
      navigate("/user-dashboard");
      // let a:any={show:true,msg:'Login Successfully'};
      // dispatch(callToaster(a))
    }
  }, [status, navigate]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  const setDataToCookie = () => {
    // Toggle rememberMe state
    setRememberMe(!rememberMe);
  };

  return (
    <>
      <div
        className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center prototype-no-background 
      widget_1600979911688 "
      >
        <div className="container-fluid content-wrapper ">
          <div
            className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5"
            style={{ backgroundColor: "black", color: "white" }}
          >
            <div className="col-md-10 dashtext ">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <h2
        className=" section-heading font-inter text-white text-center dash-heading auth-form-heading"
        style={{ backgroundColor: "black", color: "white" }}
      >
        Login
      </h2>
      {loading ? <Loader /> : ""}

      <div className="position-relative">
        <Formik
          initialValues={defaultValue}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <div className=" bg-body-tertiary  d-flex flex-row gray-bg bg-black ">
            <div className="container  mt-sm-5  align-items-stretch">
              <div className="row justify-content-center  p-0 pt-md-0 pb-md-5">
                <div className="col-md-10  overflow-hidden login-container p-0">
                  <div className="d-flex flex-column flex-md-row ">
                    <div className=" card p-2 m-3 m-sm-0 bg-black border">
                      <div className="card-body ">
                        <Form>
                          <div className="p-md-5 p-2">
                            <ToastContainer />
                            <div className="input-group mb-1">
                              <span
                                className="input-group-text mobile-icon-size"
                                id="basic-addon1"
                              >
                                <TiMail />
                              </span>
                              <Field
                                type="email"
                                className="form-control "
                                name="email"
                                placeholder="Enter Your Email ID"
                                aria-label="Enter Your Email ID"
                                aria-describedby="basic-addon1"
                                autoComplete="on"
                              />
                            </div>
                            <p className="text-danger field-errorMessage mb-3">
                              <ErrorMessage name="email" />
                            </p>

                            <div className="input-group mb-1 ">
                              <span
                                className="input-group-text mobile-icon-size"
                                id="basic-addon1"
                              >
                                <BiSolidLock />
                              </span>
                              <Field
                                type={showPassword ? "text" : "password"}
                                name="password"
                                className="form-control position-relative"
                                placeholder="Password"
                                aria-label="Password"
                                aria-describedby="basic-addon1"
                                autoComplete="current-password"
                              />
                              <div className="eye-icon-container">
                                {showPassword ? (
                                  <img
                                    src="./images/open-eye.svg"
                                    className="eye-icon-size"
                                    onClick={toggleHidePassword}
                                  />
                                ) : (
                                  <img
                                    src="./images/close-eye.svg"
                                    className="eye-icon-size"
                                    onClick={toggleShowPassword}
                                  />
                                )}
                              </div>
                            </div>
                            <p className="text-danger field-errorMessage mb-2">
                              <ErrorMessage name="password" />
                            </p>

                            {/* <div
                              className=" d-flex justify-content-between form-check pb-md-2 pb-1"
                              style={{ fontSize: "0.9rem" }}
                            >
                              <div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={rememberMe}
                                onChange={setDataToCookie}
                                id="rememberMe"
                              />
                              <label
                                htmlFor="rememberMe"
                                className="form-check-label ml-2 ml-md-0 text-white"
                              >
                                Remember me
                              </label>
                              </div>
                          <div>
                          <label
                                className="form-check-label ml-2 ml-md-0 text-white"
                              >
                                  <Link to="/forgot-password" className="text-white" >
                             Forgot password?
                             </Link>
                              </label>
                          </div>
                            </div> */}
                            <div className="row mb-3  d-flex justify-content-center ">
                              <div
                                className="g-recaptcha col-12 ml-2"
                                data-theme="light"
                                data-sitekey="XXXXXXXXXXXXX"
                                // style={{
                                //   transform: "scale(0.77)",
                                //   WebkitTransform: "scale(0.77)",
                                //   transformOrigin: "0 0",
                                //   WebkitTransformOrigin: "0 0",
                                // }}
                              >
                                <ReCAPTCHA
                                  sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                  onChange={handleRecaptchaChange}
                                />
                              </div>

                              <Field
                                type="hidden"
                                className="form-control small"
                                name="tokencap"
                                value={token}
                                autoComplete="on"
                              />
                              {/* </div> */}
                            </div>
                            <p className="text-danger field-errorMessage mb-3">
                              <ErrorMessage name="tokencap" />
                            </p>
                            <div className="d-flex justify-content-center ">
                              <button
                                type="submit"
                                className="btn btn-primary w-100  justify-content-center"
                                onClick={scrollToTop}
                              >
                                <span className="text-white custom-btn-mobile">
                                  Login
                                </span>
                              </button>
                            </div>
                            <div className="d-flex justify-content-center col-12 pt-4">
                              <p className="not-a-member text-white">
                                Not a member?
                              </p>
                              <Link to="/register" onClick={scrollToTop}>
                                <p className="register pl-1">Register</p>
                              </Link>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>

                    <div className="d-none d-md-block bg-black border">
                      <img
                        src="images/HomePageImg/newLoginImg.jpg"
                        style={{ height: "100%" }}
                        alt="Registration Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Formik>
        <FreeTrial />
      </div>
    </>
  );
};
