import React from "react";
import { Screen } from "../../RepeatedComponent/Screens";
import "../admin.css";
export const SearchAircarftRecords = () => {
  return (
    <>
      <Screen />

      <div
        id="hs_cos_wrapper_widget_1604012269835"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-multicol-media bg-white sr-padding-half text-center prototype-no-background widget_1604012269835 "
          id="research-property"
        >
          <div className="container-fluid content-wrapper">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h2 className=" section-heading font-inter">Aircraft Data</h2>
                <p>ALL AIRCRAFT DATA IS UPDATED DAILY AT 0200 EDT</p>
                <div className="row  sr-valign-center">
                  <div className="col-md-6 sr-col text-left  sr-repeater-1">
                    <div className="content-wrapper">
                      <div className="col-content">
                        <p className="justify-content">
                          Here you can search every make and model of every
                          aircraft with a US Registration. Search by
                          Manufacturer, Model, Type, Engine type, last action
                          take. Further refine your aircraft search by City,
                          State, Geographical Location, registration expiration,
                          ZIP Code and distance.
                        </p>
                        <p className="justify-content">
                          Search and download the entire US registered aircraft
                          database for a single type or multiple types depending
                          on your needs.
                        </p>
                        <h6 className="font-inter">
                          <p className="custom-text-color-orange">
                            {" "}
                            But wait....{" "}
                          </p>
                        </h6>
                      </div>
                      <div className="container custom-para-outline">
                        <div className="row  justify-content">
                          <div className="col-12 p-3 ">
                            <p>
                              {" "}
                              <span>
                                You can also search and cross reference the
                                pilot database at the same time to see if the
                                pilot has a current or expired medical.Simply
                                check the box where it says: "Search and Include
                                Airman and Pilot Information"
                              </span>
                            </p>
                            <p className="pt-0">
                              {" "}
                              <span>
                              All data is easily downloaded in a .csv format or convenient Avery Label Format for bulk mailing
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 sr-col  sr-repeater-2">
                    <div className="content-wrapper">
                      <div className="responsive-image ">
                        <img
                          src="images/AdminImages/aircraftIpadImg.png"
                          alt="Img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
