import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./newAircraftRecords.css";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { Field, Form, Formik } from "formik";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { ameZipCode } from "../../../../Features/ameZipCodeSlice";
import React from "react";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { Loader } from "../../RepeatedComponent/Loader";
import makeAnimated from "react-select/animated";
import { clearState } from "../../../../Features/authSlice";
import { getPilotStates } from "../../../../Features/downloadHistorySlice";
import apiClient from "../../../../Features/FetchClient";
import { ExportQuickAircraftRecords } from "../../../../Features/pilotRecordSlice";
const animatedComponents = makeAnimated();
export interface StateOption {
  readonly value: string;
  readonly label: string;
}
interface Option {
  label: string;
  value: string;
}
export const NewAircraftRecords = () => {
  const [loading, setLoading] = useState(0);
  const status = useAppSelector((state: any) => state.auth.status);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);
  const dispatch = useAppDispatch();
  // const { values } = useFormikContext();
  const [zip_code, setZipCode] = useState([""]);
  const [selectedTab, setSelectedTab] = useState("pilot");
  const [locations, setLocations] = useState("state");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [engineName, setEngineName] = useState<Option[]>([]);
  const [modelName, setModelName] = useState<Option[]>([]);
  const [modelType, setModelType] = useState<Option[]>([]);
  const [aircraftDays, setAirCraftDays] = useState<any>({ value: "30 Days", label: "30 Days" });
  const [distance, setDistance] = useState<any>({});
  const [includeAirmen, setIncludeAirmen] = useState<any>(false);
  const [selectedCSV, setSelectedCSV] = useState("CSV");
  const [selectedOneMonth, setselectedOneMonth] = useState("30Days");
  const [subscriptionMonth, setSubscriptionMonth] = useState<any>(0);
  const activeSubscription = useAppSelector(
    (state: any) => state.plans.message
  );

  const states = useAppSelector((state: any) => state.downloadHistory.states);
  const geoLocation = [
    { value: "Al", label: "Alaskan" },
    { value: "Ce", label: "Central" },
    { value: "Ea", label: "Eastern" },
    { value: "Fo", label: "Foreign" },
    { value: "Gl", label: "Great Lakes" },
    { value: "Nm", label: "Northwest Mountain" },
    { value: "So", label: "Southern" },
    { value: "Sw", label: "Southwestern" },
    { value: "Wp", label: "Western-Pacific" },
  ];
  const manufacturerTypeOptions = [
    {value: "210A", label: "210A"},
    {value: "58X", label: "58X"},
    {value: "62X", label: "62X"},
    {value: "AERO DESIGNSPULSARXP", label: "AERO DESIGNSPULSARXP"},
  ]
  const engineTypes = [
    { value: "7", label: "2 Cycle" },
    { value: "8", label: "4 Cycle" },
    { value: "10", label: "Electric" },
    { value: "0", label: "None" },
    { value: "6", label: "Ramjet" },
    { value: "1", label: "Reciprocating" },
    { value: "11", label: "Rotary" },
    { value: "5", label: "Turbo-fan" },
    { value: "4", label: "Turbo-jet" },
    { value: "2", label: "Turbo-prop" },
    { value: "3", label: "Turbo-shaft" },
    { value: "9", label: "Unknown" },
  ];

  const singleSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "black!important",
      borderColor: "black!important",
      margin: 0,
      marginLeft: 0,
      border: "0px solid black",
      fontSize: 13,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black!important",
      },
      
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      backgroundColor: "black",
      "&:hover": {
        backgroundColor: "black",
      },
    }),
    input: (styles:any) => ({ ...styles, color:"white"}),
    placeholder: (styles:any) => ({ ...styles, color:"gray",fontSize:"14"}),
    singleValue: (styles:any) => ({ ...styles, color:"white", fontSize:"1rem" })
  };

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      background: "black",
      borderColor: "black",
      margin: 0,
      marginLeft: 0,
      borderWidth: "0px", // Changed 'border' to 'borderWidth'
      borderStyle: "solid", // Added 'borderStyle' to specify border style
      fontSize: 14,
      outline: 'none !important',
      boxShadow: 'none',
      '&:hover': {
        borderColor: "black",
      }
    }),
    input: (styles: any) => ({ ...styles, color: "white" }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "gray",
      fontSize: 14, // Removed quotes around 14
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: "#E35D22",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      background: "black",
      "&:hover": {
        backgroundColor: "red",
      }
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      backgroundColor: "black",
      "&:hover": {
        backgroundColor: "black",
      }
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
      textAlign: "left",
    }),
  };

  const formattedStates = states
    ? states
        .filter(
          (stateObj: { state: string; state_abbrev: string }) =>
            stateObj.state && stateObj.state_abbrev
        )
        .map((stateObj: { state: string; state_abbrev: string }) => ({
          value: stateObj.state,
          label: stateObj.state_abbrev,
        }))
    : [];

  const registration = [
    {
      value: "selected",
      label: "Please select",
    },
    {
      value: "30",
      label: "30 Days",
    },
    {
      value: "60",
      label: "60 Days",
    },
    {
      value: "90",
      label: "90 Days",
    },
    {
      value: "30",
      label: "select Date Range",
    },
  ];

  // console.log(formattedStates, "formatedState,");

  let zipData: any = {
    zipCode: "",
    distance: "",
  };

  const initialValues = {
    "m_name": [],
    "model_name": [],
    "aircraft_days": "90 Days",
    "daterange": "03/21/2024 - 03/21/2024",
    "e_name": [7,8],
    "aircraft_region_type": "state",
    "zip_code": [] ,
    "include_airman": true,
    "export_file": "CSV"
  };

  const registrationDays = [
    { value: "30 Days", label: "30 Days" },
    { value: "60 Days", label: "60 Days" },
    { value: "90 Days", label: "90 Days" },
    {
      value: "30",
      label: "select Date Range",
    },
  ];

  

  const validationSchema = yup.object().shape({});

  /// Handle form submission
  const handleSubmitSetData = (values: any) => {
    setLoading(1);
    const obj = {
      "m_name": modelName.map((o:any) => o.value),
      "model_name":  modelType.map((o:any) => o.value),
      "aircraft_days": aircraftDays?.value,
      "daterange": "03/21/2024 - 03/21/2024",
      "e_name": engineName.map((o:any) => o.value),
      "aircraft_region_type": locations,
      "zip_code": [] ,
      "include_airman": includeAirmen,
      "export_file": values.export_file
    }
    const payload: any = {
      zipCode: values.zip_code,
      distance: distance?.value,
    };
    setLoading(1);
    dispatch(ameZipCode(payload))
      .then((res: any) => {
        if (res.payload.length > 0) {
          // Handle the result of the async thunk action
          const zipCodes = res.payload.map((item: any) => item.ZipCode);
          obj.zip_code = zipCodes;
        } 
        dispatch(ExportQuickAircraftRecords(obj)).then((res:any) => {
          console.log(res);
          setLoading(0);
          // if(Array.isArray(res.count) && res.count.length > 0) {
            toast.success("Export request has been submitted successfully. Your file will download shortly.", {
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 8000,
              pauseOnFocusLoss: true
            });
           
          // }
        }).catch((err:any) => {
          setLoading(0);
          console.log(err,'API error. ');
        });
      })
      .catch((error: any) => {
        setLoading(0);
        // Handle any errors that occur during the async operation
        console.error("Error: in zipdata api", error);
      });
  };

  const handleRadioLoacation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocations(event.target.value);
  };
  const handleRadioCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCSV(event.target.value);
  };

  const marketingDistance = [
    {
      value: "selected",
      label: "Please select",
    },
  ];

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
    });
  }

  const onChangeZipCode = (e: any) => {
    zipData.zipCode = e.target.value;
    const { value } = e.target;
    if (value?.length > 9) {
      e.target.value = value.slice(0, 9); // Limit to 9 characters
    }
    if (zipData.zipCode && zipData.distance) {
      dispatch(ameZipCode(zipData)).then((res: any) => {
        console.log("responce from zip code", res.payload);
      });
    }
  };

  const onChangeMarketingDistance = (e: any) => {
    const distance = e.target.value;
    if (zipData.zipCode && distance) {
      zipData.distance = distance;
      dispatch(ameZipCode(zipData)).then((res: any) => {
        const zipCodes = res.payload.map((item: any) => item.ZipCode);
        setZipCode(zipCodes);
      });
    }
  };

  useEffect(() => {
    // dispatch(clearState());
    dispatch(getPilotStates());
  }, []);

  const searchManufacturerName = async (name: string = '') => {
    if(name.length > 1) {
      const result:any = await apiClient.get(`${process.env.REACT_APP_API_URL}/manufacturer_names?q=${name}`)

      if(result.data && Array.isArray(result.data.manufacturer_names) && result.data.manufacturer_names.length > 0) {
        const newArr = result.data.manufacturer_names.map((o:any) => { return {"value":o,"label":o}});
        return newArr
      } else {
        return []
      }
    } else {
      return [];
    }
  }

  const searchManufacturerType = async (name: string = '') => {
    if(name.length > 1) {
      const result:any = await apiClient.get(`${process.env.REACT_APP_API_URL}/manufacturer_names?q=${name}`)

      if(result.data && Array.isArray(result.data.manufacturer_names) && result.data.manufacturer_names.length > 0) {
        const newArr = result.data.manufacturer_names.map((o:any) => { return {"value":o,"label":o}});
        return newArr
      } else {
        return []
      }
    } else {
      return [];
    }
  }

  return (
    <>
      {loading ? <Loader /> : ""}
      <ToastContainer />
      <div
        id="hs_cos_wrapper_widget_1695156991887"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Aviation List and Label Services */}
        <div className="sr-multicol-media bg-black text-white sr-padding-full text-center prototype-no-background widget_1695156991887 ">
          <div className="container-fluid content-wrapper newAircraft">
            <div className="row  sr-valign-center">
              <div className="col-md-12 p-2 pb-4 pt-4 sr-col text-left  sr-repeater-2 custom-border-orange">
                {loading ? <Loader /> : ""}
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmitSetData}
                >
                  <Form>
                    <div className="container-fluid">
                      {/* Select Aircraft Manufacture Names:  &  Aircraft Manufacturer Type:*/}

                      <div className="row p-3">
                        <div className="col-12 col-md-6 px-3 newAircraft-filed-m ">
                          <div className="col-12 col-md-12 d-flex  align-items-center custom-border-orange-field py-2">
                            <div className="custom-form-sub-heading">
                              Select Aircraft Manufacture Names
                            </div>
                            <AsyncSelect
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              loadOptions={searchManufacturerName}
                              isMulti
                              options={geoLocation}
                              styles={colourStyles}
                              onChange={(e:any) => setModelName(e)}
                              placeholder='Select Manufacture' 
                              className="w-100"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 px-3 newAircraft-filed-m">
                          <div className="col-12 col-md-12 d-flex  align-items-center custom-border-orange-field py-2 newAircraft-filed-m">
                            <div className="custom-form-sub-heading">
                              Aircraft Manufacturer Type:
                            </div>
                            <AsyncSelect
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              loadOptions={searchManufacturerType}
                              onChange={(e:any) => setModelType(e)}
                              options={manufacturerTypeOptions}
                              placeholder='Please select Manufacturer Name first' 
                              styles={colourStyles}
                              className="w-100"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Registration & Engine Types:(Leave blank for all) */}
                      <div className="row pt-3 px-3">
                        <div className="col-md-6 col-sm-12 p-0 px-3">
                                <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                  <div className="custom-form-sub-heading">
                                    Registration
                                  </div>
                                  <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={registrationDays}
                                    styles={singleSelectStyles}
                                    className="w-100"
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "#E35D22",
                                        primary: "black",
                                      },
                                    })}
                                    onChange={(e:any) => setAirCraftDays(e)}
                                  />

                                  <div className="amePopup-label">
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      htmlFor="outputFormat"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                        <div className="col-12 col-md-6 px-3 newAircraft-filed-m">
                          {" "}
                          <div className="col-12 custom-border-orange-field py-2 newAircraft-filed-m">
                            <div className="custom-form-sub-heading">
                              Engine Types:(Leave blank for all) *
                            </div>
                            <Select
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                              options={engineTypes}
                              placeholder='Select Engine Types to include' 
                              styles={colourStyles}
                              className="w-100 h-100"
                              onChange={(e:any) => setEngineName(e)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Geo Location */}
                      {locations === "geo_location" ? (
                        <>
                          <div className="row p-3">
                            <div className="col-12 col-md-12 p-0 px-3 newAircraft-filed-m">
                              <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                <div className="custom-form-sub-heading">
                                  Geo Location
                                </div>
                                <Select
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  isMulti
                                  options={geoLocation}
                                  placeholder='Leave blank for all' 
                                  styles={colourStyles}
                                  className="w-100"
                                  onChange={(e:any) => setSelectedOptions(e)}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* States */}
                      {locations === "state" ? (
                        <>
                          <div className="row p-3 newAircraft-filed-m mt-md-3">
                            <div className="col-12 col-md-12 p-0 px-3">
                              <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                <div className="custom-form-sub-heading">
                                  State
                                </div>
                                <Select
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  isMulti
                                  options={formattedStates}
                                  placeholder='Leave blank for all' 
                                  styles={colourStyles}
                                  className="w-100"
                                  onChange={(e:any) => setSelectedOptions(e)}
                                />

                                <div className="amePopup-label">
                                  <label
                                    className="form-check-label pl-2 pl-md-0"
                                    htmlFor="outputFormat"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                           {/* City: zip code  & miles Distance: */}
                           <div className="row p-3">
                                  <div className="col-md-6 col-sm-12 p-0 px-3">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        AME Office Zip Code
                                      </div>
                                      <Field
                                        type="number"
                                        className="form-control bg-black text-white border-0 "
                                        name="zip_code"
                                        placeholder="e.g.12345"
                                        aria-label="Enter Your Email ID"
                                        aria-describedby="basic-addon1"
                                        autoComplete="off"
                                      />

                                      <div className="amePopup-label">
                                        <label
                                          className="form-check-label pl-2 pl-md-0"
                                          htmlFor="outputFormat"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-sm-12 p-0 px-3 pt-3 pt-md-0">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        Marketing Distance From Office
                                      </div>
                                      <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={marketingDistance}
                                        styles={singleSelectStyles}
                                        placeholder='Please select' 
                                        className="w-100"
                                        onChange={(e:any) => setDistance(e)}
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 0,
                                          colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "#E35D22",
                                            primary: "black",
                                          },
                                        })}
                                      />

                                      <div className="amePopup-label">
                                        <label
                                          className="form-check-label pl-2 pl-md-0"
                                          htmlFor="outputFormat"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* Choose By:*/}
                      <div className="row p-3">
                        <div className="col-12 col-md-6  px-3 ">
                          <div className="col-12 d-flex justify-content-between custom-border-orange-field py-4 ">
                          <div className="custom-form-sub-heading">
                              Choose By:
                            </div>
                            <div className=" col-12  offmarket-output-format">
                          
                            <div className="amePopup-label d-flex align-items-center">
                              <div>
                                <Field
                                  className="form-check-input"
                                  type="radio"
                                  name="chooseBy"
                                  id="geoLocation"
                                  value="geoLocation"
                                  checked={locations === "geo_location"}
                                  onClick={handleRadioLoacation}
                                />
                              </div>

                              <label
                                className="form-check-label pl-2 pl-md-0 "
                                htmlFor="geoLocation"
                              >
                                Geo Location
                              </label>
                            </div>

                            <div className=" amePopup-label d-flex align-items-center">
                              <div>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="chooseBy"
                                id="State"
                                value="State"
                                checked={locations === "state"}
                                onClick={handleRadioLoacation}
                              />
                              </div>
                             
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="State"
                              >
                                State
                              </label>
                            </div>
                            <div className=" amePopup-label d-flex align-items-center">
                              <div>

                              </div>
                             
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="State"
                              >
                               
                              </label>
                            </div>

                            </div>
                           

                          

                            <div className="amePopup-label">
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="outputFormat"
                              ></label>
                            </div>
                          </div>
                        </div>
                        {/* <p className="text-danger field-errorMessage">
                                <ErrorMessage name="chooseBy" />
                              </p> */}

                        <div className="col-12 col-md-6 d-flex  align-items-center px-3  pl-3 newAircraft-filed-m">
                          <div className="col-12 custom-border-orange-field py-4 pl-4 ">
                            <div className="custom-form-sub-heading">
                              Include Airman
                            </div>
                            <div className="form-check amePopup-label col-12 pt-2 pt-md-0  d-flex align-items-center">
                              <div>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="airman_class"
                                id="includeAirman"
                                value={includeAirmen}
                                onChange={(e:any) => setIncludeAirmen(e.target.checked)}
                                // checked={checkboxes.includeAirman}
                                // onChange={checkedAllClass}
                              />
                              </div>
                             
                              <label
                                className="form-check-label pl-2 pl-md-2"
                                htmlFor="includeAirman"
                              >
                                Search and Include Airman and Pilot information
                              </label>
                            </div>
                          </div>

                          <div className="amePopup-label">
                            <label
                              className="form-check-label pl-2 pl-md-0"
                              htmlFor="outputFormat"
                            ></label>
                          </div>
                        </div>
                      </div>

                      {/* Output Format */}
                      <div className="row p-3">
                        <div className="col-12 col-md-12  px-3 ">
                          <div className="col-12 d-flex  custom-border-orange-field py-4 ">
                            <div className="custom-form-sub-heading">
                              Output Format
                            </div>
                            <div className="col-12 col-md-12 offmarket-output-format" >
                            <div className=" amePopup-label d-flex align-items-center">
                              <div>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="export_file"
                                id="CSV"
                                value="CSV"
                                checked={selectedCSV === "CSV"}
                                onClick={handleRadioCSV}
                              />
                              </div>

                         
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="CSV"
                              >
                                CSV
                              </label>
                            </div>
                            <div className=" amePopup-label d-flex align-items-center">
                              <div>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="export_file"
                                id="RTF"
                                value="RTF"
                                checked={selectedCSV === "RTF"}
                                onClick={handleRadioCSV}
                              />
                              </div>
                             
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="RTF"
                              >
                                RTF
                              </label>
                            </div>

                            <div className="amePopup-label d-flex align-iterm-center">
                              <div>
                              <Field
                                className="form-check-input"
                                type="radio"
                                name="export_file"
                                id="avery5163LabelPDF"
                                value="avery5163LabelPDF"
                                checked={selectedCSV === "avery5163LabelPDF"}
                                onClick={handleRadioCSV}
                              />
                              </div>
                             
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="avery5163LabelPDF"
                              >
                                Avery 5163 Label PDF
                              </label>
                            </div>

                            <div className="amePopup-label d-flex align-iterm-center ">
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="outputFormat"
                              ></label>
                            </div>
                            <div className="amePopup-label d-flex align-iterm-center ">
                              <label
                                className="form-check-label pl-2 pl-md-0"
                                htmlFor="outputFormat"
                              ></label>
                            </div>
                       
                            </div>
                          </div>
                        </div>
                        {/* <p className="text-danger field-errorMessage">
                                <ErrorMessage name="export_file" />
                              </p> */}
                      </div>
                    </div>
                    <div className="text-center pt-3">
                      <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                        <button
                          type="submit"
                          className="exports-Records-btn custom-freeTrial-btn"
                        >
                          Exports Records
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
