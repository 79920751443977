import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import "./offMarketForm.css";
import * as yup from "yup";
import * as React from "react";
import { useAppDispatch } from "../../../hook";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { exportsRecords, exportsRecordsAircraft } from "../../../Features/exportsRecordsSlice";
import { ameZipCode } from "../../../Features/ameZipCodeSlice";
import { Loader } from "../RepeatedComponent/Loader";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import { ToastContainer, toast } from "react-toastify";
import AsyncSelect from 'react-select/async';

import makeAnimated from "react-select/animated";
import apiClient from "../../../Features/FetchClient";

interface CheckboxState {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  airTrafficController: boolean;
  aircraftMechanics: boolean;
  parachuteRiggers: boolean;
  dropZoneManagement: boolean;
  jumpPilots: boolean;
  [key: string]: boolean;
}
interface Option {
  label: string;
  value: string;
}

let zipData: any = {
  zipCode: "",
  distance: "",
};

interface Pilot {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  allClasses: boolean;
}

const animatedComponents = makeAnimated();
export interface StateOption {
  readonly value: string;
  readonly label: string;
}

export const OffMarketForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { values } = useFormikContext();
  const [zip_code, setZipCode] = useState([""]);
  const [loading, setLoading] = useState(0);
  const [location, setLocation] = useState("State");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [aircraftManufacturer, setAircraftManufacturer] = useState<string[]>(
    []
  );
  const [includeAirman,setIncludeAirman]=useState(false)
  const [options, setOptions] = useState<Option[]>([]);
  const [aircraftModel, setAircraftModel] = useState<string[]>([]);
  const [aircraftTypes, setAircraftTypes] = useState<string[]>([]);
  const [engineTypes, setEngineTypes] = useState<string[]>([]);
  // const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs("MM-DD-YYYY"));
    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs("YYYY-MM-DD"));
  const [certificateDate,setCertificateDate]=useState<Dayjs | null>(dayjs("MM-DD-YYYY"));
  const [lastActionDate,setLastActionDate]=useState<Dayjs | null>(dayjs("MM-DD-YYYY"));
  const [airWorthynessDate,setAirWorthynessDate]=useState<Dayjs | null>(dayjs("MM-DD-YYYY"));
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs("MM-DD-YYYY"));
  const [selectedTab, setSelectedTab] = useState("pilot");
  const [modelName, setModelName] = useState<Option[]>([]);
    const [contains,setContains]=useState({
      radio1:true,
      radio2:true,
      radio3:true,
      radio4:true,
      radio5:true,
      radio6:true,
      radio7:true,
      radio8:true,
      // radio1:true
    })
  // console.log(value, "value");
  const searchManufacturerName = async (name: string = '') => {
    if(name.length > 1) {
      const result:any = await apiClient.get(`${process.env.REACT_APP_API_URL}/manufacturer_names?q=${name}`)

      if(result.data && Array.isArray(result.data.manufacturer_names) && result.data.manufacturer_names.length > 0) {
        const newArr = result.data.manufacturer_names.map((o:any) => { return {"value":o,"label":o}});
        return newArr
      } else {
        return []
      }
    } else {
      return [];
    }
  }

  function getClassList(classes:any) {
  
    const classList = [];
    if (classes.firstClass) classList.push("1st");
    if (classes.secondClass) classList.push("2nd");
    if (classes.thirdClass) classList.push("3rd");
    return classList;

}

  const [checkboxes, setCheckboxes] = useState<Pilot>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
  });
  const [allRadioCheck, setAllRadioCheck] = useState<CheckboxState>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    airTrafficController: false,
    aircraftMechanics: false,
    parachuteRiggers: false,
    dropZoneManagement: false,
    jumpPilots: false,
    // Add more checkboxes as needed
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const marketingDistance = [
    {
      value: "selected",
      label: "Please Select",
    },
  ];

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
    });
  }

  const manufacturerTypeOptions = [
    {value: "210A", label: "210A"},
    {value: "58X", label: "58X"},
    {value: "62X", label: "62X"},
    {value: "AERO DESIGNSPULSARXP", label: "AERO DESIGNSPULSARXP"},
  ]

  const geoLocation = [
    { value: "Al", label: "Alaskan" },
    { value: "Ce", label: "Central" },
    { value: "Ea", label: "Eastern" },
    { value: "Fo", label: "Foreign" },
    { value: "Gl", label: "Great Lakes" },
    { value: "Nm", label: "Northwest Mountain" },
    { value: "So", label: "Southern" },
    { value: "Sw", label: "Southwestern" },
    { value: "Wp", label: "Western-Pacific" },
  ];


  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      background: "white",
      borderColor: "black",
      margin: 0,
      marginLeft: 0,
      borderWidth: "0px", // Changed 'border' to 'borderWidth'
      borderStyle: "solid", // Added 'borderStyle' to specify border style
      fontSize: 14,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black",
      },
      
    }),
    input: (styles: any) => ({ ...styles, color: "black" }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "gray",
      fontSize: 14, // Removed quotes around 14
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: "#E35D22",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      background: "black",
      "&:hover": {
        backgroundColor: "red",
      },
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      backgroundColor: "black",
      "&:hover": {
        backgroundColor: "black",
      },
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
      textAlign: "left",
    }),
  };
  const manufacturerName = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  const aircraftModelName = [
    "Per Selected Manufacturer",
    "1S-28M2",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  const aircraftTypesName = [
    "Balloon",
    "Blimp/Dirigible",
    "Fixed Wing Multi Engine",
    "Fixed Wing Single Engine",
    "Glider",
    "Gyroplane",
    "Hybrid Lift",
    "Powered Parachute",
    "Rotorcraft",
    "Weight-Shift-Control",
    "Other",
  ];
  const aircraftTypesNameCheck = [
    { name: "Balloon", value: 1 },
    { name: "Blimp/Dirigible", value: 2 },
    { name: "Fixed Wing Multi Engine", value: 3 },
    { name: "Fixed Wing Single Engine", value: 4 },
    { name: "Glider", value: 4 },
    { name: "Gyroplane", value: 5 },
    { name: "Hybrid Lift", value: 6 },
    { name: "Powered Parachute", value: 7 },
    { name: "Rotorcraft", value: 8 },
    { name: "Weight-Shift-Control", value: 9 },
    { name: "Other", value: "H" },
  ];
  
  const aircraftValues : Array<string | number>= aircraftTypesName.map(name => {
    const matchingItem = aircraftTypesNameCheck.find(item => item.name === name);
    return matchingItem ? matchingItem.value : ""; // Return empty string if no matching item is found
  });
  const engineTypesName = [
    "2 Cycle",
    "4 Cycle",
    "Electric",
    "None",
    "Ramjet",
    "Reciprocating",
    "Rotary",
    "Turbo-fan",
    "Turbo-jet",
    "Turbo-prop",
    "Turbo-shaft",
    "Unknown",
  ];

  const years = [
    {
      value: "selected",
      label: "Please Select",
    },
  ];

  for (let year = 2024; year >= 1930; year--) {
    years.push({
      value: year.toString(),
      label: year.toString(),
    });
  }


  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
  };

  const checkedAllClass = (event: any) => {
    const { name, checked } = event.target;

    // Update individual checkbox state
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    // If "All Class" checkbox is checked, update all other checkboxes accordingly
    if (name === "allClasses") {
      setCheckboxes({
        firstClass: checked,
        secondClass: checked,
        thirdClass: checked,
        allClasses: checked,
      });
    } else {
      // If any other checkbox is unchecked, uncheck "All Class" checkbox
      if (!checked) {
        setCheckboxes((prevState) => ({
          ...prevState,
          allClasses: false,
        }));
      }
    }
  };

  const handleChange = (
    event: SelectChangeEvent<typeof aircraftManufacturer>
  ) => {
    const {
      target: { value },
    } = event;
    let valued = (event.target.value as string[]).filter((ele: string) => {
      if (ele !== "Please Select") {
        return true;
      }
    });
    console.log(valued, "our vauled");
    setAircraftManufacturer(
      // On autofill we get a stringified value.
      valued
    );
  };
  const handleChange2 = (
    event: SelectChangeEvent<typeof aircraftManufacturer>
  ) => {
    const {
      target: { value },
    } = event;
    let valued = (event.target.value as string[]).filter((ele: string) => {
      if (ele !== "Please Select") {
        return true;
      }
    });
    // console.log(valued, "our vauled");
    setAircraftModel(
      // On autofill we get a stringified value.
      valued
    );
  };
  const handleChange3 = (
    event: SelectChangeEvent<typeof aircraftManufacturer>
  ) => {
    const {
      target: { value },
    } = event;
    let valued = (event.target.value as string[]).filter((ele: string) => {
      if (ele !== "Please Select") {
        return true;
      }
    });
    console.log(valued, "our vauled");
    setAircraftTypes(
      // On autofill we get a stringified value.
      valued
    );
  };
  const handleChange4 = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValues = (event.target.value as string[]).filter(
      (ele: string) => ele !== "Please Select"
    );
    console.log(selectedValues, "our valued");
    setEngineTypes(selectedValues);
  };


  const [zipData, setZipData] = useState<any>({
    zipCode: 0,
    distance: 0,
    success: "",
  });
  const onChangeZipCode = (e: any) => {
    zipData.zipCode = e.target.value;
    const { value } = e.target;
    if (value?.length > 9) {
      e.target.value = value.slice(0, 9); // Limit to 9 characters
    }
    if (zipData.zipCode && zipData.distance) {
      dispatch(ameZipCode(zipData)).then((res: any) => {
        console.log("responce from zip code", res.payload);
      });
    }
  };
  // const onChangeMarketingDistance = (e: any) => {
  //   const distance = e.target.value;
  //   if (zipData.zipCode && distance) {
  //     zipData.distance = distance;
  //     dispatch(ameZipCode(zipData)).then((res: any) => {
  //       const zipCodes = res.payload.map((item: any) => item.ZipCode);
  //       setZipCode(zipCodes);
  //     });
  //   }
  // };

  const initialValues1 = {
    export_file: "",
  };

  // Validation schema using yup
  const validationSchema = yup.object().shape({
    expire: yup.string().required("Please check some option!"),
    export_file: yup.string().required("Please check some option!"),
  });

  /// Handle form submission
 
  const handleSubmitSetData = (values: any) => {
        // setLoading(1);
    
        values = {
          aircraftManufacturer,
          aircraftModel,
          aircraftTypes,
          engineTypes,
          ...values,
        };
        let airmenclass =getClassList(checkboxes);
        console.log(airmenclass,"airmen class")
        // Assuming 'zip_code' is already defined somewhere in your code
        const finalValues = { ...values, zip_code };
        console.log(finalValues, "finalValues");
    let aircraftName=aircraftValues;
        console.log("=================================================");
        console.log(
          {registration_number: values.registration_number,
          registration_number_check: values.locations1==="exactmatch1"?"exact_number":"sub_string",
          name:values.ownerName,
          name_check: values.locations2==="exactmatch2"?"exact_number":"sub_string",
          address:  values.address,
          address_check: values.locations3==="exactmatch3"?"exact_number":"sub_string",
          city_name: values.city,
          city_name_check: values.locations4==="exactmatch4"?"exact_number":"sub_string",
          region_or_state: selectedOptions.map(item => item.value),
          region_type: location,
          include_airman:includeAirman,
          zip_code:zipResponse,
        certificate_date: certificateDate?.format("MM-DD-YYYY"),
          certificate_date_check: values.lastAction1==="Least1"?"at_least":"equal_to_or_more_than",
          last_action_date: lastActionDate?.format("MM-DD-YYYY"),
          last_action_date_check: values.lastAction2==="Least2"?"at_least":"equal_to_or_more_than",
          airworthiness_date: airWorthynessDate?.format("MM-DD-YYYY"),
          airworthiness_date_check:  values.lastAction3==="Least3"?"at_least":"equal_to_or_more_than",
          manufacture_year_from:values.manufactureStartDate,
          manufacture_year_to: values.manufactureEndDate,
          aircrft_name:aircraftValues,
          e_name: engineTypesName.map(name =>engineTypesName.indexOf(name)),
          expire:values.expiry,
          expiry:values.medicalExpiring,
          
          airman: selectedTab,
          airman_class: values.airmenclass,
     
          sort_type: values.pilotype,
          sort_type_order: values.order || "asc",
          export_file: values.export_file,
          task_type: "aircraft",
        })
let updatedValues={registration_number: values.registration_number,
  registration_number_check: values.locations1==="exactmatch1"?"exact_number":"sub_string",
  name:values.ownerName,
  name_check: values.locations2==="exactmatch2"?"exact_number":"sub_string",
  address:  values.address,
  address_check: values.locations3==="exactmatch3"?"exact_number":"sub_string",
  city_name: values.city,
  city_name_check: values.locations4==="exactmatch4"?"exact_number":"sub_string",
  region_or_state: selectedOptions.map(item => item.value),
  region_type: location,
  include_airman:includeAirman,
  zip_code:zipResponse,
certificate_date: certificateDate?.format("MM-DD-YYYY"),
  certificate_date_check: values.lastAction1==="Least1"?"at_least":"equal_to_or_more_than",
  last_action_date: lastActionDate?.format("MM-DD-YYYY"),
  last_action_date_check: values.lastAction2==="Least2"?"at_least":"equal_to_or_more_than",
  airworthiness_date: airWorthynessDate?.format("MM-DD-YYYY"),
  airworthiness_date_check:  values.lastAction3==="Least3"?"at_least":"equal_to_or_more_than",
  manufacture_year_from:values.manufactureStartDate,
  manufacture_year_to: values.manufactureEndDate,
  aircrft_name:aircraftValues,
  e_name: engineTypesName.map(name =>engineTypesName.indexOf(name)),
  expire:values.expiry,
  expiry:values.medicalExpiring,
  
  airman: selectedTab,
  airman_class: values.airmenclass,
  sort_type: values.pilotype,
  sort_type_order: values.order,
  export_file: values.export_file,
  task_type: "aircraft",
}
        values=updatedValues;
        console.log(values,"values pasdded to api")
        setLoading(1);
        dispatch(exportsRecordsAircraft({...values})).
        then((res:any) => {
          console.log({taskID:res.payload.task_log_id,
            WorkerId:res.payload.worker_job_id
          },"after api response in AME Patients Lead")
          setLoading(0);
          // if(Array.isArray(res.count) && res.count.length > 0) {
            toast.success("Export request has been submitted successfully. Your file will download shortly.", {
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 8000,
              pauseOnFocusLoss: true
            });
        }).catch((err:any) => {
          setLoading(0);
          console.log(err,'API error. ');
        });


      };
    

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTab(event.target.value);
    const { value } = event.target;

    if (value === "all") {
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = true;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    } else {
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = false;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    }
  };

  React.useEffect(() => {
    let a={
      name:"",
      surname:''
    }
    
    dispatch(getPilotStates()).then((res: any) => {
      // console.log(res.payload.states, "res of state");
      if(res?.payload && Array.isArray(res.payload?.states) && res.payload?.states.length > 0) {
        let filteredStates = res.payload.states.filter((item: any) => item.state !== '');
        let hello: any = filteredStates.map((item: any) => ({
          value: item.state,
          label: item.state_abbrev,
        }));
        // console.log(hello, "all hello");
        setOptions(hello);
      }
      // console.log(hello,"show hello")
    });
  }, []);
  const [zipResponse, setZipResponse] = useState("");


  const onChangeMarketingDistance = () => {
    // Assuming zipData is of type ZipData, specify its type or provide a type assertion
    if (zipData && zipData.zipCode && zipData.distance) {
      console.log("function called for zipdata");
      const payload: any = {
        zipCode: zipData.zipCode,
        distance: zipData.distance,
        success: "", // Ensure that the payload matches the expected shape of ZipCodeState
      };
      dispatch(ameZipCode(payload))
        .then((res: any) => {
          console.log(res, "response of zipdata");
          if (res.payload.length > 0) {
            // Handle the result of the async thunk action
            const zipCodes = res.payload.map((item: any) => item.ZipCode);
            const convertedString = zipCodes.join(", ");
            console.log(convertedString, "zipcodes after");
            setZipResponse(convertedString);
          } else {
            toast.dismiss();
            toast.error("No Zip code found", {
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 8000,
              pauseOnFocusLoss: true,
            });
          }
          // setZipCode(zipCodes);
        })
        .catch((error: any) => {
          // Handle any errors that occur during the async operation
          console.error("Error: in zipdata api", error);
        });
    }
  };

  React.useEffect(() => {
    onChangeMarketingDistance();
  }, [zipData]);
  return (
    <>
      {loading ? <Loader /> : ""}
      <ToastContainer/>
      {/* Popup */}
      <Formik
        initialValues={initialValues1}
        // validationSchema={validationSchema}
        onSubmit={handleSubmitSetData}
      >
        {() => (
          <div className="modal-body bg-white p-0">
            <div className="container-fluid">
              <div className="row text-black">
                {/* content*/}
                <div className="col-md-12 col-sm-12 ">
                  <div className="col-12  px-4 py-3 text-left font-inter mb-3">
                    <Form>
                      {/* AME Zip code & Marketing Distance */}
                      <div className="row">
                              <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                                <Box
                                  component="form"
                                  sx={{
                                    "& .MuiTextField-root": {
                                      mt: 2,
                                      width: "25ch",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                 <Field name="registration_number">
                                    {({ field }: { field: any }) => (
                                      <TextField
                                        {...field}
                                        color="warning"
                                        // required
                                        id="outlined-number"
                                        // type="text"
                                        className="w-100 text-black"
                                        label="Registration Number:(Leave blank for all)"
                                        value={field.value || 'N'}
                                        placeholder="N"
                                        // onChange={onChangeZipCode}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { color: "#E35D22",fontWeight: "bold" },
                                        }}
                                        InputProps={{
                                          style: { color: "black" },
                                          // inputProps: { maxLength: 9,minLength:5 },
                                        }}
                                        sx={{
                                          "& input::placeholder": {
                                            color: "black",
                                          }, // Style for the placeholder text color
                                        }}
                                      />
                                    )}
                                  </Field>
                                </Box>
                                <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
                                 <div className=" amePopup-label col-6 d-flex align-items-center">
                                   <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations1"
                                     id="exactmatch1"
                                     value="exactmatch1"
                                     checked={!contains.radio1}
                                     onClick={() =>
                                       setContains({...contains,radio1:false})
                                     }
                                   />
                                   </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0 text-nowrap"
                                     htmlFor="exactmatch1"
                                   >
                                     Exact Match
                                   </label>
                                 </div>

                                 <div className=" amePopup-label  d-flex align-items-center">
                                 <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations1"
                                     id="Contains1"
                                     value="Contains1"
                                     onClick={() =>
                                     setContains({...contains,radio1:true})
                                    }
                                    checked={contains.radio1}
                                    //  checked
                                     // onClick={() => setLocation("State")}
                                   />
                                    </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="Contains1"
                                   >
                                     Contains
                                   </label>
                                 </div>

                                 <div className="amePopup-label">
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="outputFormat"
                                   ></label>
                                 </div>
                               </div>
                              </div>
                              <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                              <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >  <Field name="ownerName">
                      {({ field }: { field: any }) => (
                        <TextField
                        {...field}
                          required
                          color="warning"
                          id="outlined-number"
                          className="w-100 text-black"
                          label="Owner Name:(Leave blank for all)"
                          type="text"
                          // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
                          placeholder="Owner Name"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#E35D22", fontWeight: "bold" },
                          }}
                          InputProps={{
                            style: { color: "black" }, // Text color of the input
                          }}
                          sx={{
                            "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                            // Change border color when focused
                          }}
                        />)}
                        </Field>
                      </Box>
                      <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
                                 <div className=" amePopup-label col-6 d-flex align-items-center">
                                   <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations2"
                                     id="exactmatch2"
                                     value="exactmatch2"
                                     onClick={() =>
                                      setContains({...contains,radio2:false})
                                     }
                                     checked={!contains.radio2}
                                   />
                                   </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0 text-nowrap"
                                     htmlFor="exactmatch2"
                                   >
                                     Exact Match
                                   </label>
                                 </div>

                                 <div className=" amePopup-label  d-flex align-items-center">
                                 <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations2"
                                     id="Contains2"
                                     value="Contains2"
                                     onClick={() =>
                                      setContains({...contains,radio2:true})
                                     }
                                     checked={contains.radio2}
                                    //  checked
                                     // checked={location === "State"}
                                     // onClick={() => setLocation("State")}
                                   />
                                    </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="Contains2"
                                   >
                                     Contains
                                   </label>
                                 </div>

                                 <div className="amePopup-label">
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="outputFormat"
                                   ></label>
                                 </div>
                               </div>
                                {/* <p className="text-danger field-errorMessage ">
                                    <ErrorMessage name="marketingDistance" />
                                  </p> */}
                              </div>
                            </div>

                     



                      <div className="row">
                    <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      ><Field name="address">
                      {({ field }: { field: any }) => (
                        <TextField
                        {...field}
                          required
                          color="warning"
                          id="outlined-number"
                          name="address"
                          className="w-100 text-black"
                          label="Address:(Leave blank for all)"
                          type="text"
                          // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
                          placeholder="Address"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#E35D22", fontWeight: "bold" },
                          }}
                          InputProps={{
                            style: { color: "black" }, // Text color of the input
                          }}
                          sx={{
                            "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                            // Change border color when focused
                          }}
                        />)}
                        </Field>
                      </Box>
                      <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
                                  <div className=" amePopup-label col-6 d-flex align-items-center">
                                    <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="locations3"
                                      id="exactmatch3"
                                      value="exactmatch3"
                                      onClick={() =>
                                        setContains({...contains,radio3:false})
                                       }
                                       checked={!contains.radio3}
                                    />
                                    </div>
                                    <label
                                      className="form-check-label pl-2 pl-md-0 text-nowrap"
                                      htmlFor="exactmatch3"
                                    >
                                      Exact Match
                                    </label>
                                  </div>

                                  <div className=" amePopup-label  d-flex align-items-center">
                                  <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="locations3"
                                      id="Contains3"
                                      value="Contains3"
                                      onClick={() =>
                                        setContains({...contains,radio3:true})
                                       }
                                       checked={contains.radio3}
                                      // checked
                                      // checked={location === "State"}
                                      // onClick={() => setLocation("State")}
                                    />
                                     </div>
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      htmlFor="Contains3"
                                    >
                                      Contains
                                    </label>
                                  </div>

                                  <div className="amePopup-label">
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      htmlFor="outputFormat"
                                    ></label>
                                  </div>
                                </div>
                    </div>
                    <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                      <Box
                        component="form"
                        sx={{
                          "& .MuiTextField-root": {
                            mt: 2,
                            width: "25ch",
                          },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                         <Field name="city">
                                        {({ field }: { field: any }) => (
                        <TextField
                        {...field}
                          required
                          color="warning"
                          id="outlined-number"
                          name="city"
                          className="w-100 text-black"
                          label="City:(Leave blank for all)"
                          type="text"
                          // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
                          placeholder="City"
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#E35D22", fontWeight: "bold" },
                          }}
                          InputProps={{
                            style: { color: "black" }, // Text color of the input
                          }}
                          sx={{
                            "& input::placeholder": { color: "black" }, // Style for the placeholder text color
                            // Change border color when focused
                          }}
                        />)}
                          </Field>
                      </Box>
                      <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
                                 <div className=" amePopup-label col-6 d-flex align-items-center">
                                   <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations4"
                                     id="exactmatch4"
                                     value="exactmatch4"
                                     onClick={() =>
                                      setContains({...contains,radio4:false})
                                     }
                                     checked={!contains.radio4}
                                   />
                                   </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0 text-nowrap"
                                     htmlFor="exactmatch4"
                                   >
                                     Exact Match
                                   </label>
                                 </div>

                                 <div className=" amePopup-label  d-flex align-items-center">
                                 <div>
                                   <Field
                                     className="form-check-input"
                                     type="radio"
                                     name="locations4"
                                     id="Contains4"
                                     value="Contains4"
                                     onClick={() =>
                                      setContains({...contains,radio4:true})
                                     }
                                     checked={contains.radio4}
                                   />
                                    </div>
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="Contains4"
                                   >
                                     Contains
                                   </label>
                                 </div>

                                 <div className="amePopup-label">
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="outputFormat"
                                   ></label>
                                 </div>
                               </div>
                    </div>
                  </div>

                  <div className="row ">
                              <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                                {location === "State" && (
                                  <div>
                                    <Autocomplete
                                      className="mt-3"
                                      multiple
                                      id="multi-select"
                                      color="warning"
                                      options={options.filter(
                                        (option:any) =>
                                          !selectedOptions.some(
                                            (selectedOption) =>
                                              selectedOption.value ===
                                              option.value
                                          )
                                      )}
                                      getOptionLabel={(option) => option.label}
                                      value={selectedOptions}
                                      onChange={(event, newValue) =>
                                        setSelectedOptions(newValue)
                                      }
                                      inputValue={inputValue}
                                      onInputChange={handleInputChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="State:(Leave blank for all)"
                                          variant="outlined"
                                          fullWidth
                                          color="warning"
                                          InputLabelProps={{ shrink: true,style: { color: "#E35D22" ,fontWeight:600} }} // This will make the label always visible
                                        />
                                      )}
                                      renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                          <Chip
                                            // key={index}
                                            color="warning"
                                            label={option.label}
                                            // onDelete={() => handleDeleteOption(option)}
                                            {...getTagProps({ index })}
                                          />
                                        ))
                                      }
                                    />
                                    {/* <Box
                                  component="form"
                                  sx={{
                                    "& .MuiTextField-root": {
                                      mt: 2,
                                      width: "25ch",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <Field name="ameOfficeZipCode">
                                    {({ field }: { field: any }) => (
                                      <TextField
                                        {...field}
                                        color="warning"
                                        id="outlined-select-currency"
                                        select
                                        label="State:(Leave blank for all)"
                                        className="w-100"
                                        defaultValue="New Student Pilots"
                                        value={field.ameOfficeZipCode}
                                        // onChange={onChangeMarketingDistance}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: {
                                            color: "#E35D22",
                                            fontWeight: "bold",
                                          }, // Color of the label
                                        }}
                                        InputProps={{
                                          placeholder:
                                            "Marketing Distance From Office", // Set placeholder text
                                          
                                        }}
                                        sx={{
                                          "& input::placeholder": {
                                            color: "black  ",
                                          }, // Style for the placeholder text color
                                        }}
                                      >
                                        {Array.isArray(states) && states.length > 0 && states.map((state:any) => (
                                          <MenuItem
                                          key={state.state}
                                          value={state.state_abbrev}
                                          >
                                            {state.state_abbrev}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  </Field>
                                </Box> */}
                                  </div>
                                )}
                                <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                  <div className="amePopup-sub-heading">
                                    Choose By:
                                  </div>
                                  <div className=" amePopup-label col-6 d-flex align-items-center">
                                    <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="location"
                                      id="Geo Location"
                                      value="Geo Location"
                                      checked={location === "Geo Location"}
                                      onClick={() =>
                                        setLocation("Geo Location")
                                      }
                                    />
                                    </div>
                                    <label
                                      className="form-check-label pl-2 pl-md-0 text-nowrap"
                                      // htmlFor="CSV"
                                    >
                                      Geo Location
                                    </label>
                                  </div>

                                  <div className=" amePopup-label  d-flex align-items-center">
                                  <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="location"
                                      id="State"
                                      value="State"
                                      checked={location === "State"}
                                      onClick={() => setLocation("State")}
                                    />
                                     </div>
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      // htmlFor="RTF"
                                    >
                                      State
                                    </label>
                                  </div>

                                  <div className="amePopup-label">
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      htmlFor="outputFormat"
                                    ></label>
                                  </div>
                                </div>
                                <div></div>
                              </div>
                              <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                                {location === "State" ? (
                                  <div>
                                    <Box
                                      component="form"
                                      sx={{
                                        "& .MuiTextField-root": {
                                          mt: 2,
                                          width: "25ch",
                                        },
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    >
                                      <Field name="ameOfficeZipCode">
                                        {({ field }: { field: any }) => (
                                          <TextField
                                            {...field}
                                            color="warning"
                                            required
                                            id="outlined-number"
                                            type="number"
                                            name="ameOfficeZipCode"
                                            className="w-100 text-black "
                                            label="Zip Code"
                                            value={field.ameOfficeZipCode}
                                            placeholder="e.g.12345"
                                            onChange={(e: any) =>
                                              setZipData({
                                                ...zipData,
                                                zipCode: e.target.value,
                                              })
                                            }
                                            InputLabelProps={{
                                              shrink: true,
                                              style: {
                                                color: "#E35D22",
                                                fontWeight: "bold",
                                              },
                                            }}
                                            InputProps={{
                                              style: {
                                                color: "black",
                                                borderColor: "",
                                              }, // Set borderColor to 
                                              inputProps: {
                                                maxLength: 9,
                                                minLength: 5,
                                              },
                                            }}
                                            sx={{
                                              "& input::placeholder": {
                                                color: "black",
                                              }, // Style for the placeholder text color
                                            }}
                                          />
                                        )}
                                      </Field>
                                    </Box>

                                    <Box
                                      component="form"
                                      sx={{
                                        "& .MuiTextField-root": {
                                          mt: 2,
                                          width: "25ch",
                                        },
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    >
                                      <Field name="marketingDistance">
                                        {({ field }: { field: any }) => (
                                          <TextField
                                            {...field}
                                            color="warning"
                                            id="outlined-select-currency"
                                            select
                                            label=" Distance"
                                            className="w-100 "
                                            defaultValue="selected"
                                            value={field.marketingDistance}
                                            onChange={(e: any) =>
                                              setZipData({
                                                ...zipData,
                                                distance: e.target.value,
                                              })
                                            }
                                            // onChange={onChangeMarketingDistance}
                                            InputLabelProps={{
                                              shrink: true,
                                              style: {
                                                color: "#E35D22",
                                                fontWeight: "bold",
                                              }, // Color of the label
                                            }}
                                            InputProps={{
                                              placeholder:
                                                "Marketing Distance From Office", // Set placeholder text
                                              style: {
                                                outlineColor: "#E35D22",
                                              },
                                            }}
                                            sx={{
                                              "& input::placeholder": {
                                                color: "black  ",
                                              }, // Style for the placeholder text color
                                            }}
                                          >
                                            {marketingDistance.map((option) => (
                                              <MenuItem
                                                disabled={
                                                  option.label ===
                                                  "Please select"
                                                    ? true
                                                    : false
                                                }
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        )}
                                      </Field>
                                    </Box>
                                  </div>
                                ) : (
                                  <Box
                                    component="form"
                                    sx={{
                                      "& .MuiTextField-root": {
                                        mt: 2,
                                        width: "25ch",
                                      },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <Field name="geolocation">
                                      {({ field }: { field: any }) => (
                                        <TextField
                                          {...field}
                                          color="warning"
                                          id="outlined-select-currency"
                                          select
                                          label="Geo Location:(Leave blank for all)"
                                          className="w-100 "
                                          defaultValue="New Student Pilots"
                                          value={field.geolocation}
                                          // onChange={onChangeMarketingDistance}
                                          InputLabelProps={{
                                            shrink: true,
                                            style: {
                                              color: "#E35D22",
                                              fontWeight: "bold",
                                            }, // Color of the label
                                          }}
                                          InputProps={{
                                            placeholder:
                                              "Marketing Distance From Office", // Set placeholder text
                                            
                                          }}
                                          sx={{
                                            "& input::placeholder": {
                                              color: "black  ",
                                              
                                            }, // Style for the placeholder text color
                                          }}
                                        >
                                          {[
                                            "Alaskan",
                                            "Central",
                                            "Eastern",
                                            "Foreign",
                                            "Great Lakes",
                                            "Northwest Mountain",
                                            "Southern",
                                            "Southwestern",
                                            "Western-Pacific",
                                          ].map((state: any) => (
                                            <MenuItem key={state} value={state}>
                                              {state}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      )}
                                    </Field>
                                  </Box>
                                )}
                                {/* <p className="text-danger field-errorMessage ">
                                    <ErrorMessage name="marketingDistance" />
                                  </p> */}
                              </div>
                            </div>


                            <div className="row">
                        <div className="col-md-6 col-sm-12 p-0 pl-lg-0 ">
                          
                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                color: "rgb(227, 93, 34)",
                                fontWeight:"bold" // Set label color to 
                              },
                              "& .MuiInputLabel-shrink": {
                                color: "#E35D22",
                                fontWeight: "bold"
                              }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                                    {/* <Field name="lastAction">
                              {({ field }: { field: any }) => ( */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker"]}
                              >
                                <DatePicker
                                    //  {...field}
                                  label="Certificate Issue Date:(Leave blank for all)"
                                  value={certificateDate}
                                  format="YYYY/MM/DD"
                                  onChange={(newValue: Dayjs | null) => setCertificateDate(newValue)}
                               
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                                {/* )}
                                </Field> */}
                          </Box>
                          <div className="col-12 d-flex justify-content-left offMarket-box-border px-4 py-2 mt-3">
                            {/* <div className="offMarket-sub-heading">
                              Last Action
                            </div> */}
                            <div className="mt-2 d-block ">
                              <div className="col-md-12 amePopup-label d-flex align-items-center">
                                <div><Field
                                  className="form-check-input"
                                  type="radio"
                                  name="lastAction1"
                                  id="Equal1"
                                  value="Equal1"
                                  onClick={() =>
                                    setContains({...contains,radio5:true})
                                   }
                                   checked={contains.radio5}
                                /></div>
                                <label
                                  className="form-check-label pl-2 pl-md-0 text-wrap"
                                  htmlFor="Equal1"
                                >
                                  Equal or more than
                                </label>
                              </div>
                              <div className="col-md-12 amePopup-label d-flex align-items-center">
                               <div><Field
                                  className="form-check-input"
                                  type="radio"
                                  name="lastAction1"
                                  id="Least1"
                                  value="Least1"
                                  onClick={() =>
                                    setContains({...contains,radio5:false})
                                   }
                                   checked={!contains.radio5}
                                /></div> 
                                <label
                                  className="form-check-label pl-2 pl-md-0 text-wrap"
                                  htmlFor="Least1"
                                >
                                  At Least
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3 ">
                        
                          
                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                color: "rgb(227, 93, 34)",
                                fontWeight:"bold" // Set label color to 
                              },
                              "& .MuiInputLabel-shrink": {
                                color: "#E35D22",
                                fontWeight: "bold"
                              }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                                    {/* <Field name="lastAction">
                              {({ field }: { field: any }) => ( */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker"]}
                              >
                                <DatePicker
                                 format="YYYY/MM/DD"
                                    //  {...field}
                                  label="Last Action Date:(Leave blank for all)"
                                  value={lastActionDate}
                                  onChange={(newValue: Dayjs | null) => setLastActionDate(newValue)}
                               
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                                {/* )}
                                </Field> */}
                          </Box>
                          <div className="col-12 d-flex justify-content-left offMarket-box-border px-4 py-2 mt-3">
                            {/* <div className="offMarket-sub-heading">
                              Last Action
                            </div> */}
                            <div className="mt-2 d-block">
                              <div className="col-md-12 amePopup-label d-flex align-items-center">
                                <div><Field
                                  className="form-check-input"
                                  type="radio"
                                  name="lastAction2"
                                  id="Equal2"
                                  value="Equal2"
                                  onClick={() =>
                                    setContains({...contains,radio6:true})
                                   }
                                   checked={contains.radio6}
                                  // checked
                                /></div>
                                <label
                                  className="form-check-label pl-2 pl-md-0 text-nowrap"
                                  htmlFor="Equal2"
                                >
                                  Equal or more than
                                </label>
                              </div>
                              <div className="col-md-12 amePopup-label d-flex align-items-center">
                               <div><Field
                                  className="form-check-input"
                                  type="radio"
                                  name="lastAction2"
                                  id="Least2"
                                  onClick={() =>
                                    setContains({...contains,radio6:false})
                                   }
                                   checked={!contains.radio6}
                                  value="Least2"
                                /></div> 
                                <label
                                  className="form-check-label pl-2 pl-md-0 text-wrap"
                                  htmlFor="Least2"
                                >
                                  At Least
                                </label>
                              </div>
                            </div>
                          </div>
                       
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-md-6 col-sm-12 p-0 pl-lg-0 ">
                          
                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                width: "100%",
                              },
                              "& .MuiInputLabel-root": {
                                color: "rgb(227, 93, 34)",
                                fontWeight:"bold" // Set label color to 
                              },
                              "& .MuiInputLabel-shrink": {
                                color: "#E35D22",
                                fontWeight: "bold"
                              }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                                    {/* <Field name="lastAction">
                              {({ field }: { field: any }) => ( */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DatePicker"]}
                              >
                                <DatePicker
                                    //  {...field}
                                    format="YYYY/MM/DD"
                                  label="Airworthiness Date:(Leave blank for all)"
                                  value={airWorthynessDate}
                                  onChange={(newValue: Dayjs | null) => setAirWorthynessDate(newValue)}
                               
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                                {/* )}
                                </Field> */}
                          </Box>
                          <div className="col-12 d-flex justify-content-left offMarket-box-border px-4 py-2 mt-3">
                            {/* <div className="offMarket-sub-heading">
                              Last Action
                            </div> */}
                            <div className="mt-2 d-block">
                              <div className="col-md-12 amePopup-label d-flex align-items-center">
                                <div><Field
                                  className="form-check-input"
                                  type="radio"
                                  name="lastAction3"
                                  id="Equal3"
                                  value="Equal3"
                                  onClick={() =>
                                    setContains({...contains,radio7:true})
                                   }
                                   checked={contains.radio7}
                                  // checked
                                /></div>
                                <label
                                  className="form-check-label pl-2 pl-md-0 text-nowrap"
                                  htmlFor="Equal3"
                                >
                                  Equal or more than
                                </label>
                              </div>
                              <div className="col-md-12 amePopup-label d-flex align-items-center">
                               <div><Field
                                  className="form-check-input"
                                  type="radio"
                                  name="lastAction3"
                                  id="Least3"
                                  value="Least3"
                                  onClick={() =>
                                    setContains({...contains,radio7:false})
                                   }
                                   checked={!contains.radio7}
                                /></div> 
                                <label
                                  className="form-check-label pl-2 pl-md-0 text-wrap"
                                  htmlFor="Least3"
                                >
                                  At Least
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3 mt-2">
                        <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                width: "25ch",
                              },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            {" "}
                            <Field name="manufactureStartDate">
                              {({ field }: { field: any }) => (
                                <TextField
                                  {...field}
                                  color="warning"
                                  id="outlined-select-currency"
                                  select
                                  label="Manufacture Date Range From"
                                  className="w-100 "
                                  defaultValue="selected"
                                  value={field.manufactureStartDate}
                                  // helperText="Start Year"
                                  //   onChange={onChangeMarketingDistance}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      color: "#E35D22",
                                      fontWeight: "bold",
                                    },
                                  }}
                                  InputProps={{
                                    placeholder: "Manufacture Date Range", // Set placeholder text
                                    
                                  }}
                                  sx={{
                                    shrink: true,
                                    style: { color: "#E35D22" },
                                    "& input::placeholder": {
                                      color: "black  ",
                                    }, // Style for the placeholder text color
                                  }}
                                >
                                  {years.map((option) => (
                                    <MenuItem
                                      disabled={
                                        option.label === "Please select"
                                          ? true
                                          : false
                                      }
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Field>
                            {/* <FormHelperText>Start Year</FormHelperText> */}
                          </Box>
                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                width: "25ch",
                              },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            {" "}
                            <Field name="manufactureEndDate">
                              {({ field }: { field: any }) => (
                                <TextField
                                  {...field}
                                  color="warning"
                                  id="outlined-select-currency"
                                  select
                                  label="Manufacture Date Range To"
                                  className="w-100"
                                  defaultValue="selected"
                                  value={field.manufactureEndDate}
                                  // helperText="Start Year"
                                  //   onChange={onChangeMarketingDistance}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      color: "#E35D22",
                                      fontWeight: "bold",
                                    },
                                  }}
                                  InputProps={{
                                    placeholder: "Manufacture Date Range", // Set placeholder text
                                    
                                  }}
                                  sx={{
                                    shrink: true,
                                    style: { color: "#E35D22" },
                                    "& input::placeholder": {
                                      color: "black  ",
                                    }, // Style for the placeholder text color
                                  }}
                                >
                                  {years.map((option) => (
                                    <MenuItem
                                      disabled={
                                        option.label === "Please select"
                                          ? true
                                          : false
                                      }
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Field>
                            {/* <FormHelperText>Start Year</FormHelperText> */}
                          </Box>
                        </div>
                      </div>





                      {/* Aircraft Manufacturer & Aircraft Model */}
                      {/* <div className="row pt-3">
                        <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                          <Field name="aircraftManufacturer">
                            {({ field }: { field: any }) => (
                              <FormControl sx={{}}>
                                <InputLabel
                                  id="aircraftManufacturer"
                                  shrink={true}
                                  style={{
                                    color: "#E35D22",
                                    background: "white",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Aircraft Manufacturer
                                </InputLabel>
                                <Select
                                  //   {...field}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  color="warning"
                                  multiple
                                  value={
                                    aircraftManufacturer.length > 0
                                      ? aircraftManufacturer
                                      : ["Please Select"]
                                  }
                                  className=""
                                  onChange={handleChange}
                                  input={
                                    <OutlinedInput label="aircraftManufacturer" />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                  sx={{
                                    "& input::placeholder": {
                                      color: "black",
                                    },
                                  }}
                                >
                                  <MenuItem disabled value="Choose One or More">
                                    <p>Choose One or More</p>
                                  </MenuItem>
                                  {manufacturerName.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          aircraftManufacturer.indexOf(name) >
                                          -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                        </div>

                        <div className="col-md-6 col-sm-12 mt-3 mt-md-0  p-0 pl-md-2 pl-lg-3">
                          <Field name="aircraftModel">
                            {({ field }: { field: any }) => (
                              <FormControl sx={{}}>
                                <InputLabel
                                  id="demo-multiple-checkbox-label"
                                  shrink={true}
                                  style={{
                                    color: "#E35D22",
                                    background: "white",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Aircraft Model
                                </InputLabel>
                                <Select
                                  //   {...field}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  color="warning"
                                  multiple
                                  value={
                                    aircraftModel.length > 0
                                      ? aircraftModel
                                      : ["Please Select"]
                                  }
                                  className=""
                                  onChange={handleChange2}
                                  input={
                                    <OutlinedInput label="aircraftModel" />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                  sx={{
                                    "& input::placeholder": {
                                      color: "black",
                                    },
                                  }}
                                >
                                  <MenuItem disabled value="Choose One or More">
                                    <p>Choose One or More</p>
                                  </MenuItem>
                                  {aircraftModelName.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          aircraftModel.indexOf(name) > -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                        </div>
                      </div> */}

                      {/* Manufacture Date Range = Start Year End Year */}
                      {/* <div className="row">
                        <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                width: "25ch",
                              },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            {" "}
                            <Field name="manufactureStartDate">
                              {({ field }: { field: any }) => (
                                <TextField
                                  {...field}
                                  color="warning"
                                  id="outlined-select-currency"
                                  select
                                  label="Manufacture Date Range"
                                  className="w-100"
                                  defaultValue="selected"
                                  value={field.manufactureStartDate}
                                  // helperText="Start Year"
                                  //   onChange={onChangeMarketingDistance}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      color: "#E35D22",
                                      fontWeight: "bold",
                                    },
                                  }}
                                  InputProps={{
                                    placeholder: "Manufacture Date Range", // Set placeholder text
                                    
                                  }}
                                  sx={{
                                    shrink: true,
                                    style: { color: "#E35D22" },
                                    "& input::placeholder": {
                                      color: "black  ",
                                    }, // Style for the placeholder text color
                                  }}
                                >
                                  {years.map((option) => (
                                    <MenuItem
                                      disabled={
                                        option.label === "Please select"
                                          ? true
                                          : false
                                      }
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Field>
                          </Box>
                        </div>
                        <div className="col-md-6 col-sm-12 p-0 pt-3 pt-md-0 pl-md-2 pl-lg-3">
                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                // width: "25ch",
                              },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <Field name="manufactureEndDate">
                              {({ field }: { field: any }) => (
                                <TextField
                                  {...field}
                                  color="warning"
                                  id="outlined-select-currency"
                                  select
                                  label="Manufacture Date Range"
                                  className="w-100"
                                  defaultValue="selected"
                                  value={field.manufactureEndDate}
                                  helperText="End Year"
                                  //   onChange={onChangeMarketingDistance}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      color: "#E35D22",
                                      fontWeight: "bold",
                                    },
                                  }}
                                  InputProps={{
                                    placeholder: "Manufacture Date Range",
                                    
                                  }}
                                  sx={{
                                    shrink: true,
                                    style: { color: "#E35D22" },
                                    "& input::placeholder": {
                                      color: "black  ",
                                    },
                                  }}
                                >
                                  {years.map((option) => (
                                    <MenuItem
                                      disabled={
                                        option.label === "Please select"
                                          ? true
                                          : false
                                      }
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Field>
                          </Box>
                        </div>
                      </div> */}

                      {/* Aircraft Types & Engine Types */}
                      <div className="row pt-3">
                        <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                          <Field name="aircraftTypes">
                            {({ field }: { field: any }) => (
                              <FormControl sx={{}}>
                                <InputLabel
                                  id="demo-multiple-checkbox-label"
                                  shrink={true}
                                  style={{
                                    color: "#E35D22",
                                    background: "white",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Aircraft Types:(Leave blank for all)
                                </InputLabel>
                                <Select
                                  // {...field}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  color="warning"
                                  multiple
                                  value={
                                    aircraftTypes.length > 0
                                      ? aircraftTypes
                                      : ["Please Select"]
                                  }
                                  className="col-12"
                                  onChange={handleChange3}
                                  input={
                                    <OutlinedInput label="aircraftTypes" />
                                  }
                                  renderValue={(selected) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                  sx={{
                                    "& input::placeholder": {
                                      color: "black",
                                    },
                                  }}
                                >
                                  <MenuItem disabled value="Choose One or More">
                                    <p>Choose One or More</p>
                                  </MenuItem>
                                  {aircraftTypesName.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={
                                          aircraftTypes.indexOf(name) > -1
                                        }
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                        </div>
                        <div className="col-md-6 col-sm-12 p-0 pt-3 pt-md-0 pl-md-2 pl-lg-3">
                          <Field name="Aircraft Model">
                            {({ field }: { field: any }) => (
                              <FormControl sx={{}}>
                                <InputLabel
                                  id="demo-multiple-checkbox-label"
                                  shrink={true}
                                  style={{
                                    color: "#E35D22",
                                    background: "white",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Engine Types:(Leave blank for all)
                                </InputLabel>
                                <Select
                                  {...field}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  color="warning"
                                  multiple
                                  value={
                                    engineTypes.length > 0
                                      ? engineTypes
                                      : ["Please Select"]
                                  }
                                  className=""
                                  onChange={handleChange4}
                                  input={
                                    <OutlinedInput label="Aircraft Model" />
                                  }
                                  renderValue={(selected: any) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={MenuProps}
                                  sx={{
                                    "& input::placeholder": {
                                      color: "black",
                                    },
                                  }}
                                >
                                  <MenuItem disabled value="Choose One or More">
                                    <p>Choose One or More</p>
                                  </MenuItem>
                                  {engineTypesName.map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox
                                        checked={engineTypes.indexOf(name) > -1}
                                      />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Field>
                        </div>
                      </div>


                      <div className="row">
  <div className="col-6 p-0 pl-lg-0 ">
  <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": {
                                mt: 2,
                                width: "25ch",
                              },
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            {" "}
                            <Field name="expiry">
                              {({ field }: { field: any }) => (
                                <TextField
                                  {...field}
                                  color="warning"
                                  id="outlined-select-currency"
                                  select
                                  label="Aircraft Registration Expiration:"
                                  className="w-100 "
                                  defaultValue="Select AirCarft Registration Expiration"
                                  value={field.manufactureStartDate}
                                  // helperText="Start Year"
                                  //   onChange={onChangeMarketingDistance}
                                  InputLabelProps={{
                                    shrink: true,
                                    style: {
                                      color: "#E35D22",
                                      fontWeight: "bold",
                                    },
                                  }}
                                  InputProps={{
                                    placeholder: "Manufacture Date Range", // Set placeholder text
                                    
                                  }}
                                  sx={{
                                    shrink: true,
                                    style: { color: "#E35D22" },
                                    "& input::placeholder": {
                                      color: "black  ",
                                    }, // Style for the placeholder text color
                                  }}
                                >
                                  {[
                                    {name:"Select AirCarft Registration Expiration",value:"Select AirCarft Registration Expiration"},
{name:"ALL",value:"all"},
{name:"30 Days",value:"30 Days"},
{name:"2 Months",value:"2 Months"},
{name:"6 Months",value:"6 Months"},
{name:"Greater than 1 year",value:"Greater than 1 year"},
{name:"Expired",value:"Expired"},
].map((option) => (
                                    <MenuItem
                                      disabled={
                                        option.name === "Select AirCarft Registration Expiration"
                                          ? true
                                          : false
                                      }
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Field>
                            {/* <FormHelperText>Start Year</FormHelperText> */}
                          </Box>

  </div>
  <div className="col-6 p-0 pl-lg-0 p-0 pl-md-2 pl-lg-3 mt-3">
  <div className="col-12 d-flex  align-items-center custom-border-orange-field bg-white py-2 px-0">
                            <div className="custom-form-sub-heading bg-white">
                              Select Aircraft Manufacture
                            </div>
                            <AsyncSelect
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              loadOptions={searchManufacturerName}
                              isMulti
                              options={geoLocation}
                              styles={colourStyles}
                              onChange={(e:any) => setModelName(e)}
                              placeholder='Select Manufacture' 
                              className="w-100"
                            />
                          </div></div>
</div>

<div className="row">
  <div className="col-12 p-0 pl-lg-0 ">
<div className=" col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
                                 <div className="amePopup-sub-heading">
                                 Include Airman
                                  </div>
                                 <div className=" amePopup-label col-12 d-flex justify-content-center align-items-center">
                                   <div className="pl-2">
                                   <Field
                                     className="form-check-input pl-2"
                                     type="checkbox"
                                     name="includeAirman"
                                     id="includeAirman"
                                    //  value="exactmatch1"
                                     checked={includeAirman}
                                     onClick={() =>setIncludeAirman(!includeAirman)}/>
                                   </div>
                                   <label
                                     className="form-check-label pl-2"
                                     htmlFor="includeAirman"
                                   >
                                     Search and Include Airman and Pilot information
                                   </label>
                                 </div>

                                 

                                 <div className="amePopup-label">
                                   <label
                                     className="form-check-label pl-2 pl-md-0"
                                     htmlFor="outputFormat"
                                   ></label>
                                 </div>
  </div>

  </div>
  <div className="col-12 col-md-6">

 
  </div>
</div>

{includeAirman && <div className="row pt-4">
                    <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
                      <div className="amePopup-sub-heading">Airmen Type</div>
                      <div className="form-check amePopup-label  d-flex align-items-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="airmenType"
                            id="pilot"
                            value="pilot"
                            checked={selectedTab === "pilot"}
                            onChange={handleRadioChange}
                          />
                        </div>

                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="pilot"
                        >
                          Pilot
                        </label>
                      </div>
                      <div className="form-check amePopup-label d-flex align-items-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="airmenType"
                            id="non_pilot"
                            value="non_pilot"
                            checked={selectedTab === "non_pilot"}
                            onChange={handleRadioChange}
                          />
                        </div>

                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="non_Pilot"
                        >
                          Non-Pilot
                        </label>
                      </div>
                      <div className="form-check amePopup-label d-flex align-items-center">
                        <div>
                          <Field
                            className="form-check-input"
                            type="radio"
                            name="airmenType"
                            id="all"
                            value="all"
                            checked={selectedTab === "all"}
                            onChange={handleRadioChange}
                          />
                        </div>

                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="all"
                        >
                          All
                        </label>
                      </div>
                    </div>
                  </div> }
                  {includeAirman &&<div
                      className="row mx-0 mx-sm-3 popUp-tab-transition"
                      id="airmenMedical"
                    >
                      <div className="col-12  ame-sub-boxShadow">
                        <div className="col-12 pt-2 sub-heading">
                          Airmen Medical Class
                        </div>

                        {/* <div className="d-flex justify-content-center align-items-center  flex-column pt-2 pb-3"> */}
                        
                            <div className="col-12 d-flex ">
                            <div className="form-check amePopup-label col-6  d-flex align-items-center">
                              <div>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name="firstClass"
                                id="firstClassCheckbox"
                                checked={checkboxes.firstClass}
                                onChange={checkedAllClass}
                              />
                              </div>
                              <label
                                className="form-check-label pl-2 "
                                htmlFor="firstClass"
                              >
                                1st Class
                              </label>
                            </div>
                            <div className="form-check amePopup-label col-6   d-flex align-items-center">
                            <div>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name="secondClass"
                                id="secondClassCheckbox"
                                checked={checkboxes.secondClass}
                                onChange={checkedAllClass}
                              />
                              </div>
                              <label
                                className="form-check-label pl-2 "
                                htmlFor="secondClass"
                              >
                                2nd Class
                              </label>
                            </div>
                            </div>
                            <div className="col-12  d-flex pb-2">
                            <div className="form-check amePopup-label  col-6   d-flex align-items-center ">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name="thirdClass"
                                id="thirdClassCheckbox"
                                checked={checkboxes.thirdClass}
                                onChange={checkedAllClass}
                              />
                              <label
                                className="form-check-label pl-2 "
                                htmlFor="thirdClass"
                              >
                                3rd Class
                              </label>
                            </div>
                            <div className="form-check amePopup-label  col-6   d-flex align-items-center">
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name="allClasses"
                                id="allClassesCheckbox"
                                checked={checkboxes.allClasses}
                                onChange={checkedAllClass}
                              />
                              <label
                                className="form-check-label pl-2 "
                                htmlFor="allClasses"
                              >
                                All Class
                              </label>
                            </div>
                     
                          </div>
                        {/* </div> */}
                      </div>
                    </div>}

                    {includeAirman &&<div className="row pt-4">
                    <div className="col-12 d-flex offmarket-output-format  flex-wrap ame-boxShadow px-0 py-4">
                      <div className="amePopup-sub-heading">
                        Medical Expiring Within
                      </div>
                      <div className=" amePopup-label col-6 d-flex">
                        <div className="pl-4">
                        <Field
                          className="form-check-input "
                          type="radio"
                          name="medicalExpiring"
                          id="30 Days"
                          value="30 Days"
                          checked={true}
                        /></div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="30 Days"
                        >
                          30 Days
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex  align-items-center">
                      <div  className="pl-4">
                        <Field
                          className="form-check-input"
                          type="radio"
                          name="medicalExpiring"
                          id="60 Days"
                          value="60 Days"
                        /></div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="60 Days"
                        >
                          60 Days
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex  align-items-center">
                      <div  className="pl-4">
                       <Field
                          className="form-check-input"
                          type="radio"
                          name="medicalExpiring"
                          id="90 Days"
                          value="90 Days"
                        /></div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="90 Days"
                        >
                          90 Days
                        </label>
                      </div >
                      <div className=" amePopup-label  col-6 d-flex   align-items-center">
                      <div  className="pl-4">
                       <Field
                          className="form-check-input"
                          type="radio"
                          name="medicalExpiring"
                          id="all"
                          value="all"
                        /></div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="all"
                        >
                          All
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex   align-items-center">
                      <div  className="pl-4">
                       <Field
                          className="form-check-input"
                          type="radio"
                          name="medicalExpiring"
                          id="expired"
                          value="expired"
                        /></div>
                        <label
                          className="form-check-label pl-2 pl-md-0"
                          htmlFor="expired"
                        >
                          Expired
                        </label>
                      </div>
                      <div className=" amePopup-label  col-6 d-flex   align-items-center"></div>
                    </div>
                  </div>}











                      {/* Last Action & Date */}
                      

                      {/* Last Airworhiness & Date*/}
                     <div className="row pt-2  px-0">
                     <div className="col-md-12 col-sm-12 p-0 "> 
                     <Box
                                  component="form"
                                  sx={{
                                    "& .MuiTextField-root": {
                                      mt: 2,
                                      width: "25ch",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <Field name="pilotype">
                                    {({ field }: { field: any }) => (
                                      <TextField
                                        {...field}
                                        color="warning"
                                        id="outlined-select-currency"
                                        select
                                        label="Sort By:"
                                        className="w-100 col-12"
                                        defaultValue="Please select"
                                        value={field.pilotype}
                                        // onChange={onPilottype}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: {
                                            color: "#E35D22",
                                            fontWeight: "bold",
                                          }, // Color of the label
                                        }}
                                        InputProps={{
                                          placeholder:
                                            "Marketing Distance From Office", // Set placeholder text
                                          
                                        }}
                                        sx={{
                                          "& input::placeholder": {
                                            color: "black  ",
                                          }, // Style for the placeholder text color
                                        }}
                                      >
                                    
                                        {[
                                         {name: "Please select",value:"Please select"},
                                         {name:  "Owner Name",value:"owner_name"},
                                         {name:  "City",value:'city'},
                                         {name:  "Last Action Date",value:"last_action_date"},
                                         {name:  "Certificate Issue Date",value:"certificate_issue_date"}

                                        ].map((option) => (
                                          <MenuItem
                                          disabled={
                                            option.value ===
                                            "Please select"
                                              ? true
                                              : false
                                          }
                                          key={option.name} value={option.value}>
                                            {option.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  </Field>
                                </Box>
                                  </div>
                     </div>


                     <div className="row pt-4">
                  <div className="col-md-12 col-sm-12 p-0 ">            
                                <div className="col-12  d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                  <div className="amePopup-sub-heading">
                                    Order:
                                  </div>
                                  <div className=" amePopup-label col-6 d-flex align-items-center">
                                    <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="order"
                                      id="asc"
                                      value="asc"
                                      onClick={() =>
                                        setContains({...contains,radio8:true})
                                       }
                                       checked={contains.radio8}
                                      // checked
                                      // checked={location === "Geo Location"}
                                      // onClick={() =>
                                      //   setLocation("Geo Location")
                                      // }
                                    />
                                    </div>
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      // htmlFor="CSV"
                                    >
                                      Ascending Order
                                    </label>
                                  </div>

                                  <div className=" amePopup-label  d-flex align-items-center">
                                  <div>
                                    <Field
                                      className="form-check-input"
                                      type="radio"
                                      name="order"
                                      id="Descending Order"
                                      value="desc"
                                      onClick={() =>
                                        setContains({...contains,radio8:false})
                                       }
                                       checked={!contains.radio8}
                                      // checked={location === "State"}
                                      // onClick={() => setLocation("State")}
                                    />
                                     </div>
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      // htmlFor="RTF"
                                    >
                                     Descending Order
                                    </label>
                                  </div>

                                  <div className="amePopup-label">
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      htmlFor="outputFormat"
                                    ></label>
                                  </div>
                                </div>
</div>
                  
                               
                                </div>

                      {/* Output Format */}
                      <div className="row pt-4 pb-4">
                        <div className="col-12  d-flex justify-content-start offMarket-box-border px-0 py-4">
                        <div className="offMarket-sub-heading">
                            Output Format
                          </div>
                        <div className="col-12  d-flex justify-content-around">
                          
                          <div className=" amePopup-label d-flex align-items-center">
                            <div>
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="export_file"
                              id="CSV"
                              value="CSV"
                            />
                            </div>

                          
                            <label
                              className="form-check-label pl-2 pl-md-0 d-flex align-items-center"
                              htmlFor="CSV"
                            >
                              CSV
                            </label>
                          </div>
                          <div className="amePopup-label d-flex align-items-center">
                            <div>
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="export_file"
                              id="TXT"
                              value="TXT"
                            />
                            </div>
                         
                            <label
                              className="form-check-label pl-2 pl-md-0"
                              htmlFor="TXT"
                            >
                              TXT
                            </label>
                          </div>
                          <div className=" amePopup-label d-flex align-items-center">
                            <div>
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="export_file"
                              id="RTF"
                              value="RTF"
                            />
                            </div>

                       
                            <label
                              className="form-check-label pl-2 pl-md-0"
                              htmlFor="RTF"
                            >
                              RTF
                            </label>
                          </div>

                          <div className=" amePopup-label d-flex align-items-center">
                            <div>
                            <Field
                              className="form-check-input"
                              type="radio"
                              name="export_file"
                              id="avery5163LabelPDF"
                              value="avery5163LabelPDF"
                            />
                            </div>
                            <label
                              className="form-check-label pl-2 pl-md-0"
                              htmlFor="avery5163LabelPDF"
                            >
                              Avery 5163 Label PDF
                            </label>
                          </div>

                          <div className="amePopup-label">
                            <label
                              className="form-check-label pl-2 pl-md-0"
                              htmlFor="outputFormat"
                            ></label>
                          </div>
                        </div>
                        </div>
                     
                        <p className="text-danger field-errorMessage">
                          <ErrorMessage name="export_file" />
                        </p>
                      </div>
                      {/*  Exports Records */}
                      <div className="text-center ">
                        <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                          <button
                            type="submit"
                            className="custom-freeTrial-btn"
                          >
                            Exports Records
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};

































// import { useNavigate } from "react-router-dom";
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import MenuItem from "@mui/material/MenuItem";
// import { useState } from "react";
// import "./offMarketForm.css";
// import * as yup from "yup";
// import * as React from "react";
// import { useAppDispatch } from "../../../hook";
// import { Field, Formik, Form, ErrorMessage } from "formik";
// import { exportsRecords } from "../../../Features/exportsRecordsSlice";
// import { ameZipCode } from "../../../Features/ameZipCodeSlice";
// import { Loader } from "../RepeatedComponent/Loader";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";
// import ListItemText from "@mui/material/ListItemText";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import Checkbox from "@mui/material/Checkbox";
// import dayjs, { Dayjs } from "dayjs";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import Autocomplete from "@mui/material/Autocomplete";
// import { Chip } from "@mui/material";
// import { getPilotStates } from "../../../Features/downloadHistorySlice";
// import { Certificate } from "crypto";
// interface CheckboxState {
//   firstClass: boolean;
//   secondClass: boolean;
//   thirdClass: boolean;
//   airTrafficController: boolean;
//   aircraftMechanics: boolean;
//   parachuteRiggers: boolean;
//   dropZoneManagement: boolean;
//   jumpPilots: boolean;
//   [key: string]: boolean;
// }
// interface Option {
//   label: string;
//   value: string;
// }

// let zipData: any = {
//   zipCode: "",
//   distance: "",
// };

// interface Pilot {
//   firstClass: boolean;
//   secondClass: boolean;
//   thirdClass: boolean;
//   allClasses: boolean;
// }

// export const OffMarketForm = () => {
//   const navigate = useNavigate();
//   const dispatch = useAppDispatch();
//   // const { values } = useFormikContext();
//   const [zip_code, setZipCode] = useState([""]);
//   const [loading, setLoading] = useState(0);
//   const [location, setLocation] = useState("State");
//   const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
//   const [inputValue, setInputValue] = useState<string>("");
//   const [aircraftManufacturer, setAircraftManufacturer] = useState<string[]>(
//     []
//   );
//   const [includeAirman,setIncludeAirman]=useState(false)
//   const [options, setOptions] = useState<Option[]>([]);
//   const [aircraftModel, setAircraftModel] = useState<string[]>([]);
//   const [aircraftTypes, setAircraftTypes] = useState<string[]>([]);
//   const [engineTypes, setEngineTypes] = useState<string[]>([]);
//   const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs("MM-DD-YYYY"));
//   const [certificateDate,setCertificateDate]=useState<Dayjs | null>(dayjs("MM-DD-YYYY"));
//   const [lastActionDate,setLastActionDate]=useState<Dayjs | null>(dayjs("MM-DD-YYYY"));
//   const [airWorthynessDate,setAirWorthynessDate]=useState<Dayjs | null>(dayjs("MM-DD-YYYY"));


//   const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs("MM-DD-YYYY"));
//   const [selectedTab, setSelectedTab] = useState("pilot");

//   // console.log(value, "value");


//   const [checkboxes, setCheckboxes] = useState<Pilot>({
//     firstClass: false,
//     secondClass: false,
//     thirdClass: false,
//     allClasses: false,
//   });
//   const [allRadioCheck, setAllRadioCheck] = useState<CheckboxState>({
//     firstClass: false,
//     secondClass: false,
//     thirdClass: false,
//     airTrafficController: false,
//     aircraftMechanics: false,
//     parachuteRiggers: false,
//     dropZoneManagement: false,
//     jumpPilots: false,
//     // Add more checkboxes as needed
//   });

//   const ITEM_HEIGHT = 48;
//   const ITEM_PADDING_TOP = 8;
//   const MenuProps = {
//     PaperProps: {
//       style: {
//         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//         width: 250,
//       },
//     },
//   };

//   const marketingDistance = [
//     {
//       value: "selected",
//       label: "Please Select",
//     },
//   ];

//   // Generating miles from 10 to 200
//   for (let miles = 10; miles <= 200; miles += 10) {
//     marketingDistance.push({
//       value: miles.toString(),
//       label: `${miles} Miles`,
//     });
//   }

//   const manufacturerName = [
//     "Oliver Hansen",
//     "Van Henry",
//     "April Tucker",
//     "Ralph Hubbard",
//     "Omar Alexander",
//     "Carlos Abbott",
//     "Miriam Wagner",
//     "Bradley Wilkerson",
//     "Virginia Andrews",
//     "Kelly Snyder",
//   ];

//   const aircraftModelName = [
//     "Per Selected Manufacturer",
//     "1S-28M2",
//     "April Tucker",
//     "Ralph Hubbard",
//     "Omar Alexander",
//     "Carlos Abbott",
//     "Miriam Wagner",
//     "Bradley Wilkerson",
//     "Virginia Andrews",
//     "Kelly Snyder",
//   ];

//   const aircraftTypesName = [
//     "Balloon",
//     "Blimp/Dirigible",
//     "Fixed Wing Multi Engine",
//     "Fixed Wing Single Engine",
//     "Glider",
//     "Gyroplane",
//     "Hybrid Lift",
//     "Powered Parachute",
//     "Rotorcraft",
//     "Weight-Shift-Control",
//     "Other",
//   ];

//   const engineTypesName = [
//     "2 Cycle",
//     "4 Cycle",
//     "Electric",
//     "None",
//     "Ramjet",
//     "Reciprocating",
//     "Rotary",
//     "Turbo-fan",
//     "Turbo-jet",
//     "Turbo-prop",
//     "Turbo-shaft",
//     "Unknown",
//   ];

//   const years = [
//     {
//       value: "selected",
//       label: "Please Select",
//     },
//   ];

//   for (let year = 2024; year >= 1930; year--) {
//     years.push({
//       value: year.toString(),
//       label: year.toString(),
//     });
//   }


//   const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
//     setInputValue(value);
//   };

//   const checkedAllClass = (event: any) => {
//     const { name, checked } = event.target;

//     // Update individual checkbox state
//     setCheckboxes((prevState) => ({
//       ...prevState,
//       [name]: checked,
//     }));
//     // If "All Class" checkbox is checked, update all other checkboxes accordingly
//     if (name === "allClasses") {
//       setCheckboxes({
//         firstClass: checked,
//         secondClass: checked,
//         thirdClass: checked,
//         allClasses: checked,
//       });
//     } else {
//       // If any other checkbox is unchecked, uncheck "All Class" checkbox
//       if (!checked) {
//         setCheckboxes((prevState) => ({
//           ...prevState,
//           allClasses: false,
//         }));
//       }
//     }
//   };

//   const handleChange = (
//     event: SelectChangeEvent<typeof aircraftManufacturer>
//   ) => {
//     const {
//       target: { value },
//     } = event;
//     let valued = (event.target.value as string[]).filter((ele: string) => {
//       if (ele !== "Please Select") {
//         return true;
//       }
//     });
//     console.log(valued, "our vauled");
//     setAircraftManufacturer(
//       // On autofill we get a stringified value.
//       valued
//     );
//   };
//   const handleChange2 = (
//     event: SelectChangeEvent<typeof aircraftManufacturer>
//   ) => {
//     const {
//       target: { value },
//     } = event;
//     let valued = (event.target.value as string[]).filter((ele: string) => {
//       if (ele !== "Please Select") {
//         return true;
//       }
//     });
//     // console.log(valued, "our vauled");
//     setAircraftModel(
//       // On autofill we get a stringified value.
//       valued
//     );
//   };
//   const handleChange3 = (
//     event: SelectChangeEvent<typeof aircraftManufacturer>
//   ) => {
//     const {
//       target: { value },
//     } = event;
//     let valued = (event.target.value as string[]).filter((ele: string) => {
//       if (ele !== "Please Select") {
//         return true;
//       }
//     });
//     console.log(valued, "our vauled");
//     setAircraftTypes(
//       // On autofill we get a stringified value.
//       valued
//     );
//   };
//   const handleChange4 = (event: React.ChangeEvent<{ value: unknown }>) => {
//     const selectedValues = (event.target.value as string[]).filter(
//       (ele: string) => ele !== "Please Select"
//     );
//     console.log(selectedValues, "our valued");
//     setEngineTypes(selectedValues);
//   };

 
//   const [zipData, setZipData] = useState<any>({
//     zipCode: 0,
//     distance: 0,
//     success: "",
//   });
//   const onChangeZipCode = (e: any) => {
//     zipData.zipCode = e.target.value;
//     const { value } = e.target;
//     if (value?.length > 9) {
//       e.target.value = value.slice(0, 9); // Limit to 9 characters
//     }
//     if (zipData.zipCode && zipData.distance) {
//       dispatch(ameZipCode(zipData)).then((res: any) => {
//         console.log("responce from zip code", res.payload);
//       });
//     }
//   };
//   const onChangeMarketingDistance = (e: any) => {
//     const distance = e.target.value;
//     if (zipData.zipCode && distance) {
//       zipData.distance = distance;
//       dispatch(ameZipCode(zipData)).then((res: any) => {
//         const zipCodes = res.payload.map((item: any) => item.ZipCode);
//         setZipCode(zipCodes);
//       });
//     }
//   };

//   const initialValues1 = {
//     export_file: "",
//   };

//   // Validation schema using yup
//   const validationSchema = yup.object().shape({
//     expire: yup.string().required("Please check some option!"),
//     export_file: yup.string().required("Please check some option!"),
//   });

//   /// Handle form submission
//   const handleSubmitSetData = (values: any) => {
//     // setLoading(1);

//     values = {
//       aircraftManufacturer,
//       aircraftModel,
//       aircraftTypes,
//       engineTypes,
//       ...values,
//     };

//     // Assuming 'zip_code' is already defined somewhere in your code
//     const finalValues = { ...values, zip_code };
//     console.log(finalValues, "finalValues");

//     console.log("=================================================");
//     console.log(
//       {registration_number: values.registration_number,
//       registration_number_check: "exact_number",
//       name:values.ownerName,
//       name_check: "sub_string",
//       address: "add",
//       address_check: "sub_string",
//       city_name: "city",
//       city_name_check: "sub_string",
//       // region_or_state[]: "AE"
//       // region_or_state[]: "AK"
//       // region_or_state[]: "AL"
//       region_type: "state",
//       zip_code:" 28277,28271,28226,28247,28228,28270,28134,28287,28210,28104,28106,28211,28224,28263,28105,28209,28220,29715,29716,29708,28222,28274,29707,28201,28273,28231,28229,28217,28207,28234,28079,28173,28212,28203,28230,28296,28233,28235,28204,28232,28237,28299,28242,28255,28205,28218,28243,28289,28246,28281,28275,28282,28236,28241,28265,28202,28288,28244,28285,28284,28272,28280,28266,28260,28108,28227,28219,28278,28254,28206,28215,28258,28208,28290,29744,28110,28297,28126,29730,29731,28111,28213,28256,29732,28216,28214,29704,28130,29733,28269,28112,29734,28253,28012,28262,28223,28032,28107,29712,28075,28101,28174,28120,28221,29710,28056,28054,28098,29722,29721,29724,29745,29729,29703,28078,28070,29720,28103,28097,28027,29714,28053,28164,28163,28025,28026,28052,29726,28055,28034,28006,28036,28031,28129"
//       ,certificate_date: "03/01/2024",
//       certificate_date_check: "equal_to_or_more_than",
//       last_action_date: "03/02/2024",
//       last_action_date_check: "equal_to_or_more_than",
//       airworthiness_date: "03/03/2024",
//       airworthiness_date_check: "equal_to_or_more_than",
//       manufacture_year_from: "2000",
//       manufacture_year_to: "2020",
//       // aircraft_name[]: 2
//       // aircraft_name[]: 3
//       // aircraft_name[]: 5
//       // aircraft_name[]: 4
//       // aircraft_name[]: 1
//       // aircraft_name[]: 9
//       // aircraft_name[]: H
//       // aircraft_name[]: O
//       // aircraft_name[]: 8
//       // aircraft_name[]: 6
//       // aircraft_name[]: 7
//       // e_name[]: 7
//       // e_name[]: 8
//       // e_name[]: 10
//       // e_name[]: 0
//       // e_name[]: 6
//       // e_name[]: 1
//       // e_name[]: 11
//       // e_name[]: 5
//       // e_name[]: 4
//       // e_name[]: 2
//       // e_name[]: 3
//       // e_name[]: 9
//       expiry:" 1 Year",
//       include_airman: true,
//       airman: "pilot",
//       // airman_class[]: 1st
//       // airman_class[]: 2nd
//       // airman_class[]: 3rd
//       expire: "No Current Medical",
//       sort_type: "certificate_issue_date",
//       sort_type_order: "asc",
//       export_file: "RTF",
//       task_type: "aircraft",
//     })
//   };

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth", // Optional: Adds smooth scrolling animation
//     });
//   };

//   const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSelectedTab(event.target.value);
//     const { value } = event.target;

//     if (value === "all") {
//       const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
//       for (const key in updatedCheckboxes) {
//         if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
//           updatedCheckboxes[key] = true;
//         }
//       }
//       setAllRadioCheck(updatedCheckboxes);
//     } else {
//       const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
//       for (const key in updatedCheckboxes) {
//         if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
//           updatedCheckboxes[key] = false;
//         }
//       }
//       setAllRadioCheck(updatedCheckboxes);
//     }
//   };

//   React.useEffect(() => {
//     let a={
//       name:"",
//       surname:''
//     }
    
//     dispatch(getPilotStates()).then((res: any) => {
//       // console.log(res.payload.states, "res of state");
//       let filteredStates = res.payload.states.filter((item: any) => item.state !== '');
//       let hello: any = filteredStates.map((item: any) => ({
//         value: item.state,
//         label: item.state_abbrev,
//       }));
//       // console.log(hello, "all hello");
//       setOptions(hello);
//       // console.log(hello,"show hello")
//     });
//   }, []);
//   return (
//     <>
//       {loading ? <Loader /> : ""}
//       {/* Popup */}
//       <Formik
//         initialValues={initialValues1}
//         // validationSchema={validationSchema}
//         onSubmit={handleSubmitSetData}
//       >
//         {() => (
//           <div className="modal-body bg-white p-0">
//             <div className="container-fluid">
//               <div className="row text-black">
//                 {/* content*/}
//                 <div className="col-md-12 col-sm-12 ">
//                   <div className="col-12  px-4 py-3 text-left font-inter mb-3">
//                     <Form>
//                       {/* AME Zip code & Marketing Distance */}
//                       <div className="row">
//                               <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
//                                 <Box
//                                   component="form"
//                                   sx={{
//                                     "& .MuiTextField-root": {
//                                       mt: 2,
//                                       width: "25ch",
//                                     },
//                                   }}
//                                   noValidate
//                                   autoComplete="off"
//                                 >
//                                   <Field name="registration_number">
//                                     {({ field }: { field: any }) => (
//                                       <TextField
//                                         {...field}
//                                         color="warning"
//                                         // required
//                                         id="outlined-number"
//                                         // type="text"
//                                         className="w-100 text-black"
//                                         label="Registration Number:(Leave blank for all)"
//                                         value={field.value || 'N'}
//                                         placeholder="N"
//                                         // onChange={onChangeZipCode}
//                                         InputLabelProps={{
//                                           shrink: true,
//                                           style: { color: "#E35D22",fontWeight: "bold" },
//                                         }}
//                                         InputProps={{
//                                           style: { color: "black" },
//                                           // inputProps: { maxLength: 9,minLength:5 },
//                                         }}
//                                         sx={{
//                                           "& input::placeholder": {
//                                             color: "black",
//                                           }, // Style for the placeholder text color
//                                         }}
//                                       />
//                                     )}
//                                   </Field>
//                                 </Box>
//                                 <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
//                                  <div className=" amePopup-label col-6 d-flex align-items-center">
//                                    <div>
//                                    <Field
//                                      className="form-check-input"
//                                      type="radio"
//                                      name="locations1"
//                                      id="exactmatch1"
//                                      value="exactmatch1"
//                                      // checked={location === "Geo Location"}
//                                      // onClick={() =>
//                                      //   setLocation("Geo Location")
//                                      // }
//                                    />
//                                    </div>
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="exactmatch1"
//                                    >
//                                      Exact Match
//                                    </label>
//                                  </div>

//                                  <div className=" amePopup-label  d-flex align-items-center">
//                                  <div>
//                                    <Field
//                                      className="form-check-input"
//                                      type="radio"
//                                      name="locations1"
//                                      id="Contains1"
//                                      value="Contains1"
//                                      // checked={location === "State"}
//                                      // onClick={() => setLocation("State")}
//                                    />
//                                     </div>
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="Contains1"
//                                    >
//                                      Contains
//                                    </label>
//                                  </div>

//                                  <div className="amePopup-label">
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="outputFormat"
//                                    ></label>
//                                  </div>
//                                </div>
//                               </div>
//                               <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
//                               <Box
//                         component="form"
//                         sx={{
//                           "& .MuiTextField-root": {
//                             mt: 2,
//                             width: "25ch",
//                           },
//                         }}
//                         noValidate
//                         autoComplete="off"
//                       >  <Field name="ownerName">
//                       {({ field }: { field: any }) => (
//                         <TextField
//                         {...field}
//                           // required
//                           color="warning"
//                           id="outlined-number"
//                           name="ownerName"
//                           className="w-100 text-black"
//                           label="Owner Name:(Leave blank for all)"
//                           type="text"
//                           // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
//                           placeholder="Owner Name"
//                           InputLabelProps={{
//                             shrink: true,
//                             style: { color: "#E35D22", fontWeight: "bold" },
//                           }}
//                           InputProps={{
//                             style: { color: "black" }, // Text color of the input
//                           }}
//                           sx={{
//                             "& input::placeholder": { color: "black" }, // Style for the placeholder text color
//                             // Change border color when focused
//                           }}
//                         />)}
//                         </Field>
//                       </Box>
//                       <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
//                                  <div className=" amePopup-label col-6 d-flex align-items-center">
//                                    <div>
//                                    <Field
//                                      className="form-check-input"
//                                      type="radio"
//                                      name="locations2"
//                                      id="exactmatch2"
//                                      value="exactmatch2"
//                                      // checked={location === "Geo Location"}
//                                      // onClick={() =>
//                                      //   setLocation("Geo Location")
//                                      // }
//                                    />
//                                    </div>
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="exactmatch2"
//                                    >
//                                      Exact Match
//                                    </label>
//                                  </div>

//                                  <div className=" amePopup-label  d-flex align-items-center">
//                                  <div>
//                                    <Field
//                                      className="form-check-input"
//                                      type="radio"
//                                      name="locations2"
//                                      id="Contains2"
//                                      value="Contains2"
//                                      // checked={location === "State"}
//                                      // onClick={() => setLocation("State")}
//                                    />
//                                     </div>
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="Contains2"
//                                    >
//                                      Contains
//                                    </label>
//                                  </div>

//                                  <div className="amePopup-label">
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="outputFormat"
//                                    ></label>
//                                  </div>
//                                </div>
//                                 {/* <p className="text-danger field-errorMessage ">
//                                     <ErrorMessage name="marketingDistance" />
//                                   </p> */}
//                               </div>
//                             </div>

                     



//                       <div className="row">
//                     <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
//                       <Box
//                         component="form"
//                         sx={{
//                           "& .MuiTextField-root": {
//                             mt: 2,
//                             width: "25ch",
//                           },
//                         }}
//                         noValidate
//                         autoComplete="off"
//                       ><Field name="address">
//                       {({ field }: { field: any }) => (
//                         <TextField
//                         {...field}
//                           // required
//                           color="warning"
//                           id="outlined-number"
//                           name="address"
//                           className="w-100 text-black"
//                           label="Address:(Leave blank for all)"
//                           type="text"
//                           // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
//                           placeholder="Address"
//                           InputLabelProps={{
//                             shrink: true,
//                             style: { color: "#E35D22", fontWeight: "bold" },
//                           }}
//                           InputProps={{
//                             style: { color: "black" }, // Text color of the input
//                           }}
//                           sx={{
//                             "& input::placeholder": { color: "black" }, // Style for the placeholder text color
//                             // Change border color when focused
//                           }}
//                         />)}
//                         </Field>
//                       </Box>
//                       <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
//                                   <div className=" amePopup-label col-6 d-flex align-items-center">
//                                     <div>
//                                     <Field
//                                       className="form-check-input"
//                                       type="radio"
//                                       name="locations3"
//                                       id="exactmatch3"
//                                       value="exactmatch3"
//                                       // checked={location === "Geo Location"}
//                                       // onClick={() =>
//                                       //   setLocation("Geo Location")
//                                       // }
//                                     />
//                                     </div>
//                                     <label
//                                       className="form-check-label pl-2 pl-md-0"
//                                       htmlFor="exactmatch3"
//                                     >
//                                       Exact Match
//                                     </label>
//                                   </div>

//                                   <div className=" amePopup-label  d-flex align-items-center">
//                                   <div>
//                                     <Field
//                                       className="form-check-input"
//                                       type="radio"
//                                       name="locations3"
//                                       id="Contains3"
//                                       value="Contains3"
//                                       // checked={location === "State"}
//                                       // onClick={() => setLocation("State")}
//                                     />
//                                      </div>
//                                     <label
//                                       className="form-check-label pl-2 pl-md-0"
//                                       htmlFor="Contains3"
//                                     >
//                                       Contains
//                                     </label>
//                                   </div>

//                                   <div className="amePopup-label">
//                                     <label
//                                       className="form-check-label pl-2 pl-md-0"
//                                       htmlFor="outputFormat"
//                                     ></label>
//                                   </div>
//                                 </div>
//                     </div>
//                     <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
//                       <Box
//                         component="form"
//                         sx={{
//                           "& .MuiTextField-root": {
//                             mt: 2,
//                             width: "25ch",
//                           },
//                         }}
//                         noValidate
//                         autoComplete="off"
//                       >
//                          <Field name="city">
//                                         {({ field }: { field: any }) => (
//                         <TextField
//                         {...field}
//                           // required
//                           color="warning"
//                           id="outlined-number"
//                           name="city"
//                           className="w-100 text-black"
//                           label="City:(Leave blank for all)"
//                           type="text"
//                           // onChange={(e:any)=>setZipData({...zipData,zipCode:e.target.value})}
//                           placeholder="City"
//                           InputLabelProps={{
//                             shrink: true,
//                             style: { color: "#E35D22", fontWeight: "bold" },
//                           }}
//                           InputProps={{
//                             style: { color: "black" }, // Text color of the input
//                           }}
//                           sx={{
//                             "& input::placeholder": { color: "black" }, // Style for the placeholder text color
//                             // Change border color when focused
//                           }}
//                         />)}
//                           </Field>
//                       </Box>
//                       <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
//                                  <div className=" amePopup-label col-6 d-flex align-items-center">
//                                    <div>
//                                    <Field
//                                      className="form-check-input"
//                                      type="radio"
//                                      name="locations4"
//                                      id="exactmatch4"
//                                      value="exactmatch4"
//                                      // checked={location === "Geo Location"}
//                                      // onClick={() =>
//                                      //   setLocation("Geo Location")
//                                      // }
//                                    />
//                                    </div>
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="exactmatch4"
//                                    >
//                                      Exact Match
//                                    </label>
//                                  </div>

//                                  <div className=" amePopup-label  d-flex align-items-center">
//                                  <div>
//                                    <Field
//                                      className="form-check-input"
//                                      type="radio"
//                                      name="locations4"
//                                      id="Contains4"
//                                      value="Contains4"
//                                      // checked={location === "State"}
//                                      // onClick={() => setLocation("State")}
//                                    />
//                                     </div>
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="Contains4"
//                                    >
//                                      Contains
//                                    </label>
//                                  </div>

//                                  <div className="amePopup-label">
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="outputFormat"
//                                    ></label>
//                                  </div>
//                                </div>
//                     </div>
//                   </div>

//                   <div className="row ">
//                               <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
//                                 {location === "State" && (
//                                   <div>
//                                     <Autocomplete
//                                       className="mt-3"
//                                       multiple
//                                       id="multi-select"
//                                       options={options.filter(
//                                         (option:any) =>
//                                           !selectedOptions.some(
//                                             (selectedOption) =>
//                                               selectedOption.value ===
//                                               option.value
//                                           )
//                                       )}
//                                       getOptionLabel={(option) => option.label}
//                                       value={selectedOptions}
//                                       onChange={(event, newValue) =>
//                                         setSelectedOptions(newValue)
//                                       }
//                                       inputValue={inputValue}
//                                       onInputChange={handleInputChange}
//                                       renderInput={(params) => (
//                                         <TextField
//                                           {...params}
//                                           label="State:(Leave blank for all)"
//                                           variant="outlined"
//                                           fullWidth
//                                           color="warning"
//                                           InputLabelProps={{ shrink: true }} // This will make the label always visible
//                                         />
//                                       )}
//                                       renderTags={(value, getTagProps) =>
//                                         value.map((option, index) => (
//                                           <Chip
//                                             // key={index}
//                                             color="warning"
//                                             label={option.label}
//                                             // onDelete={() => handleDeleteOption(option)}
//                                             {...getTagProps({ index })}
//                                           />
//                                         ))
//                                       }
//                                     />
//                                     {/* <Box
//                                   component="form"
//                                   sx={{
//                                     "& .MuiTextField-root": {
//                                       mt: 2,
//                                       width: "25ch",
//                                     },
//                                   }}
//                                   noValidate
//                                   autoComplete="off"
//                                 >
//                                   <Field name="ameOfficeZipCode">
//                                     {({ field }: { field: any }) => (
//                                       <TextField
//                                         {...field}
//                                         color="warning"
//                                         id="outlined-select-currency"
//                                         select
//                                         label="State:(Leave blank for all)"
//                                         className="w-100"
//                                         defaultValue="New Student Pilots"
//                                         value={field.ameOfficeZipCode}
//                                         // onChange={onChangeMarketingDistance}
//                                         InputLabelProps={{
//                                           shrink: true,
//                                           style: {
//                                             color: "#E35D22",
//                                             fontWeight: "bold",
//                                           }, // Color of the label
//                                         }}
//                                         InputProps={{
//                                           placeholder:
//                                             "Marketing Distance From Office", // Set placeholder text
                                          
//                                         }}
//                                         sx={{
//                                           "& input::placeholder": {
//                                             color: "black  ",
//                                           }, // Style for the placeholder text color
//                                         }}
//                                       >
//                                         {Array.isArray(states) && states.length > 0 && states.map((state:any) => (
//                                           <MenuItem
//                                           key={state.state}
//                                           value={state.state_abbrev}
//                                           >
//                                             {state.state_abbrev}
//                                           </MenuItem>
//                                         ))}
//                                       </TextField>
//                                     )}
//                                   </Field>
//                                 </Box> */}
//                                   </div>
//                                 )}
//                                 <div className="col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
//                                   <div className="amePopup-sub-heading">
//                                     Choose By:
//                                   </div>
//                                   <div className=" amePopup-label col-6 d-flex align-items-center">
//                                     <div>
//                                     <Field
//                                       className="form-check-input"
//                                       type="radio"
//                                       name="location"
//                                       id="Geo Location"
//                                       value="Geo Location"
//                                       checked={location === "Geo Location"}
//                                       onClick={() =>
//                                         setLocation("Geo Location")
//                                       }
//                                     />
//                                     </div>
//                                     <label
//                                       className="form-check-label pl-2 pl-md-0"
//                                       // htmlFor="CSV"
//                                     >
//                                       Geo Location
//                                     </label>
//                                   </div>

//                                   <div className=" amePopup-label  d-flex align-items-center">
//                                   <div>
//                                     <Field
//                                       className="form-check-input"
//                                       type="radio"
//                                       name="location"
//                                       id="State"
//                                       value="State"
//                                       checked={location === "State"}
//                                       onClick={() => setLocation("State")}
//                                     />
//                                      </div>
//                                     <label
//                                       className="form-check-label pl-2 pl-md-0"
//                                       // htmlFor="RTF"
//                                     >
//                                       State
//                                     </label>
//                                   </div>

//                                   <div className="amePopup-label">
//                                     <label
//                                       className="form-check-label pl-2 pl-md-0"
//                                       htmlFor="outputFormat"
//                                     ></label>
//                                   </div>
//                                 </div>
//                                 <div></div>
//                               </div>
//                               <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
//                                 {location === "State" ? (
//                                   <div>
//                                     <Box
//                                       component="form"
//                                       sx={{
//                                         "& .MuiTextField-root": {
//                                           mt: 2,
//                                           width: "25ch",
//                                         },
//                                       }}
//                                       noValidate
//                                       autoComplete="off"
//                                     >
//                                       <Field name="ameOfficeZipCode">
//                                         {({ field }: { field: any }) => (
//                                           <TextField
//                                             {...field}
//                                             color="warning"
//                                             // required
//                                             id="outlined-number"
//                                             type="number"
//                                             name="ameOfficeZipCode"
//                                             className="w-100 text-black "
//                                             label="Zip Code"
//                                             value={field.ameOfficeZipCode}
//                                             placeholder="e.g.12345"
//                                             onChange={(e: any) =>
//                                               setZipData({
//                                                 ...zipData,
//                                                 zipCode: e.target.value,
//                                               })
//                                             }
//                                             InputLabelProps={{
//                                               shrink: true,
//                                               style: {
//                                                 color: "#E35D22",
//                                                 fontWeight: "bold",
//                                               },
//                                             }}
//                                             InputProps={{
//                                               style: {
//                                                 color: "black",
//                                                 borderColor: "",
//                                               }, // Set borderColor to 
//                                               inputProps: {
//                                                 maxLength: 9,
//                                                 minLength: 5,
//                                               },
//                                             }}
//                                             sx={{
//                                               "& input::placeholder": {
//                                                 color: "black",
//                                               }, // Style for the placeholder text color
//                                             }}
//                                           />
//                                         )}
//                                       </Field>
//                                     </Box>

//                                     <Box
//                                       component="form"
//                                       sx={{
//                                         "& .MuiTextField-root": {
//                                           mt: 2,
//                                           width: "25ch",
//                                         },
//                                       }}
//                                       noValidate
//                                       autoComplete="off"
//                                     >
//                                       <Field name="marketingDistance">
//                                         {({ field }: { field: any }) => (
//                                           <TextField
//                                             {...field}
//                                             color="warning"
//                                             id="outlined-select-currency"
//                                             select
//                                             label=" Distance"
//                                             className="w-100 "
//                                             defaultValue="selected"
//                                             value={field.marketingDistance}
//                                             onChange={(e: any) =>
//                                               setZipData({
//                                                 ...zipData,
//                                                 distance: e.target.value,
//                                               })
//                                             }
//                                             // onChange={onChangeMarketingDistance}
//                                             InputLabelProps={{
//                                               shrink: true,
//                                               style: {
//                                                 color: "#E35D22",
//                                                 fontWeight: "bold",
//                                               }, // Color of the label
//                                             }}
//                                             InputProps={{
//                                               placeholder:
//                                                 "Marketing Distance From Office", // Set placeholder text
//                                               style: {
//                                                 outlineColor: "#E35D22",
//                                               },
//                                             }}
//                                             sx={{
//                                               "& input::placeholder": {
//                                                 color: "black  ",
//                                               }, // Style for the placeholder text color
//                                             }}
//                                           >
//                                             {marketingDistance.map((option) => (
//                                               <MenuItem
//                                                 disabled={
//                                                   option.label ===
//                                                   "Please select"
//                                                     ? true
//                                                     : false
//                                                 }
//                                                 key={option.value}
//                                                 value={option.value}
//                                               >
//                                                 {option.label}
//                                               </MenuItem>
//                                             ))}
//                                           </TextField>
//                                         )}
//                                       </Field>
//                                     </Box>
//                                   </div>
//                                 ) : (
//                                   <Box
//                                     component="form"
//                                     sx={{
//                                       "& .MuiTextField-root": {
//                                         mt: 2,
//                                         width: "25ch",
//                                       },
//                                     }}
//                                     noValidate
//                                     autoComplete="off"
//                                   >
//                                     <Field name="geolocation">
//                                       {({ field }: { field: any }) => (
//                                         <TextField
//                                           {...field}
//                                           color="warning"
//                                           id="outlined-select-currency"
//                                           select
//                                           label="Geo Location:(Leave blank for all)"
//                                           className="w-100 "
//                                           defaultValue="New Student Pilots"
//                                           value={field.ameOfficeZipCode}
//                                           // onChange={onChangeMarketingDistance}
//                                           InputLabelProps={{
//                                             shrink: true,
//                                             style: {
//                                               color: "#E35D22",
//                                               fontWeight: "bold",
//                                             }, // Color of the label
//                                           }}
//                                           InputProps={{
//                                             placeholder:
//                                               "Marketing Distance From Office", // Set placeholder text
                                            
//                                           }}
//                                           sx={{
//                                             "& input::placeholder": {
//                                               color: "black  ",
//                                             }, // Style for the placeholder text color
//                                           }}
//                                         >
//                                           {[
//                                             "Alaskan",
//                                             "Central",
//                                             "Eastern",
//                                             "Foreign",
//                                             "Great Lakes",
//                                             "Northwest Mountain",
//                                             "Southern",
//                                             "Southwestern",
//                                             "Western-Pacific",
//                                           ].map((state: any) => (
//                                             <MenuItem key={state} value={state}>
//                                               {state}
//                                             </MenuItem>
//                                           ))}
//                                         </TextField>
//                                       )}
//                                     </Field>
//                                   </Box>
//                                 )}
//                                 {/* <p className="text-danger field-errorMessage ">
//                                     <ErrorMessage name="marketingDistance" />
//                                   </p> */}
//                               </div>
//                             </div>


//                             <div className="row">
//                         <div className="col-md-6 col-sm-12 p-0 pl-lg-0 ">
                          
//                           <Box
//                             component="form"
//                             sx={{
//                               "& .MuiTextField-root": {
//                                 mt: 2,
//                                 width: "100%",
//                               },
//                               "& .MuiInputLabel-root": {
//                                 color: "rgb(227, 93, 34)",
//                                 fontWeight:"bold" // Set label color to 
//                               },
//                               "& .MuiInputLabel-shrink": {
//                                 color: "#E35D22",
//                                 fontWeight: "bold"
//                               }
//                             }}
//                             noValidate
//                             autoComplete="off"
//                           >
//                                 <Field name="certificate_date">
//                               {({ field }: { field: any }) => ( 
//                             <LocalizationProvider dateAdapter={AdapterDayjs}>
//                               <DemoContainer
//                                 components={["DatePicker"]}
//                               >
//                                 <DatePicker
//                                     //  {...field}
//                                   label="Certificate Issue Date:(Leave blank for all)"
//                                   value={certificateDate}
//                                   onChange={(newValue: Dayjs | null) => setCertificateDate(newValue)}
                               
//                                 />
//                               </DemoContainer>
//                             </LocalizationProvider>
//                             )}
//                                 </Field> 
//                           </Box>
//                           <div className="col-12 d-flex justify-content-left offMarket-box-border px-4 py-2 mt-3">
//                             {/* <div className="offMarket-sub-heading">
//                               Last Action
//                             </div> */}
//                             <div className="mt-2 d-flex">
//                               <div className="col-md-12 amePopup-label d-flex align-items-center">
//                                 <div><Field
//                                   className="form-check-input"
//                                   type="radio"
//                                   name="lastAction"
//                                   id="Equal"
//                                   value="Equal"
//                                 /></div>
//                                 <label
//                                   className="form-check-label pl-2 pl-md-0 text-wrap"
//                                   htmlFor="Equal"
//                                 >
//                                   Equal or more than
//                                 </label>
//                               </div>
//                               <div className="col-md-12 amePopup-label d-flex align-items-center">
//                                <div><Field
//                                   className="form-check-input"
//                                   type="radio"
//                                   name="lastAction"
//                                   id="Least"
//                                   value="Least"
//                                 /></div> 
//                                 <label
//                                   className="form-check-label pl-2 pl-md-0 text-wrap"
//                                   htmlFor="Least"
//                                 >
//                                   At Least
//                                 </label>
//                               </div>
//                             </div>
//                           </div>
//                         </div>

//                         <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3 ">
                        
                          
//                           <Box
//                             component="form"
//                             sx={{
//                               "& .MuiTextField-root": {
//                                 mt: 2,
//                                 width: "100%",
//                               },
//                               "& .MuiInputLabel-root": {
//                                 color: "rgb(227, 93, 34)",
//                                 fontWeight:"bold" // Set label color to 
//                               },
//                               "& .MuiInputLabel-shrink": {
//                                 color: "#E35D22",
//                                 fontWeight: "bold"
//                               }
//                             }}
//                             noValidate
//                             autoComplete="off"
//                           >
//                                     <Field name="lastAction">
//                               {({ field }: { field: any }) => (
//                             <LocalizationProvider dateAdapter={AdapterDayjs}>
//                               <DemoContainer
//                                 components={["DatePicker"]}
//                               >
//                                 <DatePicker
//                                     //  {...field}
//                                   label="Last Action Date:(Leave blank for all)"
//                                   value={lastActionDate}
//                                   onChange={(newValue: Dayjs | null) => setLastActionDate(newValue)}
                               
//                                 />
//                               </DemoContainer>
//                             </LocalizationProvider>
//                                  )}
//                                 </Field> 
//                           </Box>
//                           <div className="col-12 d-flex justify-content-left offMarket-box-border px-4 py-2 mt-3">
//                             {/* <div className="offMarket-sub-heading">
//                               Last Action
//                             </div> */}
//                             <div className="mt-2 d-flex">
//                               <div className="col-md-12 amePopup-label d-flex align-items-center">
//                                 <div><Field
//                                   className="form-check-input"
//                                   type="radio"
//                                   name="lastAction"
//                                   id="Equal"
//                                   value="Equal"
//                                 /></div>
//                                 <label
//                                   className="form-check-label pl-2 pl-md-0 text-wrap"
//                                   htmlFor="Equal"
//                                 >
//                                   Equal or more than
//                                 </label>
//                               </div>
//                               <div className="col-md-12 amePopup-label d-flex align-items-center">
//                                <div><Field
//                                   className="form-check-input"
//                                   type="radio"
//                                   name="lastAction"
//                                   id="Least"
//                                   value="Least"
//                                 /></div> 
//                                 <label
//                                   className="form-check-label pl-2 pl-md-0 text-wrap"
//                                   htmlFor="Least"
//                                 >
//                                   At Least
//                                 </label>
//                               </div>
//                             </div>
//                           </div>
                       
//                         </div>
//                       </div>


//                       <div className="row">
//                         <div className="col-md-6 col-sm-12 p-0 pl-lg-0 ">
                          
//                           <Box
//                             component="form"
//                             sx={{
//                               "& .MuiTextField-root": {
//                                 mt: 2,
//                                 width: "100%",
//                               },
//                               "& .MuiInputLabel-root": {
//                                 color: "rgb(227, 93, 34)",
//                                 fontWeight:"bold" // Set label color to 
//                               },
//                               "& .MuiInputLabel-shrink": {
//                                 color: "#E35D22",
//                                 fontWeight: "bold"
//                               }
//                             }}
//                             noValidate
//                             autoComplete="off"
//                           >
//                                     {/* <Field name="lastAction">
//                               {({ field }: { field: any }) => ( */}
//                             <LocalizationProvider dateAdapter={AdapterDayjs}>
//                               <DemoContainer
//                                 components={["DatePicker"]}
//                               >
//                                 <DatePicker
//                                     //  {...field}
//                                   label="Airworthiness Date:(Leave blank for all)"
//                                   value={airWorthynessDate}
//                                   onChange={(newValue: Dayjs | null) => setAirWorthynessDate(newValue)}
                               
//                                 />
//                               </DemoContainer>
//                             </LocalizationProvider>
//                                 {/* )}
//                                 </Field> */}
//                           </Box>
//                           <div className="col-12 d-flex justify-content-left offMarket-box-border px-4 py-2 mt-3">
//                             {/* <div className="offMarket-sub-heading">
//                               Last Action
//                             </div> */}
//                             <div className="mt-2 d-flex">
//                               <div className="col-md-12 amePopup-label d-flex align-items-center">
//                                 <div><Field
//                                   className="form-check-input"
//                                   type="radio"
//                                   name="lastAction"
//                                   id="Equal"
//                                   value="Equal"
//                                 /></div>
//                                 <label
//                                   className="form-check-label pl-2 pl-md-0 text-wrap"
//                                   htmlFor="Equal"
//                                 >
//                                   Equal or more than
//                                 </label>
//                               </div>
//                               <div className="col-md-12 amePopup-label d-flex align-items-center">
//                                <div><Field
//                                   className="form-check-input"
//                                   type="radio"
//                                   name="lastAction"
//                                   id="Least"
//                                   value="Least"
//                                 /></div> 
//                                 <label
//                                   className="form-check-label pl-2 pl-md-0 text-wrap"
//                                   htmlFor="Least"
//                                 >
//                                   At Least
//                                 </label>
//                               </div>
//                             </div>
//                           </div>
//                         </div>

//                         <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3 mt-2">
//                         <Box
//                             component="form"
//                             sx={{
//                               "& .MuiTextField-root": {
//                                 mt: 2,
//                                 width: "25ch",
//                               },
//                             }}
//                             noValidate
//                             autoComplete="off"
//                           >
//                             {" "}
//                             <Field name="manufactureStartDate">
//                               {({ field }: { field: any }) => (
//                                 <TextField
//                                   {...field}
//                                   color="warning"
//                                   id="outlined-select-currency"
//                                   select
//                                   label="Manufacture Date Range From"
//                                   className="w-100 "
//                                   defaultValue="selected"
//                                   value={field.manufactureStartDate}
//                                   // helperText="Start Year"
//                                   //   onChange={onChangeMarketingDistance}
//                                   InputLabelProps={{
//                                     shrink: true,
//                                     style: {
//                                       color: "#E35D22",
//                                       fontWeight: "bold",
//                                     },
//                                   }}
//                                   InputProps={{
//                                     placeholder: "Manufacture Date Range", // Set placeholder text
                                    
//                                   }}
//                                   sx={{
//                                     shrink: true,
//                                     style: { color: "#E35D22" },
//                                     "& input::placeholder": {
//                                       color: "black  ",
//                                     }, // Style for the placeholder text color
//                                   }}
//                                 >
//                                   {years.map((option) => (
//                                     <MenuItem
//                                       disabled={
//                                         option.label === "Please select"
//                                           ? true
//                                           : false
//                                       }
//                                       key={option.value}
//                                       value={option.value}
//                                     >
//                                       {option.label}
//                                     </MenuItem>
//                                   ))}
//                                 </TextField>
//                               )}
//                             </Field>
//                             {/* <FormHelperText>Start Year</FormHelperText> */}
//                           </Box>
//                           <Box
//                             component="form"
//                             sx={{
//                               "& .MuiTextField-root": {
//                                 mt: 2,
//                                 width: "25ch",
//                               },
//                             }}
//                             noValidate
//                             autoComplete="off"
//                           >
//                             {" "}
//                             <Field name="manufactureStartDate">
//                               {({ field }: { field: any }) => (
//                                 <TextField
//                                   {...field}
//                                   color="warning"
//                                   id="outlined-select-currency"
//                                   select
//                                   label="Manufacture Date Range To"
//                                   className="w-100"
//                                   defaultValue="selected"
//                                   value={field.manufactureStartDate}
//                                   // helperText="Start Year"
//                                   //   onChange={onChangeMarketingDistance}
//                                   InputLabelProps={{
//                                     shrink: true,
//                                     style: {
//                                       color: "#E35D22",
//                                       fontWeight: "bold",
//                                     },
//                                   }}
//                                   InputProps={{
//                                     placeholder: "Manufacture Date Range", // Set placeholder text
                                    
//                                   }}
//                                   sx={{
//                                     shrink: true,
//                                     style: { color: "#E35D22" },
//                                     "& input::placeholder": {
//                                       color: "black  ",
//                                     }, // Style for the placeholder text color
//                                   }}
//                                 >
//                                   {years.map((option) => (
//                                     <MenuItem
//                                       disabled={
//                                         option.label === "Please select"
//                                           ? true
//                                           : false
//                                       }
//                                       key={option.value}
//                                       value={option.value}
//                                     >
//                                       {option.label}
//                                     </MenuItem>
//                                   ))}
//                                 </TextField>
//                               )}
//                             </Field>
//                             {/* <FormHelperText>Start Year</FormHelperText> */}
//                           </Box>
//                         </div>
//                       </div>





//                       {/* Aircraft Manufacturer & Aircraft Model */}
//                       {/* <div className="row pt-3">
//                         <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
//                           <Field name="aircraftManufacturer">
//                             {({ field }: { field: any }) => (
//                               <FormControl sx={{}}>
//                                 <InputLabel
//                                   id="aircraftManufacturer"
//                                   shrink={true}
//                                   style={{
//                                     color: "#E35D22",
//                                     background: "white",
//                                     fontWeight: "bold",
//                                   }}
//                                 >
//                                   Aircraft Manufacturer
//                                 </InputLabel>
//                                 <Select
//                                   //   {...field}
//                                   labelId="demo-multiple-checkbox-label"
//                                   id="demo-multiple-checkbox"
//                                   color="warning"
//                                   multiple
//                                   value={
//                                     aircraftManufacturer.length > 0
//                                       ? aircraftManufacturer
//                                       : ["Please Select"]
//                                   }
//                                   className=""
//                                   onChange={handleChange}
//                                   input={
//                                     <OutlinedInput label="aircraftManufacturer" />
//                                   }
//                                   renderValue={(selected) =>
//                                     selected.join(", ")
//                                   }
//                                   MenuProps={MenuProps}
//                                   sx={{
//                                     "& input::placeholder": {
//                                       color: "black",
//                                     },
//                                   }}
//                                 >
//                                   <MenuItem disabled value="Choose One or More">
//                                     <p>Choose One or More</p>
//                                   </MenuItem>
//                                   {manufacturerName.map((name) => (
//                                     <MenuItem key={name} value={name}>
//                                       <Checkbox
//                                         checked={
//                                           aircraftManufacturer.indexOf(name) >
//                                           -1
//                                         }
//                                       />
//                                       <ListItemText primary={name} />
//                                     </MenuItem>
//                                   ))}
//                                 </Select>
//                               </FormControl>
//                             )}
//                           </Field>
//                         </div>

//                         <div className="col-md-6 col-sm-12 mt-3 mt-md-0  p-0 pl-md-2 pl-lg-3">
//                           <Field name="aircraftModel">
//                             {({ field }: { field: any }) => (
//                               <FormControl sx={{}}>
//                                 <InputLabel
//                                   id="demo-multiple-checkbox-label"
//                                   shrink={true}
//                                   style={{
//                                     color: "#E35D22",
//                                     background: "white",
//                                     fontWeight: "bold",
//                                   }}
//                                 >
//                                   Aircraft Model
//                                 </InputLabel>
//                                 <Select
//                                   //   {...field}
//                                   labelId="demo-multiple-checkbox-label"
//                                   id="demo-multiple-checkbox"
//                                   color="warning"
//                                   multiple
//                                   value={
//                                     aircraftModel.length > 0
//                                       ? aircraftModel
//                                       : ["Please Select"]
//                                   }
//                                   className=""
//                                   onChange={handleChange2}
//                                   input={
//                                     <OutlinedInput label="aircraftModel" />
//                                   }
//                                   renderValue={(selected) =>
//                                     selected.join(", ")
//                                   }
//                                   MenuProps={MenuProps}
//                                   sx={{
//                                     "& input::placeholder": {
//                                       color: "black",
//                                     },
//                                   }}
//                                 >
//                                   <MenuItem disabled value="Choose One or More">
//                                     <p>Choose One or More</p>
//                                   </MenuItem>
//                                   {aircraftModelName.map((name) => (
//                                     <MenuItem key={name} value={name}>
//                                       <Checkbox
//                                         checked={
//                                           aircraftModel.indexOf(name) > -1
//                                         }
//                                       />
//                                       <ListItemText primary={name} />
//                                     </MenuItem>
//                                   ))}
//                                 </Select>
//                               </FormControl>
//                             )}
//                           </Field>
//                         </div>
//                       </div> */}

//                       {/* Manufacture Date Range = Start Year End Year */}
//                       {/* <div className="row">
//                         <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
//                           <Box
//                             component="form"
//                             sx={{
//                               "& .MuiTextField-root": {
//                                 mt: 2,
//                                 width: "25ch",
//                               },
//                             }}
//                             noValidate
//                             autoComplete="off"
//                           >
//                             {" "}
//                             <Field name="manufactureStartDate">
//                               {({ field }: { field: any }) => (
//                                 <TextField
//                                   {...field}
//                                   color="warning"
//                                   id="outlined-select-currency"
//                                   select
//                                   label="Manufacture Date Range"
//                                   className="w-100"
//                                   defaultValue="selected"
//                                   value={field.manufactureStartDate}
//                                   // helperText="Start Year"
//                                   //   onChange={onChangeMarketingDistance}
//                                   InputLabelProps={{
//                                     shrink: true,
//                                     style: {
//                                       color: "#E35D22",
//                                       fontWeight: "bold",
//                                     },
//                                   }}
//                                   InputProps={{
//                                     placeholder: "Manufacture Date Range", // Set placeholder text
                                    
//                                   }}
//                                   sx={{
//                                     shrink: true,
//                                     style: { color: "#E35D22" },
//                                     "& input::placeholder": {
//                                       color: "black  ",
//                                     }, // Style for the placeholder text color
//                                   }}
//                                 >
//                                   {years.map((option) => (
//                                     <MenuItem
//                                       disabled={
//                                         option.label === "Please select"
//                                           ? true
//                                           : false
//                                       }
//                                       key={option.value}
//                                       value={option.value}
//                                     >
//                                       {option.label}
//                                     </MenuItem>
//                                   ))}
//                                 </TextField>
//                               )}
//                             </Field>
//                           </Box>
//                         </div>
//                         <div className="col-md-6 col-sm-12 p-0 pt-3 pt-md-0 pl-md-2 pl-lg-3">
//                           <Box
//                             component="form"
//                             sx={{
//                               "& .MuiTextField-root": {
//                                 mt: 2,
//                                 // width: "25ch",
//                               },
//                             }}
//                             noValidate
//                             autoComplete="off"
//                           >
//                             <Field name="manufactureEndDate">
//                               {({ field }: { field: any }) => (
//                                 <TextField
//                                   {...field}
//                                   color="warning"
//                                   id="outlined-select-currency"
//                                   select
//                                   label="Manufacture Date Range"
//                                   className="w-100"
//                                   defaultValue="selected"
//                                   value={field.manufactureEndDate}
//                                   helperText="End Year"
//                                   //   onChange={onChangeMarketingDistance}
//                                   InputLabelProps={{
//                                     shrink: true,
//                                     style: {
//                                       color: "#E35D22",
//                                       fontWeight: "bold",
//                                     },
//                                   }}
//                                   InputProps={{
//                                     placeholder: "Manufacture Date Range",
                                    
//                                   }}
//                                   sx={{
//                                     shrink: true,
//                                     style: { color: "#E35D22" },
//                                     "& input::placeholder": {
//                                       color: "black  ",
//                                     },
//                                   }}
//                                 >
//                                   {years.map((option) => (
//                                     <MenuItem
//                                       disabled={
//                                         option.label === "Please select"
//                                           ? true
//                                           : false
//                                       }
//                                       key={option.value}
//                                       value={option.value}
//                                     >
//                                       {option.label}
//                                     </MenuItem>
//                                   ))}
//                                 </TextField>
//                               )}
//                             </Field>
//                           </Box>
//                         </div>
//                       </div> */}

//                       {/* Aircraft Types & Engine Types */}
//                       <div className="row pt-3">
//                         <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
//                           <Field name="aircraftTypes">
//                             {({ field }: { field: any }) => (
//                               <FormControl sx={{}}>
//                                 <InputLabel
//                                   id="demo-multiple-checkbox-label"
//                                   shrink={true}
//                                   style={{
//                                     color: "#E35D22",
//                                     background: "white",
//                                     fontWeight: "bold",
//                                   }}
//                                 >
//                                   Aircraft Types11:(Leave blank for all)
//                                 </InputLabel>
//                                 <Select
//                                   // {...field}
//                                   labelId="demo-multiple-checkbox-label"
//                                   id="demo-multiple-checkbox"
//                                   color="warning"
//                                   multiple
//                                   value={
//                                     aircraftTypes.length > 0
//                                       ? aircraftTypes
//                                       : ["Please Select"]
//                                   }
//                                   className="col-12"
//                                   onChange={handleChange3}
//                                   input={
//                                     <OutlinedInput label="aircraftTypes" />
//                                   }
//                                   renderValue={(selected) =>
//                                     selected.join(", ")
//                                   }
//                                   MenuProps={MenuProps}
//                                   sx={{
//                                     "& input::placeholder": {
//                                       color: "black",
//                                     },
//                                   }}
//                                 >
//                                   <MenuItem disabled value="Choose One or More">
//                                     <p>Choose One or More</p>
//                                   </MenuItem>
//                                   {aircraftTypesName.map((name) => (
//                                     <MenuItem key={name} value={name}>
//                                       <Checkbox
//                                         checked={
//                                           aircraftTypes.indexOf(name) > -1
//                                         }
//                                       />
//                                       <ListItemText primary={name} />
//                                     </MenuItem>
//                                   ))}
//                                 </Select>
//                               </FormControl>
//                             )}
//                           </Field>
//                         </div>
//                         <div className="col-md-6 col-sm-12 p-0 pt-3 pt-md-0 pl-md-2 pl-lg-3">
//                           <Field name="Aircraft Model">
//                             {({ field }: { field: any }) => (
//                               <FormControl sx={{}}>
//                                 <InputLabel
//                                   id="demo-multiple-checkbox-label"
//                                   shrink={true}
//                                   style={{
//                                     color: "#E35D22",
//                                     background: "white",
//                                     fontWeight: "bold",
//                                   }}
//                                 >
//                                   Engine Types:(Leave blank for all)
//                                 </InputLabel>
//                                 <Select
//                                   {...field}
//                                   labelId="demo-multiple-checkbox-label"
//                                   id="demo-multiple-checkbox"
//                                   color="warning"
//                                   multiple
//                                   value={
//                                     engineTypes.length > 0
//                                       ? engineTypes
//                                       : ["Please Select"]
//                                   }
//                                   className=""
//                                   onChange={handleChange4}
//                                   input={
//                                     <OutlinedInput label="Aircraft Model" />
//                                   }
//                                   renderValue={(selected: any) =>
//                                     selected.join(", ")
//                                   }
//                                   MenuProps={MenuProps}
//                                   sx={{
//                                     "& input::placeholder": {
//                                       color: "black",
//                                     },
//                                   }}
//                                 >
//                                   <MenuItem disabled value="Choose One or More">
//                                     <p>Choose One or More</p>
//                                   </MenuItem>
//                                   {engineTypesName.map((name) => (
//                                     <MenuItem key={name} value={name}>
//                                       <Checkbox
//                                         checked={engineTypes.indexOf(name) > -1}
//                                       />
//                                       <ListItemText primary={name} />
//                                     </MenuItem>
//                                   ))}
//                                 </Select>
//                               </FormControl>
//                             )}
//                           </Field>
//                         </div>
//                       </div>


//                       <div className="row">
//   <div className="col-12 p-0 pl-lg-0 ">
// <div className=" col-12 mt-3 d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
                                 
//                                  <div className="amePopup-sub-heading">
//                                  Include Airman
//                                   </div>
//                                  <div className=" amePopup-label col-12 d-flex justify-content-center align-items-center">
//                                    <div className="pl-2">
//                                    <Field
//                                      className="form-check-input pl-2"
//                                      type="checkbox"
//                                      name="includeAirman"
//                                      id="includeAirman"
//                                     //  value="exactmatch1"
//                                      checked={includeAirman}
//                                      onClick={() =>setIncludeAirman(!includeAirman)}/>
//                                    </div>
//                                    <label
//                                      className="form-check-label pl-2"
//                                      htmlFor="includeAirman"
//                                    >
//                                      Search and Include Airman and Pilot information
//                                    </label>
//                                  </div>

                                 

//                                  <div className="amePopup-label">
//                                    <label
//                                      className="form-check-label pl-2 pl-md-0"
//                                      htmlFor="outputFormat"
//                                    ></label>
//                                  </div>
//   </div>

//   </div>
//   <div className="col-12 col-md-6">

//   {/* <div className="row pt-4">
//                     <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
//                       <div className="amePopup-sub-heading">Airmen Type</div>
//                       <div className="form-check amePopup-label  d-flex align-items-center">
//                         <div>
//                           <Field
//                             className="form-check-input"
//                             type="radio"
//                             name="airmenType"
//                             id="pilot"
//                             value="pilot"
//                             checked={selectedTab === "pilot"}
//                             onChange={handleRadioChange}
//                           />
//                         </div>

//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="pilot"
//                         >
//                           Pilot
//                         </label>
//                       </div>
//                       <div className="form-check amePopup-label d-flex align-items-center">
//                         <div>
//                           <Field
//                             className="form-check-input"
//                             type="radio"
//                             name="airmenType"
//                             id="non_pilot"
//                             value="non_pilot"
//                             checked={selectedTab === "non_pilot"}
//                             onChange={handleRadioChange}
//                           />
//                         </div>

//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="non_Pilot"
//                         >
//                           Non-Pilot
//                         </label>
//                       </div>
//                       <div className="form-check amePopup-label d-flex align-items-center">
//                         <div>
//                           <Field
//                             className="form-check-input"
//                             type="radio"
//                             name="airmenType"
//                             id="all"
//                             value="all"
//                             checked={selectedTab === "all"}
//                             onChange={handleRadioChange}
//                           />
//                         </div>

//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="all"
//                         >
//                           All
//                         </label>
//                       </div>
//                     </div>
//                   </div> */}

//   </div>
// </div>


// {includeAirman && <div className="row pt-4">
//                     <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
//                       <div className="amePopup-sub-heading">Airmen Type</div>
//                       <div className="form-check amePopup-label  d-flex align-items-center">
//                         <div>
//                           <Field
//                             className="form-check-input"
//                             type="radio"
//                             name="airmenType"
//                             id="pilot"
//                             value="pilot"
//                             checked={selectedTab === "pilot"}
//                             onChange={handleRadioChange}
//                           />
//                         </div>

//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="pilot"
//                         >
//                           Pilot
//                         </label>
//                       </div>
//                       <div className="form-check amePopup-label d-flex align-items-center">
//                         <div>
//                           <Field
//                             className="form-check-input"
//                             type="radio"
//                             name="airmenType"
//                             id="non_pilot"
//                             value="non_pilot"
//                             checked={selectedTab === "non_pilot"}
//                             onChange={handleRadioChange}
//                           />
//                         </div>

//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="non_Pilot"
//                         >
//                           Non-Pilot
//                         </label>
//                       </div>
//                       <div className="form-check amePopup-label d-flex align-items-center">
//                         <div>
//                           <Field
//                             className="form-check-input"
//                             type="radio"
//                             name="airmenType"
//                             id="all"
//                             value="all"
//                             checked={selectedTab === "all"}
//                             onChange={handleRadioChange}
//                           />
//                         </div>

//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="all"
//                         >
//                           All
//                         </label>
//                       </div>
//                     </div>
//                   </div> }
//                   {includeAirman &&<div
//                       className="row mx-0 mx-sm-3 popUp-tab-transition"
//                       id="airmenMedical"
//                     >
//                       <div className="col-12  ame-sub-boxShadow">
//                         <div className="col-12 pt-2 sub-heading">
//                           Airmen Medical Class
//                         </div>

//                         {/* <div className="d-flex justify-content-center align-items-center  flex-column pt-2 pb-3"> */}
                        
//                             <div className="col-12 d-flex ">
//                             <div className="form-check amePopup-label col-6  d-flex align-items-center">
//                               <div>
//                               <Field
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 name="firstClass"
//                                 id="firstClassCheckbox"
//                                 checked={checkboxes.firstClass}
//                                 onChange={checkedAllClass}
//                               />
//                               </div>
//                               <label
//                                 className="form-check-label pl-2 "
//                                 htmlFor="firstClass"
//                               >
//                                 1st Class
//                               </label>
//                             </div>
//                             <div className="form-check amePopup-label col-6   d-flex align-items-center">
//                             <div>
//                               <Field
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 name="secondClass"
//                                 id="secondClassCheckbox"
//                                 checked={checkboxes.secondClass}
//                                 onChange={checkedAllClass}
//                               />
//                               </div>
//                               <label
//                                 className="form-check-label pl-2 "
//                                 htmlFor="secondClass"
//                               >
//                                 2nd Class
//                               </label>
//                             </div>
//                             </div>
//                             <div className="col-12  d-flex pb-2">
//                             <div className="form-check amePopup-label  col-6   d-flex align-items-center ">
//                               <Field
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 name="thirdClass"
//                                 id="thirdClassCheckbox"
//                                 checked={checkboxes.thirdClass}
//                                 onChange={checkedAllClass}
//                               />
//                               <label
//                                 className="form-check-label pl-2 "
//                                 htmlFor="thirdClass"
//                               >
//                                 3rd Class
//                               </label>
//                             </div>
//                             <div className="form-check amePopup-label  col-6   d-flex align-items-center">
//                               <Field
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 name="allClasses"
//                                 id="allClassesCheckbox"
//                                 checked={checkboxes.allClasses}
//                                 onChange={checkedAllClass}
//                               />
//                               <label
//                                 className="form-check-label pl-2 "
//                                 htmlFor="allClasses"
//                               >
//                                 All Class
//                               </label>
//                             </div>
                     
//                           </div>
//                         {/* </div> */}
//                       </div>
//                     </div>}

//                     {includeAirman &&<div className="row pt-4">
//                     <div className="col-12 d-flex offmarket-output-format  flex-wrap ame-boxShadow px-0 py-4">
//                       <div className="amePopup-sub-heading">
//                         Medical Expiring Within
//                       </div>
//                       <div className=" amePopup-label col-6 d-flex">
//                         <div className="pl-4">
//                         <Field
//                           className="form-check-input "
//                           type="radio"
//                           name="medicalExpiring"
//                           id="30 Days"
//                           value="30 Days"
//                           checked={true}
//                         /></div>
//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="30 Days"
//                         >
//                           30 Days
//                         </label>
//                       </div>
//                       <div className=" amePopup-label  col-6 d-flex  align-items-center">
//                       <div  className="pl-4">
//                         <Field
//                           className="form-check-input"
//                           type="radio"
//                           name="medicalExpiring"
//                           id="60 Days"
//                           value="60 Days"
//                         /></div>
//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="60 Days"
//                         >
//                           60 Days
//                         </label>
//                       </div>
//                       <div className=" amePopup-label  col-6 d-flex  align-items-center">
//                       <div  className="pl-4">
//                        <Field
//                           className="form-check-input"
//                           type="radio"
//                           name="medicalExpiring"
//                           id="90 Days"
//                           value="90 Days"
//                         /></div>
//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="90 Days"
//                         >
//                           90 Days
//                         </label>
//                       </div >
//                       <div className=" amePopup-label  col-6 d-flex   align-items-center">
//                       <div  className="pl-4">
//                        <Field
//                           className="form-check-input"
//                           type="radio"
//                           name="medicalExpiring"
//                           id="all"
//                           value="all"
//                         /></div>
//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="all"
//                         >
//                           All
//                         </label>
//                       </div>
//                       <div className=" amePopup-label  col-6 d-flex   align-items-center">
//                       <div  className="pl-4">
//                        <Field
//                           className="form-check-input"
//                           type="radio"
//                           name="medicalExpiring"
//                           id="expired"
//                           value="expired"
//                         /></div>
//                         <label
//                           className="form-check-label pl-2 pl-md-0"
//                           htmlFor="expired"
//                         >
//                           Expired
//                         </label>
//                       </div>
//                       <div className=" amePopup-label  col-6 d-flex   align-items-center"></div>
//                     </div>
//                   </div>}











//                       {/* Last Action & Date */}
                      

//                       {/* Last Airworhiness & Date*/}
//                      <div className="row pt-2  px-0">
//                      <div className="col-md-12 col-sm-12 p-0 "> 
//                      <Box
//                                   component="form"
//                                   sx={{
//                                     "& .MuiTextField-root": {
//                                       mt: 2,
//                                       width: "25ch",
//                                     },
//                                   }}
//                                   noValidate
//                                   autoComplete="off"
//                                 >
//                                   <Field name="pilotype">
//                                     {({ field }: { field: any }) => (
//                                       <TextField
//                                         {...field}
//                                         color="warning"
//                                         id="outlined-select-currency"
//                                         select
//                                         label="Sort By:"
//                                         className="w-100 col-12"
//                                         defaultValue="Please select"
//                                         value={field.pilotype}
//                                         // onChange={onPilottype}
//                                         InputLabelProps={{
//                                           shrink: true,
//                                           style: {
//                                             color: "#E35D22",
//                                             fontWeight: "bold",
//                                           }, // Color of the label
//                                         }}
//                                         InputProps={{
//                                           placeholder:
//                                             "Marketing Distance From Office", // Set placeholder text
                                          
//                                         }}
//                                         sx={{
//                                           "& input::placeholder": {
//                                             color: "black  ",
//                                           }, // Style for the placeholder text color
//                                         }}
//                                       >
                                    
//                                         {[
//                                          {name: "Please select",value:"Please select"},
//                                          {name:  "Owner Name",value:"owner_name"},
//                                          {name:  "City",value:'city'},
//                                          {name:  "Last Action Date",value:"last_action_date"},
//                                          {name:  "Certificate Issue Date",value:"certificate_issue_date"}

//                                         ].map((option) => (
//                                           <MenuItem
//                                           disabled={
//                                             option.value ===
//                                             "Please select"
//                                               ? true
//                                               : false
//                                           }
//                                           key={option.name} value={option.value}>
//                                             {option.name}
//                                           </MenuItem>
//                                         ))}
//                                       </TextField>
//                                     )}
//                                   </Field>
//                                 </Box>
//                                   </div>
//                      </div>


//                      <div className="row pt-4">
//                   <div className="col-md-12 col-sm-12 p-0 ">            
//                                 <div className="col-12  d-flex offmarket-output-format ame-boxShadow px-0 py-md-3 py-4">
//                                   <div className="amePopup-sub-heading">
//                                     Order:
//                                   </div>
//                                   <div className=" amePopup-label col-6 d-flex align-items-center">
//                                     <div>
//                                     <Field
//                                       className="form-check-input"
//                                       type="radio"
//                                       name="order"
//                                       id="asc"
//                                       value="asc"
//                                       // checked={location === "Geo Location"}
//                                       // onClick={() =>
//                                       //   setLocation("Geo Location")
//                                       // }
//                                     />
//                                     </div>
//                                     <label
//                                       className="form-check-label pl-2 pl-md-0"
//                                       // htmlFor="CSV"
//                                     >
//                                       Ascending Order
//                                     </label>
//                                   </div>

//                                   <div className=" amePopup-label  d-flex align-items-center">
//                                   <div>
//                                     <Field
//                                       className="form-check-input"
//                                       type="radio"
//                                       name="order"
//                                       id="Descending Order"
//                                       value="desc"
//                                       // checked={location === "State"}
//                                       // onClick={() => setLocation("State")}
//                                     />
//                                      </div>
//                                     <label
//                                       className="form-check-label pl-2 pl-md-0"
//                                       // htmlFor="RTF"
//                                     >
//                                      Descending Order
//                                     </label>
//                                   </div>

//                                   <div className="amePopup-label">
//                                     <label
//                                       className="form-check-label pl-2 pl-md-0"
//                                       htmlFor="outputFormat"
//                                     ></label>
//                                   </div>
//                                 </div>
// </div>
                  
                               
//                                 </div>

//                       {/* Output Format */}
//                       <div className="row pt-4 pb-4">
//                         <div className="col-12  d-flex justify-content-start offMarket-box-border px-0 py-4">
//                         <div className="offMarket-sub-heading">
//                             Output Format
//                           </div>
//                         <div className="col-12  d-flex justify-content-around">
                          
//                           <div className=" amePopup-label d-flex align-items-center">
//                             <Field
//                               className="form-check-input"
//                               type="radio"
//                               name="export_file"
//                               id="CSV"
//                               value="CSV"
//                             />
//                             <label
//                               className="form-check-label pl-2 pl-md-0 d-flex align-items-center"
//                               htmlFor="CSV"
//                             >
//                               CSV
//                             </label>
//                           </div>
//                           <div className="amePopup-label d-flex align-items-center">
//                             <Field
//                               className="form-check-input"
//                               type="radio"
//                               name="export_file"
//                               id="TXT"
//                               value="TXT"
//                             />
//                             <label
//                               className="form-check-label pl-2 pl-md-0"
//                               htmlFor="TXT"
//                             >
//                               TXT
//                             </label>
//                           </div>
//                           <div className=" amePopup-label d-flex align-items-center">
//                             <Field
//                               className="form-check-input"
//                               type="radio"
//                               name="export_file"
//                               id="RTF"
//                               value="RTF"
//                             />
//                             <label
//                               className="form-check-label pl-2 pl-md-0"
//                               htmlFor="RTF"
//                             >
//                               RTF
//                             </label>
//                           </div>

//                           <div className=" amePopup-label d-flex align-items-center">
//                             <Field
//                               className="form-check-input"
//                               type="radio"
//                               name="export_file"
//                               id="avery5163LabelPDF"
//                               value="avery5163LabelPDF"
//                             />
//                             <label
//                               className="form-check-label pl-2 pl-md-0"
//                               htmlFor="avery5163LabelPDF"
//                             >
//                               Avery 5163 Label PDF
//                             </label>
//                           </div>

//                           <div className="amePopup-label">
//                             <label
//                               className="form-check-label pl-2 pl-md-0"
//                               htmlFor="outputFormat"
//                             ></label>
//                           </div>
//                         </div>
//                         </div>
                     
//                         <p className="text-danger field-errorMessage">
//                           <ErrorMessage name="export_file" />
//                         </p>
//                       </div>
//                       {/*  Exports Records */}
//                       <div className="text-center ">
//                         <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
//                           <button
//                             type="submit"
//                             className="custom-freeTrial-btn"
//                           >
//                             Exports Records
//                           </button>
//                         </div>
//                       </div>
//                     </Form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//       </Formik>
//     </>
//   );
// };