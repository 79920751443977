import React, { useEffect } from "react";
import "./loader.css";
export const Loader = () => {
  
  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = (event: Event) => {
      window.scrollTo(100, 100);
    };

    // Add event listener to the document
    document.addEventListener('scroll', handleScroll, { passive: false });

    // Clean up function to remove the event listener when component unmounts
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className=" loader-body">
        <div className="col-12 custom-loader"></div>
      </div>
    </>
  );
};
export {};
