import React from 'react'
import { Link } from 'react-router-dom'

export const FreeTrial = () => {
  return (
    <>
      <div className="sr-offer-bar-03 bg-primary sr-padding-half   prototype-no-background widget_34472050333 text-white">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="row d-flex align-items-center h-100 sr-offer-bar-03-inner ">
                  <div className="col-lg-12">
                    <div className="row ">
                      <div className="col sr-col ">
                        <div className="row d-flex align-items-center h-100">
                          <div className="col-md-7 sr-col sr-offer-bar-03-content">
                            <span className="align-middle mx-auto">
                              <h3 className=" heading text-white font-inter">
                                What are you waiting for?
                              </h3>
                              <div className="description">
                                <p>
                                  Get started today! To experience the Power of
                                  PlaneLists.com , sign up for a free 7-day trial.
                                </p>
                              </div>
                            </span>
                          </div>
                          <div className="col-md-5 text-center text-md-right offer-btns">
                            <span className="btn-wrapper btn-white-wrapper d-inline-block btn-wrapper">
                              <Link to="/register" className="cta_button font-inter">
                                Free 7-Day Trial
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
