import React from 'react'
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";

const Terms = () => {
  return (
    <>
<div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center   prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1604012269835"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Privacy Policy content */}
        <div
          className="sr-multicol-media bg-black sr-padding-half text-center text-white
          prototype-no-background widget_1604012269835 "
          id="research-property"
        >
          <div className="container-fluid content-wrapper">
            <div className="row">
              <div className="col-md-10 offset-md-1">
              <h2 className=" section-heading font-inter">
              Terms of Service
                </h2>
                <div className="row  sr-valign-center">
                  {/* Introduction */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-1">
                    <div className="content-wrapper ">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row ">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center 
                          mt-3 pt-2 pb-2 pl-0 ">
                            <div className="border px-4 py-2 privacy-para-header">
                            Terms of Service
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                             when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                              It has survived not only five centuries, but also the leap into electronic typesetting, 
                              remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset 
                              sheets containing Lorem Ipsum passages, and more recently with desktop publishing software 
                            like Aldus PageMaker including versions of Lorem Ipsum.
                            </p>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            


                            
    </>
  )
}

export default Terms