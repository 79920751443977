import "./App.css";
import "./Assets/css/custom.css";
import "./Assets/css/footer.min.css";
import "./Assets/css/hero_video.css";
import "./Assets/css/nav_global.css";
import "./Assets/css/sr-base.css";
import "./Assets/css/stat2.css";
import "./Assets/css/style.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./app/Components/HomePage/HomePage";
import { AboutUsPage } from "./app/Components/AboutUs/AboutUsPage";
import HeaderPage from "./app/Components/Header/HeaderPage";
import Footer from "./app/Components/Footer/Footer";
import AviationServices from "./app/Components/Off-MarketAircraftLeads/AviationServices";
import AviationListServices from "./app/Components/AviationListServices/AviationListServices";
import { Pricing } from "./app/Components/Subscription/Pricing";
import { QuickSearch } from "./app/Components/Admin/QuickSearch/QuickSearch";
import { SearchAirmenRecords } from "./app/Components/Admin/BeginYourSearch/SearchAirmenRecords";
import { SearchAirmenCertificateRecords } from "./app/Components/Admin/BeginYourSearch/SearchAirmenCertificateRecords";
import { SearchAircarftRecords } from "./app/Components/Admin/BeginYourSearch/SearchAircarftRecords";
import { YourDownloadsHistory } from "./app/Components/Admin/History/YourDownloadsHistory";
import { YourPaymentsHistory } from "./app/Components/Admin/History/YourPaymentsHistory";
import { YourSubscriptionHistory } from "./app/Components/Admin/History/YourSubscriptionHistory";
import { AmeMap } from "./app/Components/AME-PatientLeads/AmeMap";
import { Login } from "./app/Components/Login/Login";
import { ActiveAirmenPilots } from "./app/Components/PlaneListDataBase/ActiveAirmen&Pilots";
import { AMEs } from "./app/Components/PlaneListDataBase/AMEs";
import { HistoricalData } from "./app/Components/PlaneListDataBase/HistoricalData";
import { MarketingGenius } from "./app/Components/PlaneListDataBase/MarketingGenius";
import { PlaneListsDatabases } from "./app/Components/PlaneListDataBase/PlaneListsDatabases";
import { USAircraftOwners } from "./app/Components/PlaneListDataBase/USAircraftOwners";
import { Register } from "./app/Components/Register/Register";
import { About } from "./app/Components/FooterLinks/About";
import { Contact } from "./app/Components/FooterLinks/Contact";
import { PrivacyPolicy } from "./app/Components/FooterLinks/PrivacyPolicy";
import { Blog } from "./app/Components/FooterLinks/Blog";
import SearchPage from "./app/Components/Search/Search";
import { useAppDispatch, useAppSelector } from "./hook";
import { loginTrue } from "./Features/authSlice";
import { AMEHealthPopup } from "./app/Components/RepeatedComponent/AMEHealthPopup";
import { CancelSubscription } from "./app/Components/Subscription/CancelSubscription";
import { Personal_InformationPopup } from "./app/Components/RepeatedComponent/Personal_InformationPopup";
import { ChangePassword } from "./app/Components/ChangePassword/ChangePassword";
import { EnquiryForm } from "./app/Components/EnquiryForm/EnquiryForm";
import NewAirMen from "./app/Components/Search/NewAirMen";
import NewAirCraft from "./app/Components/Search/NewAirCraft";
import Userdashboard from "./app/Components/dashboard/Userdashboard";
import CreatePostcard from "./app/Components/CreatePostcard/CreatePostcard";
import { NewAircraftRecords } from "./app/Components/Admin/QuickSearch/NewAircraftRecords";
import { NewPilotRecords } from "./app/Components/Admin/QuickSearch/NewPilotRecords";
import Terms from "./app/Components/FooterLinks/Terms";

function App() {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('_authToken');
  // const reduxData=useSelector((state:any)=>{return state});


  const closingToast=()=>{
console.log("toast is closed")
  }


  if(token){
    dispatch(loginTrue())
  }
  return (
    <>
      {/* <ScrollToTopOnPageChange/> */}
      {/* <Loader/> */}
      <div
        id="hs_cos_wrapper_main_flexible_column"
        className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container"
        style={{}}
        data-hs-cos-general-type="widget_container"
        data-hs-cos-type="widget_container"
      >
        <BrowserRouter>
          <HeaderPage />
          <Routes>
            {/* without login */}
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/subscriptions-list" element={<Pricing />} />
            <Route path="/cancel-subscriptions" element={<CancelSubscription />} />

            <Route
              path="/aviation-research-services"
              element={<AviationServices />}
            />
            <Route
              path="/aviation-list-services"
              element={<AviationListServices />}
            />
            <Route path="/ame-map" element={<AmeMap />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/airmenrecords" element={<NewAirMen/>} />
            <Route path="/aircraftrecords" element={<NewAirCraft />} />
           <Route path="/create-postcard" element={<CreatePostcard/>}/>
            <Route path="/register" element={<Register />} />
            {/* Admin*/}
            <Route path="/quick-search" element={<QuickSearch />} />
            <Route path="/new-aircraft-records" element={<NewAircraftRecords/>} />
            <Route path="/new-pilot-records" element={<NewPilotRecords />} />
            {/* Begin Your Search */}
            <Route
              path="/search-airmen-records"
              element={<SearchAirmenRecords />}
            />
            <Route
              path="/search-airmen-certificate-records"
              element={<SearchAirmenCertificateRecords />}
            />
            <Route
              path="/search-aircraft-records"
              element={<SearchAircarftRecords />}
            />
             <Route path="/enquiry-form" element={< EnquiryForm/>} />
            {/* History */}
            <Route
              path="/your-subscription-history"
              element={<YourSubscriptionHistory />}
            />
            <Route
              path="/your-payment-history"
              element={<YourPaymentsHistory />}
            />
            <Route
              path="/your-downloads-history"
              element={<YourDownloadsHistory />}
            />
                    <Route
              path="/personalInfo"
              element={<Userdashboard />}
            />

            {/* planListDataBase */}
            <Route
              path="/active-airmen-pilots"
              element={<ActiveAirmenPilots />}
            />
            <Route path="/AMEs" element={<AMEs />} />
            <Route path="/historical-data" element={<HistoricalData />} />
            <Route path="/marketing-genius" element={<MarketingGenius />} />
            <Route
              path="/plane-lists-databases"
              element={<PlaneListsDatabases />}
            />
            <Route path="/US-Aircraft-Owners" element={<USAircraftOwners />} />
        
       

        <Route path="/PersonalInfo-modal" element={<Personal_InformationPopup/>} />
        <Route path="/AME-modal" element={<AMEHealthPopup/>} />
          {/* Footer Links */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/blog" element={<Blog />} />
          {/* <Route path="/Feedback" element={<Feedback/>} /> */}
          <Route path="/change-password" element={<ChangePassword/>} />
          <Route path="/user-dashboard" element={<Userdashboard />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
