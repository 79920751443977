import React from "react";
import "../admin.css";
export const SearchAirmenCertificateRecords = () => {
  return (
    <>
      {/* Slider Section */}

      <div
        id="hs_cos_wrapper_widget_34472035839"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {/* Label For Modules Data Page */}
        <div className="widget_1601501547743 sr-hero-01 sr-cover-parallax  prototype-no-background  ">
          <div
            className="sr-cover-image overlay-"
            style={{
              background: "url('images/home-banner.png')",
              backgroundSize: "cover",
            }}
          />
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="col-12">
                  <div className="sr-cover d-flex align-items-center  text-center height-medium">
                    <div className="sr-cover-inner text-white">
                      <h1 className="section-heading text-white">
                        Search Airmen Certificate Records
                      </h1>
                      <div className="description mt-1 ">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s.
                        </p>
                      </div>
                      <div className="sr-spacer-topx-0 py-25 cta-wrapper mt-3 ">
                        <div className="btn-wrapper btn-primary-wrapper">
                          <a href="#" className="cta_button ">
                            Quick Search for Airmen & Aircraft
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="hs_cos_wrapper_widget_1604012269835"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-multicol-media bg-white sr-padding-half text-center prototype-no-background widget_1604012269835 "
          id="research-property"
        >
          <div className="container-fluid content-wrapper">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h2 className=" section-heading font-inter">
                  Airmen Certificate Data
                </h2>
                <p>
                  AIRMEN DATa is current as of the 2nd of each month (Last FAA
                  update)
                </p>
                <div className="row  sr-valign-center">
                  <div className="col-md-6 sr-col text-left  sr-repeater-1">
                    <div className="content-wrapper">
                      <div className="col-content">
                        <p className="justify-content">
                          From here, you can further search airmen such as
                          certified aircraft mechanics, air traffic control
                          operators and pilot records WITH additional ratings
                          such as CFI's or Certified Flight Instructors,
                          Aircraft Dispatchers, Drone Pilots, Repairman,
                          Parachute Riggers etc, and any additional certificate
                          ratings like Airplane Single Engine Land (ASEL) Sport
                          Pilots, Helicopter Pilots, Airframe and the like, as
                          well as such as current or non-current medical class
                          and when they will be expiring. Searches can be done
                          by state, geographical location and NEW! Zip Code
                          search including range and distance!
                        </p>
                        <p className="justify-content">
                          Perfect for Aviation Supply companies, Aviation
                          Insurance Agencies, Training Schools and Refresher
                          Course Operators.
                        </p>
                     
                        <p className="justify-content">
                        <strong>
                  
                  AND....!This information can be cross indexed to see if
                  they own an aircraft, what type, and have a current or
                  EXPIRED medical.
                  </strong>
                          Invaluable for aircraft and aviation sales
                          professionals.
                        </p>
                        <p className="justify-content">
                          All information is easily exported into .csv format or
                          convenient Avery Label format and is updated on the
                          second of each month.All aircraft data is update daily
                          at: 0200 EDT
                        </p>
                        <h6 className="font-inter">
                          For your{" "}
                          <span className="custom-text-color-orange">
                            {" "}
                            FREE{" "}
                          </span>{" "}
                          full access to the database, you must subscribe.
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 sr-col  sr-repeater-2">
                    <div className="content-wrapper">
                      <div className="responsive-image ">
                        <img
                          src="images/AdminImages/AirmenCertificateData.png"
                          alt="Img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="hs_cos_wrapper_widget_1604012269835"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Label For Modules Data Page */}
        <div
          className="sr-multicol-media bg-white sr-padding-half prototype-no-background widget_1604012269835 "
          id="research-property"
        >
          <div className="container content-wrapper">
            <div className="row">
              <div className="col-md-10 offset-md-1 custom-form-shadow">
                {/* start */}
                <div className="row">
                <div className="col-md-12 d-flex  border">
                   <div className="col-12 col-md-6 border">First Name</div>
                   <div className="col-12 col-md-6 border">content</div>
                </div>
                <div className="col-md-12 border d-flex">
                   <div className="col-12 col-md-6 border">Last Name</div>
                   <div className="col-12 col-md-6 border">First Name</div>
                </div>
                </div>

                <div className="row">
                <div className="col-md-12 border">Address</div>
                </div>

                <div className="row">
                <div className="col-md-12 border">Select</div>
                </div>

                <div className="row">
    
                <div className="col-md-12 border">Sort</div>
                </div>
           
               
               {/* end */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Slider Section End*/}
    </>
  );
};
