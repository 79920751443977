import { Loader } from "../RepeatedComponent/Loader";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hook";
import "./search.css";
import * as yup from "yup";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { getFreeAircraftRecords } from "../../../Features/searchSlice";
import { clearState } from "../../../Features/searchSlice";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import makeAnimated from "react-select/animated";
import Select, { StylesConfig } from "react-select";
import chroma from "chroma-js";

interface Option {
  label: string;
  value: string;
}

export const FreeAirCraft = () => {
  const [loading, setLoading] = useState(0);
  const [selectedValues, setSelectedValues] = useState([]);
  const status = useAppSelector((state: any) => state.auth.status);
  const navigate = useNavigate();
  const freeAirMenData = useAppSelector((state: any) => state.search.response);

  const defaultValue = {
    registration_number: "",
    registration_number_check: "contain_number",
    serial_number: "",
    serial_number_check: "contain_serial_number",
    name_check: "sub_string",
    name: "",
    city_name: "",
    states: [""],
    city_name_check: "sub_string",
    max_records: "20",
  };
  const dispatch = useAppDispatch();
  const states = useAppSelector((state: any) => state.downloadHistory.states);
  const freeAirCraftData = useAppSelector(
    (state: any) => state.search.response
  );
  const animatedComponents = makeAnimated();
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState([]);
  const handleSelectChange = (values: any) => {
    setSelectedValues(values);
    console.log(values);
  };

  const formattedStates = states
    ? states
        .filter(
          (stateObj: { state: string; state_abbrev: string }) =>
            stateObj.state && stateObj.state_abbrev
        )
        .map((stateObj: { state: string; state_abbrev: string }) => ({
          value: stateObj.state,
          label: stateObj.state_abbrev,
        }))
    : [];

  useEffect(() => {
    dispatch(clearState());
    dispatch(getPilotStates());
  }, []);

  const validationSchema = yup.object().shape({
    serial_number: yup.string().trim().required("Please enter serial number."),
    max_records: yup.string().trim().required("Please select maximum records."),
  });
  const StateValues = selectedValues.map((item: any) => item.value);

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "black",
      borderColor: "black",
      margin: 0,
      marginLeft: 0,
      border: "0px solid black",
      fontSize: 14,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black",
      },
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "gray",
      fontSize: "14",
      textAlign: "left",
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: "#E35D22",
    }),

    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: "black",
      textAlign: "left",
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
      textAlign: "left",
    }),
  };

  /// Handle form submission
  const handleSubmit = async (values: any) => {
    if (!Array.isArray(values.states)) {
      values.states = values.states?.split();
    }

    setLoading(1);

    const allValues = { ...values, states: StateValues };
    console.log(allValues, "allValues");
    const result: any = await dispatch(getFreeAircraftRecords(allValues));

    if (result.payload.success) {
      setLoading(0);
    } else {
      setLoading(0);
    }
  };

  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  return (
    <>
      {loading ? <Loader /> : ""}
      <Formik
        validationSchema={validationSchema}
        initialValues={defaultValue}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="container-fluid bg-black main-freeAirmen">
            <div className="row  pb-3 p-2  d-flex justify-content-center ">
              <div className="col-12 col-sm-12 col-md-12 col pb-3 pb-md-5 d-flex justify-content-center align-items-center">
                <div className="container-fluid px-3 px-md-5 py-4 nacmaxwid  custom-shadow-search ">
                  <div className="row ">
                    <div className="col-12 col-md-6">
                      <div>
                        <Field
                          type="text"
                          className="form-control nacinput custom-form-control  w-100  p-0 pt-3 pb-1"
                          id="registationNumber"
                          name="registration_number"
                          placeholder="Registration Number(Leave Blank for all)"
                        />
                      </div>

                      <div className="col-12 px-0 d-flex align-items-center ">
                        <div className="form-check">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="d-flex align-items-center">
                              <div>
                                <Field
                                  type="radio"
                                  className="form-check-input custom-form-check-input mart5"
                                  name="registration_number_check"
                                  value="exact_number"
                                />
                              </div>
                              <span className="mlmob">Exact Match</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-check ml-3">
                          <label className="d-flex align-items-center">
                            <div>
                              <Field
                                type="radio"
                                className="form-check-input custom-form-check-input mart5"
                                name="registration_number_check"
                                value="contain_number"
                              />
                            </div>

                            <span className="mlmob">Contains</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="col-12 col-md-6">
                      <div>
                        <Field
                          type="text"
                          className="form-control nacinput custom-form-control focusmb mb-0 w-100  p-0 pt-3 pb-1"
                          id="serialNumber"
                          placeholder="Serial Number"
                          name="serial_number"
                        />
                        <p className="text-danger field-errorMessage text-left">
                          <ErrorMessage name="serial_number" />
                        </p>
                      </div>
                      <div className="col-12 px-0 mt-3 d-flex  align-items-center ">
                        <div className="form-check ">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="d-flex align-items-center">
                              <div>
                                <Field
                                  type="radio"
                                  className="form-check-input custom-form-check-input mart5"
                                  name="serial_number_check"
                                  value="exact_serial_number"
                                />
                              </div>
                              <span className="mlmob">Exact Match</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-check ml-3">
                          <label className="d-flex align-items-center">
                            <div>
                              <Field
                                type="radio"
                                className="form-check-input custom-form-check-input mart5"
                                name="serial_number_check"
                                value="contain_serial_number"
                              />
                            </div>

                            <span className="mlmob">Contains</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="row">
                       {/* manufacture_name  */}
                <div className="col-12 col-md-6">
                      <div className="form-group">
                        <div>
                        <Field
                          type="text"
                          name="manufacture_name"
                          className="form-control nacinput custom-form-control   w-100  p-0 pt-3 pb-1"
                          id="aircraftManufacture"
                          aria-describedby="emailHelp"
                          placeholder="Aircraft Manufacture Name:(Leave blank for all)"
                        />
                        </div>
                        
                      </div>
                    </div>
                    {/* ownerName  */}
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <div>
                        <Field
                          type="text"
                          name="name"
                          className="form-control nacinput custom-form-control  w-100  p-0 pt-3 pb-1"
                          id="ownerName"
                          aria-describedby="emailHelp"
                          placeholder="Owner Name(Leave Blank for all)"
                        />
                        </div>
                      </div>
                      <div className="col-12 px-0 d-flex d-flex align-items-center">
                        <div className="form-check">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="d-flex align-items-center">
                              <div>
                                <Field
                                  type="radio"
                                  className="form-check-input custom-form-check-input mart5"
                                  name="name_check"
                                  value="exact_string"
                                />
                              </div>

                              <span className="mlmob">Exact Match</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-check ml-3">
                          <label className="d-flex align-items-center">
                            <div>
                              <Field
                                type="radio"
                                className="form-check-input custom-form-check-input mart5"
                                name="name_check"
                                value="sub_string"
                              />
                            </div>
                            <span className="mlmob">Contains</span>
                          </label>
                        </div>
                      </div>
                    </div>
             
                   
                  </div>
                  <div className="row">
                  <div className="col-12 col-md-6">
                      <div className="form-group">
                        <div className="pt-md-1 p-0">
                        <Field
                          type="text"
                          className="form-control nacinput custom-form-control  w-100   p-0 pt-3 pb-1"
                          id="city"
                          aria-describedby="emailHelp"
                          placeholder="City(Leave Blank for all)"
                          name="city_name"
                        />
                        </div>
                      
                      </div>
                      <div className="col-12 px-0 d-flex align-items-center">
                        <div className="form-check">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="d-flex align-items-center">
                              <div>
                                <Field
                                  type="radio"
                                  className="form-check-input custom-form-check-input mart5"
                                  name="city_name_check"
                                  value="exact_string"
                                />
                              </div>

                              <span className="mlmob">Exact Match</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-check ml-3">
                          <label className="d-flex align-items-center">
                            <div>
                              <Field
                                type="radio"
                                className="form-check-input custom-form-check-input mart5"
                                name="city_name_check"
                                value="sub_string"
                              />
                            </div>
                            <span className="mlmob">Contains</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 selected-states ">
                      {/*  */}
                      <div className="form-group">
                        <div className="custom-form-control">
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={formattedStates}
                            styles={colourStyles}
                            placeholder="Please Select State"
                            className="w-100"
                            value={selectedValues} // Set selected values
                            onChange={handleSelectChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6 mb-3">
                      <div className="form-group  ">
                        <div className="form-group">
                          <Field
                            as="select"
                            className="form-control nacinput focusmb mb-0 custom-form-control pl-0"
                            name="max_records"
                            id="max_records"
                          >
                            <option selected disabled>
                              Maximum Records
                            </option>

                            <option>20</option>
                            <option>40</option>
                            <option>60</option>
                          </Field>
                          <p className="text-danger field-errorMessage text-left">
                            <ErrorMessage name="max_records" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Begin Search btn */}
                  <div className="raw justify-content-center">
                    <div className="pb-4 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        className="custom-btn-begin-search border-1 font-inter"
                      >
                        Begin Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      {freeAirCraftData && freeAirCraftData ? (
        <>
          <div
            id="hs_cos_wrapper_widget_1604099686647"
            className="hs_cos_wrapper  bg-black  hs_cos_wrapper_widget hs_cos_wrapper_type_module"
            style={{}}
            data-hs-cos-general-type="widget"
            data-hs-cos-type="module"
          >
            <span
              id="hs_cos_wrapper_main_flexible_column"
              className="hs_cos_wrapper  bg-black  hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container bg-secondary"
              style={{}}
              data-hs-cos-general-type="widget_container"
              data-hs-cos-type="widget_container"
            >
              <div
                id="hs_cos_wrapper_widget_1604340283000"
                className="hs_cos_wrapper  bg-black  nacbor hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                style={{ background: "#EDF1D1" }}
                data-hs-cos-general-type="widget"
                data-hs-cos-type="module"
              >
                {/* Label For Modules Data Page */}
                <div className="sr-cards-grid-02 bg-black text-white  prototype-no-background widget_1604340283000">
                  <div className="container-fluid  bg-black text-white ">
                    <div className="col-md-12  bg-black text-white ">
                      <h2 className=" section-heading font-inter text-white">
                        Search Result
                      </h2>
                    </div>
                    <div className="col-md-12">
                      <div className=" aircraft-registration-table-boxShadow  bg-black text-white">
                        {freeAirCraftData ? (
                          <div
                            id="hs_cos_wrapper_widget_1604099686647"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="module"
                          >
                            <span
                              id="hs_cos_wrapper_main_flexible_column"
                              className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container bg-secondary"
                              style={{}}
                              data-hs-cos-general-type="widget_container"
                              data-hs-cos-type="widget_container"
                            >
                              <div
                                id="hs_cos_wrapper_widget_1604340283000"
                                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                style={{ background: "#EDF1D1" }}
                                data-hs-cos-general-type="widget"
                                data-hs-cos-type="module"
                              >
                                {/* Label For Modules Data Page */}
                                <div className="sr-cards-grid-02  text-white bg-black sr-padding-full prototype-no-background widget_1604340283000">
                                  <div className="container-fluid px-0">
                                    <div className="row justify-content-center  text-white bg-black ">
                                      <div className="col-md-12 px-0">
                                        <div className="cards row">
                                          <div className="container-fluid">
                                            <div className="row ">
                                              <div className="col-md-12  text-white bg-black ">
                                                <h4 className="font-inter">
                                                  Aircraft Registration Records
                                                </h4>
                                              </div>
                                              <div className="col-md-12  table-responsive">
                                                <table className="table custom-downloads-table table-bordered aircraft-registration-table-boxShadow">
                                                  <thead className="thead custom-downloads-thead">
                                                    <tr>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        Identification Number
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        Name
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        Address
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        City
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        State
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        Zip Code
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        Country Name
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        Region
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        Certificate Issue Date
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        Engine Type
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        className="text-nowrap"
                                                      >
                                                        Airworthiness Date
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody className="overflow-auto">
                                                    {freeAirCraftData &&
                                                    Array.isArray(
                                                      freeAirCraftData.records
                                                    ) &&
                                                    freeAirCraftData.records
                                                      .length ? (
                                                      freeAirCraftData.records.map(
                                                        (data: any) => (
                                                          <tr>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.identification_number
                                                              }
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.registrant_name
                                                              }
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {data.street1}{" "}
                                                              {data.street2}
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.registrant_city
                                                              }
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.state_abbrev
                                                              }
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.registrant_zip
                                                              }
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.country_mail
                                                              }
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.region_abbrev
                                                              }
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.certificate_issue_date
                                                              }
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.engine_abbrev
                                                              }
                                                            </td>
                                                            <td className="text-nowrap">
                                                              {
                                                                data.airworthiness_date
                                                              }
                                                            </td>
                                                          </tr>
                                                        )
                                                      )
                                                    ) : (
                                                      <tr>
                                                        <td colSpan={11}>
                                                          No Aircraft
                                                          Registration Records
                                                          Founds
                                                        </td>
                                                      </tr>
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        ) : (
                          <div className="col-12 col-sm-12 pt-4 col-md-12 mt-4 pb-4 bg-black text-white col-lg-12">
                            <div className="row justify-content-center border-0">
                              <div className="col-12 col-md-6  rounded-pill border d-flex flex-wrap justify-content-center p-2 text-center freeAirmen-td">
                                <div className="d-flex pr-1">
                                  No Aircraft Registration{" "}
                                </div>
                                <div className=""> Records Found</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>{" "}
          </div>
        </>
      ) : (
        ""
      )}

      {/* Aircraft Registration Records */}
    </>
  );
};

{
  /* <div className="col-12 col-sm-12 col-md-12 pb-4 bg-black text-white col-lg-12">
   <div className="row justify-content-center border-0">
     <div className="col-6 rounded-pill border p-2 text-center freeAirmen-td">
     No Aircraft Registration Records found
     </div>
   </div>
 </div> */
}
