import { useState } from "react";
import "./search.css";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { useAppSelector } from "../../../hook";
import { NewPilotRecords } from "../Admin/QuickSearch/NewPilotRecords";
import { NewAircraftRecords } from "../Admin/QuickSearch/NewAircraftRecords";
const SearchPage = () => {
  const [btnClick,setBtnClick] = useState(true);
  const status = useAppSelector((state: any) => state.auth.status);
 
  return (
    <>
     <div className="sr-multicol-media bg-black pt-1 text-start prototype-no-background widget_1600979911688">
          <div className="container-fluid">
 
            <div className="row">
              <div className="col-md-10 offset-md-1 text-center px-4">
                <div className="bg-black container-fluid content-wrapper px-0 pt-4 bg-black  d-flex flex-wrap justify-content-around">
                  <div className="col-12 d-flex justify-content-center p-0 text-white">
                    {/* btn */}
                    <div
                      className={
                        btnClick
                          ? " col-sm-6  text-white  col-lg-6 text-center custom-btn mt-md-0 mt-3  personalInfo-btn-clicked  custom-btn-information"
                          : " col-sm-6  text-white col-lg-6 text-center custom-btn mt-md-0 mt-3 custom-btn-information"
                      }
                      onClick={() => {
                        setBtnClick(true);
                      }}
                    >
                      New Pilot Records
                    </div>
 
                    <div
                      className={
                        btnClick
                          ? "  col-sm-6   text-white col-lg-6 text-center custom-btn mt-md-0 mt-3 custom-btn-Certificates"
                          : "col-sm-6  text-white col-lg-6 text-center custom-btn personalInfo-btn-clicked custom-btn-Certificates mt-md-0 mt-3 "
                      }
                      onClick={() => {
                        setBtnClick(false);
                      }}
                    >
                      New Aircraft Records
                    </div>
                  </div>
                </div>
                {/* <div className="col-12  offset-md-1 text-center p-0  "> */}
 
                <div className="pb-4">
                  {btnClick ? (
                 <NewPilotRecords/>
                  ) : (
                  <NewAircraftRecords/>
                  )}
                </div>
 
                {/* </div> */}
              </div>
 
              {/* Dynamic Template*/}
            </div>
          </div>
        </div>
    </>
  );
};
 

export default SearchPage;
