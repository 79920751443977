import React, { useState, useRef, useEffect } from "react";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import "./CreatePostcard.css";

import { usePapaParse } from "react-papaparse";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FaUserAlt } from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import { BiSolidLock, BiSolidCoupon } from "react-icons/bi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";

import "../Login/login.css";
import { useAppDispatch, useAppSelector } from "../../../hook";
import tempImg from "./tempimg.png";
import { ToastContainer, toast } from "react-toastify";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Loader } from "../RepeatedComponent/Loader";
import { PostCardpayment } from "./PostCardpayment";
import fntimg from "./Front.png";
import bkimg from "./newBack.png";
const CreatePostcard = () => {
  const [token, setToken] = useState("");
  interface Person {
    firstName: string;
    lastName: string;
    address: string;
    address2: string;
    city: string;
    state: string;
    zipcode: string;
    // Add other properties as needed
  }
  const [sendData, setSendData] = useState<Person[]>([]);
  const states = useAppSelector((state: any) => state.downloadHistory.states);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [btnClick, setBtnClick] = useState(true);
  const status = useAppSelector((state: any) => state.auth.status);
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
  const [totalAmt, setTotalAmount] = useState(0);
  const buttonRef1 = useRef<HTMLButtonElement | null>(null); // Ref for first button
  const buttonRef2 = useRef<HTMLButtonElement | null>(null);
  const formikRef = useRef<any>(null);
  const [callPopup, setCallPopup] = useState(false);

  const handleClick = () => {
    if (sendData.length === 0) {
      toast.dismiss();
      toast.error("At least 1 recipients is required", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
      });
    }
  };

  const [sendersDetail, setSendersDetail] = useState({
    firstName: "",
    lastName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    mailDate:"",
  });

  //   useEffect(() => {
  // if(callPopup)
  // { toast.dismiss() ;
  // toast.error("Please fill your details correctly", {
  //   position: "top-center",
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   draggablePercent: 8000,
  //   pauseOnFocusLoss: true,
  // })
  // }
  //   }, [callPopup]);

  useEffect(() => {
    dispatch(getPilotStates());
  }, []);
  console.log(sendersDetail, "sender details");
  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  // defaultValue form values
  const defaultValue = {
    firstName: "",
    lastName: "",
    state: "",
    house: "",
    city: "",
    zipcode: "",
    street: "",
    dateofmail: "",
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // Validation schema using yup
  const validationSchema = yup.object().shape({
    // postcardFile: yup.mixed().required('File is required'),
    firstName: yup
      .string()
      .trim()
      .required("First Name is required")
      .matches(/\S+/, "Name must not contain only white spaces"),
    dateofmail: yup
      .date()
      .required("Date is required")
      .min(new Date(today.getTime() + 86400000), "Future date is required"),
    lastName: yup
      .string()
      .trim()
      .required("Last Name is required")
      .matches(/\S+/, "Name must not contain only white spaces"),
    street: yup.string().required("Street is required"),
    house: yup.string().required("Building/Apartment/House No. is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zipcode: yup
      .string()
      .required("Zip Code is required")
      .min(5, "Zip Code must be at least 5 characters long"),
  });

  useEffect(() => {
    let result = sendData.length * 0.89;

    const decimalPart = (result.toString().split(".")[1] || "").length;

    if (decimalPart <= 2) {
      console.log(result, "no changes");
      setTotalAmount(result);
      // return result;
    } else {
      result = result + 0.01;
      result = parseFloat(result.toFixed(2));
      setTotalAmount(result);
      // return result;
    }
  }, [sendData]);

  /// Handle form submission
  const handleFormButtonClick = (e: any) => {
    console.log(formikRef.current.values.city, "all form data");
    //  Object.keys(formikRef.current.error).length === 0;
    let abc = Object.keys(formikRef.current.errors).length === 0;
    console.log(!formikRef.current.dirty || !abc, "final check");
    if (!formikRef.current.dirty || !abc) {
      toast.dismiss();
      toast.error("Please fill your details correctly", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
      });
    }
  };
  const handleSubmit = async (values: any) => {
    console.log(values,"handle submit working");
    if (sendData.length > 0) {
      if (buttonRef2.current) {
        buttonRef2.current.click();
      }
      // toast.dismiss();
      // toast.success("Details Submitted Successfully", {
      //   position: "top-center",
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   draggablePercent: 8000,
      //   pauseOnFocusLoss: true,
      // });
      setSendersDetail({
        mailDate:`${values.dateofmail}`,
        firstName: `${values.firstName}`,
        lastName: `${values.lastName}`,
        address: `${values.street}`,
        address2: `${values.house}`,
        city: `${values.city}`,
        state: `${values.state}`,
        zipcode: `${values.zipcode}`,
      });
    } else {
      toast.dismiss();
      toast.error("Please upload Recipients List", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 1500,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
      });
    }
    // axios
    //   .post("https://v3.pcmintegrations.com/auth/login", {
    //     apiKey: `${process.env.REACT_APP_TOKEN_API_KEY}`,
    //     apiSecret: `${process.env.REACT_APP_TOKEN_API_SECRET}`,
    //   })
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setToken(res.data.token);
    //       axios
    //         .post(
    //           "https://v3.pcmintegrations.com/order/postcard",
    //           {
    //             size: "46S",
    //             front:
    //               "<!DOCTYPE html><html><body><h1>My First Heading</h1><p>My first paragraph.</p></body></html>",
    //             back: "<!DOCTYPE html><html><body><h1>My First Heading</h1><p>My first paragraph.</p></body></html>",
    //             mailClass: "FirstClass",
    //             mailDate: "2024-03-10",
    //             returnAddress: {
    //               firstName: `${values.firstName}`,
    //               lastName: `${values.lastName}`,
    //               address: `${values.street}`,
    //               address2: `${values.house}`,
    //               city: `${values.city}`,
    //               state: `${values.state}`,
    //               zipcode: `${values.zipcode}`,
    //             },
    //             recipients: sendData,
    //           },
    //           {
    //             headers: {
    //               Authorization: "Bearer " + res.data.token,
    //             },
    //           }
    //         )
    //         .then((res) => {
    //           console.log(
    //             res,
    //             res.data.batchId,
    //             res.data.orderID,
    //             "response in final api to upload data"
    //           );
    //           if (res.data.orderID) {
    //             setLoading(false);
    //             // console.log("RUN TOAST******************");
    //             toast.dismiss() ;
    // toast.success("Postcard Sent Successfully", {
    //               position: "top-center",
    //               hideProgressBar: false,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               autoClose: 1500,
    //               draggablePercent: 8000,
    //               pauseOnFocusLoss: true,
    //             });
    //             setTimeout(() => {
    //               window.location.reload();
    //               window.scrollTo({
    //                 top: 0,
    //                 behavior: "smooth", // Optional: Adds smooth scrolling animation
    //               });
    //             }, 1500);
    //           }
    //         })
    //         .catch((err) => {
    //           setLoading(false);
    //           toast.dismiss() ;
    // toast.error(`error in final api of uplaod data : ${err}`, {
    //             position: "top-center",
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             draggablePercent: 8000,
    //             pauseOnFocusLoss: true,
    //           });
    //         });
    //     } else {
    //       setLoading(false);
    //       toast.dismiss() ;
    // toast.error("status of token api is not 200", {
    //         position: "top-center",
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         draggablePercent: 8000,
    //         pauseOnFocusLoss: true,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     toast.dismiss() ;
    // toast.error(`Token API failed : ${err}`, {
    //       position: "top-center",
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 8000,
    //       pauseOnFocusLoss: true,
    //     });
    //   });
    // Dispatching the signInUser action with form values
  };
  const handlePincode = (e:any) => {
    if(e.target.value.length >= 10){
      return;
    }
    setSendersDetail({
      ...sendersDetail,
      zipcode: e.target.value,
    })
  }
  const changeData = (oldData: any) => {
    console.log(oldData, "our old data");
    let upDatedData = oldData
      .filter((ele: any) => {
        return (
          ele.length > 6 &&
          ele[0] &&
          ele[1] &&
          ele[2] &&
          ele[3] &&
          ele[4] &&
          ele[5] &&
          ele[6]
        );
      })
      .map((ele: any) => {
        return {
          firstName: ele[0],
          lastName: ele[1],
          address: ele[2],
          address2: ele[3],
          city: ele[4],
          state: ele[5],
          zipcode: ele[6],
        };
      });
    console.log(upDatedData, "our updated data");
    setSendData(upDatedData);
  };

  const [csvData, setCSVData] = useState<string[][]>([]);
  const dropAreaRef = useRef<HTMLDivElement>(null);
  const { readString } = usePapaParse();
  const [tableData, setTableData] = useState<any>();
  const [tableHeader, setTableHeader] = useState<any>();
  const handleCSVUploaded = (data: string[][]) => {
    setCSVData(data);
  };

  const sampleCSVfile = () => {
    // fetch('Csvfile/Samplecsv.csv')
    // .then(response => response.text())
    // .then(csvData => {
    //     readString(csvData, {
    //         complete: (results) => {
    //             console.log('CSV Data:', results?.data);
    //             if (Array.isArray(results?.data)) {
    //                 console.log(results?.data.slice(1), "all data")

    //                 setTableHeader(results?.data?.[0])
    //                 setTableData(results?.data.slice(1,-1));
    //             } else {
    //                 console.error('CSV data is not an array.');
    //             }
    //         }
    //     });
    // })
    // .catch(error => {
    //     console.error('Error fetching CSV file:', error);
    // });

    const url = "/Csvfile/PostCardSampleCSV.csv";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "PostCardSampleCSV.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const csvData = e.target.result as string;
          readString(csvData, {
            complete: (results: any) => {
              toast.dismiss();
              toast.success("CSV file Uploaded Successfully", {
                position: "top-center",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 8000,
                pauseOnFocusLoss: true,
              });
              console.log("CSV Data:", results?.data);
              if (Array.isArray(results?.data)) {
                console.log(results?.data.slice(1), "all data");
                changeData(results?.data.slice(1));
                setTableHeader(results?.data?.[0]);
                setTableData(results?.data.slice(1));
              } else {
                console.error("CSV data is not an array.");
              }
            },
          });
        }
      };
      reader.readAsText(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    // Handle the dropped files here
    handleFileUpload({
      target: { files },
    } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleButtonClick = () => {
    document.getElementById("file-input")?.click();
  };

  const handlePaymentModal = () => {};

  const orderSummary = {
    totalRecipients: sendData.length,
    chargesPerRecipients: "$ 0.89",
    totalAmount: totalAmt,
    recipientslist: sendData,
    sendersDetail: sendersDetail,
  };

  let htmlfrontTemplate1 = `
  <div class="p-2 p-md-5" style="width: 100%;background-color: rgb(137, 230, 199);">
      <div class="h2 text-white" style="display: flex;justify-content: center;font-family: sans-serif;margin-top: 20px;font-weight: 600;">Flight Health Check</div>
      <div  class="h2 text-white" style="display: flex;justify-content: center;font-family: sans-serif;font-weight: 600;">Renew Your FAA Certificate Now</div>
      <div class="my-3 style="display: flex;justify-content: center;">
          <img src="${tempImg}" class="col-md-6 col-10"/>
      </div>
      <div  class="h2 text-white"  style="display: flex;justify-content: center;font-family: sans-serif;font-weight: 600;">${
        sendersDetail.firstName ? sendersDetail.firstName : "John"
      }  ${sendersDetail.lastName ? sendersDetail.lastName : "Doe"}</div>
      <div style="display: flex;justify-content: center;flex-wrap: wrap;">
          <div style="width: 100%;display: flex;font-size: 1.5rem;margin-top: 23px; "><div  style="width: 50%;display: flex;justify-content: center; word-spacing: 5px">Senior AME - Felow Pilot</div><div style="width: 50%;display: flex;justify-content: center;">${
            sendersDetail.address ? sendersDetail.address : "123 Runway"
          },  ${
    sendersDetail.address2 ? sendersDetail.address2 : "DR."
  }</div></div>
          <div style="width: 100%;display: flex;font-size: 1.5rem;margin-top: 15px;"><div style="width: 50%;display: flex;justify-content: center;">Phone&nbsp;:&nbsp;xxx-xxx-xxxx</div><div style="width: 50%;display: flex;justify-content: center;">${
            sendersDetail.state ? sendersDetail.state : "NC "
          },
            ${sendersDetail.city ? sendersDetail.city : " Kitty Hawk"}
            ${sendersDetail.zipcode ? sendersDetail.zipcode : "28196"}
          </div></div>
      </div>
    
      <div style="display: flex;justify-content: center;font-size: 1.5rem;font-family: sans-serif;margin-top: 50px;"> <div >Call or Visit <span class="pl-2" style="font-weight: 600;"><i>Planelist.com</i></span>  </div> <div> &nbsp;to schedule your today</div>
          
      </div>
`;
  let htmlbackTemplate1 = `
<div class="p-2 p-md-5" style="width: 100%;background-color: rgb(137, 230, 199);">
    <div class="h2 text-white" style="display: flex;justify-content: center;font-family: sans-serif;margin-top: 20px;font-weight: 600;">${
      sendData?.[0]?.firstName || "Recipient"
    },your FAA Medical</div>
    <div  class="h2 text-white" style="display: flex;justify-content: center;font-family: sans-serif;font-weight: 600;"> Certification expires in April,</div>
    <div  class="h2 text-white" style="display: flex;justify-content: center;font-family: sans-serif;font-weight: 600;">don't let it expire!</div>
  
    <div style="display: flex;justify-content: center;flex-wrap: wrap;">
        <div style="width: 100%;display: flex;font-size: 1.5rem;margin-top: 23px;"><div style="width: 50%;display: flex;justify-content: start;">
        <div class="col-3 col-md-1 px-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
        <g clip-path="url(#clip0_617_58)">
        <path d="M14.698 0C16.9779 0 19.1354 0.5194 21.0632 1.44583C20.2741 2.07011 19.5424 2.66942 18.8607 3.24625C17.5622 2.7743 16.1613 2.51709 14.7005 2.51709C11.3369 2.51709 8.29042 3.88052 6.08797 6.08547C3.88301 8.29042 2.51959 11.3344 2.51959 14.698C2.51959 18.0616 3.88301 21.1056 6.08797 23.3106C8.29292 25.5155 11.3369 26.879 14.7005 26.879C18.0641 26.879 21.1106 25.5155 23.3131 23.3106C25.518 21.1056 26.8815 18.0616 26.8815 14.698C26.8815 13.8989 26.804 13.1149 26.6567 12.3582C27.2885 11.5342 27.9352 10.7126 28.597 9.89857C29.1164 11.4018 29.3985 13.0175 29.3985 14.698C29.3985 18.7558 27.7529 22.4316 25.0935 25.091C22.4341 27.7504 18.7583 29.396 14.7005 29.396C10.6427 29.396 6.96695 27.7504 4.30752 25.091C1.6456 22.4316 0 18.7558 0 14.698C0 10.6402 1.6456 6.96446 4.30503 4.30503C6.96446 1.6456 10.6402 0 14.698 0ZM7.85093 12.2833L11.4368 12.2359L11.704 12.3058C12.4281 12.7228 13.1099 13.1998 13.7466 13.7391C14.2061 14.1287 14.6456 14.5532 15.0626 15.0127C16.3486 12.9426 17.7195 11.0422 19.1679 9.29427C20.7535 7.37898 22.4366 5.64098 24.2045 4.05532L24.5541 3.92047H28.4671L27.678 4.79696C25.2533 7.49135 23.0534 10.2756 21.0657 13.1473C19.078 16.0215 17.3 18.9881 15.7193 22.042L15.2274 22.9909L14.7754 22.0246C13.9414 20.2341 12.9426 18.591 11.7514 17.1227C10.5603 15.6544 9.1744 14.3509 7.55877 13.2422L7.85093 12.2833Z" fill="#01A601"/>
        </g>
        <defs>
        <clipPath id="clip0_617_58">
        <rect width="29.396" height="29.396" fill="white"/>
        </clipPath>
        </defs>
        </svg></div>
        <div  class="pl-1 text-left">Renew on Time</div></div><div style="width: 50%;display: flex;justify-content: center;">${
          sendData?.[0]?.firstName && sendData?.[0]?.lastName
            ? sendData?.[0]?.firstName + " " + sendData?.[0]?.lastName
            : " Recipients Doe"
        }</div></div>
        <div style="width: 100%;display: flex;font-size: 1.5rem;margin-top: 15px;"><div style="width: 50%;display: flex;justify-content: start;">
        <div class="col-3 col-md-1 px-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
        <g clip-path="url(#clip0_617_58)">
        <path d="M14.698 0C16.9779 0 19.1354 0.5194 21.0632 1.44583C20.2741 2.07011 19.5424 2.66942 18.8607 3.24625C17.5622 2.7743 16.1613 2.51709 14.7005 2.51709C11.3369 2.51709 8.29042 3.88052 6.08797 6.08547C3.88301 8.29042 2.51959 11.3344 2.51959 14.698C2.51959 18.0616 3.88301 21.1056 6.08797 23.3106C8.29292 25.5155 11.3369 26.879 14.7005 26.879C18.0641 26.879 21.1106 25.5155 23.3131 23.3106C25.518 21.1056 26.8815 18.0616 26.8815 14.698C26.8815 13.8989 26.804 13.1149 26.6567 12.3582C27.2885 11.5342 27.9352 10.7126 28.597 9.89857C29.1164 11.4018 29.3985 13.0175 29.3985 14.698C29.3985 18.7558 27.7529 22.4316 25.0935 25.091C22.4341 27.7504 18.7583 29.396 14.7005 29.396C10.6427 29.396 6.96695 27.7504 4.30752 25.091C1.6456 22.4316 0 18.7558 0 14.698C0 10.6402 1.6456 6.96446 4.30503 4.30503C6.96446 1.6456 10.6402 0 14.698 0ZM7.85093 12.2833L11.4368 12.2359L11.704 12.3058C12.4281 12.7228 13.1099 13.1998 13.7466 13.7391C14.2061 14.1287 14.6456 14.5532 15.0626 15.0127C16.3486 12.9426 17.7195 11.0422 19.1679 9.29427C20.7535 7.37898 22.4366 5.64098 24.2045 4.05532L24.5541 3.92047H28.4671L27.678 4.79696C25.2533 7.49135 23.0534 10.2756 21.0657 13.1473C19.078 16.0215 17.3 18.9881 15.7193 22.042L15.2274 22.9909L14.7754 22.0246C13.9414 20.2341 12.9426 18.591 11.7514 17.1227C10.5603 15.6544 9.1744 14.3509 7.55877 13.2422L7.85093 12.2833Z" fill="#01A601"/>
        </g>
        <defs>
        <clipPath id="clip0_617_58">
        <rect width="29.396" height="29.396" fill="white"/>
        </clipPath>
        </defs>
        </svg></div><div class="pl-1  text-left">Complete MedXPress</div></div><div style="width: 50%;display: flex;justify-content: center;">${
          sendData?.[0]?.address && sendData?.[0]?.address
            ? sendData?.[0]?.address + "," + sendData?.[0]?.address2
            : "123 Resi Apartment"
        }</div></div>
        <div style="width: 100%;display: flex;font-size: 1.5rem;margin-top: 15px;"><div style="width: 50%;display: flex;justify-content: start;">
        <div class="col-3 col-md-1 px-0"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
        <g clip-path="url(#clip0_617_58)">
        <path d="M14.698 0C16.9779 0 19.1354 0.5194 21.0632 1.44583C20.2741 2.07011 19.5424 2.66942 18.8607 3.24625C17.5622 2.7743 16.1613 2.51709 14.7005 2.51709C11.3369 2.51709 8.29042 3.88052 6.08797 6.08547C3.88301 8.29042 2.51959 11.3344 2.51959 14.698C2.51959 18.0616 3.88301 21.1056 6.08797 23.3106C8.29292 25.5155 11.3369 26.879 14.7005 26.879C18.0641 26.879 21.1106 25.5155 23.3131 23.3106C25.518 21.1056 26.8815 18.0616 26.8815 14.698C26.8815 13.8989 26.804 13.1149 26.6567 12.3582C27.2885 11.5342 27.9352 10.7126 28.597 9.89857C29.1164 11.4018 29.3985 13.0175 29.3985 14.698C29.3985 18.7558 27.7529 22.4316 25.0935 25.091C22.4341 27.7504 18.7583 29.396 14.7005 29.396C10.6427 29.396 6.96695 27.7504 4.30752 25.091C1.6456 22.4316 0 18.7558 0 14.698C0 10.6402 1.6456 6.96446 4.30503 4.30503C6.96446 1.6456 10.6402 0 14.698 0ZM7.85093 12.2833L11.4368 12.2359L11.704 12.3058C12.4281 12.7228 13.1099 13.1998 13.7466 13.7391C14.2061 14.1287 14.6456 14.5532 15.0626 15.0127C16.3486 12.9426 17.7195 11.0422 19.1679 9.29427C20.7535 7.37898 22.4366 5.64098 24.2045 4.05532L24.5541 3.92047H28.4671L27.678 4.79696C25.2533 7.49135 23.0534 10.2756 21.0657 13.1473C19.078 16.0215 17.3 18.9881 15.7193 22.042L15.2274 22.9909L14.7754 22.0246C13.9414 20.2341 12.9426 18.591 11.7514 17.1227C10.5603 15.6544 9.1744 14.3509 7.55877 13.2422L7.85093 12.2833Z" fill="#01A601"/>
        </g>
        <defs>
        <clipPath id="clip0_617_58">
        <rect width="29.396" height="29.396" fill="white"/>
        </clipPath>
        </defs>
        </svg></div><div  class="pl-1 text-left">Get answers to your concerns</div></div>
        <div style="width: 50%;display: flex;justify-content: center;" >${
          sendData?.[0]?.state && sendData?.[0]?.city && sendData?.[0]?.zipcode
            ? sendData?.[0]?.state +
              "," +
              sendData?.[0]?.city +
              " " +
              sendData?.[0]?.zipcode
            : "NewYork ,cal 343434"
        }</div></div>
        <div style="width: 100%;display: flex;font-size: 1.5rem;margin-top: 15px;">
        <div class="flex-wrap pl-4 pl-md-4" style="width: 50%;display: flex;margin-left:15px;justify-content: start; font-weight:600">
        <div   class="w-100 mt-1 d-flex justify-content-start">${
          sendersDetail.firstName ? sendersDetail.firstName : "John"
        } ${sendersDetail.lastName ? sendersDetail.lastName : "Doe"}</div>
        <div  class="w-100 d-flex justify-content-start mt-2">xxx-xxx-xxxx</div></div>
        </div>
    
        </div>
        <div style="display: flex;justify-content: center;font-size: 1.5rem;font-family: sans-serif;margin-top: 50px;" class="">Your Partner in Safe Aviation</div>
        </div>
  
    

</div>
`;

  let htmlfrontTemplate2 = `
  <div class="p-0" style="width: 100%;background:#ECECEE">
  <div class="p-2 p-md-5" style="padding-bottom:0">
      <div class="h2 text-black" style="display: flex;font-family: sans-serif;margin-top: 20px;font-weight: 600;">Flight Health Check</div>
      <div  class="h2 text-black text-left" style="display: flex;font-family: sans-serif;font-weight: 600;justify-content:start">Renew Your FAA Certificate Now</div>
      <div class="mt-3" style="display: flex;justify-content: start;flex-wrap: wrap;">
      <div  class=" text-black h3"  style="width: 100%;display: flex;font-weight:600"><div class="col-6 col-md-8  px-0"  style="width: 50%;display: flex;justify-content: start;">${
        sendersDetail.firstName ? sendersDetail.firstName : "John"
      }  ${sendersDetail.lastName ? sendersDetail.lastName : "Doe"}</div></div>
          <div style="width: 100%;display: flex;font-size: 1.5rem;"><div class=" text-black col-6 col-md-8 px-0 text-left"  style="width: 50%;display: flex;justify-content: start;">Senior AME <span>&nbsp;- Felow Pilot</span></div><div class="text-black col-6 col-md-4  px-0" style="display: flex;justify-content: start;">${
            sendersDetail.address ? sendersDetail.address : "123 Runway"
          },  ${
    sendersDetail.address2 ? sendersDetail.address2 : "DR."
  }
  
  
  </div></div>
          <div class="text-left" style="width: 100%;display: flex;font-size: 1.5rem;margin-top: 15px;"><div  class="text-black col-6 col-md-8  px-0"  style="display: flex;justify-content: start;">Phone : &nbsp;xxx-xxx-xxxx</div><div  class=" text-black col-6 col-md-4  px-0" style="display: flex;justify-content: start;">${
            sendersDetail.state ? sendersDetail.state : "NC "
          },
            ${sendersDetail.city ? sendersDetail.city : " Kitty Hawk"}
            ${
              sendersDetail.zipcode ? sendersDetail.zipcode : "28196"
            }</div></div>

      </div>
    
    <div style="font-size: 1.5rem;font-family: sans-serif;margin-top: 50px;"> <div class="text-black pb-2" style="display:flex;flex-wrap:wrap;justify-content: start;">Call or Visit</div><div  class="text-black pb-2"  style="display:flex;flex-wrap:wrap;justify-content: start;"> <span  style="font-weight: 600;"><i>Planelist.com</i></span>  </div> <div  class="text-black pb-2"  style="display:flex;flex-wrap:wrap;justify-content: start;">to schedule your today</div>
    </div> 
    
    </div>
    <div>
    <img src="${fntimg}" class="mtNeg" style="width:100%;"/>
    </div>   
`;

  let htmlbackTemplate2 = `
<div class="p-0" style="width: 100%;background:#ECECEE">
<div  class="p-2 p-md-5" style="padding-bottom:0 !important">
    <div class="h2 text-black" style="display: flex;justify-content: start;font-family: sans-serif;margin-top: 20px;font-weight: 600;">${
      sendData?.[0]?.firstName || "Recipient"
    },your FAA Medical</div>
    <div  class="h2 text-black" style="display: flex;justify-content: start;font-family: sans-serif;font-weight: 600;"> Certification expires in April, don't let it expire!</div>
  <div class=" d-flex col-12 mt-5  px-0">
  <div class="col-6 mt-5  px-0">
  <div class="text-black mb-3" style="display: flex;font-weight:600;justify-content: start;font-size: 1.5rem;">${
    sendData?.[0]?.firstName && sendData?.[0]?.lastName
      ? sendData?.[0]?.firstName + " " + sendData?.[0]?.lastName
      : " Recipients Doe"
  }</div>
  <div class="text-black mb-3"   style="display: flex;justify-content: start;font-size: 1.5rem;">${
    sendData?.[0]?.address && sendData?.[0]?.address
      ? sendData?.[0]?.address + "," + sendData?.[0]?.address2
      : "123 Resi Apartment"
  }</div>
  <div   style="display: flex;justify-content: start;font-size: 1.5rem;" class="text-black mb-3">${
    sendData?.[0]?.state && sendData?.[0]?.city && sendData?.[0]?.zipcode
      ? sendData?.[0]?.state +
        "," +
        sendData?.[0]?.city +
        " " +
        sendData?.[0]?.zipcode
      : "NewYork ,cal 343434"
  }</div>

  <div  class="mb-3 mt-5 text-black"  style="display: flex;justify-content: start;font-size: 1.5rem;" >Your partner in Safe Aviation</div>
  
  </div>
  <div  class="col-6 mt-5">
  <div class="mb-5 mt-4" style="display: flex;justify-content: start;font-size: 1.5rem;">
  <div class="col-4 col-md-3 px-0  text-right">
  <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 12 16"
            fill="none"
          >
            <path
              d="M3.53221 15.3135C3.35905 15.1441 3.35905 14.8687 3.53221 14.6993L9.37559 8.97012C9.79264 8.56123 9.79291 7.88968 9.3762 7.48045L3.53221 1.74132C3.35905 1.57188 3.35905 1.29653 3.53221 1.12708C3.70537 0.957639 3.98674 0.957639 4.1599 1.12708L11.0657 7.90788C11.1522 7.9926 11.1955 8.09851 11.1955 8.215C11.1955 8.3209 11.1522 8.4374 11.0657 8.52212L4.1599 15.3029C3.98674 15.483 3.70537 15.483 3.53221 15.3135Z"
              fill="#E35D22"
              stroke="#E35D22"
              stroke-width="1.04353"
            />
            <path
              d="M1.08954 12.2156C0.970154 12.0988 0.970154 11.909 1.08954 11.7921L4.13867 8.81418C4.55759 8.40504 4.55792 7.73135 4.1394 7.3218L1.08954 4.33728C0.970154 4.22045 0.970154 4.03061 1.08954 3.91379C1.20892 3.79697 1.40292 3.79697 1.5223 3.91379L5.54399 7.84932C5.60368 7.90773 5.63353 7.98075 5.63353 8.06106C5.63353 8.13408 5.60368 8.2144 5.54399 8.27281L1.5223 12.2083C1.40292 12.3325 1.20892 12.3325 1.08954 12.2156Z"
              fill="#FF7125"
              stroke="#FF8F53"
              stroke-width="1.04353"
            />
          </svg>
        </div>
        <div  class="pl-1 pl-md-2 text-left text-black">Renew on Time</div>
        </div>


        <div class="mb-5 mt-3" style="display: flex;justify-content: start;font-size: 1.5rem;">
        <div class="col-4 col-md-3 px-0  text-right">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 12 16"
            fill="none"
          >
            <path
              d="M3.53221 15.3135C3.35905 15.1441 3.35905 14.8687 3.53221 14.6993L9.37559 8.97012C9.79264 8.56123 9.79291 7.88968 9.3762 7.48045L3.53221 1.74132C3.35905 1.57188 3.35905 1.29653 3.53221 1.12708C3.70537 0.957639 3.98674 0.957639 4.1599 1.12708L11.0657 7.90788C11.1522 7.9926 11.1955 8.09851 11.1955 8.215C11.1955 8.3209 11.1522 8.4374 11.0657 8.52212L4.1599 15.3029C3.98674 15.483 3.70537 15.483 3.53221 15.3135Z"
              fill="#E35D22"
              stroke="#E35D22"
              stroke-width="1.04353"
            />
            <path
              d="M1.08954 12.2156C0.970154 12.0988 0.970154 11.909 1.08954 11.7921L4.13867 8.81418C4.55759 8.40504 4.55792 7.73135 4.1394 7.3218L1.08954 4.33728C0.970154 4.22045 0.970154 4.03061 1.08954 3.91379C1.20892 3.79697 1.40292 3.79697 1.5223 3.91379L5.54399 7.84932C5.60368 7.90773 5.63353 7.98075 5.63353 8.06106C5.63353 8.13408 5.60368 8.2144 5.54399 8.27281L1.5223 12.2083C1.40292 12.3325 1.20892 12.3325 1.08954 12.2156Z"
              fill="#FF7125"
              stroke="#FF8F53"
              stroke-width="1.04353"
            />
          </svg>
            </div>
            <div  class="pl-1  pl-md-2 text-black text-left">Complete MedXPress</div>
            </div>


            <div class="mb-5 mt-3" style="display: flex;justify-content: start;font-size: 1.5rem;">
            <div class="col-4 col-md-3 px-0 text-right">
              <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 12 16"
            fill="none"
          >
            <path
              d="M3.53221 15.3135C3.35905 15.1441 3.35905 14.8687 3.53221 14.6993L9.37559 8.97012C9.79264 8.56123 9.79291 7.88968 9.3762 7.48045L3.53221 1.74132C3.35905 1.57188 3.35905 1.29653 3.53221 1.12708C3.70537 0.957639 3.98674 0.957639 4.1599 1.12708L11.0657 7.90788C11.1522 7.9926 11.1955 8.09851 11.1955 8.215C11.1955 8.3209 11.1522 8.4374 11.0657 8.52212L4.1599 15.3029C3.98674 15.483 3.70537 15.483 3.53221 15.3135Z"
              fill="#E35D22"
              stroke="#E35D22"
              stroke-width="1.04353"
            />
            <path
              d="M1.08954 12.2156C0.970154 12.0988 0.970154 11.909 1.08954 11.7921L4.13867 8.81418C4.55759 8.40504 4.55792 7.73135 4.1394 7.3218L1.08954 4.33728C0.970154 4.22045 0.970154 4.03061 1.08954 3.91379C1.20892 3.79697 1.40292 3.79697 1.5223 3.91379L5.54399 7.84932C5.60368 7.90773 5.63353 7.98075 5.63353 8.06106C5.63353 8.13408 5.60368 8.2144 5.54399 8.27281L1.5223 12.2083C1.40292 12.3325 1.20892 12.3325 1.08954 12.2156Z"
              fill="#FF7125"
              stroke="#FF8F53"
              stroke-width="1.04353"
            />
          </svg>
                  </div>
                  <div  class="pl-1  pl-md-2 text-left text-black">Get answers to your concerns</div>
                  </div>
                  <div class="mb-5 mt-3" style="display: flex;justify-content: start;font-size: 1.5rem;">
                  <div class="col-4 col-md-3 px-0 text-right">
                  
                                </div>
                                <div  class="pl-1" style="font-weight:600"> <div   class="text-black w-100 mt-1 d-flex justify-content-start">${
                                  sendersDetail.firstName
                                    ? sendersDetail.firstName
                                    : "John"
                                }  ${
    sendersDetail.lastName ? sendersDetail.lastName : "Doe"
  }</div>
                                <div  class="w-100   d-flex justify-content-start mt-2 text-black" style="font-weight:600">xxx-xxx-xxxx</div></div></div>
                                </div>
        </div>
        
      
  </div>
  
  
  
  </div>
  
  </div>
  
  </div>
  </div>
  
  
  <div class="mtneg2" style="background:#ECECEE">
  <img src="${bkimg}" style="width:100%"/>
  </div>     
</div>
`;



  return (
    <>
      {" "}
      <ToastContainer />
      {loading ? <Loader /> : ""}
      <div className="bg-black text-white">
        {/* Breadcrumb */}
        <>
          <Formik
            innerRef={formikRef}
            initialValues={defaultValue}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Form className="p-3">
              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center prototype-no-background widget_1600979911688 ">
                <div className="container-fluid content-wrapper ">
                  <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
                    <div className="col-md-10">
                      <Breadcrumbs textColor={"text-white"} />
                    </div>
                  </div>
                </div>
              </div>

              {/*  Create Postcard */}
              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-start prototype-no-background widget_1600979911688 ">
                <div className="container-fluid">
                  <div className="row pt-md-3">
                    <div className="col-md-10 offset-md-1">
                      <h2 className=" section-heading font-inter text-white  text-center">
                        Create Postcard
                      </h2>

                      <div
                        id="hs_cos_wrapper_widget_1604012269835"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
                        style={{}}
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="module"
                      >
                        <div
                          className="sr-multicol-media bg-black   text-start prototype-no-background widget_1604012269835 "
                          id="investors"
                        >
                          <div className="sr-multicol-media bg-black   text-start prototype-no-background widget_1600979911688 ">
                            <div className="container-fluid content-wrapper p-0 ">
                              <div className="bg-black text-white">
                                <div className="container-fluid px-0  text-center">
                                  {/* <img src='images/finalpostcard.png' /> */}
                                  Instructions: Lorem ipsum dolor sit amet
                                  consectetur, adipisicing elit. Accusamus
                                  voluptates, aliquam perferendis mollitia.
                                </div>
                                <div className="container-fluid  text-start mt-4 p-0">
                                  <h5 className="font-inter mb-1 pr-2">
                                    Step 1: Upload the Recipients{"    "}({" "}
                                    <a href="#" onClick={sampleCSVfile}>
                                      Download Sample File{" "}
                                    </a>
                                    )
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid pt-0 pt-md-2 p-1">
                            <div className="">
                              <div className="container-fluid bg-black text-white p-0">
                                <div
                                  className="headingheight p-5 bg-black text-white"
                                  ref={dropAreaRef}
                                  onDragOver={handleDragOver}
                                  onDrop={handleDrop}
                                >
                                  <div className="headingmain">
                                    Choose a file or drag and drop{" "}
                                    <span className="name">CSV file</span> here
                                    <div className="mb-3 d-flex align-content-center justify-content-center mt-md-5 mt-2">
                                      <div className="btn-wrapper amecol btn-wrapper-sm btn-primary-wrapper d-xl-inline mt-3 d-flex mt-xl-0 ">
                                        <Link
                                          to="#"
                                          className="custom-login-btn bg-theme border-0"
                                          onClick={handleButtonClick}
                                        >
                                          <img
                                            src="images/ameimg/cloud.png"
                                            alt="Start"
                                            className="mr-1 text-center"
                                          />{" "}
                                          <span className="amef">
                                            Browse File
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className=" d-xl-inline text-white mt-3 mt-xl-0 text-center ">
                                      <div className=" d-flex justify-content-center"></div>
                                    </div>
                                  </div>
                                  <input
                                    type="file"
                                    name="postcardFile"
                                    accept=".csv"
                                    onChange={handleFileUpload}
                                    style={{ display: "none" }}
                                    id="file-input"
                                  />
                                </div>
                                <p className="text-danger text-left   field-errorMessage ">
                                  <ErrorMessage name="postcardFile" />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row pt-md-3">
                      <div className="col-md-10 offset-md-1 p-0">
                        {tableData?.length > 0 && (
                          <div className="container-fluid mt-4 p-0">
                            <div className=" table-responsive p-0">
                              <table className="table custom-downloads-table table-bordered aircraft-registration-table-boxShadow">
                                <thead className="thead custom-downloads-thead">
                                  <tr>
                                    {tableHeader?.map((ele: any) => {
                                      return (
                                        <th key={ele} scope="col">
                                          {ele}
                                        </th>
                                      );
                                    })}
                                    {/* <th></th>
                                  <th></th> */}
                                  </tr>
                                </thead>
                                <tbody className="overflow-auto">
                                  {tableData &&
                                    Array.isArray(tableData) &&
                                    tableData.map((data: []) => {
                                      return (
                                        <tr>
                                          {data.length > 0 &&
                                            data.map((ele: any) => {
                                              return (
                                                <td className="text-nowrap">
                                                  {ele}
                                                </td>
                                              );
                                            })}
                                          {/*  
{data.length > 0 &&  <td  className="text-nowrap "><div className='d-flex justify-content-center mt03'><img src='images/editicon.png' className='editiconcss'/></div></td>}
{data.length > 0 &&  <td  className="text-nowrap"><div className='d-flex justify-content-center mt03'><img src='images/crossicon.png' className='editiconcss'/></div></td>} */}
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Provide your Informations */}
              <div className="sr-multicol-media bg-black pt-4 pt-md-2 text-start prototype-no-background widget_1600979911688">
                <div className="container-fluid">
                  <div className="row pt-md-3">
                    <div className="col-md-10 offset-md-1">
                      <div className="bg-black container-fluid content-wrapper  text-start p-0">
                        <h5 className="font-inter">
                          Step 2: Provide your Information{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row text-center px-4 pb-4   ">
                    <div className="col-md-10 offset-md-1 pb-3 p-4  custom-orange-border-search">
                      {/* <div className="row "> */}
                      {/* <div className="col-11 col-sm-11 col-md-11 col pb-3 pb-md-5 d-flex justify-content-center align-items-center"> */}
                      {/* <div className="container-fluid px-5 pmob0 py-4  nacmaxwid  custom-shadow-search "> */}
                      <div className="row mb-md-3">
                        <div className="col-12 col-md-6">
                          <div className="">
                            <Field
                              type="text"
                              className="form-control nacinput focusmb focusmb  custom-form-control  mb-0  w-100  p-0 pt-3 pb-1"
                              name="firstName"
                              placeholder="First Name"
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  firstName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="firstName" />
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div>
                            <Field
                              type="text"
                              className="form-control nacinput focusmb  custom-form-control mb-0  w-100  p-0 pt-3 pb-1"
                              id="last_name"
                              placeholder="Last Name"
                              name="lastName"
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  lastName: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="lastName" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row  mb-md-3">
                        <div className="col-12 col-md-6">
                          <div className="">
                            <Field
                              type="text"
                              className="form-control nacinput focusmb focusmb  custom-form-control  mb-0  w-100  p-0 pt-3 pb-1"
                              name="street"
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  address: e.target.value,
                                })
                              }
                              placeholder="Street"
                            />
                          </div>
                          <div className="">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="street" />
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div>
                            <Field
                              type="text"
                              className="form-control nacinput focusmb  custom-form-control mb-0  w-100  p-0 pt-3 pb-1"
                              id="last_name"
                              placeholder="Building/Apartment/House No."
                              name="house"
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  address2: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="house" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row  mb-md-2">
                        <div className="col-12 col-md-4">
                          <div className="">
                            {/* <Field
                type="text"
                className="form-control nacinput focusmb focusmb  custom-form-control  mb-0  w-100  p-0 pt-3 pb-1"
                
                name="state"
                placeholder="State"
              /> */}
                            <div className="form-group  mb-0 ">
                              <Field
                                as="select"
                                className="form-control  mb-0  nacinput focusmb  custom-form-control pl-0"
                                name="state"
                                value={sendersDetail.state}
                                onInput={(e: any) =>
                                  setSendersDetail({
                                    ...sendersDetail,
                                    state: e.target.value,
                                  })
                                }
                              >
                                {states && states.length ? (
                                  <>
                                    <option value="">Select State</option>
                                    {states.map(
                                      (state: any) =>
                                        state.state && (
                                          <option
                                            key={state.state}
                                            value={state.state_abbrev}
                                          >
                                            {state.state_abbrev}
                                          </option>
                                        )
                                    )}
                                  </>
                                ) : (
                                  <option value="">Select State</option>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="state" />
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div>
                            <Field
                              type="text"
                              className="form-control nacinput focusmb  custom-form-control mb-0  w-100  p-0 pt-3 pb-1"
                              id="last_name"
                              placeholder="City"
                              name="city"
                              onInput={(e: any) =>
                                setSendersDetail({
                                  ...sendersDetail,
                                  city: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="city" />
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div>
                            {/* setSendersDetail({...sendersDetail,zipcode:e.target.value}) */}
                            <Field
                              type="number"
                              className="form-control nacinput focusmb  custom-form-control mb-0  w-100  p-0 pt-3 pb-1"
                              id="last_name"
                              placeholder="Zip Code"
                              name="zipcode"
                              minLength={5}
                              maxLength={9}
                              value={sendersDetail.zipcode}
                              onChange={handlePincode}
                              onInput={(e: any) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ); // Remove non-numeric characters
                              }}
                            />
                          </div>
                          <div className="">
                            <p className="text-danger text-left   field-errorMessage ">
                              <ErrorMessage name="zipcode" />
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="raw justify-content-center d-none">
                        <div className=" d-flex justify-content-center align-items-center">
                          <button
                            ref={buttonRef1}
                            type="submit"
                            className="custom-btn-begin-search border-1 font-inter"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Multipale template*/}
              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-start prototype-no-background widget_1600979911688">
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-md-10 offset-md-1">
                      <div className=" bg-black container-fluid content-wrapper  text-start p-0">
                        <h5 className="font-inter">
                          Step 3: Select a Template{" "}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row  px-4 pb-4   ">
                    <div className="col-md-10 offset-md-1 text-center whiteborder px-4">
                      <div className="bg-black container-fluid content-wrapper px-0 pt-4 bg-black  d-flex flex-wrap justify-content-around">
                        <div className="col-12 d-flex justify-content-center p-0 text-white">
                          {/* btn */}
                          <div
                            className={
                              btnClick
                                ? " col-sm-6  text-white  col-lg-6 text-center custom-btn mt-md-0 mt-3  personalInfo-btn-clicked  custom-btn-information"
                                : " col-sm-6  text-white col-lg-6 text-center custom-btn mt-md-0 mt-3 custom-btn-information"
                            }
                            onClick={() => {
                              setBtnClick(true);
                            }}
                          >
                            Template 1
                          </div>

                          <div
                            className={
                              btnClick
                                ? "  col-sm-6   text-white col-lg-6 text-center custom-btn mt-md-0 mt-3 custom-btn-Certificates"
                                : "col-sm-6  text-white col-lg-6 text-center custom-btn personalInfo-btn-clicked custom-btn-Certificates mt-md-0 mt-3 "
                            }
                            onClick={() => {
                              setBtnClick(false);
                            }}
                          >
                            Template 2
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-12  offset-md-1 text-center p-0  "> */}
                      <div className="h4 text-white pb-2 pt-4 ">
                        Example of how Postcard is being Received
                      </div>
                      <div className="pb-4">
                        {btnClick ? (
                          <div>
                            <div
                              className="pb-4"
                              dangerouslySetInnerHTML={{
                                __html: htmlfrontTemplate1,
                              }}
                            />
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: htmlbackTemplate1,
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <div
                              className="pb-4"
                              dangerouslySetInnerHTML={{
                                __html: htmlfrontTemplate2,
                              }}
                            />
                            <div
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: htmlbackTemplate2,
                              }}
                            />
                          </div>
                        )}
                      </div>

                      {/* </div> */}
                    </div>

                    {/* Dynamic Template*/}
                  </div>
                </div>
              </div>
              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-start prototype-no-background widget_1600979911688">
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-md-10 offset-md-1">
                      <div className=" bg-black container-fluid content-wrapper  text-start p-0">
                        <h5 className="font-inter">Step 4: Postcard Date </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row text-center px-4 pb-4   ">
                    <div className="col-md-10 offset-md-1 pb-3 p-2  custom-orange-border-search">
                      <div className="d-flex name pt-2 px-4 col-12 col-md-4 create-post-card-date">
                        <Field
                          type="date"
                          className="form-control small bg-black text-white date-input"
                          aria-describedby="basic-addon1"
                          name="dateofmail"
                        />
                      </div>
                      <p className="text-danger text-left mt-2 px-4 bg-black field-errorMessage ">
                        <ErrorMessage name="dateofmail" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-start prototype-no-background widget_1600979911688">
                <div className="container-fluid">
                  <div className="row ">
                    <div className="col-md-10 offset-md-1">
                      <div className=" bg-black container-fluid content-wrapper  text-start p-0">
                        <h5 className="font-inter">Step 5: Pay Now </h5>
                      </div>
                    </div>
                  </div>

                  <div className="row text-center px-4 pb-4   ">
                    <div className="col-md-10 offset-md-1 pb-3 p-2  custom-orange-border-search">
                      <div className="d-flex name pt-2">
                        <div className="col-8 text-left text-white h5">
                          Total Recipients
                        </div>
                        <div className="col-4 name text-right h5">
                          {sendData.length}
                        </div>
                      </div>
                      <div className="d-flex name pt-2">
                        <div className="col-8 text-left text-white h5">
                          Price per Recipient
                        </div>
                        <div className="col-4 name text-right h5 ">$ 0.89</div>
                      </div>
                      <div className="d-flex name pt-2">
                        <div className="col-8 text-left name h5">
                          Total Price
                        </div>
                        <div className="col-4 text-right name h5">
                          $ {totalAmt}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row text-center px-4 pb-4   ">
                    <div className="col-md-10 offset-md-1 pb-3 p-2  text-right">
                      {" "}
                      <button
                        className="btn btn-primary"
                        onClick={handleFormButtonClick}
                        type="submit"
                      >
                        Pay Now
                      </button>
                      <button
                        className="d-none"
                        // disabled
                        ref={buttonRef2}
                        data-toggle="modal"
                        data-target=".postcardmodal"
                      >
                        hello
                      </button>
                    </div>
                  </div>
                  {/* modal start from */}
            
                  <Elements stripe={stripePromise}>
                    <PostCardpayment orderDetails={orderSummary} front={btnClick?htmlfrontTemplate1:htmlfrontTemplate2} back={btnClick?htmlbackTemplate1:htmlbackTemplate2} />
                  </Elements>
                </div>
              </div>
            </Form>
          </Formik>
        </>
      </div>
    </>
  );
};

export default CreatePostcard;
{
  /* <div
id="hs_cos_wrapper_widget_1604012269835"
className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
style={{}}
data-hs-cos-general-type="widget"
data-hs-cos-type="module"
>
<div
className="sr-multicol-media bg-black sr-spacer-bottom-50  text-center prototype-no-background widget_1604012269835 "
id="investors"
>
<div className="container-fluid pt-0 pt-md-2">


      <Formik
initialValues={defaultValue}
validationSchema={validationSchema}
onSubmit={handleSubmit}
>

<div className="bg-body-tertiary d-flex flex-row gray-bg bg-black ">
<div className=" container">
<div className="row justify-content-center ">
  <div className="col-md-10  mt-sm-5 mb-sm-5 p-0  login-container overflow-hidden">
    <div className="d-flex flex-column                                    ">
      <div className=" card   m-4 m-sm-0 ">
        <div className="card-body  bg-black ">
          <Form>
            <div className="py-md-5 px-md-2 p-2">
        
              <div className='row mb-3  '>
              <div className="input-group col-md-6 ">
              <div  className='col-md-12 text-white mb-1 text-left'>First Name</div>
                {/* <span
                  className="input-group-text"
                  id="basic-addon1"
                >
                  <FaUserAlt />
                </span> 
                <div  className='col-md-12'>
                <Field
                  type="text"
                  className="form-control small"
                  placeholder="ex. John"
                  aria-label="First Name"
                  aria-describedby="basic-addon1"
                  name="firstName"
                />
                </div>
                <div className='col-md-12'>
              <p className="text-danger text-left   field-errorMessage mt-1">
                <ErrorMessage name="firstName" />
              </p>
              </div>
              </div>
              <div className="input-group  col-md-6 ">
                {/* <span
                  className="input-group-text"
                  id="basic-addon1"
                >
                  <FaUserAlt />
                </span> 
                <div  className='col-md-12'>
                <div  className='col-md-12 text-white mb-1 text-left'>Last Name</div>
                <div  className='col-md-12'>
                <Field
                  type="text"
                  className="form-control small"
                  placeholder="ex. Doe"
                  aria-label="Last Name"
                  aria-describedby="basic-addon1"
                  name="lastName"
                />
                </div>
                <div  className='col-md-12'>
              <p className="text-danger  text-left  field-errorMessage mt-1">
              <ErrorMessage name="lastName" />
              </p>
                </div>
              </div>
              </div>
              </div>
              <div className='row mb-3 '>
              <div className="input-group col-md-6   ">
              <div  className='col-md-12 text-white mb-1 text-left'>Address</div>
                {/* <span
                  className="input-group-text"
                  id="basic-addon1"
                >
                  <FaUserAlt />
                </span> 
                <div  className='col-md-12'>
                <Field
                  type="text"
                  className="form-control small"
                  placeholder="Street"
                  aria-label="First Name"
                  aria-describedby="basic-addon1"
                  name="street"
                />
                </div>
                <div className='col-md-12'>
              <p className="text-danger text-left   field-errorMessage mt-1">
                <ErrorMessage name="street" />
              </p>
              </div>
              </div>
              <div className="input-group  col-md-6 ">
                {/* <span
                  className="input-group-text"
                  id="basic-addon1"
                >
                  <FaUserAlt />
                </span> 
                <div  className='col-md-12'>
                <div  className='col-md-12 text-white mb-1 text-left'>&nbsp;</div>
                <div  className='col-md-12'>
                <Field
                  type="text"
                  className="form-control small"
                  placeholder="Building/Apartment/House No"
                  aria-label="Last Name"
                  aria-describedby="basic-addon1"
                  name="lastName"
                />
                </div>
                <div  className='col-md-12'>
              <p className="text-danger  text-left  field-errorMessage mt-1">
              <ErrorMessage name="lastName" />
              </p>
                </div>
              </div>
              </div>
              </div>
              <div className='row'>
              <div className="input-group col-md-4 mb-3  ">
            
                {/* <span
                  className="input-group-text"
                  id="basic-addon1"
                >
                  <FaUserAlt />
                </span> 
                <div  className='col-md-12'>
                <Field
                  type="text"
                  className="form-control small"
                  placeholder="City"
                  aria-label="City"
                  aria-describedby="basic-addon1"
                  name="city"
                />
                </div>
                <div className='col-md-12'>
              <p className="text-danger text-left   field-errorMessage mt-1">
                <ErrorMessage name="city" />
              </p>
              </div>
              </div>
              <div className="input-group  col-md-4 mb-3 ">
                {/* <span
                  className="input-group-text"
                  id="basic-addon1"
                >
                  <FaUserAlt />
                </span> 
                <div  className='col-md-12'>
              
                <div  className='col-md-12'>
                <div className="form-group">
          <Field 
          as="select" 
          className="form-control nacinput focusmb  custom-form-control pl-0"
          name="state">
            {states && states.length ? (
              states.map((state:any) => 
                state.state && <option value={state.state}>{state.state_abbrev}</option>
              )
              ) : <option value="">Select State</option>
            }
          </Field>
        </div>
                {/* <Field
                  type="text"
                  className="form-control small"
                  placeholder="State"
                  aria-label="State"
                  aria-describedby="basic-addon1"
                  name="state"
                /> 
                </div>
                <div  className='col-md-12'>
              <p className="text-danger  text-left  field-errorMessage mt-1">
              <ErrorMessage name="state" />
              </p>
                </div>
              </div>
              </div>
              <div className="input-group col-md-4 mb-3  ">
            
                {/* <span
                  className="input-group-text"
                  id="basic-addon1"
                >
                  <FaUserAlt />
                </span> 
                <div  className='col-md-12'>
                <Field
                  type="text"
                  className="form-control small"
                  placeholder="Zip Code"
                  aria-label="Zip Code"
                  aria-describedby="basic-addon1"
                  name="zipcode"
                />
                </div>
                <div className='col-md-12'>
              <p className="text-danger text-left   field-errorMessage mt-1">
                <ErrorMessage name="zipcode" />
              </p>
              </div>
              </div>
              </div>

            
          

            

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary w-25 justify-content-center"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  <span className="text-white">Submit</span>
                </button>
              </div>
          
            </div>
          </Form>
        </div>
      </div>
    
    </div>
  </div>
</div>
</div>
</div>
</Formik>

</div>

</div>

</div> */
}

// import React, { useState } from 'react';
// import { Breadcrumbs } from '../RepeatedComponent/Breadcrumb';
// import './CreatePostcard.css'
// import { Link } from 'react-router-dom';
// const CreatePostcard = () => {
//     const [csvData, setCSVData] = useState<string[][]>([]);

//     const handleCSVUploaded = (data: string[][]) => {
//         setCSVData(data);
//     };

//     const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const file = event.target.files?.[0];
//         if (file) {
//             if (file.name.endsWith('.csv')) {
//                 parseCSV(file);
//             } else {
//                 console.error('Please select a CSV file.');
//             }
//         }
//     };

//     const parseCSV = (file: File) => {
//         const reader = new FileReader();
//         reader.onload = () => {
//             if (typeof reader.result === 'string') {
//                 console.log()
//                 const csvData = reader.result;
//                 const parsedData = csvData.split('\n').map(row => row.split(','));

//                 setCSVData(parsedData);
//             }
//         };
//         reader.readAsText(file);
//     };

//     const handleButtonClick = () => {
//         document.getElementById('file-input')?.click();
//     };

//     return (
//         <>
//             <div className='bg-black text-white'>
//                 <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center prototype-no-background widget_1600979911688 ">
//                     <div className="container-fluid content-wrapper ">
//                         <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
//                             <div className="col-md-10">
//                                 <Breadcrumbs textColor={"text-white"} />
//                             </div>
//                         </div>
//                     </div>
//                 </div><div className='pd'>
//                 <div className='container-fluid bg-black text-white'>
//                     <div className='headingheight p-5 bg-black text-white'>
//                         <div className='headingmain'>Choose a file or drag and drop CSV file here
//                         <div className="mb-3 d-flex align-content-center justify-content-center mt-5">
//                             <div className="btn-wrapper amecol btn-wrapper-sm btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ">
//                                 <Link
//                                     to="#"
//                                     className="custom-login-btn border-0"
//                                     onClick={handleButtonClick}
//                                 >
//                                     <img src="images/ameimg/youtube.png" alt="Start" className="mr-1" /> <span className="amef">Browse File</span>
//                                 </Link>
//                             </div>
//                         </div></div>
//                         <input
//                             type="file"
//                             accept=".csv"
//                             onChange={handleFileUpload}
//                             style={{ display: 'none' }}
//                             id="file-input"
//                         />
//                         {/* <button >Upload CSV</button> */}

//                     </div>
//                 </div>
//                 </div>
//
//             </div>
//         </>
//     );
// };

// export default CreatePostcard;