import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { ToastContainer, toast } from "react-toastify";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { Link } from "react-router-dom";
import { AMEHealthPopup } from "../RepeatedComponent/AMEHealthPopup";
import { useEffect, useState } from "react";
import { callToaster, clearState } from "../../../Features/authSlice";
import { useSelector } from "react-redux";

const HomePage = () => {
  const dispatch = useAppDispatch();
  const [showComponent, setShowComponent] = useState(false);
  const fulfilled = useAppSelector((state: any) => state.auth.response);
  const status = useAppSelector((state: any) => state.auth.status);
  const reduxData=useSelector((state:any)=>{return state});
  const handleClick = () => {
    setShowComponent(true);
  };
  
  useEffect(()=>{
    // console.log(reduxData,"reduxdata==============")
    if(reduxData?.auth?.showToast){
      toast.success(reduxData?.auth?.showToastMessage, {
                  position: "top-center",
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 8000,
                  pauseOnFocusLoss: true,
                  // style:{
                  //   width:'600px'
                  // },
                  onClose:(e)=>closingToast(e)
                })
                
              }
              else{
                // console.log("console of elseeeeeeeeeeeeeeeeee")
              }
        
},[])
  // useEffect(() => {
  //   if (fulfilled) {
  //     toast.success("Login Successful", {
  //       position: "top-center",
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       draggablePercent: 8000,
  //       pauseOnFocusLoss: true,
  //     });
  //     dispatch(clearState());
  //   }
  // }, []);

  const closingToast=(e:any)=>{
    console.log("toast is closed");
    const changeData:object={show:false,msg:''}
    e={...e,changeData}
    dispatch(callToaster(e));
      }

  const slider1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 896,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const slider2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {/*Home Window  */}
      <div
        id="hs_cos_wrapper_widget_34472035839"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {/* Label For Modules Data Page */}
        <div className="widget_1601501547743 sr-hero-01 sr-cover-parallax  prototype-no-background  homebg">
          <div className="sr-cover-image home-window-bg-img" />
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="col-12">
                  <div className="sr-cover d-flex align-items-center  text-center height-medium">
                    <div className="sr-cover-inner text-white">
                      <h6 className="font-inter text-white">
                        Databases that offer you more than just data.
                      </h6>
                      <h1 className="section-heading text-white">
                        <span className="ft-lg">Most Trusted </span> Provider of <br></br> Aviation Information
                      </h1>
                      <div className="sr-spacer-topx-0 py-25 cta-wrapper">
                        <div className="btn-wrapper btn-primary-wrapper">
                          {status == true ? (
                            <>
                              <Link
                                to="#"
                                onClick={handleClick}
                                data-toggle="modal"
                                data-target="#ameHealthModal"
                                className="cta_button"
                              >
                                AME Health Certificate List
                              </Link>
                              {showComponent && <AMEHealthPopup />}
                            </>
                          ) : (
                            <Link to="/register" className="cta_button">
                              START YOUR FREE 7-DAY TRIAL TODAY
                            </Link>
                          )}
                        </div>
                      </div>
                      {/* <div className="description mt-3">
                        <p>
                          Contact us today for a personalized quote for your
                          marketing campaign.
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Slider Section End*/}
      <div
        id="hs_cos_wrapper_widget_34472035840"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        <div className="sr-multicol-media sr-spacer-50 sr-three-col-01 bg-none  text-center prototype-no-background widget_34472035840 ">
          <div className="container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-10">
                <h2 className="h2 section-heading">
                  Powerful Aviation Data + Targeted Marketing

                </h2>
                <div className="section-description">
                  <p>Increase efficiency and find more opportunities. PlaneLists.com helps you locate, evaluate and market to highly-targeted aviation prospects.</p>
                </div>
                <div className="row sr-valign-top">
                  <div className="col-md-4 sr-col text-left  text-  sr-repeater-1 ">
                    <div className="content-wrapper">
                      <ToastContainer />
                      <div className="responsive-image mb-4">
                        <img
                          src="images/HomePageImg/HomePage1.png"
                          alt="identify-leads@2x"
                          width={740}
                          height={548}
                          sizes="(max-width: 740px) 100vw, 740px"
                        />
                      </div>
                      <h4 className="col-heading font-inter text-center ">
                        Discover AME Patients
                      </h4>
                      <div className="col-content ">
                        <p>
                        Designed for Aviation Medical Examiners 
(AMEs), PlaneLists.com simplifies the search for 
pilot and non-pilot aviation groups, including 
air traffic controllers, mechanics, and 
inspectors. 
                        </p>
                        <div className="unorder-list pl-3 pl-sm-home  font-bold">
                          <strong> <ul>
                            <li>
                              Choose certifications and medical dates.
                            </li>
                            <li>
                              Centralize data by location.
                            </li>
                            <li>
                              Focus on specific qualifications.
                            </li>
                            <li >
                              Streamline identification of individuals
                              needing medical examinations.
                            </li>
                          </ul>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 sr-col text-left  text-  sr-repeater-2 ">
                    <div className="content-wrapper">
                      <div className="responsive-image mb-4">
                        <img
                          src="images/HomePageImg/HomePage2.png"
                          alt="research-property@2x"
                          width={740}
                          height={548}
                          sizes="(max-width: 740px) 100vw, 740px"
                        />
                      </div>
                      <h4 className="col-heading font-inter  text-center ">Find Off-Market Aircraft</h4>
                      <div className="col-content">
                        <p>
                          Plane flippers can easy searches for
                          potentially available aircraft. Effortlessly
                          locate planes with expired certifications or
                          registrations using intuitive sorting options
                          like manufacture, class, powerplant, and
                          more.
                        </p>
                        <div className="unorder-list pl-3 pl-sm-home font-bold">
                          <strong>
                            <ul>
                              <li>
                                Simple Search and Sorting.
                              </li>
                              <li>
                                Custom Access to Aircraft Owners
                              </li>
                              <li>
                                Intuitive Design for User-Friendliness.
                              </li>
                              <li>
                                Organize by Aircraft Type Easily.
                              </li>
                            </ul>
                          </strong></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 sr-col text-left  text-  sr-repeater-3 ">
                    <div className="content-wrapper">
                      <div className="responsive-image mb-4">
                        <img
                          src="images/HomePageImg/HomePage3.png"
                          alt="market-to-lead@2x"
                          width={740}
                          height={548}
                          sizes="(max-width: 740px) 100vw, 740px"
                        />
                      </div>
                      <h4 className="col-heading font-inter text-center">Top Sales Opportunities</h4>
                      <div className="col-content">
                        <p>
                          Aviation sales professionals unlock
                          targeted opportunities with a blend of pilot
                          certifications, strategic locations, and
                          aircraft types. Catering to insurance, real
                          estate, investment advising, and flight
                          schools sales, ensuring efficient access to:
                        </p>
                        <div className="unorder-list pl-3 pl-sm-home font-bold">
                          <strong>
                        <ul>
                          <li>
                          Identify New Aircraft Owners.
                          </li>
                          <li>
                          Reach Pilots in High Net-Worth Areas.
                          </li>
                          <li>
                          Target Aircraft Owners by Type.
                          </li>
                          <li>
                          Locate Pilots in Specific Zip Codes
                          </li>
                        </ul></strong></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id="hs_cos_wrapper_widget_1609865787403"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        
        <div className="sr-offer-bar-03 bg-secondary sr-padding-custom   sr-line-bottom  prototype-no-background widget_1609865787403 text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <div className="row d-flex align-items-center h-100 sr-offer-bar-03-inner ">
                  <div className="col-lg-12">
                    <div className="row ">
                      <div className="col sr-col ">
                        <div className="row d-flex align-items-center h-100">
                          <div className="col-md-7 sr-col sr-offer-bar-03-content">
                            <span className="align-middle mx-auto">
                              <div className="description">
                                <p>
                                  <img
                                    src="images/HomePageImg/HomepageAd@2x-1.png"
                                    alt="HomepageAd@2x-1"
                                    width={303}
                                    style={{ width: 303 }}
                                    sizes="(max-width: 303px) 100vw, 303px"
                                  />
                                </p>
                              </div>
                            </span>
                          </div>
                          <div className="col-md-5 text-center text-md-right offer-btns">
                            <span className="btn-wrapper btn-black-wrapper d-inline-block btn-wrapper">
                              <a href="#" className="cta_button font-inter">
                                Learn More
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div
        id="hs_cos_wrapper_widget_34472050324"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        <div className="sr-multicol-media bg-white sr-padding-half text-center prototype-no-background widget_1600979911688 ">
          <div className="container-fluid content-wrapper">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h2 className=" section-heading font-inter">
                  PlaneLists.com Benefits
                </h2>
                <div className="row  sr-valign-center">
                  <div className="col-md-6 nopadding sr-col text-left  sr-repeater-1">
                    <div className="content-wrapper">
                      <div className="responsive-image ">
                        <img
                          src="images/HomePageImg/planelists-benefits.png"
                          alt="planelists benefit"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 sr-col text-left  sr-repeater-2">
                    <div className="content-wrapper">
                      <div className="col-content">
                        <p>
                          <strong className="font-inter">
                            Free Airmen Records
                          </strong>
                          - Lorem Ipsum is simply dummy text of the printing and
                          been the industry's standard dummy text ever since the
                          1500s, when an unknown printer took a galley of type
                          and scrambled it to make a type specimen book
                        </p>
                        <p>
                          <strong className="font-inter">Lorem Ipsum</strong> -
                          Lorem Ipsum is simply dummy text of the printing and
                          been the industry's standard dummy text ever since the
                          1500s.
                          <strong>&nbsp;</strong>
                        </p>
                        <p>
                          <strong className="font-inter">
                            Free Airmen Records
                          </strong>
                          - Lorem Ipsum is simply dummy text of the printing and
                          been the industry's standard dummy text ever since the
                          1500s, when an unknown printer took a galley of type
                          and scrambled it.
                        </p>
                        <p>
                          <strong className="font-inter">
                            Paid Subscription
                          </strong>
                          - Lorem Ipsum is simply dummy text of the printing and
                          been the industry's standard dummy text ever since the
                          1500s, when an unknown printer took a galley of type
                          and scrambled it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    
        <div
          className="sr-stats widget_34472050324 sr-padding-half home-data-group "
          id="homeData"
        >
          <div className="container-fluid">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-lg-11 ">
                <div className="text-center text-white">
                  <h2 className=" heading text-white  font-inter">
                    The Power of PlaneLists
                  </h2>
                  <div className="description">
                    <p>
                      <strong className="font-inter">
                        Customizable, Detailed, Nationwide Search
                      </strong>
                      <br />
                      Increase efficiency and miss fewer opportunities.
                    </p>
                  </div>
                  <div className="stats-wrapper">
                    <div className="stat ">
                      <h2 className="text-white font-inter">155+</h2>
                      <p>
                        Million
                        <br />
                        Property Records
                      </p>
                    </div>
                    <div className="stat ">
                      <h2 className="text-white font-inter">308+</h2>
                      <p>
                        Lorem Ipsum
                        <br />
                        Dummy Text
                      </p>
                    </div>
                    <div className="stat ">
                      <h2 className="text-white font-inter">185+</h2>
                      <p>
                        Lorem Ipsum
                        <br />
                        Dummy Text
                      </p>
                    </div>
                    <div className="stat ">
                      <h2 className="text-white font-inter">41+</h2>
                      <p>
                        Lorem Ipsum
                        <br />
                        Dummy Text
                      </p>
                    </div>
                    <div className="stat ">
                      <h2 className="text-white font-inter">70+</h2>
                      <p>
                        Lorem Ipsum
                        <br />
                        Dummy Text
                      </p>
                    </div>
                    <div className="stat ">
                      <h2 className="text-white font-inter">150+</h2>
                      <p>
                        Lorem Ipsum
                        <br />
                        Dummy Text
                      </p>
                    </div>
                    <div className="stat ">
                      <h2 className="text-white font-inter">7+</h2>
                      <p>
                        Lorem Ipsum
                        <br />
                        Dummy Text
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        id="hs_cos_wrapper_widget_1600979911688"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
       
      </div> */}
      {/* <div
        id="hs_cos_wrapper_widget_1600980690758"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
     
        <div className="sr-multicol-media  sr-padding-custom text-center prototype-no-background widget_1600980690758 ">
          <div className="container-fluid content-wrapper">
            <h2 className=" section-heading font-inter">
              FAA Aircraft Registration Database
            </h2>
            <div className="row  ">
              <div className="col-md-4 offset-md-1 sr-col text-left  sr-repeater-1">
                <div className="content-wrapper">
                  <div className="col-content">
                    <p className="justify-content">
                      PlaneLists.com’s databases put the power in your palms as
                      you have the ability to search accurate and up-to-date
                      aviation mailing lists for your marketing strategies.
                      Designed by aviation professionals for use by the aviation
                      industry, our databases offer more than just detailed
                      lists. They give you access to comprehensive information
                      that is updated regularly, so you always have the most
                      current details for your mailing lists and marketing
                      materials.
                    </p>
                    <p className="justify-content">
                      Through customized searches, you’ll have access to
                      detailed records on US aircraft owners, pilots, A&Ps,
                      drone operators, and other aviation professionals and
                      businesses. Data can be extrapolated, sorted, searched,
                      and manipulated to suit your business requirements to
                      maximize your profits. Analyze our data to understand the
                      aviation market and predict current trends. Use our
                      information to gain a global industry perspective or
                      create aircraft and activity reports. The possibilities
                      are endless. We also offer full-service mailing list
                      services from our database, using our Amazon-based web
                      server. No request is too big or too small. We offer a
                      customized approach and create a marketing program to fit
                      your needs.
                    </p>

                    {/* <p>
                      <strong className="font-inter">Lorem Ipsum Dummy Text</strong>
                    </p>
                    <p className="justify-content">
                      Lorem Ipsum is simply dummy text of the printing and been
                      the industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it.
                    </p>
                    <p>
                      <strong className="font-inter">Lorem Ipsum is Simply Dummy Text</strong>
                    </p>
                    <p className="justify-content">
                      Lorem Ipsum is simply dummy text of the printing and been
                      the industry's standard dummy text ever since the 1500s,
                      when an unknown printer took a galley of type and
                      scrambled it to make a type specimen book lorem Ipsum is
                      simply dummy text.
                    </p> 
                  </div>
                </div>
              </div>
              <div className="col-md-7 sr-col text-center  sr-repeater-2">
                <div className="content-wrapper">
                  <div className="responsive-image ">
                    <img
                      src="images/HomePageImg/view-3d-airplane.png"
                      alt="PlaneLists.com Mobile"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginBottom: "20px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>*/}
      {/* <div
        id="hs_cos_wrapper_widget_1599685903509"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      /> */}
      {/* <div
        id="hs_cos_wrapper_widget_1600385718853"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
   
        <div className="sr-cards-articles-01 widget_1600385718853 bg-white sr-padding-half   text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <h2 className="heading  font-inter">
                  Lorem Ispum is Dummy Text{" "}
                </h2>
                <div className="description  ">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and been
                    the industry's standard dummy text
                  </p>
                </div>

               
                <div className=" ">
                  <Slider {...slider1}>
                    <div className="px-0 py-2">
                      <div
                        className="article-item equal-height odd text-white "
                        style={{
                          backgroundImage:
                            'url("images/HomePageImg/AircraftImg1.png")',
                        }}
                      >
                        <div className="article-inner">
                          <a className="post-name" href="#">
                            <p className="lead font-inter">
                              Lorem Ispum is Dummy Text
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="px-0 py-2">
                      <div
                        className="article-item equal-height odd text-white "
                        style={{
                          backgroundImage:
                            'url("images/HomePageImg/AircraftImg2.png")',
                        }}
                      >
                        <div className="article-inner">
                          <a className="post-name" href="#">
                            <p className="lead font-inter">
                              Lorem Ispum is Dummy Text
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="px-0 py-2">
                      <div
                        className="article-item equal-height odd text-white "
                        style={{
                          backgroundImage:
                            'url("images/HomePageImg/AircraftImg3.png")',
                        }}
                      >
                        <div className="article-inner">
                          <a className="post-name" href="#">
                            <p className="lead font-inter">
                              Lorem Ispum is Dummy Text
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="px-0 py-2">
                      <div
                        className="article-item equal-height odd text-white "
                        style={{
                          backgroundImage:
                            'url("images/HomePageImg/AircraftImg4.png")',
                        }}
                      >
                        <div className="article-inner">
                          <a className="post-name" href="#">
                            <p className="lead font-inter">
                              Lorem Ispum is Dummy Text
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="px-0 py-2">
                      <div
                        className="article-item equal-height odd text-white "
                        style={{
                          backgroundImage:
                            'url("images/HomePageImg/AircraftImg1.png")',
                        }}
                      >
                        <div className="article-inner">
                          <a className="post-name" href="#">
                            <p className="lead font-inter">
                              Lorem Ispum is Dummy Text
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="px-0 py-2">
                      <div
                        className="article-item equal-height odd text-white "
                        style={{
                          backgroundImage:
                            'url("images/HomePageImg/AircraftImg1.png")',
                        }}
                      >
                        <div className="article-inner">
                          <a className="post-name" href="#">
                            <p className="lead font-inter">
                              Lorem Ispum is Dummy Text
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    
      {/*   <div
        id="hs_cos_wrapper_widget_1608054340396"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
 
      <div className="sr-padding-full sr-cards-testimonial-slider-01 text-center    ">
          <div className="container-fluid  ">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="sr-cards-testimonial-slider-01-content-wrapper">
                  <div className="sr-spacer-bottom-50 ">
                    <h2 className=" heading font-inter">PlaneLists.com Testimonials</h2>
                  </div>
                  <section className="second-slider">
                    <Slider {...slider2}>
                      
                      
                        <div className="slickSliderItem text-left">
                          <div className="sr-cards-testimonial-slider-01-content equal-height">
                            
                            <div className="slide-content">
                              <div>
                                <svg
                                  width="30px"
                                  height="26px"
                                  viewBox="0 0 30 26"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                  
                                  <title className="font-inter">left-quote</title>
                                  <desc className="font-inter">Created with Sketch.</desc>
                                  <g
                                    id="Modules"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Phase-9-11-Modules"
                                      transform="translate(-980.000000, -2768.000000)"
                                    >
                                      <g
                                        id="SR-Cards-Testimonials-02"
                                        transform="translate(0.000000, 2630.000000)"
                                      >
                                        <g
                                          id="article"
                                          transform="translate(930.000000, 60.000000)"
                                        >
                                          <g
                                            id="Group"
                                            transform="translate(50.000000, 78.000000)"
                                          >
                                            <g id="Group-4">
                                              <g id="left-quote">
                                                <path
                                                  d="M9.535,11.5434375 C8.80125,11.3321875 8.0675,11.225 7.35375,11.225 C6.2515625,11.225 5.331875,11.476875 4.61875,11.7853125 C5.30625,9.2684375 6.9578125,4.925625 10.2478125,4.4365625 C10.5525,4.39125 10.8021875,4.1709375 10.8853125,3.874375 L11.604375,1.3025 C11.665,1.085 11.6290625,0.8521875 11.505,0.6634375 C11.3809375,0.4746875 11.1815625,0.34875 10.958125,0.318125 C10.7153125,0.285 10.4678125,0.268125 10.2225,0.268125 C6.2734375,0.268125 2.3625,4.39 0.7121875,10.291875 C-0.2565625,13.754375 -0.540625,18.96 1.845625,22.2365625 C3.1809375,24.07 5.1290625,25.0490625 7.6359375,25.146875 C7.64625,25.1471875 7.65625,25.1475 7.6665625,25.1475 C10.7596875,25.1475 13.5025,23.064375 14.336875,20.0821875 C14.8353125,18.299375 14.61,16.4296875 13.701875,14.81625 C12.8034375,13.2209375 11.32375,12.058125 9.535,11.5434375 Z"
                                                  id="Path"
                                                />
                                                <path
                                                  d="M28.8971875,14.8165625 C27.99875,13.2209375 26.5190625,12.058125 24.7303125,11.5434375 C23.9965625,11.3321875 23.2628125,11.225 22.549375,11.225 C21.4471875,11.225 20.5271875,11.476875 19.8140625,11.7853125 C20.5015625,9.2684375 22.153125,4.925625 25.4434375,4.4365625 C25.748125,4.39125 25.9975,4.1709375 26.0809375,3.874375 L26.8,1.3025 C26.860625,1.085 26.8246875,0.8521875 26.700625,0.6634375 C26.576875,0.4746875 26.3775,0.34875 26.15375,0.318125 C25.91125,0.285 25.66375,0.268125 25.418125,0.268125 C21.4690625,0.268125 17.558125,4.39 15.9075,10.291875 C14.9390625,13.754375 14.655,18.96 17.0415625,22.2371875 C18.3765625,24.0703125 20.325,25.0496875 22.8315625,25.1471875 C22.841875,25.1475 22.851875,25.1478125 22.8625,25.1478125 C25.9553125,25.1478125 28.6984375,23.0646875 29.5328125,20.0825 C30.030625,18.2996875 29.805,16.4296875 28.8971875,14.8165625 Z"
                                                  id="Path"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                            </div>
                            <div className="sr-cards-testimonial-slider-01-author">
                              <div className="author-meta">
                                <div className="author-details">
                                  <h6 className="author-name font-inter">Joe C.</h6>
                                  <small className="author-title v">
                                    Rocking Porch Homes - Atlanta, GA
                                  </small>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>

                        <div className="slickSliderItem text-left">
                          <div className="sr-cards-testimonial-slider-01-content equal-height">
                           
                            <div className="slide-content">
                              <div>
                                <svg
                                  width="30px"
                                  height="26px"
                                  viewBox="0 0 30 26"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                 
                                  <title className="font-inter">left-quote</title>
                                  <desc className="font-inter">Created with Sketch.</desc>
                                  <g
                                    id="Modules"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Phase-9-11-Modules"
                                      transform="translate(-980.000000, -2768.000000)"
                                    >
                                      <g
                                        id="SR-Cards-Testimonials-02"
                                        transform="translate(0.000000, 2630.000000)"
                                      >
                                        <g
                                          id="article"
                                          transform="translate(930.000000, 60.000000)"
                                        >
                                          <g
                                            id="Group"
                                            transform="translate(50.000000, 78.000000)"
                                          >
                                            <g id="Group-4">
                                              <g id="left-quote">
                                                <path
                                                  d="M9.535,11.5434375 C8.80125,11.3321875 8.0675,11.225 7.35375,11.225 C6.2515625,11.225 5.331875,11.476875 4.61875,11.7853125 C5.30625,9.2684375 6.9578125,4.925625 10.2478125,4.4365625 C10.5525,4.39125 10.8021875,4.1709375 10.8853125,3.874375 L11.604375,1.3025 C11.665,1.085 11.6290625,0.8521875 11.505,0.6634375 C11.3809375,0.4746875 11.1815625,0.34875 10.958125,0.318125 C10.7153125,0.285 10.4678125,0.268125 10.2225,0.268125 C6.2734375,0.268125 2.3625,4.39 0.7121875,10.291875 C-0.2565625,13.754375 -0.540625,18.96 1.845625,22.2365625 C3.1809375,24.07 5.1290625,25.0490625 7.6359375,25.146875 C7.64625,25.1471875 7.65625,25.1475 7.6665625,25.1475 C10.7596875,25.1475 13.5025,23.064375 14.336875,20.0821875 C14.8353125,18.299375 14.61,16.4296875 13.701875,14.81625 C12.8034375,13.2209375 11.32375,12.058125 9.535,11.5434375 Z"
                                                  id="Path"
                                                />
                                                <path
                                                  d="M28.8971875,14.8165625 C27.99875,13.2209375 26.5190625,12.058125 24.7303125,11.5434375 C23.9965625,11.3321875 23.2628125,11.225 22.549375,11.225 C21.4471875,11.225 20.5271875,11.476875 19.8140625,11.7853125 C20.5015625,9.2684375 22.153125,4.925625 25.4434375,4.4365625 C25.748125,4.39125 25.9975,4.1709375 26.0809375,3.874375 L26.8,1.3025 C26.860625,1.085 26.8246875,0.8521875 26.700625,0.6634375 C26.576875,0.4746875 26.3775,0.34875 26.15375,0.318125 C25.91125,0.285 25.66375,0.268125 25.418125,0.268125 C21.4690625,0.268125 17.558125,4.39 15.9075,10.291875 C14.9390625,13.754375 14.655,18.96 17.0415625,22.2371875 C18.3765625,24.0703125 20.325,25.0496875 22.8315625,25.1471875 C22.841875,25.1475 22.851875,25.1478125 22.8625,25.1478125 C25.9553125,25.1478125 28.6984375,23.0646875 29.5328125,20.0825 C30.030625,18.2996875 29.805,16.4296875 28.8971875,14.8165625 Z"
                                                  id="Path"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                            </div>
                            <div className="sr-cards-testimonial-slider-01-author">
                              <div className="author-meta">
                                <div className="author-details">
                                  <h6 className="author-name font-inter">Joe C.</h6>
                                  <small className="author-title font-inter">
                                    Rocking Porch Homes - Atlanta, GA
                                  </small>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                        <div className="slickSliderItem text-left">
                          <div className="sr-cards-testimonial-slider-01-content equal-height">
                            
                            <div className="slide-content">
                              <div>
                                <svg
                                  width="30px"
                                  height="26px"
                                  viewBox="0 0 30 26"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                 
                                  <title className="font-inter">left-quote</title>
                                  <desc className="font-inter">Created with Sketch.</desc>
                                  <g
                                    id="Modules"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Phase-9-11-Modules"
                                      transform="translate(-980.000000, -2768.000000)"
                                    >
                                      <g
                                        id="SR-Cards-Testimonials-02"
                                        transform="translate(0.000000, 2630.000000)"
                                      >
                                        <g
                                          id="article"
                                          transform="translate(930.000000, 60.000000)"
                                        >
                                          <g
                                            id="Group"
                                            transform="translate(50.000000, 78.000000)"
                                          >
                                            <g id="Group-4">
                                              <g id="left-quote">
                                                <path
                                                  d="M9.535,11.5434375 C8.80125,11.3321875 8.0675,11.225 7.35375,11.225 C6.2515625,11.225 5.331875,11.476875 4.61875,11.7853125 C5.30625,9.2684375 6.9578125,4.925625 10.2478125,4.4365625 C10.5525,4.39125 10.8021875,4.1709375 10.8853125,3.874375 L11.604375,1.3025 C11.665,1.085 11.6290625,0.8521875 11.505,0.6634375 C11.3809375,0.4746875 11.1815625,0.34875 10.958125,0.318125 C10.7153125,0.285 10.4678125,0.268125 10.2225,0.268125 C6.2734375,0.268125 2.3625,4.39 0.7121875,10.291875 C-0.2565625,13.754375 -0.540625,18.96 1.845625,22.2365625 C3.1809375,24.07 5.1290625,25.0490625 7.6359375,25.146875 C7.64625,25.1471875 7.65625,25.1475 7.6665625,25.1475 C10.7596875,25.1475 13.5025,23.064375 14.336875,20.0821875 C14.8353125,18.299375 14.61,16.4296875 13.701875,14.81625 C12.8034375,13.2209375 11.32375,12.058125 9.535,11.5434375 Z"
                                                  id="Path"
                                                />
                                                <path
                                                  d="M28.8971875,14.8165625 C27.99875,13.2209375 26.5190625,12.058125 24.7303125,11.5434375 C23.9965625,11.3321875 23.2628125,11.225 22.549375,11.225 C21.4471875,11.225 20.5271875,11.476875 19.8140625,11.7853125 C20.5015625,9.2684375 22.153125,4.925625 25.4434375,4.4365625 C25.748125,4.39125 25.9975,4.1709375 26.0809375,3.874375 L26.8,1.3025 C26.860625,1.085 26.8246875,0.8521875 26.700625,0.6634375 C26.576875,0.4746875 26.3775,0.34875 26.15375,0.318125 C25.91125,0.285 25.66375,0.268125 25.418125,0.268125 C21.4690625,0.268125 17.558125,4.39 15.9075,10.291875 C14.9390625,13.754375 14.655,18.96 17.0415625,22.2371875 C18.3765625,24.0703125 20.325,25.0496875 22.8315625,25.1471875 C22.841875,25.1475 22.851875,25.1478125 22.8625,25.1478125 C25.9553125,25.1478125 28.6984375,23.0646875 29.5328125,20.0825 C30.030625,18.2996875 29.805,16.4296875 28.8971875,14.8165625 Z"
                                                  id="Path"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                            </div>
                            <div className="sr-cards-testimonial-slider-01-author">
                              <div className="author-meta">
                                <div className="author-details">
                                  <h6 className="author-name">Joe C.</h6>
                                  <small className="author-title font-inter">
                                    Rocking Porch Homes - Atlanta, GA
                                  </small>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                        <div className="slickSliderItem text-left">
                          <div className="sr-cards-testimonial-slider-01-content equal-height">
                            
                            <div className="slide-content">
                              <div>
                                <svg
                                  width="30px"
                                  height="26px"
                                  viewBox="0 0 30 26"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                  
                                  <title className="font-inter">left-quote</title>
                                  <desc className="font-inter">Created with Sketch.</desc>
                                  <g
                                    id="Modules"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Phase-9-11-Modules"
                                      transform="translate(-980.000000, -2768.000000)"
                                    >
                                      <g
                                        id="SR-Cards-Testimonials-02"
                                        transform="translate(0.000000, 2630.000000)"
                                      >
                                        <g
                                          id="article"
                                          transform="translate(930.000000, 60.000000)"
                                        >
                                          <g
                                            id="Group"
                                            transform="translate(50.000000, 78.000000)"
                                          >
                                            <g id="Group-4">
                                              <g id="left-quote">
                                                <path
                                                  d="M9.535,11.5434375 C8.80125,11.3321875 8.0675,11.225 7.35375,11.225 C6.2515625,11.225 5.331875,11.476875 4.61875,11.7853125 C5.30625,9.2684375 6.9578125,4.925625 10.2478125,4.4365625 C10.5525,4.39125 10.8021875,4.1709375 10.8853125,3.874375 L11.604375,1.3025 C11.665,1.085 11.6290625,0.8521875 11.505,0.6634375 C11.3809375,0.4746875 11.1815625,0.34875 10.958125,0.318125 C10.7153125,0.285 10.4678125,0.268125 10.2225,0.268125 C6.2734375,0.268125 2.3625,4.39 0.7121875,10.291875 C-0.2565625,13.754375 -0.540625,18.96 1.845625,22.2365625 C3.1809375,24.07 5.1290625,25.0490625 7.6359375,25.146875 C7.64625,25.1471875 7.65625,25.1475 7.6665625,25.1475 C10.7596875,25.1475 13.5025,23.064375 14.336875,20.0821875 C14.8353125,18.299375 14.61,16.4296875 13.701875,14.81625 C12.8034375,13.2209375 11.32375,12.058125 9.535,11.5434375 Z"
                                                  id="Path"
                                                />
                                                <path
                                                  d="M28.8971875,14.8165625 C27.99875,13.2209375 26.5190625,12.058125 24.7303125,11.5434375 C23.9965625,11.3321875 23.2628125,11.225 22.549375,11.225 C21.4471875,11.225 20.5271875,11.476875 19.8140625,11.7853125 C20.5015625,9.2684375 22.153125,4.925625 25.4434375,4.4365625 C25.748125,4.39125 25.9975,4.1709375 26.0809375,3.874375 L26.8,1.3025 C26.860625,1.085 26.8246875,0.8521875 26.700625,0.6634375 C26.576875,0.4746875 26.3775,0.34875 26.15375,0.318125 C25.91125,0.285 25.66375,0.268125 25.418125,0.268125 C21.4690625,0.268125 17.558125,4.39 15.9075,10.291875 C14.9390625,13.754375 14.655,18.96 17.0415625,22.2371875 C18.3765625,24.0703125 20.325,25.0496875 22.8315625,25.1471875 C22.841875,25.1475 22.851875,25.1478125 22.8625,25.1478125 C25.9553125,25.1478125 28.6984375,23.0646875 29.5328125,20.0825 C30.030625,18.2996875 29.805,16.4296875 28.8971875,14.8165625 Z"
                                                  id="Path"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                            </div>
                            <div className="sr-cards-testimonial-slider-01-author">
                              <div className="author-meta">
                                <div className="author-details">
                                  <h6 className="author-name font-inter">Joe C.</h6>
                                  <small className="author-title font-inter">
                                    Rocking Porch Homes - Atlanta, GA
                                  </small>
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                        <div className="slickSliderItem text-left">
                          <div className="sr-cards-testimonial-slider-01-content equal-height">
                            
                            <div className="slide-content">
                              <div>
                                <svg
                                  width="30px"
                                  height="26px"
                                  viewBox="0 0 30 26"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                  
                                  <title className="font-inter">left-quote</title>
                                  <desc className="font-inter">Created with Sketch.</desc>
                                  <g
                                    id="Modules"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Phase-9-11-Modules"
                                      transform="translate(-980.000000, -2768.000000)"
                                    >
                                      <g
                                        id="SR-Cards-Testimonials-02"
                                        transform="translate(0.000000, 2630.000000)"
                                      >
                                        <g
                                          id="article"
                                          transform="translate(930.000000, 60.000000)"
                                        >
                                          <g
                                            id="Group"
                                            transform="translate(50.000000, 78.000000)"
                                          >
                                            <g id="Group-4">
                                              <g id="left-quote">
                                                <path
                                                  d="M9.535,11.5434375 C8.80125,11.3321875 8.0675,11.225 7.35375,11.225 C6.2515625,11.225 5.331875,11.476875 4.61875,11.7853125 C5.30625,9.2684375 6.9578125,4.925625 10.2478125,4.4365625 C10.5525,4.39125 10.8021875,4.1709375 10.8853125,3.874375 L11.604375,1.3025 C11.665,1.085 11.6290625,0.8521875 11.505,0.6634375 C11.3809375,0.4746875 11.1815625,0.34875 10.958125,0.318125 C10.7153125,0.285 10.4678125,0.268125 10.2225,0.268125 C6.2734375,0.268125 2.3625,4.39 0.7121875,10.291875 C-0.2565625,13.754375 -0.540625,18.96 1.845625,22.2365625 C3.1809375,24.07 5.1290625,25.0490625 7.6359375,25.146875 C7.64625,25.1471875 7.65625,25.1475 7.6665625,25.1475 C10.7596875,25.1475 13.5025,23.064375 14.336875,20.0821875 C14.8353125,18.299375 14.61,16.4296875 13.701875,14.81625 C12.8034375,13.2209375 11.32375,12.058125 9.535,11.5434375 Z"
                                                  id="Path"
                                                />
                                                <path
                                                  d="M28.8971875,14.8165625 C27.99875,13.2209375 26.5190625,12.058125 24.7303125,11.5434375 C23.9965625,11.3321875 23.2628125,11.225 22.549375,11.225 C21.4471875,11.225 20.5271875,11.476875 19.8140625,11.7853125 C20.5015625,9.2684375 22.153125,4.925625 25.4434375,4.4365625 C25.748125,4.39125 25.9975,4.1709375 26.0809375,3.874375 L26.8,1.3025 C26.860625,1.085 26.8246875,0.8521875 26.700625,0.6634375 C26.576875,0.4746875 26.3775,0.34875 26.15375,0.318125 C25.91125,0.285 25.66375,0.268125 25.418125,0.268125 C21.4690625,0.268125 17.558125,4.39 15.9075,10.291875 C14.9390625,13.754375 14.655,18.96 17.0415625,22.2371875 C18.3765625,24.0703125 20.325,25.0496875 22.8315625,25.1471875 C22.841875,25.1475 22.851875,25.1478125 22.8625,25.1478125 C25.9553125,25.1478125 28.6984375,23.0646875 29.5328125,20.0825 C30.030625,18.2996875 29.805,16.4296875 28.8971875,14.8165625 Z"
                                                  id="Path"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                            </div>
                            <div className="sr-cards-testimonial-slider-01-author">
                              <div className="author-meta">
                                <div className="author-details">
                                  <h6 className="author-name font-inter">Joe C.</h6>
                                  <small className="author-title font-inter">
                                    Rocking Porch Homes - Atlanta, GA
                                  </small>
                                </div>
                              </div>
                            </div>
                         
                          </div>
                        </div>
                        <div className="slickSliderItem text-left">
                          <div className="sr-cards-testimonial-slider-01-content equal-height">
                            
                            <div className="slide-content">
                              <div>
                                <svg
                                  width="30px"
                                  height="26px"
                                  viewBox="0 0 30 26"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                  
                                  <title>left-quote</title>
                                  <desc>Created with Sketch.</desc>
                                  <g
                                    id="Modules"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Phase-9-11-Modules"
                                      transform="translate(-980.000000, -2768.000000)"
                                    >
                                      <g
                                        id="SR-Cards-Testimonials-02"
                                        transform="translate(0.000000, 2630.000000)"
                                      >
                                        <g
                                          id="article"
                                          transform="translate(930.000000, 60.000000)"
                                        >
                                          <g
                                            id="Group"
                                            transform="translate(50.000000, 78.000000)"
                                          >
                                            <g id="Group-4">
                                              <g id="left-quote">
                                                <path
                                                  d="M9.535,11.5434375 C8.80125,11.3321875 8.0675,11.225 7.35375,11.225 C6.2515625,11.225 5.331875,11.476875 4.61875,11.7853125 C5.30625,9.2684375 6.9578125,4.925625 10.2478125,4.4365625 C10.5525,4.39125 10.8021875,4.1709375 10.8853125,3.874375 L11.604375,1.3025 C11.665,1.085 11.6290625,0.8521875 11.505,0.6634375 C11.3809375,0.4746875 11.1815625,0.34875 10.958125,0.318125 C10.7153125,0.285 10.4678125,0.268125 10.2225,0.268125 C6.2734375,0.268125 2.3625,4.39 0.7121875,10.291875 C-0.2565625,13.754375 -0.540625,18.96 1.845625,22.2365625 C3.1809375,24.07 5.1290625,25.0490625 7.6359375,25.146875 C7.64625,25.1471875 7.65625,25.1475 7.6665625,25.1475 C10.7596875,25.1475 13.5025,23.064375 14.336875,20.0821875 C14.8353125,18.299375 14.61,16.4296875 13.701875,14.81625 C12.8034375,13.2209375 11.32375,12.058125 9.535,11.5434375 Z"
                                                  id="Path"
                                                />
                                                <path
                                                  d="M28.8971875,14.8165625 C27.99875,13.2209375 26.5190625,12.058125 24.7303125,11.5434375 C23.9965625,11.3321875 23.2628125,11.225 22.549375,11.225 C21.4471875,11.225 20.5271875,11.476875 19.8140625,11.7853125 C20.5015625,9.2684375 22.153125,4.925625 25.4434375,4.4365625 C25.748125,4.39125 25.9975,4.1709375 26.0809375,3.874375 L26.8,1.3025 C26.860625,1.085 26.8246875,0.8521875 26.700625,0.6634375 C26.576875,0.4746875 26.3775,0.34875 26.15375,0.318125 C25.91125,0.285 25.66375,0.268125 25.418125,0.268125 C21.4690625,0.268125 17.558125,4.39 15.9075,10.291875 C14.9390625,13.754375 14.655,18.96 17.0415625,22.2371875 C18.3765625,24.0703125 20.325,25.0496875 22.8315625,25.1471875 C22.841875,25.1475 22.851875,25.1478125 22.8625,25.1478125 C25.9553125,25.1478125 28.6984375,23.0646875 29.5328125,20.0825 C30.030625,18.2996875 29.805,16.4296875 28.8971875,14.8165625 Z"
                                                  id="Path"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                            </div>
                            <div className="sr-cards-testimonial-slider-01-author">
                              <div className="author-meta">
                                <div className="author-details">
                                  <h6 className="author-name font-inter">Joe C.</h6>
                                  <small className="author-title font-inter">
                                    Rocking Porch Homes - Atlanta, GA
                                  </small>
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                        <div className="slickSliderItem text-left">
                          <div className="sr-cards-testimonial-slider-01-content equal-height">
                            
                            <div className="slide-content">
                              <div>
                                <svg
                                  width="30px"
                                  height="26px"
                                  viewBox="0 0 30 26"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                 
                                  <title>left-quote</title>
                                  <desc>Created with Sketch.</desc>
                                  <g
                                    id="Modules"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Phase-9-11-Modules"
                                      transform="translate(-980.000000, -2768.000000)"
                                    >
                                      <g
                                        id="SR-Cards-Testimonials-02"
                                        transform="translate(0.000000, 2630.000000)"
                                      >
                                        <g
                                          id="article"
                                          transform="translate(930.000000, 60.000000)"
                                        >
                                          <g
                                            id="Group"
                                            transform="translate(50.000000, 78.000000)"
                                          >
                                            <g id="Group-4">
                                              <g id="left-quote">
                                                <path
                                                  d="M9.535,11.5434375 C8.80125,11.3321875 8.0675,11.225 7.35375,11.225 C6.2515625,11.225 5.331875,11.476875 4.61875,11.7853125 C5.30625,9.2684375 6.9578125,4.925625 10.2478125,4.4365625 C10.5525,4.39125 10.8021875,4.1709375 10.8853125,3.874375 L11.604375,1.3025 C11.665,1.085 11.6290625,0.8521875 11.505,0.6634375 C11.3809375,0.4746875 11.1815625,0.34875 10.958125,0.318125 C10.7153125,0.285 10.4678125,0.268125 10.2225,0.268125 C6.2734375,0.268125 2.3625,4.39 0.7121875,10.291875 C-0.2565625,13.754375 -0.540625,18.96 1.845625,22.2365625 C3.1809375,24.07 5.1290625,25.0490625 7.6359375,25.146875 C7.64625,25.1471875 7.65625,25.1475 7.6665625,25.1475 C10.7596875,25.1475 13.5025,23.064375 14.336875,20.0821875 C14.8353125,18.299375 14.61,16.4296875 13.701875,14.81625 C12.8034375,13.2209375 11.32375,12.058125 9.535,11.5434375 Z"
                                                  id="Path"
                                                />
                                                <path
                                                  d="M28.8971875,14.8165625 C27.99875,13.2209375 26.5190625,12.058125 24.7303125,11.5434375 C23.9965625,11.3321875 23.2628125,11.225 22.549375,11.225 C21.4471875,11.225 20.5271875,11.476875 19.8140625,11.7853125 C20.5015625,9.2684375 22.153125,4.925625 25.4434375,4.4365625 C25.748125,4.39125 25.9975,4.1709375 26.0809375,3.874375 L26.8,1.3025 C26.860625,1.085 26.8246875,0.8521875 26.700625,0.6634375 C26.576875,0.4746875 26.3775,0.34875 26.15375,0.318125 C25.91125,0.285 25.66375,0.268125 25.418125,0.268125 C21.4690625,0.268125 17.558125,4.39 15.9075,10.291875 C14.9390625,13.754375 14.655,18.96 17.0415625,22.2371875 C18.3765625,24.0703125 20.325,25.0496875 22.8315625,25.1471875 C22.841875,25.1475 22.851875,25.1478125 22.8625,25.1478125 C25.9553125,25.1478125 28.6984375,23.0646875 29.5328125,20.0825 C30.030625,18.2996875 29.805,16.4296875 28.8971875,14.8165625 Z"
                                                  id="Path"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                            </div>
                            <div className="sr-cards-testimonial-slider-01-author">
                              <div className="author-meta">
                                <div className="author-details">
                                  <h6 className="author-name font-inter">Joe C.</h6>
                                  <small className="author-title font-inter">
                                    Rocking Porch Homes - Atlanta, GA
                                  </small>
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
                        <div className="slickSliderItem text-left">
                          <div className="sr-cards-testimonial-slider-01-content equal-height">
                            
                            <div className="slide-content">
                              <div>
                                <svg
                                  width="30px"
                                  height="26px"
                                  viewBox="0 0 30 26"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                >
                                 
                                  <title>left-quote</title>
                                  <desc>Created with Sketch.</desc>
                                  <g
                                    id="Modules"
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Phase-9-11-Modules"
                                      transform="translate(-980.000000, -2768.000000)"
                                    >
                                      <g
                                        id="SR-Cards-Testimonials-02"
                                        transform="translate(0.000000, 2630.000000)"
                                      >
                                        <g
                                          id="article"
                                          transform="translate(930.000000, 60.000000)"
                                        >
                                          <g
                                            id="Group"
                                            transform="translate(50.000000, 78.000000)"
                                          >
                                            <g id="Group-4">
                                              <g id="left-quote">
                                                <path
                                                  d="M9.535,11.5434375 C8.80125,11.3321875 8.0675,11.225 7.35375,11.225 C6.2515625,11.225 5.331875,11.476875 4.61875,11.7853125 C5.30625,9.2684375 6.9578125,4.925625 10.2478125,4.4365625 C10.5525,4.39125 10.8021875,4.1709375 10.8853125,3.874375 L11.604375,1.3025 C11.665,1.085 11.6290625,0.8521875 11.505,0.6634375 C11.3809375,0.4746875 11.1815625,0.34875 10.958125,0.318125 C10.7153125,0.285 10.4678125,0.268125 10.2225,0.268125 C6.2734375,0.268125 2.3625,4.39 0.7121875,10.291875 C-0.2565625,13.754375 -0.540625,18.96 1.845625,22.2365625 C3.1809375,24.07 5.1290625,25.0490625 7.6359375,25.146875 C7.64625,25.1471875 7.65625,25.1475 7.6665625,25.1475 C10.7596875,25.1475 13.5025,23.064375 14.336875,20.0821875 C14.8353125,18.299375 14.61,16.4296875 13.701875,14.81625 C12.8034375,13.2209375 11.32375,12.058125 9.535,11.5434375 Z"
                                                  id="Path"
                                                />
                                                <path
                                                  d="M28.8971875,14.8165625 C27.99875,13.2209375 26.5190625,12.058125 24.7303125,11.5434375 C23.9965625,11.3321875 23.2628125,11.225 22.549375,11.225 C21.4471875,11.225 20.5271875,11.476875 19.8140625,11.7853125 C20.5015625,9.2684375 22.153125,4.925625 25.4434375,4.4365625 C25.748125,4.39125 25.9975,4.1709375 26.0809375,3.874375 L26.8,1.3025 C26.860625,1.085 26.8246875,0.8521875 26.700625,0.6634375 C26.576875,0.4746875 26.3775,0.34875 26.15375,0.318125 C25.91125,0.285 25.66375,0.268125 25.418125,0.268125 C21.4690625,0.268125 17.558125,4.39 15.9075,10.291875 C14.9390625,13.754375 14.655,18.96 17.0415625,22.2371875 C18.3765625,24.0703125 20.325,25.0496875 22.8315625,25.1471875 C22.841875,25.1475 22.851875,25.1478125 22.8625,25.1478125 C25.9553125,25.1478125 28.6984375,23.0646875 29.5328125,20.0825 C30.030625,18.2996875 29.805,16.4296875 28.8971875,14.8165625 Z"
                                                  id="Path"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                              <p>
                                <span className="font-inter">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of type and scrambled it to make
                                  a type specimen book. It has survived not only
                                  five centuries, but also the leap into
                                  electronic typesetting, remaining essentially
                                  unchanged.
                                </span>
                              </p>
                            </div>
                            <div className="sr-cards-testimonial-slider-01-author">
                              <div className="author-meta">
                                <div className="author-details">
                                  <h6 className="author-name">Joe C.</h6>
                                  <small className="author-title font-inter">
                                    Rocking Porch Homes - Atlanta, GA
                                  </small>
                                </div>
                              </div>
                            </div>
                           
                          </div>
                        </div>
            

                    </Slider>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>*/}
          <div
        id="hs_cos_wrapper_widget_34472050333"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {status ? (
          ""
        ) : (
          //  7-day free trial component
          <FreeTrial />
        )}
      </div>
    </>
  );
};

export default HomePage;
