import { ErrorMessage, Field, Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import "./newpilotrecords.css";
import { useAppSelector } from "../../../../hook";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { getPilotStates } from "../../../../Features/downloadHistorySlice";
import { Loader } from "../../RepeatedComponent/Loader";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useAppDispatch } from "../../../../hook";
import Autocomplete from "@mui/material/Autocomplete";
import { Chip } from "@mui/material";
import { ameZipCode } from "../../../../Features/ameZipCodeSlice";
import { Export_Page } from "../../../../Features/pilotRecordSlice";
import { exportsRecords } from "../../../../Features/exportsRecordsSlice";
interface Option {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: Option[];
}

interface CheckboxState {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  airTrafficController: boolean;
  aircraftMechanics: boolean;
  parachuteRiggers: boolean;
  dropZoneManagement: boolean;
  jumpPilots: boolean;
  [key: string]: boolean;
}

interface Pilot {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  allClasses: boolean;
}

const animatedComponents = makeAnimated();

export const NewPilotRecords = () => {
  const states = useAppSelector((state: any) => state.downloadHistory.states);
  const [locations, setLocations] = useState("state");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [pilot_type, setPilotType] = useState({
    value: "S",
    label: "New Student Pilots",
  });
  const [days, setDays] = useState<any>({ value: "30 Days", label: "30 Days" });
  const [distance, setDistance] = useState<any>({});
  const [includeAircraft, setIncludeAircraft] = useState<any>(false);
  const [selectedCSV, setSelectedCSV] = useState("CSV");
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedPilotType, setSelectedPilotType] = useState<string>("S");
  const [registration, setRegistration] = useState("30 Days");
  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
  };
  const [loading, setLoading] = useState<any>(0);

  const handleOptionSelect = (option: Option | null) => {
    if (option) {
      setSelectedOptions([...selectedOptions, option]);
      setInputValue("");
    }
  };

  const geoLocation = [
    { value: "Al", label: "Alaskan" },
    { value: "Ce", label: "Central" },
    { value: "Ea", label: "Eastern" },
    { value: "Fo", label: "Foreign" },
    { value: "Gl", label: "Great Lakes" },
    { value: "Nm", label: "Northwest Mountain" },
    { value: "So", label: "Southern" },
    { value: "Sw", label: "Southwestern" },
    { value: "Wp", label: "Western-Pacific" },
  ];
  const pilotType = [
    { value: "S", label: "New Student Pilots" },
    { value: "P", label: "New Private Pilots" },
    { value: "New Instrument Pilots", label: "New Instrument Pilots" },
    { value: "C", label: "New Commercial Pilots" },
    { value: "A", label: "New ATP Pilots" },
    { value: "New Multi Engine Pilots", label: "New Multi Engine Pilots" },
    { value: "New Type Related Pilots", label: "New Type Related Pilots" },
    { value: "New Turbine Pilots", label: "New Turbine Pilots" },
  ];

  const registrationDays = [
    { value: "30 Days", label: "30 Days" },
    { value: "60 Days", label: "60 Days" },
    { value: "90 Days", label: "90 Days" },
    {
      value: "30",
      label: "select Date Range",
    },
  ];

  const formattedStates = states
    ? states
        .filter(
          (stateObj: { state: string; state_abbrev: string }) =>
            stateObj.state && stateObj.state_abbrev
        )
        .map((stateObj: { state: string; state_abbrev: string }) => ({
          value: stateObj.state,
          label: stateObj.state_abbrev,
        }))
    : [];

  const onChangeZipCode = (e: any) => {
    zipData.zipCode = e.target.value;
    const { value } = e.target;
    if (value?.length > 9) {
      e.target.value = value.slice(0, 9); // Limit to 9 characters
    }
    if (zipData.zipCode && zipData.distance) {
      dispatch(ameZipCode(zipData)).then((res: any) => {
        console.log("responce from zip code", res.payload);
      });
    }
  };

  const singleSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "black",
      borderColor: "black",
      margin: 0,
      marginLeft: 0,
      border: "0px solid black",
      fontSize: 13,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black",
      },
    }),
    input: (styles: any) => ({ ...styles, color: "white" }),
    placeholder: (styles: any) => ({
      ...styles,
      color: "white",
      fontSize: "14",
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      backgroundColor: "black",
      "&:hover": {
        backgroundColor: "black",
      },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: "white",
      fontSize: "1rem",
    }),
  };

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      background: "black",
      borderColor: "black",
      margin: 0,
      marginLeft: 0,
      borderWidth: "0px", // Changed 'border' to 'borderWidth'
      borderStyle: "solid", // Added 'borderStyle' to specify border style
      fontSize: 14,
      outline: "none !important",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black",
      },
      
    }),
    input: (styles: any) => ({ ...styles, color: "white" }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: "gray",
      fontSize: 14, // Removed quotes around 14
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: "#E35D22",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
      background: "black",
      "&:hover": {
        backgroundColor: "red",
      },
    }),
    menuList: (base: any) => ({
      ...base,
      padding: 0,
      backgroundColor: "black",
      "&:hover": {
        backgroundColor: "black",
      },
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: "white",
      textAlign: "left",
    }),
  };

  const [zipData, setZipData] = useState<any>({
    zipCode: 0,
    distance: 0,
    success: "",
  });

  const [zipResponse, setZipResponse] = useState("");

  const dispatch = useAppDispatch();

  const onPilottype = (e: any) => {
    // console.log(e.target.value, "pilot ype");
    if (e.target.value === "New Student Pilots") {
      setSelectedPilotType("S");
      // console.log("S");
    } else if (e.target.value === "New Private Pilots") {
      setSelectedPilotType("P");
      // console.log("P");
    } else if (e.target.value === "New Commertial Pilots") {
      setSelectedPilotType("C");
      // console.log("C");
    } else if (e.target.value === "New ATP Pilots") {
      // console.log("A");
      setSelectedPilotType("A");
    } else {
      // console.log("empty");
      setSelectedPilotType("");
    }
  };

  // useEffect(() => {
  //   console.log(zipData, "value of zip data");
  //   onChangeMarketingDistance();
  // }, [zipData]);
  const onChangeMarketingDistance = () => {
    // Assuming zipData is of type ZipData, specify its type or provide a type assertion
    if (zipData && zipData.zipCode && zipData.distance) {
      const payload: any = {
        zipCode: zipData.zipCode,
        distance: zipData.distance,
        success: "", // Ensure that the payload matches the expected shape of ZipCodeState
      };
      dispatch(ameZipCode(payload))
        .then((res: any) => {
          console.log(res, "response of zipdata");
          if (res.payload.length > 0) {
            // Handle the result of the async thunk action
            const zipCodes = res.payload.map((item: any) => item.ZipCode);
            const convertedString = zipCodes.join(", ");
            // console.log(convertedString, "zipcodes after");
            setZipResponse(convertedString);
          } else {
            toast.dismiss();
            toast.error("No Zip code found", {
              position: "top-center",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 8000,
              pauseOnFocusLoss: true,
            });
          }
          // setZipCode(zipCodes);
        })
        .catch((error: any) => {
          // Handle any errors that occur during the async operation
          console.error("Error: in zipdata api", error);
        });
    }
  };

  const handleDeleteOption = (optionToDelete: Option) => {
    setSelectedOptions(
      selectedOptions.filter((option) => option?.value !== optionToDelete.value)
    );
  };

  const [options, setOptions] = useState<Option[]>([]);
  const [location, setLocation] = useState("State");
  const status = useAppSelector((state: any) => state.auth.status);

  const defaultValue = {
    pilot_type: "S",
    days: "30 Days",
    region_or_state: [],
    region_type: "state", //state,
    zip_code: "",
    distance: "",
    include_aircraft: false,
    export_file: "CSV",
  };

  const handleSubmit = (values: any) => {
    const obj = {
      pilot_type: pilot_type.value,
      days: days.value,
      region_or_state: selectedOptions.map((o: any) => o.value),
      region_type: values?.region_type, //state,
      zip_code: [],
      distance: distance?.value,
      include_aircraft: includeAircraft,
      export_file: values.export_file,
    };
    const payload: any = {
      zipCode: values.zip_code,
      distance: distance?.value,
    };

    setLoading(1);
    dispatch(ameZipCode(payload))
      .then((res: any) => {
        if (res.payload.length > 0) {
          // Handle the result of the async thunk action
          const zipCodes = res.payload.map((item: any) => item.ZipCode);
          obj.zip_code = zipCodes;
        }
        dispatch(Export_Page(obj))
          .then((res: any) => {
            // if(Array.isArray(res.count) && res.count.length > 0) {
            toast.success(
              "Export request has been submitted successfully. Your file will download shortly.",
              {
                position: "top-center",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 8000,
                pauseOnFocusLoss: true,
              }
            );
            setLoading(0);
            setLocations("state");
            setSelectedOptions([]);
            setPilotType({ value: "S", label: "New Student Pilots" });
            setDays({ value: "30 Days", label: "30 Days" });
            setDistance({});
            setIncludeAircraft(false);
            setSelectedCSV("CSV");
            // }
          })
          .catch((err: any) => {
            setLoading(0);
            console.log(err, "API error. ");
          });
      })
      .catch((error: any) => {
        setLoading(0);
        // Handle any errors that occur during the async operation
        console.error("Error: in zipdata api", error);
      });
    // console.log("value on submit");
  };

  const marketingDistance = [
   
  ];

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
    });
  }

  // console.log(states, "states");

  const validationSchema = yup.object().shape({
    max_records: yup.string().trim().required("Please select maximum records."),
  });

  useEffect(() => {
    let a = {
      name: "",
      surname: "",
    };

    dispatch(getPilotStates());
  }, []);
  const handleRadioLoacation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocations(event.target.value);
  };
  const handleRadioCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCSV(event.target.value);
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <ToastContainer />
      <div
        id="hs_cos_wrapper_widget_1695156991887"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module "
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Aviation List and Label Services */}
        <div className="sr-multicol-media bg-black text-white sr-padding-full text-center prototype-no-background widget_1695156991887 ">
          <div className="container-fluid content-wrapper">
            <div className="row   sr-valign-center">
              <div></div>
              <div className="col-md-12 p-2 pb-4 pt-4 sr-col text-left  sr-repeater-2 custom-border-orange">
                <div className="content-wrapper newPilot">
                  <div className="container-fluid text-black bg-black p-0">
                    <div>
                      <Formik
                        initialValues={defaultValue}
                        onSubmit={handleSubmit}
                        // validationSchema={validationSchema}
                      >
                        <div className="col-12  px-4 py-3 text-left font-inter mb-3 bg-black text-black">
                          <Form>
                            <div className="row px-3 ">
                              {/*  */}

                              <div className="col-md-6 col-sm-12 p-0 px-3">
                                <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                  <div className="custom-form-sub-heading">
                                    Select Pilot Type
                                  </div>
                                  <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={pilotType}
                                    styles={singleSelectStyles}
                                    className="w-100"
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "#E35D22",
                                        primary: "black",
                                      },
                                    })}
                                    name="pilot_type"
                                    value={pilot_type}
                                    onChange={(e: any) => setPilotType(e)}
                                  />

                                  <div className="amePopup-label">
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      htmlFor="outputFormat"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12 pt-4 pt-md-0 px-3">
                                <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                  <div className="custom-form-sub-heading">
                                    Registration
                                  </div>
                                  <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    options={registrationDays}
                                    styles={singleSelectStyles}
                                    value={days}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        text: "white",
                                        primary25: "#E35D22",
                                        primary: "black",
                                      },
                                    })}
                                    className="w-100"
                                    name="days"
                                    onChange={(e: any) => setDays(e)}
                                  />

                                  <div className="amePopup-label">
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      htmlFor="outputFormat"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* Geo Location */}
                            {locations === "geo_location" ? (
                              <>
                                <div className="row p-3">
                                  <div className="col-12 col-md-12 p-0 px-3 newAircraft-filed-m">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        Geo Location
                                      </div>
                                      <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={geoLocation}
                                        styles={colourStyles}
                                        className="w-100"
                                        name="region_or_state"
                                        onChange={(e: any) =>
                                          setSelectedOptions(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {/* States */}
                            {locations === "state" ? (
                              <>
                                <div className="row p-3 newAircraft-filed-m mt-md-3">
                                  <div className="col-12 col-md-12 p-0 px-3">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        State
                                      </div>
                                      <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={formattedStates}
                                        placeholder="Leave blank for all"
                                        styles={colourStyles}
                                        className="w-100"
                                        name="region_or_state"
                                        onChange={(e: any) =>
                                          setSelectedOptions(e)
                                        }
                                      />

                                      <div className="amePopup-label">
                                        <label
                                          className="form-check-label pl-2 pl-md-0"
                                          htmlFor="outputFormat"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* City: zip code  & miles Distance: */}
                                <div className="row p-3">
                                  <div className="col-md-6 col-sm-12 p-0 px-3">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        AME Office Zip Code
                                      </div>
                                      <Field
                                        type="number"
                                        className="form-control bg-black text-white border-0 "
                                        name="zip_code"
                                        placeholder="e.g.12345"
                                        aria-label="Enter Your Email ID"
                                        aria-describedby="basic-addon1"
                                        autoComplete="on"
                                      />

                                      <div className="amePopup-label">
                                        <label
                                          className="form-check-label pl-2 pl-md-0"
                                          htmlFor="outputFormat"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-sm-12 pt-4 pt-md-0 px-3">
                                    <div className="col-12 d-flex  align-items-center custom-border-orange-field py-2">
                                      <div className="custom-form-sub-heading">
                                        Marketing Distance From Office
                                      </div>
                                      <Select
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        options={marketingDistance}
                                        styles={singleSelectStyles}
                                        className="w-100"
                                        placeholder="Please Select"
                                        theme={(theme) => ({
                                          ...theme,
                                          borderRadius: 0,
                                          colors: {
                                            ...theme.colors,
                                            text: "white",
                                            primary25: "#E35D22",
                                            primary: "black",
                                          },
                                        })}
                                        name="distance"
                                        onChange={(e: any) => setDistance(e)}
                                      
                                      />

                                      <div className="amePopup-label">
                                        <label
                                          className="form-check-label pl-2 pl-md-0"
                                          htmlFor="outputFormat"
                                        ></label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {/* Choose By:*/}
                            <div className="row p-3">
                              <div className="col-12 col-md-6  px-3 ">
                                <div className="col-12 d-flex justify-content-between custom-border-orange-field py-4 ">
                                  <div className="custom-form-sub-heading">
                                    Choose By:
                                  </div>
                                  <div className=" col-12  offmarket-output-format">
                                    <div className="amePopup-label d-flex align-items-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="region_type"
                                          id="geo_location"
                                          value="geo_location"
                                          checked={locations === "geo_location"}
                                          onClick={handleRadioLoacation}
                                        />
                                      </div>

                                      <label
                                        className="form-check-label pl-2 pl-md-0 "
                                        htmlFor="geo_location"
                                      >
                                        Geo Location
                                      </label>
                                    </div>

                                    <div className=" amePopup-label d-flex align-items-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="region_type"
                                          id="state"
                                          value="state"
                                          checked={locations === "state"}
                                          onClick={handleRadioLoacation}
                                        />
                                      </div>

                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="state"
                                      >
                                        State
                                      </label>
                                    </div>
                                  </div>

                                  <div className="amePopup-label">
                                    <label
                                      className="form-check-label pl-2 pl-md-0"
                                      htmlFor="outputFormat"
                                    ></label>
                                  </div>
                                </div>
                              </div>
                              {/* <p className="text-danger field-errorMessage">
                                <ErrorMessage name="chooseBy" />
                              </p> */}

                              <div className="col-12 col-md-6 d-flex  align-items-center px-3  pl-3 newAircraft-filed-m pt-3 pt-md-0">
                                <div className="col-12 custom-border-orange-field py-4 pl-4 ">
                                  <div className="custom-form-sub-heading">
                                    Include Airman
                                  </div>
                                  <div className="form-check amePopup-label col-12 pt-2 pt-md-0  d-flex align-items-center">
                                    <div>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="include_aircraft"
                                        id="includeAirman"
                                        value={includeAircraft}
                                        // checked={checkboxes.includeAirman}
                                        onChange={(e: any) =>
                                          setIncludeAircraft(e.target.checked)
                                        }
                                      />
                                    </div>

                                    <label
                                      className="form-check-label pl-2 pl-md-2"
                                      htmlFor="includeAirman"
                                    >
                                      Search and Include Airman and Pilot
                                      information
                                    </label>
                                  </div>
                                </div>

                                <div className="amePopup-label">
                                  <label
                                    className="form-check-label pl-2 pl-md-0"
                                    htmlFor="outputFormat"
                                  ></label>
                                </div>
                              </div>
                            </div>
                            {/* Output Format */}
                            <div className="row p-3">
                              <div className="col-12 col-md-12  px-3 ">
                                <div className="col-12 d-flex  custom-border-orange-field py-4 ">
                                  <div className="custom-form-sub-heading">
                                    Output Format
                                  </div>
                                  <div className="col-12 col-md-12 offmarket-output-format">
                                    <div className=" amePopup-label d-flex align-items-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="export_file"
                                          id="CSV"
                                          value="CSV"
                                          checked={selectedCSV === "CSV"}
                                          onClick={handleRadioCSV}
                                        />
                                      </div>

                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="CSV"
                                      >
                                        CSV
                                      </label>
                                    </div>
                                    <div className=" amePopup-label d-flex align-items-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="export_file"
                                          id="RTF"
                                          value="RTF"
                                          checked={selectedCSV === "RTF"}
                                          onClick={handleRadioCSV}
                                        />
                                      </div>

                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="RTF"
                                      >
                                        RTF
                                      </label>
                                    </div>

                                    <div className="amePopup-label d-flex align-iterm-center">
                                      <div>
                                        <Field
                                          className="form-check-input"
                                          type="radio"
                                          name="export_file"
                                          id="avery5163LabelPDF"
                                          value="avery5163LabelPDF"
                                          checked={
                                            selectedCSV === "avery5163LabelPDF"
                                          }
                                          onClick={handleRadioCSV}
                                        />
                                      </div>

                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="avery5163LabelPDF"
                                      >
                                        Avery 5163 Label PDF
                                      </label>
                                    </div>

                                    <div className="amePopup-label d-flex align-iterm-center ">
                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="outputFormat"
                                      ></label>
                                    </div>
                                    <div className="amePopup-label d-flex align-iterm-center ">
                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="outputFormat"
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <p className="text-danger field-errorMessage">
                                <ErrorMessage name="export_file" />
                              </p> */}
                            </div>

                            <div className="text-center pt-2 ">
                              <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                                {/* <Link to="/your-downloads-history"> */}
                                <button
                                  type="submit"
                                  // onClick={(e:any) => handleSubmit(e)}
                                  className="exports-Records-btn custom-freeTrial-btn"
                                  // data-dismiss="modal"
                                >
                                  Export Records
                                </button>
                                {/* </Link> */}
                              </div>
                            </div>
                          </Form>
                        </div>
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
