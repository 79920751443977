import { Link } from "react-router-dom";
import { Feedback } from "../FooterLinks/Feedback";

// import "";

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };


  return (
    <>
   <Feedback/>
      <div
        id="hs_cos_wrapper_widget_34472050335"
        className=" hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_custom_widget backgroundImg"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="custom_widget"
      >
        {/* Label For Modules Data Page */}
        <footer
          className="sr-footer sr-footer-01  sr-padding-full footer-content text-light widget_34472050335"
          id="footer"
        >
          <div className="sr-footer-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="row sr-spacer-bottom-50">
                    <div className="col-md-3 d-flex align-items-center">
                      <div>
                      <p className="pb-4 d-flex justify-content-center">
                      <img src="images/logo.svg" style={{}} alt="PlaneList" />
                        {/* <br />
                        <strong>PlaneLists</strong>
                        <br />
                        12345, Address Line 1
                        <br />
                        Address Line 3,
                        <br />
                        Address Line 2,
                        <br />
                        12345 */}
                      </p>
                      <div className="pb-3 fsinc">
                        PlaneLists.com is The Most Trusted All-in-one Solution That
                        Provides Comprehensive Aviation Data Nationwide
                      </div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <span
                        id="hs_cos_wrapper_widget_34472050335_"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu"
                        style={{}}
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="menu"
                      >
                        <div
                          id="hs_menu_wrapper_widget_34472050335_"
                          className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                          role="navigation"
                          data-sitemap-name="default"
                          data-menu-id={34777947028}
                          aria-label="Navigation Menu"
                        >
                          <ul role="menu">
                            <li
                              className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                              role="none"
                            >
                              <a
                                href="#"
                                aria-haspopup="true"
                                aria-expanded="false"
                                role="menuitem"
                              >
                                Links
                              </a>
                              <ul
                                role="menu"
                                className="hs-menu-children-wrapper"
                              >
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option one
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option second
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option Third
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option Fourth
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option Fifth
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option sixth
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option seventh
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option eight
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                              role="none"
                            >
                              <a
                                href="#"
                                aria-haspopup="true"
                                aria-expanded="false"
                                role="menuitem"
                              >
                                PlaneLists.com Features
                              </a>
                              <ul
                                role="menu"
                                className="hs-menu-children-wrapper"
                              >
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                              role="none"
                            >
                              <a
                                href="#"
                                aria-haspopup="true"
                                aria-expanded="false"
                                role="menuitem"
                              >
                                Lorem Ipsum
                              </a>
                              <ul
                                role="menu"
                                className="hs-menu-children-wrapper"
                              >
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                              role="none"
                            >
                              <a
                                href="#"
                                aria-haspopup="true"
                                aria-expanded="false"
                                role="menuitem"
                              >
                                Lorem Ipsum
                              </a>
                              <ul
                                role="menu"
                                className="hs-menu-children-wrapper"
                              >
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option One
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option second
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option Fifth
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option seventh
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Option eight
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li
                              className="hs-menu-item hs-menu-depth-1 hs-item-has-children"
                              role="none"
                            >
                              {/* <a
                                href="#"
                                aria-haspopup="true"
                                aria-expanded="false"
                                role="menuitem"
                              >
                                Lorem Ipsum
                              </a> */}
                              {/* <ul
                                role="menu"
                                className="hs-menu-children-wrapper"
                              >
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum Is Dummy Text
                                    <br />
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                                <li
                                  className="hs-menu-item hs-menu-depth-2"
                                  role="none"
                                >
                                  <a href="#" role="menuitem">
                                    Lorem Ipsum
                                  </a>
                                </li>
                              </ul> */}
                            </li>
                          </ul>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright container-fluid container-fluid">
            <div className="row no-gutters footer-on-big">
              <div className="col-md-8 offset-md-1">
                <div className="text-light ">
                  <div className="small">
                    
                    <p>
                      Copyright 2024 PlaneLists.com - All Rights Reserved
                      <span>|</span>{" "}
                      <Link  to="/" onClick={scrollToTop}>
                        Home
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link  to="/about" onClick={scrollToTop}>
                        About
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link  to="/contact" onClick={scrollToTop}>
                        Contact
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link  to="/privacy-policy" onClick={scrollToTop}>
                        Privacy Policy
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link
                         to="/terms" onClick={scrollToTop}
                        // data-toggle="modal"
                        // data-target="#exampleModalCenter"
                      >
                        Terms of Service
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 text-md-right socialFooter">
                <div className="d-flex justify-content-end  pt-1 pb-1">
                  <div className="for_icons mx-1 px-1">
                    <a href="#" target="_blank">
                      <i className="fab fa-linkedin" />
                    </a>
                  </div>
                  <div className="for_icons mx-1 px-1">
                    <a href="#" target="_blank">
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                  <div className="for_icons mx-1 px-1">
                    <a href="#" target="_blank">
                      <i className="fab fa-youtube" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row no-gutters footer-on-small">
              <div className="col-md-10 offset-md-1">
                <div className="text-light ">
                  <div className="small">
                    <p>
                    <div className="d-flex justify-content-center">
                    {/* <span>|</span>{" "} */}
                    <Link to="/" className="fd-big" onClick={scrollToTop}>    Copyright 2024 PlaneLists.com - All Rights Reserved  </Link>
                      {/* <span>|</span>{" "} */}
                      </div>
                      <div  className="d-flex justify-content-center my-2">
                      <Link className="fd-big" to="/" onClick={scrollToTop}>
                        Home
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link className="fd-big" to="/about" onClick={scrollToTop}>
                        About
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link className="fd-big" to="/contact" onClick={scrollToTop}>
                        Contact
                      </Link>{" "}
                      <span>|</span>{" "}
                      <Link className="fd-big" to="/privacy-policy" onClick={scrollToTop}>
                        Privacy Policy
                      </Link>{" "}
                      <span>|</span>{" "}
                     
                      <Link
                        className="fd-big" to="/terms" onClick={scrollToTop}
                      >
                     Terms of Service
                      </Link>{" "}
                     
                     
                      </div>
                      <div className="d-flex justify-content-center  pt-1 pb-1">
                  <div className="for_icons mx-1 px-1  fa-size">
                    <a href="#" target="_blank" >
                      <i className="fab fa-linkedin" />
                    </a>
                  </div>
                  <div className="for_icons mx-1 px-1 fa-size">
                    <a href="#" target="_blank">
                      <i className="fab fa-instagram " />
                    </a>
                  </div>
                  <div className="for_icons mx-1 px-1 fa-size">
                    <a href="#" target="_blank">
                      <i className="fab fa-youtube " />
                    </a>
                  </div>
                </div>
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-2 text-md-right socialFooter">
               
              </div> */}
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
