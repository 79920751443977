import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useRef, useState } from "react";
import "./ameHealthPopup.css";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { Loader } from "./Loader";
import { Field, Formik, Form, useFormikContext, ErrorMessage } from "formik";
import { exportsRecords, exportsRecordsCertificate } from "../../../Features/exportsRecordsSlice";
import { fetchUser } from "../../../Features/fetchUserSlice";
import { ameZipCode } from "../../../Features/ameZipCodeSlice";
import { toast } from "react-toastify";
import { currentPlan, plans } from "../../../Features/PlansSlice";

interface CheckboxState {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  airTrafficController: boolean;
  aircraftMechanics: boolean;
  parachuteRiggers: boolean;
  dropZoneManagement: boolean;
  jumpPilots: boolean;
  [key: string]: boolean;
}

interface Pilot {
  firstClass: boolean;
  secondClass: boolean;
  thirdClass: boolean;
  allClasses: boolean;
}

export const AMEHealthPopup = () => {
  const closeRef:any = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const { values } = useFormikContext();
  const [zip_code, setZipCode] = useState([""]);
  const [loading, setLoading] = useState(0);
  const [selectedTab, setSelectedTab] = useState("pilot");
  const [selectedCSV, setSelectedCSV] = useState("CSV");
  const [selectedOneMonth, setselectedOneMonth] = useState("30Days");
  const [subscriptionMonth, setSubscriptionMonth] = useState<any>(0);
  const activeSubscription = useAppSelector((state: any) => state.plans.message);


  // console.log(activeSubscription?.subscriptions_history
  // , "activePlan");

  var intervalId: any = "";
  const taskStatus = useAppSelector(
    (state: any) => state.exportsRecords.taskStatus
  );
  const loggedin_user = useAppSelector((state: any) => state.user.user);
  // const { task_log_id} = taskStatus;

  // const status = useAppSelector((state: any) => state.auth.status);

  const allPlans: any = useAppSelector(
    (state: any) => state.plans.subscriptions
  );

  const activePlan: any = useAppSelector((state: any) => state.plans.message);

  let zipData: any = {
    zipCode: "",
    distance: "",
  };

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, []);

  const [checkboxes, setCheckboxes] = useState<Pilot>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
  });

  const [allRadioCheck, setAllRadioCheck] = useState<CheckboxState>({
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    airTrafficController: false,
    aircraftMechanics: false,
    parachuteRiggers: false,
    dropZoneManagement: false,
    jumpPilots: false,
    // Add more checkboxes as needed
  });

  // defaultValue form values
  const defaultValue: CheckboxState = {
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    airTrafficController: false,
    aircraftMechanics: false,
    parachuteRiggers: false,
    dropZoneManagement: false,
    jumpPilots: false,
  };

  const initialValues: Pilot = {
    ...defaultValue,
    firstClass: false,
    secondClass: false,
    thirdClass: false,
    allClasses: false,
  };

  const onChangeZipCode = (e: any) => {
    zipData.zipCode = e.target.value;
    const { value } = e.target;
    if (value?.length > 9) {
      e.target.value = value.slice(0, 9); // Limit to 9 characters
    }
    if (zipData.zipCode && zipData.distance) {
      dispatch(ameZipCode(zipData)).then((res: any) => {
        console.log("responce from zip code", res.payload);
      });
    }
  };
  const onChangeMarketingDistance = (e: any) => {
    const distance = e.target.value;
    if (zipData.zipCode && distance) {
      zipData.distance = distance;
      dispatch(ameZipCode(zipData)).then((res: any) => {
        const zipCodes = res.payload.map((item: any) => item.ZipCode);
        setZipCode(zipCodes);
      });
    }
  };
  const initialValues1 = {
    airman: "pilot",
    airman_class: [],
    expire: "",
    export_file: "",
    ameOfficeZipCode: "",
    marketingDistance: "",
    nonPilotDesignation: [],
    airman_classAll: [
      "Air Traffic Controller",
      "Aircraft Mechanics",
      "Parachute Riggers & Packers",
      "Drop Zone Management",
      "Jump Pilots",
      "1st",
      "2nd",
      "3rd",
    ],
  };

  // const zipCodeState: ZipCodeState = {
  //   success: "",
  //   firstzipcode: zipData.zipCode,
  //   radiuscoverage: zipData.distance,
  // };

  // useEffect(() => {
  //   dispatch(ameZipCode(zipData));
  // }, []);

  // Validation schema using yup
  const validationSchema = yup.object().shape({
    // // zip_code: [],
    // // airman: "pilot",
    // // airman_class: [],
    // expire: yup.string().required("Please check some option!"),
    // export_file: yup.string().required("Please check some option!"),
    // ameOfficeZipCode: yup
    //   .string()
    //   .required("Please enter zip code")
    //   .matches(/^\d{5}$/, "Zip code must be exactly 5 digits"),
    // marketingDistance: yup.string().required("Please select some option!"),
    // marketingDistance: yup.string().required("Please select an option"),
    // // nonPilotDesignation:[],
    // // airman_classAll: [
    // //   "Air Traffic Controller",
    // //   "Aircraft Mechanics",
    // //   "Parachute Riggers & Packers",
    // //   "Drop Zone Management",
    // //   "Jump Pilots",
    // //   "1st",
    // //   "2nd",
    // //   "3rd",
    // // ],
  });

  useEffect(() => {
    setLoading(1);
    dispatch(plans()).then((plans: any) => {
      if (
        plans?.payload?.success &&
        Array.isArray(plans.payload.subscriptions) &&
        plans.payload.subscriptions.length
      ) {
        setSubscriptionMonth(plans.payload.subscriptions[1]);
      }
      dispatch(currentPlan()).then((currentPlan: any) => {
        setLoading(0);
        if (
          plans?.payload?.success &&
          Array.isArray(plans.payload.subscriptions) &&
          plans.payload.subscriptions.length
        ) {
          plans.payload.subscriptions.forEach((plan: any) => {
            if (
              plan.id ===
              currentPlan.payload?.current_subscription?.subscription
                ?.subscription_plan_id
            ) {
              setSubscriptionMonth(plan);
            }
          });
        }
      });
    });
  }, []);
  function getClassList(classes:any) {
  
    const classList = [];
    if (classes.firstClass) classList.push("1st");
    if (classes.secondClass) classList.push("2nd");
    if (classes.thirdClass) classList.push("3rd");
    return classList;

}
  /// Handle form submission
  const handleSubmitSetData = (values: any) => {
    setLoading(1);
    console.log({ ...values, zip_code }, "value");
    let airmenclass =getClassList(checkboxes);
    // console.log(airmenclass,"ppppppppp")
  let valued={
    "region_type": selectedTab,
      "last_name": "",
      "last_name_check": "sub_string",
      "first_name": "",
      "first_name_check": "sub_string",
      "address": "",
      "address_check": "sub_string",
      "city_name": "",
      "city_name_check": "sub_string",
      "zip_code":values.zip_code,
      "airman":values.airman,
      "airman_class":values.airman_class,
      "expire":selectedOneMonth ,
      "sort_type": "first_middle_name",
      "sort_type_order": "asc",
      "export_file":selectedCSV,
      "task_type": "pilot convert to json"
    }
 
    values=valued;
    dispatch(exportsRecordsCertificate({ ...values, zip_code })).then((res: any) => {
      setLoading(0);
      
      if (res.payload && res.payload.task_log_id) {
        // navigate('download-history')
        toast.success("Export request has been submitted successfully. Your file will download shortly.", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 8000,
          pauseOnFocusLoss: true
        });
        setLoading(0)
        closeRef.current.click();
      } else {
        setLoading(0);
        toast.error(
          "You don't have any active Subscription to access this page.",
          {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 8000,
            pauseOnFocusLoss: true,
          }
          );
          closeRef.current.click();
      }
    });
  };

  const marketingDistance = [
    {
      value: "selected",
      label: "Please select",
    },
  ];

  // Generating miles from 10 to 200
  for (let miles = 10; miles <= 200; miles += 10) {
    marketingDistance.push({
      value: miles.toString(),
      label: `${miles} Miles`,
    });
  }
  // console.log(marketingDistance);

  const checkedAllClass = (event: any) => {
    const { name, checked } = event.target;

    // Update individual checkbox state
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    // If "All Class" checkbox is checked, update all other checkboxes accordingly
    if (name === "allClasses") {
      setCheckboxes({
        firstClass: checked,
        secondClass: checked,
        thirdClass: checked,
        allClasses: checked,
      });
    } else {
      // If any other checkbox is unchecked, uncheck "All Class" checkbox
      if (!checked) {
        setCheckboxes((prevState) => ({
          ...prevState,
          allClasses: false,
        }));
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTab(event.target.value);
    const { value } = event.target;

    if (value === "all") {
      console.log("all working")
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = true;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    } else {
      const updatedCheckboxes: CheckboxState = { ...allRadioCheck };
      for (const key in updatedCheckboxes) {
        if (Object.prototype.hasOwnProperty.call(updatedCheckboxes, key)) {
          updatedCheckboxes[key] = false;
        }
      }
      setAllRadioCheck(updatedCheckboxes);
    }
  };

  const handleRadioCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCSV(event.target.value);
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setselectedOneMonth(event.target.value);
  };


  // console.log(selectedTab, "selected Tab");

  const naviii = () => {
    navigate("/your-downloads-history");
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      {/* Popup */}
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema}
        onSubmit={handleSubmitSetData}
      >
        {() => (
          <div
            className="modal fade bd-example-modal-lg"
            role="dialog"
            id="ameHealthModal"
            aria-labelledby="ameHealthModal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg ">
              <div className="modal-content" style={{ borderRadius: "1rem" }}>
                <div className="modal-header modal-order-summary-header">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-8 text-left">
                        <h5
                          className="modal-title  modal-order-summary-header-text "
                          id="exampleModalLabel"
                        >
                          AME Health Certificate List
                        </h5>
                      </div>
                    </div>
                  </div>
                  <img
                    src="images/modal-img/cross-img.svg"
                    className="close custom-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref={closeRef}
                  ></img>
                </div>
                <div className="modal-body ">
                  <div className="container-fluid">
                    <div className="row text-black">
                      {/* content*/}
                      <div className="col-md-12 col-sm-12 ">
                        <div className="col-12  px-4 py-3 text-left font-inter mb-3">
                          <Form>
                            
                            <div className="row">
                              <div className="col-md-6 col-sm-12 p-0 pl-lg-0">
                                <Box
                                  component="form"
                                  sx={{
                                    "& .MuiTextField-root": {
                                      mt: 2,
                                      width: "25ch",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <Field name="ameOfficeZipCode">
                                    {({ field }: { field: any }) => (
                                      <TextField
                                        {...field}
                                        color="warning"
                                        // required
                                        id="outlined-number"
                                        type="number"
                                        name="ameOfficeZipCode"
                                        className="w-100 text-black text-alignment orange-autocomplete"
                                        label="AME Office Zip Code"
                                        value={field.ameOfficeZipCode}
                                        placeholder="e.g.12345"
                                        onChange={onChangeZipCode}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { color: "#E35D22",fontWeight: "bold" },
                                        }}
                                        InputProps={{
                                          style: { color: "black" },
                                          inputProps: { maxLength: 9,minLength:5 },
                                        }}
                                        sx={{
                                          "& input::placeholder": {
                                            color: "black",
                                          }, // Style for the placeholder text color
                                        }}
                                      />
                                    )}
                                  </Field>
                                </Box>
                              </div>
                              <div className="col-md-6 col-sm-12 p-0 pl-md-2 pl-lg-3">
                                <Box
                                  component="form"
                                  sx={{
                                    "& .MuiTextField-root": {
                                      mt: 2,
                                      width: "25ch",
                                    },
                                  }}
                                  noValidate
                                  autoComplete="off"
                                >
                                  <Field name="marketingDistance">
                                    {({ field }: { field: any }) => (
                                      <TextField
                                        {...field}
                                        color="warning"
                                        id="outlined-select-currency"
                                        select
                                        label="Marketing Distance From Office "
                                        className="w-100 text-alignment orange-autocomplete"
                                        defaultValue="selected"
                                        value={field.marketingDistance}
                                        onChange={onChangeMarketingDistance}
                                        InputLabelProps={{
                                          shrink: true,
                                          style: { color: "#E35D22",fontWeight: "bold" }, // Color of the label
                                        }}
                                        InputProps={{
                                          placeholder:
                                            "Marketing Distance From Office", // Set placeholder text
                                          style: { outlineColor: "#E35D22" },
                                        }}
                                        sx={{
                                          "& input::placeholder": {
                                            color: "black  ",
                                          }, // Style for the placeholder text color
                                        }}
                                      >
                                        {marketingDistance.map((option) => (
                                          <MenuItem
                                            disabled={
                                              option.label === "Please select"
                                                ? true
                                                : false
                                            }
                                            key={option.value}
                                            value={option.value}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  </Field>
                                </Box>
                                {/* <p className="text-danger field-errorMessage ">
                                    <ErrorMessage name="marketingDistance" />
                                  </p> */}
                              </div>
                            </div>

                            {/* Airmen Type */}
                            <div className="row pt-4">
                              <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
                                <div className="amePopup-sub-heading">
                                  Airmen Type
                                </div>
                                <div className="form-check amePopup-label d-flex align-items-center">
                                 <div> <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="airman"
                                    id="pilot"
                                    value="pilot"
                                    checked={selectedTab === "pilot"}
                                    onClick={handleRadioChange}
                                  /></div>
                                  <label
                                    className="form-check-label pl-2 pl-md-0"
                                    htmlFor="pilot"
                                  >
                                    Pilot
                                  </label>
                                </div>
                                <div className="form-check amePopup-label  d-flex align-items-center">
                                 <div> <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="airman"
                                    id="nonPilot"
                                    value="non_pilot"
                                    checked={selectedTab === "non_pilot"}
                                    onClick={handleRadioChange}
                                  /></div>
                                  <label
                                    className="form-check-label pl-2 pl-md-0 text-nowrap"
                                    htmlFor="nonPilot"
                                  >
                                    Non-Pilot
                                  </label>
                                </div>
                                <div className="form-check amePopup-label  d-flex align-items-center">
                                  <div><Field
                                    className="form-check-input"
                                    type="radio"
                                    name="airman"
                                    id="airman"
                                    value="all"
                                    checked={selectedTab === "all"}
                                    onClick={handleRadioChange}
                                  /></div>
                                  <label
                                    className="form-check-label pl-2 pl-md-0"
                                    htmlFor="airman"
                                  >
                                    All
                                  </label>
                                </div>
                                <div className="form-check amePopup-label">
                                 
                                </div>
                              </div>
                            </div>

                          {/* Airmen Medical Class */}
                            {/* {selectedTab === "pilot" ? ( */}
                              <div
                                className="row mx-0 mx-sm-3 popUp-tab-transition"
                                id="airmenMedical"
                              >
                                <div className="col-12  ame-sub-boxShadow">
                                  <div className="col-12 pt-2 sub-heading">
                                    Airmen Medical Class
                                  </div>

                                  <div className="d-flex justify-content-center align-items-center  flex-column pt-2 pb-3">
                                    <div className="col-11 col-md-12 col-lg-11 d-flex flex-column flex-md-row ">
                                      <div className="form-check amePopup-label col-12 col-md-3  pt-2 pt-md-0 d-flex align-items-center">
                                      <div>  <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="airman_class"
                                          id="firstClassCheckbox"
                                          value="1st"
                                          // checked={checkboxes.firstClass}
                                          // onChange={checkedAllClass}
                                        />
                                        </div>
                                        <label
                                          className="form-check-label pl-2 "
                                          htmlFor="firstClass"
                                        >
                                          1st Class
                                        </label>
                                      </div>
                                      <div className="form-check amePopup-label col-12 col-md-3  pt-2 pt-md-0  d-flex align-items-center">
                                      <div>  <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="airman_class"
                                          id="secondClassCheckbox"
                                          value="2nd"
                                          // checked={checkboxes.secondClass}
                                          // onChange={checkedAllClass}
                                        /></div>
                                        <label
                                          className="form-check-label pl-2 "
                                          htmlFor="secondClass"
                                        >
                                          2nd Class
                                        </label>
                                      </div>

                                      <div className="form-check amePopup-label col-12 col-md-3  pt-2 pt-md-0  d-flex align-items-center">
                                      <div><Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="airman_class"
                                          id="thirdClassCheckbox"
                                          value="3rd"
                                          // checked={checkboxes.thirdClass}
                                          // onChange={checkedAllClass}
                                        /> </div>
                                        <label
                                          className="form-check-label pl-2 "
                                          htmlFor="thirdClass"
                                        >
                                          3rd Class
                                        </label>
                                      </div>
                                      <div className="form-check amePopup-label col-12 col-md-3  pt-2 pt-md-0  d-flex align-items-center">
                                      <div> <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="allClasses"
                                          id="allClassesCheckbox"
                                          value="all"
                                          // checked={checkboxes.allClasses}
                                          // onChange={checkedAllClass}
                                        /> </div>
                                        <label
                                          className="form-check-label pl-2   d-flex align-items-center"
                                          htmlFor="allClasses"
                                        >
                                          All Class
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                           
                           
                          {/*   ) : (
                               ""
                             )} */}
                            <div
                              className={
                                selectedTab === "nonPilot"
                                  ? "tab-transition"
                                  : ""
                              }
                            >
                              {/* Non-Pilot Designation */}
                              {/* {selectedTab === "nonPilot" ? (
                                <div className="row mx-0 mx-sm-3 ">
                                  <div className="col-12  ame-sub-boxShadow">
                                    <div className="col-12 pt-2 sub-heading">
                                      Non-Pilot Designation
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center  flex-column pt-1 pb-2">
                                      <div className="col-11 col-md-12 col-lg-11 d-flex flex-column flex-md-row ">
                                        <div className="form-check amePopup-label col-12 col-md-6  pt-2 pt-md-0 d-flex align-items-center">
                                          <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="nonPilotDesignation"
                                            id="airTrafficController"
                                            value="Air Traffic Controller"
                                          />
                                          </div>
                                        
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="airTrafficController"
                                          >
                                            Air Traffic Controller
                                          </label>
                                        </div>
                                        <div className="form-check amePopup-label col-12 col-md-6  pt-2 pt-md-0  d-flex align-items-center">
                                         <div>
                                         <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="nonPilotDesignation"
                                            id="aircraftMechanics"
                                            value="Aircraft Mechanics"
                                          />
                                         </div>

                                       
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="aircraftMechanics "
                                          >
                                            Aircraft Mechanics
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-11 col-md-12 col-lg-11 d-flex flex-column flex-md-row ">
                                        <div className="form-check amePopup-label col-12 col-md-6  pt-2 pt-md-0  d-flex align-items-center">
                                          <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="nonPilotDesignation"
                                            id="parachuteRiggers"
                                            value="Parachute Riggers & Packers"
                                          />
                                          </div>
                                         
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="parachuteRiggers"
                                          >
                                            Parachute Riggers & Packers
                                          </label>
                                        </div>
                                        <div className="form-check amePopup-label col-12 col-md-6  pt-2 pt-md-0  d-flex align-items-center">
                                          <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="nonPilotDesignation"
                                            id="dropZoneManagement"
                                            vlaue="Drop Zone Management"
                                          />
                                          </div>
                                       
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="dropZoneManagement"
                                          >
                                            Drop Zone Management
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-11 col-md-12 col-lg-11 d-flex flex-column flex-md-row">
                                        <div className="form-check amePopup-label col-12 col-md-6  pt-2 pt-md-0  d-flex align-items-center">
                                          <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="nonPilotDesignation"
                                            id="jumpPilots"
                                            value="Jump Pilots"
                                          />
                                          </div>
                                         
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="jumpPilots"
                                          >
                                            Jump Pilots
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )} */}
                            </div>
                            {/* {selectedTab === "all" ? (
                             
                              <div className="row mx-0 mx-sm-3">
                                <div className="col-12 d-flex flex-lg-row flex-column ame-sub-boxShadow ">
                                  <div className="col col-lg-6">
                                    <div className="col-12 pt-2 sub-heading">
                                      Airmen Medical Class
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center pl-1 flex-column pt-1 pb-2">
                                      <div className="col-11 col-md-12 d-flex flex-column p-0 pl-md-3">
                                        <div className="form-check amePopup-label col-12  pt-2 pt-md-1 d-flex align-items-center">
                                         <div>
                                         <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="airman_classAll"
                                            id="firstClass"
                                            value="1st"
                                            // checked={allRadioCheck.firstClass}
                                            // onChange={handleCheckboxChange}
                                          />
                                         </div>
                                        
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="firstClass"
                                          >
                                            1st Class
                                          </label>
                                        </div>
                                        <div className="form-check amePopup-label col-12  pt-2 pt-md-1 d-flex align-items-center">
                                         <div>
                                         <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="airman_classAll"
                                            id="secondClass"
                                            value="2nd"
                                            // checked={allRadioCheck.secondClass}
                                            // onChange={handleCheckboxChange}
                                          />
                                          </div> 
                                       
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="secondClass"
                                          >
                                            2nd Class
                                          </label>
                                        </div>

                                        <div className="form-check amePopup-label col-12  pt-2 pt-md-1 d-flex align-items-center">
                                          <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="airman_classAll"
                                            id="thirdClass"
                                            value="3rd"
                                            // checked={allRadioCheck.thirdClass}
                                            // onChange={handleCheckboxChange}
                                          />
                                          </div>
                                          
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="thirdClass"
                                          >
                                            3rd Class
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12  col-lg-6">
                                    <div className="col-12 pt-2 sub-heading">
                                      Non-Pilot Designation
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center flex-column pt-1 pb-2 ">
                                      <div className="col-12 col-md-12 d-flex flex-column ">
                                        <div className="form-check amePopup-label col-12  pt-2 pt-md-1 d-flex align-items-center">
                                        <div>
                                        <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="airman_classAll"
                                            id="airTrafficController"
                                            value="Air Traffic Controller"
                                            // checked={
                                            //   allRadioCheck.airTrafficController
                                            // }
                                            // onChange={handleCheckboxChange}
                                          />
                                        </div>
                                         
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="airTrafficController"
                                          >
                                            Air Traffic Controller
                                          </label>
                                        </div>
                                        <div className="form-check amePopup-label col-12  pt-2 pt-md-1 d-flex align-items-center">
                                         <div>
                                         <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="airman_classAll"
                                            id="aircraftMechanics"
                                            value="Aircraft Mechanics"
                                            // checked={
                                            //   allRadioCheck.aircraftMechanics
                                            // }
                                            // onChange={handleCheckboxChange}
                                          />
                                          </div>
                                          
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="aircraftMechanics "
                                          >
                                            Aircraft Mechanics
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-md-12 d-flex flex-column">
                                        <div className="form-check amePopup-label col-12  pt-2 pt-md-1 d-flex align-items-center">
                                         <div>
                                         <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="airman_classAll"
                                            id="parachuteRiggers"
                                            value="Parachute Riggers & Packers"
                                            // checked={allRadioCheck.parachuteRiggers}
                                            // onChange={handleCheckboxChange}
                                          />
                                         </div>
                                         
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="parachuteRiggers"
                                          >
                                            Parachute Riggers & Packers
                                          </label>
                                        </div>
                                        <div className="form-check amePopup-label col-12  pt-2 pt-md-1 d-flex align-items-center">
                                         <div>
                                         <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="airman_classAll"
                                            id="dropZoneManagement"
                                            value="Drop Zone Management"
                                            // checked={
                                            //   allRadioCheck.dropZoneManagement
                                            // }
                                            // onChange={handleCheckboxChange}
                                          />
                                         </div>
                                         
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="dropZoneManagement"
                                          >
                                            Drop Zone Management
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-md-12 d-flex flex-column flex-md-row">
                                        <div className="form-check amePopup-label col-12  pt-2 pb-2 pt-md-1 d-flex align-items-center">
                                          <div>
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="airman_classAll"
                                            id="jumpPilots"
                                            value="Jump Pilots"
                                            // checked={allRadioCheck.jumpPilots}
                                            // onChange={handleCheckboxChange}
                                          />
                                          </div>
                                       
                                          <label
                                            className="form-check-label pl-2 pl-md-1"
                                            htmlFor="jumpPilots"
                                          >
                                            Jump Pilots
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )} */}

                            {/* Medical Expiring Within: */}
                            <div className="row pt-4">
                          
                                { activeSubscription?.current_subscription?.plan === null || subscriptionMonth?.plan_name === "ONE YEAR" ||
                                subscriptionMonth?.plan_name ===
                                  "THREE MONTH" ?
                                
                                  <>
                                   <div className="col-12 d-flex offmarket-output-format ame-boxShadow px-0 py-4">
                                <div className="amePopup-sub-heading">
                                  Medical Expiring Within
                                </div>
                                    {/*   30 Days */}
                                    <div className=" amePopup-label d-flex align-items-center">
                                      <div><Field
                                        className="form-check-input"
                                        type="radio"
                                        name="expire"
                                        id="30Days"
                                        value="30Days"
                                        checked={selectedOneMonth === "30Days"}
                                        onClick={handleDateChange}
                                      /></div>
                                      <label
                                        className="form-check-label pl-2 pl-md-0  text-nowrap"
                                        htmlFor="30Days"
                                      >
                                        30 Days
                                      </label>
                                    </div>
                                    {/*  60 Days */}
                                    <div className=" amePopup-label  d-flex align-items-center">
                                    <div><Field
                                        className="form-check-input"
                                        type="radio"
                                        name="expire"
                                        id="60Days"
                                        value="60Days"
                                        checked={selectedOneMonth === "60Days"}
                                        onClick={handleDateChange}
                                      /></div>
                                      <label
                                        className="form-check-label pl-2 pl-md-0  text-nowrap"
                                        htmlFor="60Days"
                                      >
                                        60 Days
                                      </label>
                                    </div>
                                    {/*   90 Days */}
                                    <div className=" amePopup-label  d-flex align-items-center">
                                    <div><Field
                                        className="form-check-input"
                                        type="radio"
                                        name="expire"
                                        id="90Days"
                                        value="90Days"
                                        checked={selectedOneMonth === "90Days"}
                                        onClick={handleDateChange}
                                      /></div>
                                      <label
                                        className="form-check-label pl-2 pl-md-0  text-nowrap"
                                        htmlFor="90Days"
                                      >
                                        90 Days
                                      </label>
                                    </div>
                                    {/*  All */}
                                    <div className=" amePopup-label   d-flex align-items-center">
                                    <div> <Field
                                        className="form-check-input"
                                        type="radio"
                                        name="expire"
                                        id="expireall"
                                        value="all"
                                        checked={selectedOneMonth === "all"}
                                        onClick={handleDateChange}
                                      /></div>
                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="expireall"
                                        
                                      >
                                        All
                                      </label>
                                    </div>
                                    {/*  Expired */}
                                    <div className=" amePopup-label   d-flex align-items-center">
                                      <div><Field
                                        className="form-check-input"
                                        type="radio"
                                        name="expire"
                                        id="expired"
                                        value="expired"
                                        checked={selectedOneMonth === "expired"}
                                        onClick={handleDateChange}
                                      /></div>
                                      <label
                                        className="form-check-label pl-2 pl-md-0"
                                        htmlFor="expired"
                                      >
                                        Expired
                                      </label>
                                    </div>
                                    </div>
                                  </>
                              :
                              subscriptionMonth?.plan_name === "MONTHLY" ? (
                                <>
                                    <div className="col-12 d-flex justify-content-around ame-boxShadow px-0 py-4">
                                <div className="amePopup-sub-heading">
                                  Medical Expiring Within
                                </div>
                                  <div className=" amePopup-label text-left ml-0 ml-md-3 d-flex align-items-center">
                                 <div>
                                 <Field
                                    className="form-check-input "
                                    type="radio"
                                    name="expire"
                                    id="30Days"
                                    value="30Days"
                                    checked={selectedOneMonth === "30Days"}
                                    onClick={handleDateChange}
                                  />
                                 </div>
                                  <label
                                    className="form-check-label pl-2 pl-md-0"
                                    htmlFor="30Days"
                                  >
                                    30 Days
                                  </label>
                                </div>
                                    <div className=" amePopup-label">
                                    
                                  </div>
                                  <div className=" amePopup-label">
                                    
                                  </div>
                                  <div className=" amePopup-label">
                                    
                                  </div>
                                  <div className=" amePopup-label">
                                    
                                  </div>
                               
                                    </div>
                                </>
                              
                              ) : (
                                ""
                              )}
                              
                              {/* <p className="text-danger field-errorMessage ">
                                <ErrorMessage name="expire" />
                              </p> */}
                            </div>

                            {/* Output Format */}
                            <div className="row pt-4 pb-4">
                              <div className="col-12 d-flex offmarket-output-format ame-boxShadow px-0 py-4">
                                <div className="amePopup-sub-heading">
                                  Output Format
                                </div>
                                <div className=" amePopup-label d-flex align-items-center">
                                  <div><Field
                                    className="form-check-input"
                                    type="radio"
                                    name="export_file"
                                    id="CSV"
                                    value="CSV"
                                    checked={selectedCSV === "CSV"}
                                    onClick={handleRadioCSV}
                                  /></div>
                                  <label
                                    className="form-check-label pl-2 "
                                    htmlFor="CSV"
                                  >
                                    CSV
                                  </label>
                                </div>
                                <div className=" amePopup-label  d-flex align-items-center">
                                <div> <Field
                                    className="form-check-input"
                                    type="radio"
                                    name="export_file"
                                    id="TXT"
                                    value="TXT"
                                    checked={selectedCSV === "TXT"}
                                    onClick={handleRadioCSV}
                                  /></div>
                                  <label
                                    className="form-check-label pl-2 "
                                    htmlFor="TXT"
                                  >
                                    TXT
                                  </label>
                                </div>
                                <div className=" amePopup-label  d-flex align-items-center">
                                <div><Field
                                    className="form-check-input"
                                    type="radio"
                                    name="export_file"
                                    id="RTF"
                                    value="RTF"
                                    checked={selectedCSV === "RTF"}
                                    onClick={handleRadioCSV}
                                  /></div>
                                  <label
                                    className="form-check-label pl-2 "
                                    htmlFor="RTF"
                                  >
                                    RTF
                                  </label>
                                </div>

                                <div className=" amePopup-label  d-flex align-items-center">
                                  <div><Field
                                    className="form-check-input"
                                    type="radio"
                                    name="export_file"
                                    id="avery5163LabelPDF"
                                    value="avery5163LabelPDF"
                                    checked={selectedCSV === "avery5163LabelPDF"}
                                    onClick={handleRadioCSV}
                                  /></div>
                                  <label
                                    className="form-check-label pl-2 text-nowrap"
                                    htmlFor="avery5163LabelPDF"
                                  >
                                    Avery 5163 Label PDF
                                  </label>
                                </div>

                                <div className="amePopup-label">
                                  <label
                                    className="form-check-label pl-2 pl-md-0"
                                    htmlFor="outputFormat"
                                  ></label>
                                </div>
                              </div>
                              {/* <p className="text-danger field-errorMessage">
                                <ErrorMessage name="export_file" />
                              </p> */}
                            </div>

                            <div className="text-center ">
                              <div className=" col-12 btn-wrapper btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                                {/* <Link to="/your-downloads-history"> */}
                                <button
                                  type="submit"
                                  // onClick={() => {
                                  //   handleSubmitSetData();
                                  // }}
                                  className="exports-Records-btn custom-freeTrial-btn"
                                  // data-dismiss="modal"
                                >
                                  Exports Records
                                </button>
                                {/* </Link> */}
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
