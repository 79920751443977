import axios from 'axios';

const Api = axios.create({
    baseURL:process.env.REACT_APP_API_URL
})

console.log(Api);

// LocalStorageService
//const localStorageService = LocalStorageService.getService()
//localStorage.getItem('authToken');

// Add a request interceptor

Api.interceptors.request.use(
    config => {
     const token =localStorage.getItem('_authToken');
   
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
      }
      // config.headers['Content-Type'] = 'application/json';
      return config
    },
    error => {
      Promise.reject(error)
    }
  )
 // Add a response interceptor
 Api.interceptors.response.use(
    response => {
      return response
    },
    function (error) {
      const originalRequest = error.config
  
      if (
        error.response.status === 401 &&
        originalRequest.url ===   `${process.env.REACT_APP_API_URL}/authentication_tokens/create`
      ) {
      //  router.push('/login')
        return Promise.reject(error)
      }
  
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        const refreshToken = localStorage.getItem('_authToken');
        return axios
          .post('/authentication_tokens/create', {
            refresh_token: refreshToken
          })
          .then(res => {
            if (res.status === 201) {
             // localStorageService.setToken(res.data)
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + localStorage.getItem('authToken');
              return axios(originalRequest)
            }
          })
      }
    })
export default Api;