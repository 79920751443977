import React from 'react'
import "../admin.css";
import { Screen } from '../../RepeatedComponent/Screens';
export const SearchAirmenRecords = () => {
  return (
   <>
   
           {/* Slider Section */}
           <Screen/>

      <div
  id="hs_cos_wrapper_widget_1604012269835"
  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
  style={{}}
  data-hs-cos-general-type="widget"
  data-hs-cos-type="module"
>
  {/* Label For Modules Data Page */}
  <div
    className="sr-multicol-media bg-white sr-padding-half text-center prototype-no-background widget_1604012269835 "
    id="research-property"
  >
    <div className="container-fluid content-wrapper">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h2 className=" section-heading font-inter">Airmen Data</h2>
          <p>AIRMEN DATa is current as of the 2nd of each month (Last FAA update)</p>
          <div className="row  sr-valign-center">
            <div className="col-md-6 sr-col text-left  sr-repeater-1">
              <div className="content-wrapper">
                <div className="col-content">
                  <p className="justify-content">
                  From here, you can search airmen such as certified aircraft mechanics, air traffic control operators and pilot records such as current or non-current medical class and when they will be expiring. Searches can be done by state, geographical location and NEW! Zip Code search including range and distance! Perfect for Aviation Medical Examiners who are looking to build their AME patient base.
                  </p>
                  <p className="justify-content">
                  All information is easily exported into .csv format or convenient Avery Label format and is updated on the second of each month.
                  </p>
                  <h6 className='font-inter'>
                  For your <span className='custom-text-color-orange'> FREE </span> full access to the database, you must subscribe.
                  </h6>
                </div>
                <div className="btn-wrapper btn-primary-wrapper sr-spacer-top-25 text-center">
                  <a
                    href="#"
                    className="cta_button font-inter"
                  >
                  You will not be charged for your free trial
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 sr-col  sr-repeater-2">
              <div className="content-wrapper">
                <div className="responsive-image ">
                  <img
                    src="images/AdminImages/AirmenRecodes.png"
                 
                    alt="Img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
      {/* Slider Section End*/}
   </>
  )
}
