import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";

interface downloadFile {
  success: "",
}
interface TaskStatus {
  task_log_id:string,
}
const initialState:downloadFile = {
  success: "",
};

// -------  Download File  -----
export const downloadFile = createAsyncThunk(
  "file/downloadFile",
  async ({task_log_id}:TaskStatus, thunkAPI) => {
    console.log(task_log_id, "File id")
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/export_task_file?task_log_id=${task_log_id}`,
      );

      if (response.data) {
        console.log(response.data, "Download File")
        return response.data.success;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {

      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);



// ------- createSlice for all ----
export const downloadFileSlice = createSlice({
  name: "downloadFile",
  initialState,
  reducers: {
  },
  extraReducers: (builder: any) => {

    // downloadFile
    builder
      .addCase(downloadFile.pending, (state: any) => {
        state.status = false;
      })
      .addCase(downloadFile.fulfilled, (state: any, action: any) => {
        state.status = true;
        state.error = "";
        state.response = action.meta.requestStatus;
      })
      .addCase(downloadFile.rejected, (state: any, action: any) => {
        state.status = false;
        state.error = action.error.message;
      });
  },
});

export const userList = (state: RootState) => state.downloadFile;
export default downloadFileSlice.reducer;