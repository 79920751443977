import { Loader } from "../RepeatedComponent/Loader";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hook";
import "./search.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import * as yup from "yup";
import { Personal_InformationPopup } from "../RepeatedComponent/Personal_InformationPopup";
import { getPilotStates } from "../../../Features/downloadHistorySlice";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  clearState,
  getFreeAirmenRecords,
} from "../../../Features/searchSlice";
import { useNavigate } from "react-router-dom";
import makeAnimated from "react-select/animated";
import Select, { StylesConfig } from "react-select";
import chroma from "chroma-js";

export const FreeAirmen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const animatedComponents = makeAnimated();
  const [selectedValues, setSelectedValues] = useState([]);
  const [loading, setLoading] = useState(0);
  const [showComponent, setShowComponent] = useState(false);
  const [popUpData, setPopUpData] = useState();
  const [designation, setDesignation] = useState();

  const freeAirMenData = useAppSelector((state: any) => state.search.response);
  const status = useAppSelector((state: any) => state.auth.status);
  const states = useAppSelector((state: any) => state.downloadHistory.states);

  const handleSelectChange = (values: any) => {
    setSelectedValues(values);
    console.log(values);
  };
  const StateValues = selectedValues.map((item: any) => item.value);

  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor:"black",
      borderColor: "black",
      margin: 0,
      marginLeft: 0,
      border: "0px solid black",
      fontSize: 14,
      outline: 'none !important',
      boxShadow: 'none',
      '&:hover': {
        borderColor: "black",
      }
    }),
    placeholder: (defaultStyles:any) => ({
           ...defaultStyles,
          color: 'gray',
          fontSize:"14",
          textAlign:"left"
  }),
    multiValue: (styles:any) => ({
        ...styles,
        backgroundColor: "#E35D22",
   
    }),
 
    menu: (provided:any) => ({ ...provided, zIndex: 9999, backgroundColor:"black" ,textAlign:"left"}),
     multiValueLabel: (styles:any) => ({
    ...styles,
    color:"white",
    textAlign:"left"

  }),
  };

  const validationSchema = yup.object().shape({
    max_records: yup.string().required("Please select maximum records."),
  });
  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  const defaultValue = {
    last_name: "",
    last_name_check:"sub_string",
    first_name: "",
    first_name_check:"sub_string",
    city_name: "",
    city_name_check: "sub_string",
    states:[""],
    type: "Pilot",
    max_records: "20",
  };

  const formattedStates = states
    ? states
        .filter(
          (stateObj: { state: string; state_abbrev: string }) =>
            stateObj.state && stateObj.state_abbrev
        )
        .map((stateObj: { state: string; state_abbrev: string }) => ({
          value: stateObj.state,
          label: stateObj.state_abbrev,
        }))
    : [];

  useEffect(() => {
    dispatch(clearState());
    dispatch(getPilotStates());
  }, []);

  /// Handle form submission
  const handleSubmit = async (values: any) => {
    console.log(values, "values");
    setDesignation(values.type);

    // setDesignation()
    if (!Array.isArray(values.states)) {
      values.states = values.states?.split();
    }
    if (values.first_name == "" && values.last_name == "") {
      toast.error("Please enter atleast first name or last name", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
      });
      return;
    }
    setLoading(1);
    const allValues = { ...values, states: StateValues };
    console.log(allValues, "allValues");
    const result: any = await dispatch(getFreeAirmenRecords(allValues));
    if (result.payload.success) {
      setLoading(0);
      console.log(freeAirMenData);
    } else {
      setLoading(0);
    }
  };
  const handleClick = (event: any) => {
    setShowComponent(true);
  };
  return (
    <>
      {/* <Link
        to="#"

        data-toggle="modal"
        data-target="#personalInformation"
        className="cta_button"
      >
        AME Health Certificate List
      </Link> */}
      {showComponent && <Personal_InformationPopup />}
      {loading ? <Loader /> : ""}
      <Formik
        initialValues={defaultValue}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <div className="container">
            <div className="row  pb-3 p-2  d-flex justify-content-center ">
              <div className="col-11 col-sm-11 col-md-11 col pb-3 pb-md-5 d-flex justify-content-center align-items-center">
                <div className="container-fluid px-md-5 px-3   py-4  nacmaxwid  custom-shadow-search ">
                  <div className="row">
                  <div className="col-12 col-md-6">
                      <div>
                        <Field
                          type="text"
                          className="form-control nacinput custom-form-control  w-100  p-0 pt-3 pb-1"
                          id="last_name"
                          placeholder="Last Name"
                          name="last_name"
                        />
                         <p className="text-danger field-errorMessage mb-3 text-left">
                            <ErrorMessage name="first_name" />
                          </p>
                      </div>
                        {/* that is how every radio filed visisbl */}
                      <div className="col-12 px-0 d-flex align-items-center ">
                        <div className="form-check">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="d-flex align-items-center">
                              <div>
                                <Field
                                  name="last_name_check"
                                  type="radio"
                                  className="form-check-input mart5 custom-form-check-input"
                                  value="exact_string"
                                />
                              </div>

                              <span className="mlmob">Exact Match</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-check ml-3">
                          <label className="d-flex align-items-center">
                            <div>
                              <Field
                                name="last_name_check"
                                type="radio"
                                className="form-check-input mart5 custom-form-check-input"
                                value="sub_string"
                              />
                            </div>
                            <span className="mlmob">Contains</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div>
                        <Field
                          type="text"
                          className="form-control nacinput custom-form-control  w-100  p-0 pt-3 pb-1"
                          id="first_name"
                          name="first_name"
                          placeholder="First/Middle Name(Leave Blank for all)                          "
                        />
                        <p className="text-danger field-errorMessage mb-3 text-left">
                            <ErrorMessage name="last_name" />
                          </p>
                      </div>

                      {/* ////////////////// */}
                      <div className="col-12 px-0  d-flex align-items-center">
                        <div className="form-check">
                          <div
                            role="group"
                            className="d-flex align-item-center"
                            aria-labelledby="my-radio-group"
                          >
                             <label className="d-flex align-items-center">
                              <div>
                              <Field
                                type="radio"
                                className="form-check-input mart5 custom-form-check-input"
                                name="first_name_check"
                                value="exact_string"
                              />
                              </div>
                            
                              <span className="mlmob">Exact Match</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-check ml-3">
                        <label className="d-flex align-items-center">
                        <div>
                              <Field
                                name="first_name_check"
                                type="radio"
                                className="form-check-input mart5 custom-form-check-input"
                                value="sub_string"
                              />
                            </div>
                            <span className="mlmob">Contains</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                      <div className="pt-md-1 p-0">
                        <Field
                          type="text"
                          name="city_name"
                          className="form-control nacinput custom-form-control  w-100  p-0 pt-3 pb-1"
                          id="city_name"
                          aria-describedby="emailHelp"
                          placeholder="City(Leave Blank for all)"
                        />
                        </div>
                         <p className="text-danger field-errorMessage mb-3 text-left">
                            <ErrorMessage name="city_name" />
                          </p>
                      </div>
                      <div className="col-12 px-0 d-flex align-items-center ">
                        <div className="form-check">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label className="d-flex align-items-center">
                              <div>
                              <Field
                                type="radio"
                                className="form-check-input  mart5 custom-form-check-input"
                                name="city_name_check"
                                value="exact_string"
                              />
                              </div>
                            
                              <span className="mlmob">Exact Match</span>
                            </label>
                          </div>
                        </div>
                        <div className="form-check ml-3">
                        <label className="d-flex align-items-center">
                          <div>
                          <Field
                              type="radio"
                              className="form-check-input mart5 custom-form-check-input"
                              name="city_name_check"
                              value="sub_string"
                            />
                          </div>
                            <span className="mlmob">Contains</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 selected-states ">
                      {/*  */}
                      <div className="form-group">
                        <div className="custom-form-control">
                       
                          <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={formattedStates}
                            styles={colourStyles}
                            className="w-100"
                            placeholder='Please Select State'
                            value={selectedValues} // Set selected values
                            onChange={handleSelectChange}
                            // menuPlacement="top"
                            // required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row"></div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <Field
                          as="select"
                          className="form-control nacinput custom-form-control pl-0"
                          name="type"
                          id="type"
                          placeholder="Type(Leave Blank for all)"
                        >
                          {" "}
                          <option selected disabled>
                            Type(Leave Blank for all)
                          </option>
                          <option>Pilot</option>
                          <option>Non-Pilot</option>
                        </Field>
                        <p className="text-danger field-errorMessage mb-3 text-left">
                            <ErrorMessage name="type" />
                          </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <div className="form-group  ">
                        <div className="form-group">
                          <Field
                            as="select"
                            className="form-control nacinput custom-form-control pl-0 mb-0"
                            name="max_records"
                            id="max_records"
                            
                            placeholder="Maximum Records"
                          >
                            <option selected disabled>
                              Maximum Records
                            </option>
                            <option value="20">20</option>
                            <option value="30">40</option>
                            <option value="60">60</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Begin Search btn */}
                  <div className="raw justify-content-center">
                    <div className="pb-4 d-flex justify-content-center align-items-center">
                      <button
                        type="submit"
                        className="custom-btn-begin-search border-1 font-inter"
                      >
                        Begin Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      {/* Airmen  Records */}
      {freeAirMenData && freeAirMenData ? (
        <>
          <div
            id="hs_cos_wrapper_widget_1604099686647"
            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
            style={{}}
            data-hs-cos-general-type="widget"
            data-hs-cos-type="module"
          >
            <span
              id="hs_cos_wrapper_main_flexible_column"
              className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container bg-secondary"
              style={{}}
              data-hs-cos-general-type="widget_container"
              data-hs-cos-type="widget_container"
            >
              <div
                id="hs_cos_wrapper_widget_1604340283000"
                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                style={{ background: "#EDF1D1" }}
                data-hs-cos-general-type="widget"
                data-hs-cos-type="module"
              >
                {/* Label For Modules Data Page */}
                <div className="sr-cards-grid-02 bg-black text-white prototype-no-background widget_1604340283000">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-md-11">
                        <div className="cards row">
                          <div className="container-fluid">
                            <div className="row ">
                              <div className="col-md-12">
                                <h2 className=" section-heading font-inter text-white">
                                  Search Result
                                </h2>
                              </div>
                              <div className="col-md-12 bg-black text-white">
                                <div className="row aircraft-registration-table-boxShadow bg-black text-white">
                                  {freeAirMenData &&
                                  Array.isArray(freeAirMenData.records) &&
                                  freeAirMenData.records.length ? (
                                    freeAirMenData.records.map((data: any) => (
                                      <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                        <div className="border-0 ">
                                          <button
                                            onClick={() => setPopUpData(data)}
                                            data-toggle="modal"
                                            data-target="#personalInformation"
                                            className="col-12 rounded-pill border p-2 text-center text-white freeAirmen-td"
                                          >
                                            {data.first_middle_name},{" "}
                                            {data.last_name_suffix}
                                          </button>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="col-12 col-sm-12 col-md-12 pt-4col-lg-12">
                                      <div className="row justify-content-center border-0 ">
                                        <div className="col-12 col-md-6 rounded-pill border p-2 text-center freeAirmen-td">
                                          No Airmen Registration Records found
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>{" "}
          </div>
        </>
      ) : (
        ""
      )}

      <Personal_InformationPopup type={designation} data={popUpData} />
      <ToastContainer />
    </>
  );
};
