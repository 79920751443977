import React from 'react'
import { Screen } from '../RepeatedComponent/Screens'

export const PlaneListsDatabases = () => {
  return (
   <>
    <Screen/>
    <div
  id="hs_cos_wrapper_widget_1695156991887"
  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
  style={{}}
  data-hs-cos-general-type="widget"
  data-hs-cos-type="module"
>
  {/* Label For Modules Data Page */}
  <div className="sr-multicol-media bg-white sr-padding-full text-center prototype-no-background widget_1695156991887 ">
    <div className="container content-wrapper">
   
      <div className="row  sr-valign-center">
      
        <div className="col-md-12 mt-3  p-2 sr-col text-left  sr-repeater-2">
          <div className="content-wrapper">
            <div className="container">
            <div className="col-content">
            <p className="justify-content">
            Unlock the freshest and most precise aviation marketing insights from our cutting-edge data vault. Updated regularly using proprietary software, it empowers your marketing efforts. Access precise details instantly, from anywhere in the world. Timely pilot database changes ensure your marketing reaches the right people promptly, whether buying airplanes or offering aircraft services. With updates on the 1st of each month and aircraft records compiled daily at 2:00 a.m. EST (GMT-5), trust the accuracy and reliability of our quality data to make your marketing efforts impactful. 
           </p>
            </div>
            </div>
          </div>
        </div>
      

      </div>
    </div>
    
  </div>
    {/* Label For Modules Data Page */}
    <div className="sr-offer-bar-03 bg-primary sr-padding-half     prototype-no-background widget_34472050333 text-white">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="row d-flex align-items-center h-100 sr-offer-bar-03-inner ">
            <div className="col-lg-12">
              <div className="row ">
                <div className="col sr-col ">
                  <div className="row d-flex align-items-center h-100">
                    <div className="col-md-7 sr-col sr-offer-bar-03-content">
                      <span className="align-middle mx-auto">
                        <h3 className=" heading text-white font-inter">
                          Lorem Ipsum&nbsp;is simply dummy
                        </h3>
                        <div className="description">
                          <p>
                            Lorem Ipsum&nbsp;is simply dummy text of the
                            printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since
                            the 1500s, when an unknown printer took a galley of
                            type and{" "}
                          </p>
                        </div>
                      </span>
                    </div>
                    <div className="col-md-5 text-center text-md-right offer-btns">
                      <span className="btn-wrapper btn-white-wrapper d-inline-block btn-wrapper">
                        <a
                          href="#"
                          className="cta_button font-inter"
                        >
                          GET STARTED NOW
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  )
}
