import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import "../admin.css";
import { downloadHistory } from "../../../../Features/downloadHistorySlice";
import { Breadcrumbs } from "../../RepeatedComponent/Breadcrumb";
import { Loader } from "../../RepeatedComponent/Loader";
import { useNavigate } from "react-router-dom";

export const YourDownloadsHistory = () => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(0);
  const status = useAppSelector((state:any) => state.auth.status);
  const navigate = useNavigate()
  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  const historyData = useAppSelector(
    (state: any) => state.downloadHistory.download_history
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Change this to whatever date format you desire
  };

  const capitalizeFirstLetterOfWords = (str: string) => {
    return str.replace(/\b\w/g, (char: string) => char.toUpperCase());
  };

  const transformData = (data: any) => {
    return data.map((item: any) => ({
      ...item,
      task_type: item.task_type
        ? capitalizeFirstLetterOfWords(
            item.task_type.replace(/_/g, " ").toLowerCase()
          )
        : "",
      export_file_type: item.export_file_type
        ? item.export_file_type.replace(/_/g, " ").toUpperCase()
        : "",
      planType: item.planType
        ? capitalizeFirstLetterOfWords(item.planType.toLowerCase())
        : "",
    }));
  };

  useEffect(() => {
    setLoading(1);
    dispatch(downloadHistory()).then((res: any) => {
      setLoading(0);
    });
  }, []);

  return (
    <>
      {loading ? <Loader /> : ""}
      <div
        className="sr-multicol-media pt-1 pt-lg-4 text-center prototype-no-background 
      widget_1600979911688 bg-black "
      >
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1604099686647"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module bg-black"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        <span
          id="hs_cos_wrapper_main_flexible_column"
          className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container 
          bg-black"
          style={{}}
          data-hs-cos-general-type="widget_container"
          data-hs-cos-type="widget_container"
        >
          <div
            id="hs_cos_wrapper_widget_1604340283000"
            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
            style={{ background: "#EDF1D1" }}
            data-hs-cos-general-type="widget"
            data-hs-cos-type="module"
          >
            {/* Download History table */}
            <div className="sr-cards-grid-02 bg-black sr-padding-full prototype-no-background widget_1604340283000">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-10 offset-md-1 pb-5">
                    <div className="container-fluid">
                      <div className="row ">
                        <div className="col-md-12">
                          <h2
                            className=" section-heading font-inter text-white text-center dash-heading"
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            Download History
                          </h2>
                        </div>
                        <div className="col-md-12 table-responsive border p-0 mt-4">
                          <table className="table table bordered custom-downloads-table bg-white">
                            <thead className="thead custom-downloads-thead">
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col" className="text-nowrap">
                                  Record Type
                                </th>
                                <th scope="col" className="text-nowrap">
                                  File Type
                                </th>
                                <th scope="col" className="text-nowrap">
                                  Plan Type
                                </th>
                                <th scope="col" className="text-nowrap"></th>
                              </tr>
                            </thead>
                            <tbody
                              className="overflow-auto"
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                            >
                              {historyData && historyData.length > 0 ? (
                                transformData(historyData).map(
                                  (item: any, index: number) => (
                                    <tr key={index}>
                                      <td scope="row" className="text-nowrap">
                                        {formatDate(item.created_at)}
                                      </td>
                                      <td className="text-nowrap">
                                        {item.task_type}
                                      </td>
                                      <td className="text-nowrap">
                                        {item.export_file_type}
                                      </td>
                                      <td className="text-nowrap">
                                        {/* {item.planType} */}
                                        Free Trial
                                      </td>
                                      <td
                                        className="text-nowrap"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {/* {item.planType} */}
                                        Download
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan={5}>No Download History found</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>{" "}
      </div>
    </>
  );
};
