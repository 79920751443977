export const Feedback = () => {
  return (
 <>
   <div>
        <div
          className="modal fade "
          id="exampleModalCenter"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title feedback-modal-header">
                  Feedback Us
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row justify-content-center m-2">
                  <input
                    type="text"
                    className="form-group col-md-12 feedback-input pt-3 pb-2  rounded-0"
                    placeholder="First Name"
                  />
                  <input
                    type="text"
                    className="form-group col-md-12 feedback-input pt-3 pb-2 rounded-0 "
                    placeholder="Last Name"
                  />
                  <textarea
                    className="form-group col-md-12 p-3 feedback-textarea pb-0 mb-0"
                    rows={3}
                    placeholder="Your message here..."
                  />
                </div>
              </div>
              <div className="modal-footer border-0 justify-content-center pt-0">
                <button type="button" className="btn btn-primary px-4 ">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

 </>
  )
}
