import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { BiSolidLock } from "react-icons/bi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import "../Login/login.css";
import {
  userChangePassword,
} from "../../../Features/authSlice";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { Loader } from "../RepeatedComponent/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import { clearState } from "../../../Features/authSlice";

export const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const status = useAppSelector((state: any) => state.auth.status);
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(0);
  const [defaultValue, setDefaultValue] = useState({
    password: "",
    password_confirmation: "",
  });

  useEffect(() => {
    if (!status) {
      navigate("/");
    }
  }, [status, navigate]);

  // Validation schema using yup
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(" Password is required")
      .matches(/^.{8,}$/, "Password must contain at least 8 characters"),
    password_confirmation: yup
      .string()
      .oneOf(
        [yup.ref("password"), ""],
        " Password and confirmation password do not match"
      )
      .required("Confirm New Password is required"),
  });


  const handleSubmit = (values: any) => {
    console.log(values, "values");
    setLoading(1);
    dispatch(userChangePassword(values)).then((res: any) => {
      console.log(res,"respon")
      setLoading(0);
      navigate("/login")
    });
  };

  useEffect(() => {
    if (error && error === "Rejected") {
      toast.error(" password", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 8000,
        pauseOnFocusLoss: true,
      });
      dispatch(clearState());
    }
  }, [handleSubmit]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(true);
  };

  const toggleHidePassword = () => {
    setShowPassword(false);
  };



  return (
    <>
          {loading  ? <Loader /> : ""}
      <div
        className="sr-multicol-media pt-1 pt-lg-4 text-center prototype-no-background 
      widget_1600979911688 bg-black "
      >
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <h2
        className=" section-heading font-inter text-white text-center dash-heading"
        style={{ backgroundColor: "black", color: "white" }}
      >
        Change Password
      </h2>

      <Formik
        initialValues={defaultValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {/* <Screen /> */}
        <div className="bg-body-tertiary d-flex flex-row gray-bg bg-black ">
          <div className=" container maxy-wid">
            <div className="row justify-content-center py-md-4 ">
              <div className="col-md-10 mt-sm-5 mb-sm-5 p-0  login-container overflow-hidden">
                <div className="d-flex flex-column ">
                  <div className=" card p-2 m-4 m-sm-0 bg-black border ">
                    <div className="">
                      <Form>
                        <div className="px-md-5 pt-4 p-2">
                          {/* <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <BiSolidLock />
                            </span>
                              <Field
                                  type={showPassword ? "text" : "password"}
                                  className="form-control small m-0"
                                  placeholder="Current Password"
                                  aria-label="Current Password"
                                  aria-describedby="basic-addon1"
                                  name="oldpassword"
                              />
                              <div className="eye-icon-container">
                                {showPassword ? (
                                  <img
                                    src="./images/open-eye.svg"
                                    className="eye-icon-size"
                                    onClick={toggleHidePassword}
                                  />
                                ) : (
                                  <img
                                    src="./images/close-eye.svg"
                                    className="eye-icon-size"
                                    onClick={toggleShowPassword}
                                  />
                                )}
                              </div>
                          </div>
                          <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="oldpassword" />
                          </p> */}

                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <BiSolidLock />
                            </span>
                            <Field
                              type={showPassword ? "text" : "password"}
                              className="form-control small m-0"
                              placeholder="New Password"
                              aria-label="New Password"
                              aria-describedby="basic-addon1"
                              name="password"
                            />
                            <div className="eye-icon-container">
                              {showPassword ? (
                                <img
                                  src="./images/open-eye.svg"
                                  className="eye-icon-size"
                                  onClick={toggleHidePassword}
                                />
                              ) : (
                                <img
                                  src="./images/close-eye.svg"
                                  className="eye-icon-size"
                                  onClick={toggleShowPassword}
                                />
                              )}
                            </div>
                          </div>
                          <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="password" />
                          </p>

                          <div className="input-group">
                            <span
                              className="input-group-text"
                              id="basic-addon1"
                            >
                              <BiSolidLock />
                            </span>
                            <Field
                              type={showPassword ? "text" : "password"}
                              className="form-control small m-0"
                              placeholder="Confirm New Password"
                              aria-label="Confirm Password"
                              aria-describedby="basic-addon2"
                              name="password_confirmation"
                            />
                            <div className="eye-icon-container">
                              {showPassword ? (
                                <img
                                  src="./images/open-eye.svg"
                                  className="eye-icon-size"
                                  onClick={toggleHidePassword}
                                />
                              ) : (
                                <img
                                  src="./images/close-eye.svg"
                                  className="eye-icon-size"
                                  onClick={toggleShowPassword}
                                />
                              )}
                            </div>
                          </div>
                          <p className="text-danger field-errorMessage mb-3">
                            <ErrorMessage name="password_confirmation" />
                          </p>

                          <div className="d-flex my-3 justify-content-center">
                            <button
                              type="submit"
                              className="btn btn-primary w-100 justify-content-center"
                              onClick={() => {
                                scrollToTop();
                              }}
                            >
                              <span className="text-white">
                                Update Password
                              </span>
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Formik>
      {/* <FreeTrial /> */}
      <ToastContainer />
    </>
  );
};
