import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./headerPage.css";
import { useAppDispatch, useAppSelector } from "../../../hook";
import { logoutTrue, userLogout } from "../../../Features/authSlice";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../RepeatedComponent/Loader";
import { fetchUser } from "../../../Features/fetchUserSlice";

const HeaderPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.auth.status);
  const [mobileMenu, showMobileMenu] = useState(false);
  const [subMobileMenu,setSubMobileMenu] =  useState(false);
  const [loading, setLoading] = useState(0);
  const [activeItem, setActiveItem] = useState("");
  
  async function checkTasksAndDownload(){
      let intervalId = setInterval(async () => {
        const userData: any = await dispatch(fetchUser());
        if(userData.payload.success){
          localStorage.setItem("tasksScheduler", "true");
          let currentUser = userData.payload.loggedin_user;
          
          if (currentUser.pending_tasks && Object.keys(currentUser.pending_tasks).length > 0) {
            // Show pending tasks or perform any other necessary action
            console.log('Pending tasks:', currentUser.pending_tasks);
          }
        
          if (
            currentUser.ready_to_download_task &&
            Object.keys(currentUser.ready_to_download_task).length > 0
          ) {
            const taskId:any = Object.keys(currentUser.ready_to_download_task)[0];
            const windowId:any = window.open(
              `${process.env.REACT_APP_API_URL}/export_task_file?task_log_id=${taskId}`,
              'width=600,height=600,scrollbars=yes'
            );
            windowId?.focus();
            if(windowId && windowId?.onblur){
              windowId.onblur = function() {windowId.close(); };
            }
          }
        } 
      }, 5000);
     }

   

  const logOut = () => {
    setLoading(1);
    dispatch(userLogout()).then((res:any) => {
      setLoading(0);
      dispatch(logoutTrue());
    });
  };

  // const [showmyacc, setShowmyAcc] = useState<boolean>(false);

  // useEffect(() => {
  //   console.log(subMobileMenu)
  // },[subMobileMenu])
  // useEffect(()=>{
  //   if(status===false){
  //     toast.success("Logout Successful", {
  //       position: "top-center",
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       draggablePercent: 8000,
  //       pauseOnFocusLoss: true,
  //     });
  //   }
  // },[logOut])

  //The navbar is hidden when scrolling down and visible when scrolling up.
  useEffect(() => {
    window.onbeforeunload = function() {
      localStorage.removeItem("tasksScheduler");
    };


    let prevScrollPos = window.pageYOffset;
    const isTasksScheduled = localStorage.getItem("tasksScheduler");
    if(!isTasksScheduled || isTasksScheduled == "false"){
      console.log(123132123132)
      checkTasksAndDownload();
    }
    const handleResize = () => {
      // setScreenWidth(window.innerWidth);
      // console.log(window.innerWidth<1200,"inner width")
      if(window.innerWidth<1200){
        setSubMobileMenu(true);
      } else {
        // console.log('andar aaya')
        setSubMobileMenu(false)
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);


    const handleScroll = () => {
    
      const currentScrollPos = window.pageYOffset;
      const navbar = document.querySelector("#navbar-main");

      if (navbar instanceof HTMLElement) {
        if (prevScrollPos > currentScrollPos) {
          navbar.style.transform = "translateY(0)";
        } else {
          navbar.style.transform = "translateY(-100%)";
        }
      }
      prevScrollPos = currentScrollPos;
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.onbeforeunload = null;
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  };

  const { pathname } = useLocation();

  useEffect(() => {
    setActiveItem(pathname); // Update active item when pathname changes
  }, [pathname]);

  const handleNavigation = (pathname: string) => {
    navigate(pathname);
    setActiveItem(pathname);
    showMobileMenu(false);
  };
   
  return (
<>
{loading  ? <Loader /> : ""}
    <div
      id="hs_cos_wrapper_widget_1600383188162"
      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
      style={{}}
      data-hs-cos-general-type="widget"
      data-hs-cos-type="module"
    >
      {/* <ToastContainer/> */}
      <nav
        id="navbar-main"
        className="headroom headroom--not-bottom headroom--pinned headroom--top"
      >
        {/* Top Bar */}
        <div className="top-bar">
          <div className="container-fluid">
            <div className="row">
              {/* Top Bar Text */}
              {/* <div className="col-12 top-bar-text">
                <div>
                  <p>
                    In observance of Martin Luther King Jr. Day, PlaneLists.com will
                    be closed on Monday, January 15, 2024.
                  </p>
                </div>
                <div className="close-top-bar">
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
                </div>
              </div> */}

              {/* Left Section */}
              <div className="col-6 left">
                {/* Left Menu */}
                <span className="hs_cos_wrapper_widget_1600383188162_">
                  <div
                    className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                    role="navigation"
                    data-sitemap-name="default"
                    data-menu-id="34807156149"
                    aria-label="Navigation Menu"
                  >
                    <ul role="menu">
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link to="" role="menuitem">
                          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                          Support@PlaneLists.com
                        </Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link to="" role="menuitem">
                          <i className="fa fa-clock" aria-hidden="true"></i> Mon
                          - Fri: 6:00 AM - 6:00 PM PT
                        </Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link to="" role="menuitem">
                          <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          Toll Free (877) 204-9040
                        </Link>
                      </li>
                    </ul>
                  </div>
                </span>
              </div>

              {/* Right Section */}
              <div className="col-6 right">
                {/* Right Menu */}
                <span className="hs_cos_wrapper_widget_1600383188162_">
                  <div
                    className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                    role="navigation"
                    data-sitemap-name="default"
                    data-menu-id="83812160326"
                    aria-label="Navigation Menu"
                  >
                    <ul role="menu">
                      {/* <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link
                          to="/"
                          role="menuitem"
                          target="_blank"
                          rel="noopener"
                        >
                          Free Trial
                        </Link>
                      </li> */}
                      {/* <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link
                          to="/"
                          role="menuitem"
                          target="_blank"
                          rel="noopener"
                        >
                          Activate
                        </Link>
                      </li> */}
                      {/* <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link
                          to="/"
                          role="menuitem"
                          target="_blank"
                          rel="noopener"
                        >
                          Community
                        </Link>
                      </li> */}
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link
                          to="/"
                          role="menuitem"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="fab fa-facebook" aria-hidden="true" />
                        </Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link
                          to="/"
                          role="menuitem"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="fab fa-twitter" aria-hidden="true" />
                        </Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link
                          to="/"
                          role="menuitem"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="fab fa-linkedin" aria-hidden="true" />
                        </Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link
                          to="/"
                          role="menuitem"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="fab fa-instagram" aria-hidden="true" />
                        </Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link
                          to="/"
                          role="menuitem"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="fab fa-youtube" aria-hidden="true" />
                        </Link>
                      </li>
                      <li className="hs-menu-item hs-menu-depth-1" role="none">
                        <Link
                          to="/"
                          role="menuitem"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="fab fa-tiktok" aria-hidden="true" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Main Navbar */}
        <div className="navbar navbar-main navbar-expand-xl navbar-theme-default navbar-light">
          <div className="container-fluid p-0">
            <Link className="navbar-brand" style={{ width: "260px" }} to="/">
              <img
                className="navbar-brand-dark"
                src="images/"
                alt="PlaneLists.com Online"
                width={200}
                srcSet="images/logo.svg"
              />
              <img
                className="navbar-brand-light"
                src="images/logo.svg"
                alt="PlaneLists.com Real Estate Investment Software"
                width={311}
                srcSet="images/logo.svg"
              />
            </Link>
            <div
              className={`navbar-collapse collapse ${mobileMenu ? "show" : ""}`}
              id="navbar_global"
            >
              <div className="navbar-collapse-header">
                <div className="row">
                  <div className="col-6 collapse-brand">
                    <Link to="/" onClick={() => handleNavigation("/")}>
                      <img
                        src="images/logo.svg"
                        alt="PlaneLists.com Real Estate Investment Software"
                      />
                    </Link>
                  </div>
                  <div className="col-6 collapse-close">
                    <Link
                      to=""
                      role="button"
                      className="fa fa-times"
                      onClick={() => {
                        showMobileMenu(false)
                      }}
                    />
                  </div>
                </div>
              </div>
              <ul className="navbar-nav navbar-nav-hover navbar-align ml-auto plinmob">
                <li
                  className={`menui nav-item ${activeItem === "/" ? "active" : ""}`}
                  onClick={() => handleNavigation("/")}
                >
                  <Link
                    to="/"
                    state={`Welcome to the ${pathname.substring(1)} page`}
                    className="nav-link headerFontSize  colinmob  text-left"
                    onClick={scrollToTop}
                    role="button" 
                  >
                    Home
                  </Link>
                </li>

                {/* <li className="menui nav-item planelists-dataBase"  onClick={() => handleNavigation('/plane-lists-databases')}>
                  <div className="dropdown">
                    <Link
                      to="/plane-lists-databases"
                      style={{ color: "white" }}
                      onClick={() => handleNavigation('/plane-lists-databases')}
                    >
                      <div
                        className="dropdown-toggle"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={scrollToTop}
                      >
                        PlaneLists.com Databases
                      </div>
                    </Link>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      
                      <Link
                        to="/US-Aircraft-Owners"
                        className="dropdown-item"
                        style={{ backgroundColor: "black" }}
                        onClick={scrollToTop}
                      >
                        US Aircraft Owners
                      </Link>
                      <Link
                        to="/AMEs"
                        className="dropdown-item"
                        style={{ backgroundColor: "black" }}
                        onClick={scrollToTop}
                      >
                        AMEs
                      </Link>
                      <Link
                        to="/active-airmen-pilots"
                        className="dropdown-item"
                        style={{ backgroundColor: "black" }}
                        onClick={scrollToTop}
                      >
                        Active Airmen & Pilots
                      </Link>
                      <Link
                        to="/historical-data"
                        className="dropdown-item"
                        style={{ backgroundColor: "black" }}
                        onClick={scrollToTop}
                      >
                        Historical Data
                      </Link>
                      <Link
                        to="/marketing-genius"
                        className="dropdown-item"
                        style={{ backgroundColor: "black" }}
                        onClick={scrollToTop}
                      >
                        Marketing Genius
                      </Link>
                    </div>
                  </div>
                </li> */}
                {/* <li className="menui nav-item planelists-dataBase p-0">
                <div className="dropdown d-flex pr-3 flex-column">
             
                    <div
                      className="dropdown-toggle"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      // onClick={() => {
                      //   scrollToTop();
                      //   handleNavigation('/plane-lists-databases');
                      // }}
                    >
                     AME Patient Leads
                    </div>
                 
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <Link to="/ame-map"
                      className="dropdown-item"
                      onClick={() => {
                        scrollToTop();
                        handleNavigation('/ame-map');
                      }}
                      style={{ backgroundColor: "black" }}
                    >
                    AME Map
                    </Link>
                    <Link to="/US-Aircraft-Owners"
                      className="dropdown-item"
                      onClick={() => {
                        scrollToTop();
                        handleNavigation('/US-Aircraft-Owners');
                      }}
                      style={{ backgroundColor: "black" }}
                    >
                    US Aircraft Owners
                    </Link>
                    <Link to="/AMEs"
                      className="dropdown-item"
                      onClick={() => {
                        scrollToTop();
                        handleNavigation('/AMEs');
                      }}
                      style={{ backgroundColor: "black" }}
                    >
                    AMEs
                    </Link>
                    <Link  to="/active-airmen-pilots"
                      className="dropdown-item"
                      onClick={() => {
                        scrollToTop();
                        handleNavigation('/active-airmen-pilots');
                      }}
                      style={{ backgroundColor: "black" }}
                    >
                 Active Airmen & Pilots
                    </Link>
                    <Link to="/historical-data"
                      className="dropdown-item"
                      onClick={() => {
                        scrollToTop();
                        handleNavigation('/historical-data');
                      }}
                      style={{ backgroundColor: "black" }}
                    >
                         Historical Data
                    </Link>
                    <Link to="/marketing-genius"
                      className="dropdown-item"
                      onClick={() => {
                        scrollToTop();
                        handleNavigation('/marketing-genius');
                      }}
                      style={{ backgroundColor: "black" }}
                      onChange={scrollToTop}
                    >
                   Marketing Genius
                    </Link>
                  </div>
                </div>
              </li> */}

                <li
                  className= {`menui nav-item ${
                    activeItem === "/ame-map" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/ame-map")}
                >
                  <Link
                    to="/ame-map"
                    className= "nav-link headerFontSize  colinmob  text-left "
                    onClick={scrollToTop}
                    
                  >
                    AME Patient Leads
                  </Link>
                </li>
                {/* <li className="menui nav-item  ">
                  <Link
                    to="/subscriptions-list"
                    state={`Welcome to the ${pathname.substring(1)} page`}
                    className="nav-link"
                    onClick={scrollToTop}
                  >
                    Subscriptions Lists
                  </Link>
                </li> */}

                <li
                  className={`menui nav-item ${
                    activeItem === "/aviation-research-services" ? "active" : ""
                  }`}
                  onClick={() =>
                    handleNavigation("/aviation-research-services")
                  }
                >
                  <Link
                    to="/aviation-research-services"
                    className="nav-link headerFontSize  colinmob  text-left"
                    onClick={scrollToTop}
                  >
                    Off-Market Aircraft Leads
                  </Link>
                </li>
                {status ?
                <li
                className={`menui nav-item ${
                  activeItem === "/quick-search" ? "active" : ""
                }`}
                onClick={() => handleNavigation("/quick-search")}
                style={{ backgroundColor: "black" }}
              >
                <Link
                  to="/quick-search"
                  className="nav-link headerFontSize  colinmob  text-left"
                  onClick={scrollToTop}
                  style={{ backgroundColor: "black" }}
                >
                  Quick Search
                </Link>
              </li>:
              <li
              className={`menui nav-item `}
              onClick={() => handleNavigation("/login")}
              style={{ backgroundColor: "black" }}
            >
              <Link
                to="/login"
                className="nav-link headerFontSize  colinmob  text-left"
                onClick={scrollToTop}
                style={{ backgroundColor: "black" }}
              >
                Quick Search
              </Link>
            </li>
                }
                  {status ?
                <li
                className={`menui nav-item ${
                  activeItem === "/create-postcard" ? "active" : ""
                }`}
                onClick={() => handleNavigation("/create-postcard")}
              >
                <Link
                  to="/create-postcard"
                  className="nav-link headerFontSize  colinmob  text-left"
                  onClick={scrollToTop}
                >
                Postcard
                </Link>
              </li>:
              <li
              className={`menui nav-item `}
              onClick={() => handleNavigation("/login")}
            >
              <Link
                to="/login"
                className="nav-link headerFontSize  colinmob  text-left"
                onClick={scrollToTop}
              >
               Postcard
              </Link>
            </li>
                }
                {/* <li
                  className={`menui nav-item ${
                    activeItem === "/aviation-list-services" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/aviation-list-services")}
                >
                  <Link
                    to="/aviation-list-services"
                    className="nav-link headerFontSize  colinmob  text-left"
                    onClick={scrollToTop}
                  >
                    Aviation List and Label Services
                  </Link>
                </li> */}
                {/* <li className="menui nav-item  "  onClick={() => handleNavigation('/aviation-research-services')}>
                  <Link
                    to="/aviation-research-services"
                    className="nav-link"
                    onClick={scrollToTop}
                  >
                    Aviation Research Services
                  </Link>
                </li> */}
                {/* <li className="menui nav-item  "  onClick={() => handleNavigation('/ame-map')}>
                  <Link
                    to="/ame-map"
                    className="nav-link"
                    onClick={scrollToTop}
                  >
                    AME Map
                  </Link>
                </li> */}
                {/* topbar left menu */}
                {/* <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    <i className="fa fa-envelope" aria-hidden="true" />{" "}
                    Support@PlaneLists.com
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    <i className="fa fa-clock-o" aria-hidden="true" /> Mon -
                    Fri: 6:00 AM - 6:00 PM PT
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    <i className="fa fa-phone" aria-hidden="true" /> Toll Free
                    (877) 204-9040
                  </Link>
                </li> */}
                {/* topbar right menu */}
                {/* <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    Free Trial
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    Activate
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    Community
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    <i className="fa fa-facebook"></i>
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    <i className="fa fa-square-twitter" />
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    <i className="fa fa-linkedin" />
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    <i className="fa fa-instagram" />
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    <i className="fa fa-youtube" />
                  </Link>
                </li>
                <li className="menui nav-item mobile-only">
                  <Link to=""  className="nav-link">
                    <i className="fa fa-tiktok" />
                  </Link>
                </li> */}
                <li
                  className={`menui nav-item d-none ${
                    activeItem === "/search" ? "active" : ""
                  }`}
                  onClick={() => handleNavigation("/search")}
                >
                  <div className="search">
                    <span className="nav-link sr-search-toggle">
                      {/* d-flex align-items-baseline */}
                      <Link
                        to="/search"
                        className="show-search "
                        onClick={scrollToTop}
                      >
                        <i className="fa fa-search" aria-hidden="true" />
{/* 
                        <span style={{ paddingLeft: "0.5rem" }}>Search</span> */}
                      </Link>
                    </span>
                    {/* <div className="navbar-search-form-wrapper active">
                      <div className="hs-search-field">
                        <div className="hs-search-field__bar">
                          <form action="https://www.planelists.com/hs-search-results">
                            <input
                              type="text"
                              className="hs-search-field__input form-control"
                              name="term"
                              autoComplete="off"
                              aria-label="Search"
                              
                            />
                          </form>
                        </div>
                        
                      </div>
                    </div> */}
                  </div>
                </li>

                {status? (
                  //  after login
                  <>
                    <li className="menui nav-item planelists-dataBase ptn-2">
                      <div className="dropdown d-flex pr-3 flex-column ">
                        <div
                          className="dropdown-toggle myaccFontSize"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          // onClick={() => setSubMobileMenu(!subMobileMenu)}
                        >
                          <img
                            src="images/AdminImages/myAccount-icon.svg"
                            className="pr-2 icon-show"
                          />
                          My Account
                        </div>
                        <div
                          className={`dropdown-menu ${subMobileMenu || mobileMenu ? "show" : ""}`}
                          aria-labelledby="dropdownMenuButton"
                        >
                          <Link
                            to="/user-dashboard"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/user-dashboard");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                             Dashboard
                          </Link>

                          <Link
                            to="/airmenrecords"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/airmenrecords");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                            Airmen Records
                          </Link>
                          {/* <Link
                            to="/userdashboard"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/userdashboard");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                            User Dashboard
                          </Link> */}
                         
                          <Link
                            to="/aircraftrecords"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/aircraftrecords");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                           Aircraft Records
                          </Link>
                          {/* <Link
                            to="/create-postcard"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/create-postcard");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                            Create Postcard
                          </Link> */}

                          {/* <Link
                            to="/your-payment-history"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/your-payment-history");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                            Payment History
                          </Link> */}
                          <Link
                            to="/your-downloads-history"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/your-downloads-history");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                            Download History
                          </Link>
                          <Link
                            to="/subscriptions-list"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/subscriptions-list");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                            Subscription Info
                          </Link>
                          {/* <Link
                            to="/subscriptions-list"
                            className="dropdown-item colinmob text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/subscriptions-list");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                            Cancel Subscription
                          </Link> */}
                          <Link
                            to="/change-password"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              scrollToTop();
                              handleNavigation("/change-password");
                            }}
                            style={{ backgroundColor: "black" }}
                          >
                            Change Password
                          </Link>
                          <Link
                            to="/"
                            className="dropdown-item colinmob  text-left"
                            onClick={() => {
                              logOut();
                              scrollToTop();
                              handleNavigation("/subscriptions-list");
                            }}
                            style={{ backgroundColor: "black" }}
                            onChange={scrollToTop}
                          >
                            Logout
                          </Link>
                        </div>
                      </div>
                    </li>
                  </>
                ) : (
                 
                  <>
                    <li className="d-flex align-items-center">
                      <div className="btn-wrapper btn-wrapper-sm btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                        <Link
                          to="/login"
                          className="custom-login-btn"
                          onClick={() => {
                            scrollToTop();
                            handleNavigation("/login");
                          }}
                        >
                          Login
                        </Link>
                      </div>
                    </li>
                    {/*   7 day free trial  */}
                    <li className="d-flex align-items-center">
                      <div className="btn-wrapper btn-wrapper-sm btn-primary-wrapper d-xl-inline mt-3 mt-xl-0 ml-xl-3">
                        <Link to="/register" className="custom-freeTrial-btn"
                               onClick={() => {
                                scrollToTop();
                                handleNavigation("/register");
                              }}>
                          7-Day Free Trial
                        </Link>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="d-flex d-xl-none align-items-center">
              <button
                onClick={() => {
                  showMobileMenu(true);
                }}
                className="navbar-toggler"
                type="button"
              >
                <span className="navbar-toggler-icon">
                  <svg
                    className="toggler"
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeMiterlimit={10}
                      d="M4 7h22M4 15h22M4 23h22"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
    </>
  );
};

export default HeaderPage;

