import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BiBuildings } from "react-icons/bi";
import { FaWhatsapp, FaPhoneAlt } from "react-icons/fa";
import "./contact.css";
import MyMap from "./MyMap";
import Iframe from "react-iframe";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { useAppSelector } from "../../../hook";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, Form, Field, ErrorMessage } from "formik";

export const Contact = () => {
  const [token, setToken] = useState("");
  const status = useAppSelector((state) => state.auth.status);
  const iconSize = 40;
  const iconS = 25;

  const handleRecaptchaChange = (token: string) => {
    console.log("Recaptcha token:", token);
    setToken(token);
    // Add any logic for handling reCAPTCHA verification
  };
  return (
    <>
    
    <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center   prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="sr-multicol-media bg-black sr-padding-half text-center prototype-no-background 
        widget_1604012269835 text-white"
        id="research-property"
      >
        <div className="container-fluid content-wrapper">
          <div className="row justify-content-center ">
            <div className="col-md-10">
              <h2 className=" section-heading font-inter">
                Contact US
              </h2>
              <div className="row  sr-valign-center">
                <div className="col-md-6 sr-col text-left  sr-repeater-1">
                  <div className="container">
                    <h2 className=" section-heading   text-center text-white">
                      Questions? We are here to help!
                    </h2>
                    <p className="text-white">
                      <b>
                        If you would like a personalized tour of the system; or
                        have general questions; please contact us at any time.
                      </b>
                    </p>
                    <div className="content-wrapper">
                      <Row>
                        <Col md={5}>
                          <p className=" text-white">
                            <BiBuildings size={iconSize} />
                            <br />
                            <b>
                              Mailing Address
                              <br />
                              PlaneLists.com
                              <br />
                              336 Airport Road
                              <br />
                              Ridgeland, SC 29936
                            </b>
                          </p>
                        </Col>
                        <Col md={6}>
                          <p className=" text-white">
                            <FaWhatsapp size={iconS} />{" "}
                            <b>Planelists.com</b>
                          </p>
                          <p className=" text-white">
                            <FaPhoneAlt size={iconS} />{" "}
                            <b>(843) 321-8973</b>
                          </p>
                          <p className=" text-white">
                            <b>subscribe@planelists.com</b>
                          </p>
                        </Col>
                      </Row>

                      <p className=" text-white">
                        <b>
                          Hours of operation:
                          <br />
                          0900- 1700 Eastern Time, Monday through Friday
                          (generally...)
                        </b>
                      </p>

                      <div className=" pb-1 pt-4 pb-md-5 d-flex justify-content-center align-items-center ">
                        <div className=" pt-md-3  custom-shadow ">
                          <div className="row d-flex flex-column  justify-content-start  
                          align-items-sm-center flex-xs-column flex-sm-column flex-md-row">
                            <div className="  p-4 d-flex justify-content-start">
                              <Formik
                                initialValues={{
                                  firstName: "",
                                  lastName: "",
                                  message: "",
                                  tokencap: "",
                                }}
                                onSubmit={(values, actions) => {
                                  // Handle form submission here
                                  console.log(values);
                                  console.log("Recaptcha token:", values.tokencap);
                                  actions.setSubmitting(false);
                                }}
                              >
                                {(formikProps) => (
                                  <Form >
                                    <div className="row ">
                                      <div className="col-lg-6 col-md-6 col-sm-10 col-10 ">
                                        <div className="form-group">
                                          <Field
                                            type="text"
                                            name="firstName"
                                            className="custom-form-control w-100  p-0 pt-3 pb-1 bg-black text-white"
                                            placeholder="First Name"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-10 col-10 ">
                                        <div className="form-group">
                                          <Field
                                            type="text"
                                            name="lastName"
                                            className="custom-form-control w-100 p-0 pt-3 pb-1 bg-black text-white"
                                            placeholder="Last Name"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mb-1  form-floating">
                                      <Field
                                        as="textarea"
                                        name="message"
                                        className="form-control bg-black text-white"
                                        placeholder="Your message here..."
                                        style={{ height: "90px" }}
                                      />
                                    </div>

                                    <div className="row mb-3  d-flex justify-content-center ">
                                      <div className="g-recaptcha col-12 ml-2">
                                        <ReCAPTCHA
                                          sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                          onChange={(token) =>
                                            formikProps.setFieldValue("tokencap", token)
                                          }
                                        />
                                      </div>
                                      <ErrorMessage name="tokencap" component="div" className="text-danger" />
                                    </div>

                                    <button
                                      type="submit"
                                      className="btn justify-content-center"
                                      style={{
                                        backgroundColor: "#FF7125",
                                        color: "#FFFFFF",
                                        textAlign: "center",
                                        display: "block",
                                        border: "none",
                                        marginTop: "15px",
                                        borderRadius: "8px",
                                        width: "90px",
                                        height: "36px",
                                      }}
                                      disabled={formikProps.isSubmitting}
                                    >
                                      <span className="text-white">Send</span>
                                    </button>
                                  </Form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 sr-col  sr-repeater-2 h-100 d-flex justify-content-center ">
                  <div className=" ">
                    
 <Iframe
                      url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3365.2773874487975!2d-80.99345422552769!3d32.49201449849858!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fbefccdb82a00f%3A0x24b83f6ec7ce51d6!2sPlaneLists.com!5e0!3m2!1sen!2sin!4v1707132449244!5m2!1sen!2sin"
                      width="640px"
                      frameBorder={0}
                      id=""
                      className="contact-iframe "
                      display="block"
                      position="relative"
                    />


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {status ? "" : <FreeTrial />}
    </>
  );
};
