import "../Admin/admin.css";
import { FreeTrial } from "../RepeatedComponent/FreeTrial";
import { useAppSelector } from "../../../hook";
import { Breadcrumbs } from "../RepeatedComponent/Breadcrumb";

export const PrivacyPolicy = () => {

  const status = useAppSelector((state) => state.auth.status);

  return (
    <>
     <div className="sr-multicol-media bg-black pt-1 pt-lg-4 text-center   prototype-no-background widget_1600979911688 ">
        <div className="container-fluid content-wrapper ">
          <div className="row d-flex justify-content-center pt-1 pt-md-3 pt-lg-5">
            <div className="col-md-10">
              <Breadcrumbs textColor={"text-white"} />
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs_cos_wrapper_widget_1604012269835"
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
        style={{}}
        data-hs-cos-general-type="widget"
        data-hs-cos-type="module"
      >
        {/* Privacy Policy content */}
        <div
          className="sr-multicol-media bg-black sr-padding-half text-center text-white
          prototype-no-background widget_1604012269835 "
          id="research-property"
        >
          <div className="container-fluid content-wrapper">
            <div className="row">
              <div className="col-md-10 offset-md-1">
              <h2 className=" section-heading font-inter">
              Privacy Policy
                </h2>
                <div className="row  sr-valign-center">
                  {/* Introduction */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-1">
                    <div className="content-wrapper ">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row ">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center 
                          mt-3 pt-2 pb-2 pl-0 ">
                            <div className="border px-4 py-2 privacy-para-header">
                              Introduction
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              Leading Edge Group, LLC, dba PlaneLists.com, (“we”
                              or “us” or “our”) respects the privacy of our
                              users (“user” or “you”). This Privacy Policy
                              explains how we collect, use, disclose, and
                              safeguard your information when you visit our
                              website PlaneLists.com, including any other media
                              form, media channel, mobile website, or mobile
                              application related or connected thereto
                              (collectively, the “Site”). Please read this
                              privacy policy carefully. If you do not agree with
                              the terms of this privacy policy, please do not
                              access the site.
                            </p>
                            <p>
                              We reserve the right to make changes to this
                              Privacy Policy at any time and for any reason. We
                              will alert you about any changes by updating the
                              “Last Updated” date of this Privacy Policy. Any
                              changes or modifications will be effective
                              immediately upon posting the updated Privacy
                              Policy on the Site, and you waive the right to
                              receive specific notice of each such change or
                              modification.
                            </p>
                            <p>
                              You are encouraged to periodically review this
                              Privacy Policy to stay informed of updates. You
                              will be deemed to have been made aware of, will be
                              subject to, and will be deemed to have accepted
                              the changes in any revised Privacy Policy by your
                              continued use of the Site after the date such
                              revised Privacy Policy is posted.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Collection of Your Information */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Collection of Your Information
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              We may collect information about you in a variety
                              of ways. The information we may collect on the
                              Site includes:
                            </p>

                            <p>
                              <strong>Personal Data</strong>
                              <br></br>Personally identifiable information, such
                              as your name, shipping address, email address, and
                              telephone number, and demographic information,
                              such as your age, gender, hometown, and interests,
                              that you voluntarily give to us when you register
                              with the Site or when you choose to participate in
                              various activities related to the Site, such as
                              online chat and message boards. You are under no
                              obligation to provide us with personal information
                              of any kind, however your refusal to do so may
                              prevent you from using certain features of the
                              Site.
                            </p>

                            <p>
                              <strong>Derivative Data</strong>
                              <br></br>Information our servers automatically
                              collect when you access the Site, such as your IP
                              address, your browser type, your operating system,
                              your access times, and the pages you have viewed
                              directly before and after accessing the Site. If
                              you are using our mobile application, this
                              information may also include your device name and
                              type, your operating system, your phone number,
                              your country, your likes and replies to a post,
                              and other interactions with the application and
                              other users via server log files, as well as any
                              other information you choose to provide.
                            </p>

                            <p>
                              <strong>Financial Data</strong>
                              <br></br>Financial information, such as data
                              related to your payment method (e.g. valid credit
                              card number, card brand, expiration date) that we
                              may collect when you purchase, order, return,
                              exchange, or request information about our
                              services from the Site [or our mobile
                              application]. [We store only very limited, if any,
                              financial information that we collect. Otherwise,
                              all financial information is stored by our payment
                              processor, Paypal, and you are encouraged to
                              review their privacy policy and contact them
                              directly for responses to your questions.
                            </p>

                            <p>
                              <strong>Facebook Permissions</strong>
                              <br></br>The Site may by default access your
                              Facebook basic account information, including your
                              name, email, gender, birthday, current city, and
                              profile picture URL, as well as other information
                              that you choose to make public. We may also
                              request access to other permissions related to
                              your account, such as friends, check-ins, and
                              likes, and you may choose to grant or deny us
                              access to each individual permission. For more
                              information regarding Facebook permissions, refer
                              to the Facebook Permissions Reference page.
                            </p>
                            <p>
                              <strong>Data From Social Networks</strong>
                              <br></br>User information from social networking
                              sites, such as [Apple’s Game Center, Facebook,
                              Google+, Instagram, Pinterest, Twitter], including
                              your name, your social network username, location,
                              gender, birth date, email address, profile picture
                            </p>
                            <p>
                              <strong>Mobile Device Data</strong>
                              <br></br>Device information, such as your mobile
                              device ID, model, and manufacturer, and
                              information about the location of your device, if
                              you access the Site from a mobile device.
                            </p>
                            <p>
                              <strong>Third-Party Data</strong>
                              <br></br>nformation from third parties, such as
                              personal information or network friends, if you
                              connect your account to the third party and grant
                              the Site permission to access this information.
                            </p>
                            <p>
                              <strong>
                                Data from Contests, Giveaways, and Surveys
                              </strong>
                              <br></br>Personal and other information you may
                              provide when entering contests or giveaways and/or
                              responding to surveys.
                            </p>
                            <p>
                              <strong>Mobile Application Information</strong>
                              <br></br>If you connect using our mobile
                              application:
                            </p>
                            <div className="container unorder-list">
                              <ul>
                                <p>
                                  <li>
                                    Geo-Location Information. We may request
                                    access or permission to and track
                                    location-based information from your mobile
                                    device, either continuously or while you are
                                    using our mobile application, to provide
                                    location-based services. If you wish to
                                    change our access or permissions, you may do
                                    so in your device’s settings.
                                  </li>
                                </p>
                                <p>
                                  <li>
                                    Mobile Device Access. We may request access
                                    or permission to certain features from your
                                    mobile device, including your mobile
                                    device’s [bluetooth, calendar, camera,
                                    contacts, microphone, reminders, sensors,
                                    SMS messages, social media accounts,
                                    storage,] and other features. If you wish to
                                    change our access or permissions, you may do
                                    so in your device’s settings.
                                  </li>
                                </p>
                                <p>
                                  <li>
                                    Mobile Device Data. We may collect device
                                    information (such as your mobile device ID,
                                    model and manufacturer), operating system,
                                    version information and IP address.
                                  </li>
                                </p>
                                <p>
                                  <li>
                                    Push Notifications. We may request to send
                                    you push notifications regarding your
                                    account or the Application. If you wish to
                                    opt-out from receiving these types of
                                    communications, you may turn them off in
                                    your device’s settings.
                                  </li>
                                </p>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Use of Your Information */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Use of Your Information
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              Having accurate information about you permits us
                              to provide you with a smooth, efficient, and
                              customized experience. Specifically, we may use
                              information collected about you via the Site [or
                              our mobile application] to:
                            </p>

                            <div className="container unorder-list">
                              <ul>
                                <li>
                                  Administer sweepstakes, promotions, and
                                  contests.
                                </li>
                                <li>
                                  Assist law enforcement and respond to
                                  subpoena.
                                </li>
                                <li>
                                  Compile anonymous statistical data and
                                  analysis for use internally or with third
                                  parties.
                                </li>
                                <li>Create and manage your account.</li>
                                <li>
                                  Deliver targeted advertising, coupons,
                                  newsletters, and other information regarding
                                  promotions and the Site [and our mobile
                                  application] to you.
                                </li>
                                <li>
                                  Email you regarding your account or order.
                                </li>
                                <li>Enable user-to-user communications.</li>
                                <li>
                                  Fulfill and manage purchases, orders,
                                  payments, and other transactions related to
                                  the Site [and our mobile application].
                                </li>
                                <li>
                                  Generate a personal profile about you to make
                                  future visits to the Site [and our mobile
                                  application] more personalized.
                                </li>
                                <li>
                                  Increase the efficiency and operation of the
                                  Site [and our mobile application].
                                </li>
                                <li>
                                  Monitor and analyze usage and trends to
                                  improve your experience with the Site [and our
                                  mobile application].
                                </li>
                                <li>
                                  Notify you of updates to the Site [and our
                                  mobile application]s.
                                </li>
                                <li>
                                  Offer new products, services, [mobile
                                  applications,] and/or recommendations to you
                                </li>
                                <li>
                                  Perform other business activities as needed.{" "}
                                </li>
                                <li>
                                  Prevent fraudulent transactions, monitor
                                  against theft, and protect against criminal
                                  activity.{" "}
                                </li>
                                <li>Process payments and refunds </li>
                                <li>
                                  Request feedback and contact you about your
                                  use of the Site [and our mobile application].{" "}
                                </li>
                                <li>
                                  Resolve disputes and troubleshoot problems.{" "}
                                </li>
                                <li>
                                  Respond to product and customer service
                                  requests.{" "}
                                </li>
                                <li>Send you a newsletter. </li>
                                <li>Solicit support for the Site </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  Disclosure of Your Information */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Disclosure of Your Information
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              We may share information we have collected about
                              you in certain situations. Your information may be
                              disclosed as follows:
                            </p>
                            <p>
                              <strong>By Law or to Protect Rights</strong>
                              <br></br>If we believe the release of information
                              about you is necessary to respond to legal
                              process, to investigate or remedy potential
                              violations of our policies, or to protect the
                              rights, property, and safety of others, we may
                              share your information as permitted or required by
                              any applicable law, rule, or regulation. This
                              includes exchanging information with other
                              entities for fraud protection and credit risk
                              reduction.
                            </p>
                            <p>
                              <strong>Third-Party Service Providers</strong>
                              <br></br>We may share your information with third
                              parties that perform services for us or on our
                              behalf, including payment processing, data
                              analysis, email delivery, hosting services,
                              customer service, and marketing assistance..
                            </p>

                            <p>
                              <strong>Marketing Communications</strong>
                              <br></br>With your consent, or with an opportunity
                              for you to withdraw consent, we may share your
                              information with third parties for marketing
                              purposes, as permitted by law.
                            </p>

                            <p>
                              <strong>Interactions with Other Users</strong>
                              <br></br>If you interact with other users of the
                              Site those users may see your name, profile photo,
                              and descriptions of your activity, including
                              sending invitations to other users, chatting with
                              other users, liking posts, following blogs.
                            </p>

                            <p>
                              <strong>Online Postings</strong>
                              <br></br>When you post comments, contributions or
                              other content to the Site, your posts may be
                              viewed by all users and may be publicly
                              distributed outside the Site in perpetuity.
                            </p>

                            <p>
                              <strong>Third-Party Advertisers</strong>
                              <br></br>We may use third-party advertising
                              companies to serve ads when you visit the Site [or
                              our mobile application]. These companies may use
                              information about your visits to the Site [and our
                              mobile application] and other websites that are
                              contained in web cookies in order to provide
                              advertisements about goods and services of
                              interest to you.
                            </p>

                            <p>
                              <strong>Affiliates</strong>
                              <br></br>We may share your information with our
                              affiliates, in which case we will require those
                              affiliates to honor this Privacy Policy.
                              Affiliates include our parent company and any
                              subsidiaries, joint venture partners or other
                              companies that we control or that are under common
                              control with us.
                            </p>

                            <p>
                              <strong>Business Partners</strong>
                              <br></br>We may share your information with our
                              business partners to offer you certain products,
                              services or promotions.
                            </p>

                            <p>
                              <strong>Social Media Contacts</strong>
                              <br></br>If you connect to the Site through a
                              social network, your contacts on the social
                              network will see your name, profile photo, and
                              descriptions of your activity.
                            </p>

                            <p>
                              <strong>Other Third Parties</strong>
                              <br></br>We may share your information with
                              advertisers and investors for the purpose of
                              conducting general business analysis. We may also
                              share your information with such third parties for
                              marketing purposes, as permitted by law.
                            </p>

                            <p>
                              <strong>Sale or Bankruptcy</strong>
                              <br></br>If we reorganize or sell all or a portion
                              of our assets, undergo a merger, or are acquired
                              by another entity, we may transfer your
                              information to the successor entity. If we go out
                              of business or enter bankruptcy, your information
                              would be an asset transferred or acquired by a
                              third party. You acknowledge that such transfers
                              may occur and that the transferee may decline
                              honor commitments we made in this Privacy Policy.
                            </p>
                            <p>
                              We are not responsible for the actions of third
                              parties with whom you share personal or sensitive
                              data, and we have no authority to manage or
                              control third-party solicitations. If you no
                              longer wish to receive correspondence, emails or
                              other communications from third parties, you are
                              responsible for contacting the third party
                              directly.
                            </p>
                            {/* 
                            <div className="container unorder-list">
                              <ul>
                                <p>
                                  <li>
                                    Geo-Location Information. We may request
                                    access or permission to and track
                                    location-based information from your mobile
                                    device, either continuously or while you are
                                    using our mobile application, to provide
                                    location-based services. If you wish to
                                    change our access or permissions, you may do
                                    so in your device’s settings.
                                  </li>
                                </p>
                                <p>
                                  <li>
                                  Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device’s [bluetooth, calendar, camera, contacts, microphone, reminders, sensors, SMS messages, social media accounts, storage,] and other features. If you wish to change our access or permissions, you may do so in your device’s settings.
                                  </li>
                                </p>
                                <p>
                                  <li>
                                  Mobile Device Data. We may collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and IP address.
                                  </li>
                                </p>
                                <p>
                                  <li>
                                  Push Notifications. We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.
                                  </li>
                                </p>
                              </ul>
                            </div>
                             */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Tracking Technologies */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Tracking Technologies
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              <strong>Cookies and Web Beacons</strong>
                              <br></br>We may use cookies, web beacons, tracking
                              pixels, and other tracking technologies on the
                              Site to help customize the Site and improve your
                              experience. When you access the Site your personal
                              information is not collected through the use of
                              tracking technology. Most browsers are set to
                              accept cookies by default. You can remove or
                              reject cookies, but be aware that such action
                              could affect the availability and functionality of
                              the Site You may not decline web beacons. However,
                              they can be rendered ineffective by declining all
                              cookies or by modifying your web browser’s
                              settings to notify you each time a cookie is
                              tendered, permitting you to accept or decline
                              cookies on an individual basis.
                            </p>
                            <p>
                              We may use cookies, web beacons, tracking pixels,
                              and other tracking technologies on the Site, to
                              help customize the Site and improve your
                              experience. For more information on how we use
                              cookies, please refer to our Cookie Policy posted
                              on the Site, which is incorporated into this
                              Privacy Policy. By using the Site, you agree to be
                              bound by our Cookie Policy.
                            </p>
                            <p>
                              <strong>Internet-Based Advertising</strong>
                              <br></br>Additionally, we may use third-party
                              software to serve ads on the Site [and our mobile
                              application], implement email marketing campaigns,
                              and manage other interactive marketing
                              initiatives. This third-party software may use
                              cookies or similar tracking technology to help
                              manage and optimize your online experience with
                              us. For more information about opting-out of
                              interest-based ads, visit the Network Advertising
                              Initiative Opt-Out Tool or Digital Advertising
                              Alliance Opt-Out Tool.
                            </p>

                            <p>
                              <strong>Website Analytics</strong>
                              <br></br>We may also partner with selected
                              third-party vendors[, such as Google Analytics,
                              and others, to allow tracking technologies and
                              remarketing services on the Site, through the use
                              of first party cookies and third-party cookies,
                              to, among other things, analyze and track users’
                              use of the Site, determine the popularity of
                              certain content and better understand online
                              activity. By accessing the Site, you consent to
                              the collection and use of your information by
                              these third-party vendors. You are encouraged to
                              review their privacy policy and contact them
                              directly for responses to your questions. We do
                              not transfer personal information to these
                              third-party vendors. However, if you do not want
                              any information to be collected and used by
                              tracking technologies, you can visit the
                              third-party vendor or the Network Advertising
                              Initiative Opt-Out Tool or Digital Advertising
                              Alliance Opt-Out Tool.
                            </p>

                            <p>
                              <br></br>You should be aware that getting a new
                              computer, installing a new browser, upgrading an
                              existing browser, or erasing or otherwise altering
                              your browser’s cookies files may also clear
                              certain opt-out cookies, plug-ins, or settings.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Third-Party Websites */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                              Tracking Technologies
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                              The Site may contain links to third-party websites and applications of interest, including advertisements and external services that are not affiliated with us. Once you have used these links to leave the Site any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site.
                            </p> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Security of Your Information */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                            Security of Your Information
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                            We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.
                            </p> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Policy for Children */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                            Policy for Children
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                            We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.
                            </p> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Controls for Do-Not-Track Features */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                            Controls for Do-Not-Track Features
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                            Most web browsers and some mobile operating systems include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy./Most web browsers and some mobile operating systems [and our mobile applications] include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. If you set the DNT signal on your browser, we will respond to such DNT browser signals.
                            </p> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Options Regarding Your Informtion */}
                  
                             <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                            Options Regarding Your Informtion
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                        <p>
                          <strong>Account Information</strong>
                          <br></br>
                          You may at any time review or change the information in your account or terminate your account by:
                        </p>
                          <div className="container unorder-list">
                              <ul>
                                  <li>
                                  Logging into your account settings and updating your account
                                  </li>
                                  <li>
                                  Contacting us using the contact information provided below
                                  </li>
                                  <li>
                                  Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
                                  </li>
                              </ul>
                            </div>

                            <p>
                          <strong>Emails and Communications</strong>
                          <br></br>
                          If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:
                        </p>
                          <div className="container unorder-list">
                              <ul>
                                  <li>
                                  Noting your preferences at the time you register your account with the Site
                                  </li>
                                  <li>
                                  Logging into your account settings and updating your preferences.
                                  </li>
                                  <li>
                                  Contacting us using the contact information provided below
                                  </li>
                                  <li>
                                  If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.
                                  </li>
                              </ul>
                            </div>
                             
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* California Privacy Rights */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                            California Privacy Rights
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                            California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                            </p> 
                            <p>
                            If you are under 18 years of age, reside in California, and have a registered account with the Site, you have the right to request removal of unwanted data that you publicly post on the Site. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Site, but please be aware that the data may not be completely or comprehensively removed from our systems.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Contact Us */}
                  <div className="col-md-12 sr-col text-left  sr-repeater-1 pt-5">
                    <div className="content-wrapper">
                      <div className="container custom-para-shadow bg-black border text-white">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-md-start justify-content-sm-center mt-3 pt-2 pb-2 pl-0">
                            <div className="border px-4 py-2 privacy-para-header">
                            Contact Us
                            </div>
                          </div>
                        </div>
                        <div className="row  justify-content">
                          <div className="col-12 px-4 py-4">
                            <p>
                            If you have questions or comments about this Privacy Policy, please contact us at:
                            </p> 
                            <p>
                              <ul>
                                <li>Leading Edge Group, LLC</li>
                                <li>336 Airport RoadRidgeland, SC 29936</li>
                                <li>704-608-5012</li>
                                <li>Fax-Number - xxxxxxxxx</li>
                                <li>Email - lists@planelists.com</li>
                              </ul>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
           status ?"":
           
          //  7-day free trial component 
          <FreeTrial/>
         }
      </div>
    </>
  );
};
