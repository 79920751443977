import { createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import type { RootState } from "../store";
import apiClient from "./FetchClient";

interface downloadHistory {
    download_history:[]
}

const initialState: downloadHistory = {
    download_history:[]
};

// ------- Download History -----
export const downloadHistory = createAsyncThunk(
  "downloadHistory/history",
  async (body,  thunkAPI) => {
    // console.log(body,"data")
    try {
    const authToken = localStorage.getItem("_authToken");
    if (!authToken) {
        // Handle the case where auth token is not available
        throw new Error("Authentication token not found");
      }

      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/user_download_history`,
      );
    
      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {

      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- get_pilot_states  -----
export const getPilotStates = createAsyncThunk(
  "downloadHistory/states",
  async (body,  thunkAPI) => {
    // console.log(body,"data")
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/get_pilot_states`,
      );
    
      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {

      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- Aircraft Manufacturer  -----
export const getAircraftManufacturer = createAsyncThunk(
  "downloadHistory/aircraftManufacturer",
  async (body,  thunkAPI) => {
    // console.log(body,"data")
    try {
      const response = await apiClient.get(
        `${process.env.REACT_APP_API_URL}/get_form_select_options`,
      );
    
      if (response.data.success) {
        return response.data;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {

      return thunkAPI.rejectWithValue({ success: false, error: error.message });
    }
  }
);

// ------- downloadHistorySlice----
export const downloadHistorySlice = createSlice({
  name: "downloadHistory",
  initialState,
  reducers: {
  },
  extraReducers: (builder:any) => {

    // Download History
    builder
      .addCase(downloadHistory.pending, (state:any) => {
        state.status = false;
      })
      .addCase(downloadHistory.fulfilled, (state:any, action:any) => {
        state.status = true;
        state.error = "";
        state.download_history=action.payload.download_history;
        state.response=action.meta.requestStatus;
      })
      .addCase(downloadHistory.rejected, (state: any, action:any) => {
        state.status = false;
        state.error = action.error.message;
      });
      
      // States
      builder
      .addCase(getPilotStates.pending, (state:any) => {
        state.status = false;
      })
      .addCase(getPilotStates.fulfilled, (state:any, action:any) => {
        state.status = true;
        state.error = "";
        state.states=action.payload.states;
        state.response=action.meta.requestStatus;
      })
      .addCase(getPilotStates.rejected, (state: any, action:any) => {
        state.status = false;
        state.error = action.error.message;
      });

       // getAircraftManufacturer
       builder
       .addCase(getAircraftManufacturer.pending, (state:any) => {
         state.status = false;
       })
       .addCase(getAircraftManufacturer.fulfilled, (state:any, action:any) => {
         state.status = true;
         state.error = "";
        //  state.states=action.payload.states;

        state.selectOption=action.payload.states;
        //  state.response=action.meta.requestStatus;
       })
       .addCase(getAircraftManufacturer.rejected, (state: any, action:any) => {
         state.status = false;
         state.error = action.error.message;
       });
  },
});

export const userList = (state: RootState) => state.downloadHistory;
export default downloadHistorySlice.reducer;